/* eslint-disable react/function-component-definition */
import { Box, Checkbox, SelectProps as BaseProps } from "@mui/material";
import { getDayLetters } from "@src/lib/Time";
import React from "react";
import withController from "../../withController";

type ManagedProps = "select" | "children" | "multiple";
export type Props = Omit<BaseProps, ManagedProps> & {
  onChange: (v: number[]) => void;
  value: number[];
  disabled?: boolean;
};
const DAY_LETTERS = getDayLetters();

/**
 * DaysInput is seven checkboxes for selecting a subset of the days of the week.
 */
function DayInput({ onChange, value, disabled }: Props) {
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)" }}>
      {DAY_LETTERS.map((letter, i) => (
        <Box key={`${letter}_${i}`} justifyContent="center" display="flex">
          {letter}
        </Box>
      ))}
      {DAY_LETTERS.map((letter, i) => (
        <Checkbox
          key={`${letter}_${i}`}
          sx={{ p: 0 }}
          checked={value.includes(i)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              onChange(value.concat([i]));
            } else {
              onChange(value.filter((x) => x !== i));
            }
          }}
          disabled={disabled}
        />
      ))}
    </Box>
  );
}

export default withController(DayInput);
