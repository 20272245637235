import { belowLargeTablet, IconButton } from "@ameelio/ui";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Paper,
  useMediaQuery as measureScreenWidth,
  useTheme,
} from "@mui/material";
import { common } from "@mui/material/colors";
import Menu from "@src/lib/Menu";
import { MenuTab } from "@src/pages/routes";
import { MENU_BACKGROUND_COLOR } from "@src/theme";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const DRAWER_WIDTH = 280;

type LayoutProps = {
  selectedTab: MenuTab;
  children: React.ReactNode;
};

function MobileSmallTabletLayout({ selectedTab, children }: LayoutProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const handleClick = () => {
    setMenuOpen(false);
  };
  const menuChildren = <Menu selectedTab={selectedTab} onClick={handleClick} />;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 1,
        width: 1,
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: 1,
          minHeight: theme.spacing(8),
          px: 2,
          backgroundColor: MENU_BACKGROUND_COLOR,
        }}
      >
        <IconButton
          sx={{
            color: common.white,
          }}
          ariaLabel={t("Menu")}
          size="large"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      {menuOpen && (
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: 1,
              width: 1,
              zIndex: 1101,
              backgroundColor: "rgba(255,255,255, 0.6)",
            }}
            onClick={() => setMenuOpen(false)}
          />
          <Paper
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              height: 1,
              borderRadius: 0,
              display: "flex",
              minWidth: DRAWER_WIDTH,
              flexDirection: "column",
              maxHeight: "100%",
              overflow: "auto",
              backgroundColor: MENU_BACKGROUND_COLOR,
              zIndex: "modal",
            }}
          >
            {menuChildren}
          </Paper>
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: 1,
          maxWidth: "100%",
          backgroundColor: "#f0f2f5",
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  );
}

function LargeTabletLaptopUpLayout({ selectedTab, children }: LayoutProps) {
  const menuChildren = <Menu selectedTab={selectedTab} />;
  return (
    <Box
      sx={{
        display: "flex",
        padding: 0,
        height: 1,
        backgroundColor: "#f0f2f5",
      }}
    >
      <Paper
        sx={{
          minWidth: DRAWER_WIDTH,
          maxWidth: DRAWER_WIDTH,
          display: "flex",
          flexDirection: "column",
          height: "100h",
          minHeight: "100vh",
          maxHeight: "100%",
          overflow: "auto",
          borderRadius: 0,
          backgroundColor: MENU_BACKGROUND_COLOR,
        }}
      >
        {menuChildren}
      </Paper>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  );
}

export default function ResponsiveMenuLayout({
  currentTab,
  children,
}: {
  currentTab: MenuTab;
  children?: React.ReactNode;
}) {
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  if (isMobileOrSmallTablet) {
    return (
      <MobileSmallTabletLayout selectedTab={currentTab}>
        {children}
      </MobileSmallTabletLayout>
    );
  }

  return (
    <LargeTabletLaptopUpLayout selectedTab={currentTab}>
      {children}
    </LargeTabletLaptopUpLayout>
  );
}
