import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import { StaffTimelineEventObject } from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import DateRangePicker from "@src/lib/DateRangePicker";
import errorReporter from "@src/lib/errorReporter";
import PageLoader from "@src/lib/PageLoader";
import { formatDate } from "@src/lib/Time";
import { TypographyText } from "@src/lib/typography";
import { Card, Cascader } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetStaffTimelineDocument } from "./GetStaffTimeline.generated";
import TimelineEventAffectedParties from "./TimelineEventAffectedParties";
import TimelineEventObjectDescription from "./TimelineEventObjectDescription";

const LIMIT = 100;
interface Props {
  staffId: string;
  systemId: string;
}

type Filters = {
  facilities: string[];
  eventTypes: string[];
};

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const StaffTimeline = ({ staffId, systemId }: Props) => {
  const [startDate, setStartDate] = useState<number | null>(null);
  const [endDate, setEndDate] = useState<number | null>(null);

  const { t } = useTranslation();

  const { loading, data, refetch } = useQuery(GetStaffTimelineDocument, {
    variables: {
      staffId,
      systemId,
      limit: LIMIT,
      createdAfter: startDate,
      createdBefore: endDate,
    },
    skip: !staffId,
    onError: errorReporter,
  });

  useEffect(() => {
    refetch();
  }, [refetch, startDate, endDate]);

  const timelineEventTypeLabel = (
    eventType: StaffTimelineEventObject["__typename"]
  ) => {
    if (!eventType) return "";
    return {
      Connection: t("Connection"),
      ConnectionRestriction: t("Connection Restriction"),
      Meeting: t("Meeting"),
      Message: t("Message"),
      MessageKeyword: t("Message Keyword"),
      Kiosk: t("Kiosk"),
      StaffPosition: t("Staff Position"),
      StaffEntitlement: t("Staff Entitlement"),
      FacilityApprovedPhone: t("Facility phone contact"),
      Facility: t("Facility settings"),
      SystemUser: t("Visitor ID"),
      Call: t("Call"),
    }[eventType];
  };

  const filterSelectOptions: Option[] = [
    {
      label: t("All Facilities"),
      value: "facilities",
      children: data?.system.facilities.map((f) => ({
        label: f.publicId,
        value: f.id,
      })),
    },

    {
      label: t("All Categories"),
      value: "eventTypes",
      children: [
        { label: t("Connection"), value: "Connection" },
        { label: t("Connection Restriction"), value: "ConnectionRestriction" },
        { label: t("Meeting"), value: "Meeting" },
        { label: t("Message"), value: "Message" },
        { label: t("Message Keyword"), value: "MessageKeyword" },
        { label: t("Kiosk"), value: "Kiosk" },
        { label: t("Staff Position"), value: "StaffPosition" },
        { label: t("Staff Entitlement"), value: "StaffEntitlement" },
        { label: t("Visitor ID"), value: "SystemUser" },
        { label: t("Recording"), value: "Recording" },
      ],
    },
  ];

  const [filters, setFilters] = useState<Filters>({
    facilities: [],
    eventTypes: [],
  });

  const [selections, setSelections] = useState<string[][]>([]);

  useEffect(() => {
    // if a parent (All facilities or All eventTypes) is selected, it appears as ['facilities'] or ['eventTypes']
    // if some but not all children of a parent are selected, they each appear as ['facilities', facilityId]
    setFilters({
      facilities: selections.some(
        (selection) => selection.length === 1 && selection[0] === "facilities"
      )
        ? [] // If the parent is selected, don't filter on facility ids
        : selections
            .filter((entry) => entry.length === 2 && entry[0] === "facilities")
            .map((entry) => entry[1]),
      eventTypes: selections.some(
        (selection) => selection.length === 1 && selection[0] === "eventTypes"
      )
        ? [] // If the parent is selected, don't filter on eventTypes
        : selections
            .filter((entry) => entry.length === 2 && entry[0] === "eventTypes")
            .map((entry) => entry[1]),
    });
  }, [selections]);

  const filteredTimeline = useMemo(
    () =>
      (data?.staff.timelineEvents.edges.map(({ node }) => node) || [])
        .filter(
          (te) =>
            filters.facilities.length === 0 ||
            !te.facility || // always show system-wide events
            filters.facilities.some((fId) => te.facility?.id === fId)
        )
        .filter(
          (te) =>
            filters.eventTypes.length === 0 ||
            filters.eventTypes.some((et) => et === te.object.__typename)
        )
        .reverse(),
    [filters, data]
  );

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: filteredTimeline,
    resetOnChange: [filters],
  });

  if (loading || !data) return <PageLoader />;

  return (
    <>
      <Card title={t("Activity")} bodyStyle={{ padding: "1em" }}>
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{
            xs: "flex-start",
            sm: "center",
          }}
        >
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            spacing={2}
          >
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </Stack>
          <TypographyText>{t("Filters:")}</TypographyText>
          <Cascader
            options={filterSelectOptions}
            onChange={(selections) =>
              setSelections(selections as unknown as string[][])
            }
            value={selections}
            multiple
            maxTagCount="responsive"
            placeholder={t("by Facility or Category")}
          />
        </Stack>
        <Box sx={{ overflow: "auto", mt: 2 }}>
          <Table aria-label={t("Staff timeline table")}>
            <TableHead>
              <TableRow>
                <TableCell>{t("Created At")}</TableCell>
                <TableCell>{t("Facility")}</TableCell>
                <TableCell>{t("Category")}</TableCell>
                <TableCell>{t("Activity")}</TableCell>
                <TableCell>{t("Affected Parties")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTimeline.length === 0 && <TableNoData colSpan={5} />}
              {filteredTimeline.length > 0 &&
                currentPageData.map((timeline) => {
                  return (
                    <TableRow key={timeline.id}>
                      <TableCell>
                        {formatDate(new Date(timeline.createdAt), "datetime")}
                      </TableCell>
                      <TableCell>
                        {!!timeline.facility && (
                          <Chip
                            color="grey"
                            label={timeline.facility.publicId}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Chip
                          color="grey"
                          label={timelineEventTypeLabel(
                            timeline.object.__typename
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TimelineEventObjectDescription event={timeline} />
                      </TableCell>
                      <TableCell>
                        <TimelineEventAffectedParties event={timeline} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalCount={filteredTimeline.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        </Box>
      </Card>
      {data?.staff.timelineEvents.pageInfo.hasPreviousPage && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {t(
            "Only the most recent {{limit}} events are shown. Please refine your search to view older events.",
            { limit: LIMIT }
          )}
        </Alert>
      )}
    </>
  );
};

export default StaffTimeline;
