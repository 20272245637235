import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationFragmentFragmentDoc } from '../../graphql/fragments/OrganizationFragment.generated';
import { VisitorFragmentFragmentDoc } from '../../graphql/fragments/VisitorFragment.generated';
export type GetOrganizationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetOrganizationQuery = { __typename: 'Query', organization: { __typename: 'Organization', logoImageUrl: string, id: string, name: string, type: Types.OrganizationType, description: string, websiteLink: string | null, email: string, phone: string, admins: Array<{ __typename: 'OrganizationMembership', id: string, visitor: { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string, malanId: string, phone: string | null, dateOfBirth: string | null, email: string } }>, memberships: Array<{ __typename: 'OrganizationMembership', id: string, role: Types.OrganizationMembershipRole, visitor: { __typename: 'Visitor', profileImageUrl: string | null, id: string, firstName: string, lastName: string, fullName: string, malanId: string, phone: string | null, dateOfBirth: string | null, email: string } }>, address: { __typename: 'Address', lineOne: string, lineTwo: string | null, city: string, province: string, country: string, postalCode: string } } };


export const GetOrganizationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOrganization"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationFragment"}},{"kind":"Field","alias":{"kind":"Name","value":"admins"},"name":{"kind":"Name","value":"memberships"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"role"},"value":{"kind":"EnumValue","value":"ADMIN"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"memberships"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}},{"kind":"Field","name":{"kind":"Name","value":"profileImageUrl"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}},{"kind":"Field","name":{"kind":"Name","value":"logoImageUrl"}}]}}]}},...OrganizationFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetOrganizationQuery, GetOrganizationQueryVariables>;