import { useParams } from "react-router";
import StaffDetail from "./StaffDetail";

const SystemStaffDetailPage = () => {
  const { id: staffId } = useParams<{ id: string }>();
  if (!staffId) throw new Error("missing staffId param");

  return <StaffDetail staffId={staffId} />;
};

export default SystemStaffDetailPage;
