import {
  Button,
  ConfirmDialog,
  Dialog,
  DialogActions,
  DialogContent,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { appendItem } from "@src/api/client";
import { Entitlement } from "@src/api/graphql";
import { GetSystemFacilitiesDocument } from "@src/graphql/GetSystemFacilities.generated";
import errorReporter from "@src/lib/errorReporter";
import isEmail from "@src/lib/isEmail";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import { Form, Input, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateStaffDocument } from "./CreateStaff.generated";
import { DeactivateStaffDocument } from "./DeactivateStaff.generated";
import { GetSystemStaffDocument } from "./GetSystemStaff.generated";
import { Staff } from "./SystemStaff";
import { UpdateStaffDocument } from "./UpdateStaff.generated";

interface Props {
  onClose: () => void;
  staff?: Staff;
  systemId: string;
}

const AddEditStaffModal = ({ onClose, staff, systemId }: Props) => {
  const { system } = useGuaranteedFacilityContext();
  const user = useCurrentStaff();
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const { data: systemFacilitiesData } = useQuery(GetSystemFacilitiesDocument, {
    variables: {
      systemId: system.id,
    },
    onError: errorReporter,
  });

  const [createStaff] = useMutation(CreateStaffDocument, {
    onError: errorReporter,
    onCompleted: () => {
      snackbarContext.alert("success", t("Staff Added"));
      onClose();
    },
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          id: cache.identify({ __typename: "System", id: systemId }),
          fields: {
            staff: appendItem(data.createStaff.staff),
          },
        });
      }
    },
  });
  const [updateStaff] = useMutation(UpdateStaffDocument, {
    onError: errorReporter,
    onCompleted: () => {
      snackbarContext.alert("success", t("Staff Updated"));
      onClose();
    },
    refetchQueries: [
      { query: GetSystemStaffDocument, variables: { systemId: systemId } },
    ],
  });
  const [form] = Form.useForm();

  const [deactivateStaff] = useMutation(DeactivateStaffDocument, {
    onError: errorReporter,
    onCompleted: () => {
      snackbarContext.alert("success", t("Staff Deleted"));
      onClose();
    },
  });

  function onDelete() {
    if (staff) {
      deactivateStaff({
        variables: {
          input: {
            systemId,
            staffId: staff.id,
          },
        },
      });
    }
    setDeleting(false);
    onClose();
  }

  const [deleting, setDeleting] = useState<boolean>(false);

  const footer = [
    <Button variant="outlined" key="back" onClick={onClose} sx={{ mr: 1 }}>
      {t("Cancel")}
    </Button>,
    <Button
      color="error"
      variant="outlined"
      disabled={
        staff && (staff.staffPositions.length === 0 || staff.id === user.id)
      }
      disabledReason={
        staff?.id === user.id
          ? t("Staff may not deactivate their own accounts")
          : ""
      }
      key="delete"
      onClick={() => setDeleting(true)}
      sx={{ mr: 1 }}
    >
      {t("Deactivate")}
    </Button>,
    <Button variant="contained" key="submit" onClick={form.submit}>
      {t("Save")}
    </Button>,
  ];

  if (!staff) footer.splice(1, 1);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      title={
        staff
          ? t("Editing {{name}}", {
              name: staff.fullName,
            })
          : t("Add New Staff")
      }
    >
      <DialogContent>
        {deleting && (
          <ConfirmDialog
            title={t("Deactivate staff")}
            description={t(
              "Are you sure? This will deactivate the staff positions for {{name}} at all facilities.",
              {
                name: staff?.fullName || t("this staff member"),
              }
            )}
            variant="remove"
            onCancel={() => setDeleting(false)}
            onConfirm={onDelete}
            confirmText={t("Confirm deactivation")}
          />
        )}
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          form={form}
          onFinish={(values) => {
            if (!staff) {
              createStaff({
                variables: {
                  input: {
                    systemId,
                    ...values,
                    entitlements: values.entitlements ?? [],
                  },
                },
              });
            } else {
              updateStaff({
                variables: {
                  input: {
                    systemId,
                    staffId: staff.id,
                    ...values,
                    entitlements: values.entitlements ?? [],
                  },
                },
              });
            }
          }}
        >
          <Form.Item
            label={t("First name")}
            name={"firstName"}
            initialValue={staff?.firstName}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("Last name")}
            name={"lastName"}
            initialValue={staff?.lastName}
            rules={[]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("Email address")}
            name={"email"}
            initialValue={staff?.email}
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  // Only validate if there is a value
                  if (!value.trim() || isEmail(value)) return Promise.resolve();
                  return Promise.reject();
                },
                message: t("Must be a valid email address."),
              },
            ]}
          >
            <Input disabled={!!staff} />
          </Form.Item>
          {!staff && (
            <Form.Item
              label={t("Password")}
              name={"password"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            label={t("Phone number")}
            name={"phone"}
            initialValue={staff?.phone}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          {
            <Form.Item
              label={t("Entitlements:")}
              name={"entitlements"}
              initialValue={staff?.staffEntitlements.map(
                (se) => se.entitlement
              )}
              tooltip={
                staff?.id === user.id &&
                t("Staff may not edit their own entitlements")
              }
            >
              <Select
                mode="multiple"
                placeholder={null}
                allowClear
                disabled={staff?.id === user.id}
              >
                {Object.values(Entitlement).map((enumValue: string) => (
                  <Select.Option key={enumValue} value={enumValue}>
                    {enumValue}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          <Form.Item
            label={t("Facilities")}
            name={"facilityIds"}
            initialValue={staff?.staffPositions.map((sp) => sp.facility.id)}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select mode="multiple" placeholder={null} allowClear>
              {(systemFacilitiesData?.system.facilities || []).map(
                (facility) => (
                  <Select.Option key={facility.id} value={facility.id}>
                    {facility.name}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </Form>
      </DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  );
};

export default AddEditStaffModal;
