import { FacilityService } from "@src/api/graphql";

const facilityServiceLabel = (serviceName: FacilityService) => {
  //  grammatical context: "restriction on [facilityServiceLabel] with [visitor]", or "restriction on [facilityServiceLabel] between [visitor]  and [resident]"
  return {
    [FacilityService.VideoCall]: "video calls",
    [FacilityService.Emessaging]: "messaging",
    [FacilityService.InPersonVisit]: "in person visits",
    [FacilityService.VoiceCall]: "voice calls",
    [FacilityService.Webinar]: "webinars",
  }[serviceName];
};

export default facilityServiceLabel;
