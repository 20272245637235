import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetVisitorPhotosQueryVariables = Types.Exact<{
  visitorId: Types.Scalars['ID'];
}>;


export type GetVisitorPhotosQuery = { __typename: 'Query', visitor: { __typename: 'Visitor', identity: { __typename: 'VisitorIdentity', id: string, documentType: Types.IdDocumentType, idImageOneUrl: string | null, idImageTwoUrl: string | null, idImageThreeUrl: string | null } | null } };


export const GetVisitorPhotosDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetVisitorPhotos"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"visitorId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"visitor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"visitorId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"identity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"documentType"}},{"kind":"Field","name":{"kind":"Name","value":"idImageOneUrl"}},{"kind":"Field","name":{"kind":"Name","value":"idImageTwoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"idImageThreeUrl"}}]}}]}}]}}]} as unknown as DocumentNode<GetVisitorPhotosQuery, GetVisitorPhotosQueryVariables>;