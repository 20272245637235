import { LightbulbOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

type Props = {
  info: string;
};

export default function LightbulbCard({ info }: Props) {
  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 2,
        backgroundColor: grey["100"],
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <LightbulbOutlined
        sx={{ height: 20, width: 20, color: "text.secondary", mr: 0.5 }}
      />
      <Box sx={{ flex: 1 }}>
        <Typography variant="caption" color="text.secondary">
          {info}
        </Typography>
      </Box>
    </Box>
  );
}
