import { Button } from "@ameelio/ui";
import { Entitlement } from "@src/api/graphql";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useEntitlement from "@src/lib/useEntitlement";
import { Layout } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddStaffModal from "./AddEditStaffModal";
import { GetSystemStaffQuery } from "./GetSystemStaff.generated";
import StaffTable from "./StaffTable";

export type Staff = GetSystemStaffQuery["system"]["staff"][0];

const { Content } = Layout;
const SystemStaff = () => {
  const navigate = useAppNavigate();
  const { system } = useGuaranteedFacilityContext();

  const [isAddStaffModalOpen, setIsAddStaffModalOpen] = useState(false);
  const [editingStaff, setEditingStaff] = useState<Staff | null>(null);

  const canManageStaff = useEntitlement(Entitlement.ManageStaff);
  const { t } = useTranslation();

  return (
    <Content>
      <StaffTable
        renderActionButtons={(staff) => (
          <div className="flex gap-x-4">
            <Button
              variant="outlined"
              onClick={() => navigate(`/system/staff/${staff.id}`)}
            >
              {t("View Activity")}
            </Button>
            <Button
              variant="outlined"
              disabled={!canManageStaff}
              disabledReason={t(
                "You do not have permission to perform this function."
              )}
              onClick={() => setEditingStaff(staff)}
            >
              {t("Edit Details")}
            </Button>
          </div>
        )}
        headerExtra={[
          <div key="add-staff" className="flex gap-x-4">
            <Button
              variant="contained"
              disabled={!canManageStaff}
              disabledReason={t(
                "You do not have permission to perform this function."
              )}
              onClick={() => setIsAddStaffModalOpen(true)}
            >
              {t("Add Staff")}
            </Button>
          </div>,
        ]}
      />
      {isAddStaffModalOpen && (
        <AddStaffModal
          onClose={() => setIsAddStaffModalOpen(false)}
          systemId={system.id}
        />
      )}
      {!!editingStaff && (
        <AddStaffModal
          onClose={() => setEditingStaff(null)}
          systemId={system.id}
          staff={editingStaff}
        />
      )}
    </Content>
  );
};

export default SystemStaff;
