import { useSnackbarContext } from "@ameelio/ui";
import { useAuthContext } from "@src/AuthProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const API_URL = `${import.meta.env.VITE_API_ORIGIN}/api/v1/`;

export default function useFetchAuthenticated() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const snackbarContext = useSnackbarContext();

  return async (url: string, options: RequestInit = {}) => {
    const response = await fetch(`${API_URL}${url}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      mode: "cors",
    });

    if (response.status === 403) {
      const responseJson = await response.json();
      if (responseJson.message === "API token is expired or revoked") {
        snackbarContext.alert(
          "error",
          t("Your session expired. Please log in again.")
        );
        navigate("/logout");
      }
    }

    if (response.status === 400 || response.status === 403) {
      snackbarContext.alert(
        "error",
        t("You are not allowed to perform this action. Contact the admin.")
      );
    }

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`Unexpected error: ${response.status}.`);
    }

    const body = await response.json();
    return body;
  };
}
