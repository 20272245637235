import { Dialog, DialogContent, useSnackbarContext } from "@ameelio/ui";
import Spinner from "@src/lib/Spinner";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorResult from "./ErrorResult";
import RosterDropzone from "./RosterDropzone";
import useRosterValidation, {
  isErroredResult,
  isValidatedResult,
  ValidationResult,
} from "./useRosterValidation";
import ValidatedResult from "./ValidatedResult";

type Props = {
  systemId: string;
  onClose: () => void;
};

const ImportModal: React.FC<Props> = ({ systemId, onClose }) => {
  const [results, setResults] = useState<ValidationResult[]>();
  const errors = results?.filter(isErroredResult) || [];
  const records = results?.filter(isValidatedResult) || [];
  const snackbarContext = useSnackbarContext();

  // validate on drop with loaded system data
  const [validator, { loading }] = useRosterValidation(systemId);
  const { t } = useTranslation();

  return (
    <Dialog title={t("Import Roster")} onClose={() => onClose()}>
      <DialogContent>
        {errors.length > 0 ? (
          <ErrorResult errors={errors.map((e) => e.error)} />
        ) : results ? (
          <ValidatedResult
            systemId={systemId}
            inmates={records.map((r) => r.inmate)}
            onComplete={onClose}
          />
        ) : loading ? (
          <p>
            {t("Loading facility data")} <Spinner />
          </p>
        ) : (
          <RosterDropzone
            onDrop={(json) => setResults(validator(json))}
            onError={(e) => {
              snackbarContext.alert("error", e.message);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImportModal;
