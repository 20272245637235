import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { TypographyText } from "@src/lib/typography";
import { theme } from "@src/theme";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ConnectionListSidebar from "./ConnectionListSidebar";

type Props = {
  connectionList?: ReactElement;
  hasSelectedItem: boolean;
  messagePanelContent: React.ReactNode;
};

export const MESSAGE_PANEL_SIDEBAR_WIDTH = "300px";

const MessagePanelStructure = ({
  connectionList,
  messagePanelContent,
  hasSelectedItem,
}: Props) => {
  const { t } = useTranslation();
  const headerHeight =
    document.querySelectorAll("header")[0]?.clientHeight ||
    // Retrocompatibility
    document.querySelectorAll(".ant-page-header")[0]?.clientHeight ||
    100;
  return (
    <Stack
      direction={{
        xs: "column",
        md: "row",
      }}
      spacing={{
        xs: 2,
        md: 0,
      }}
      height={`calc(100vh - ${headerHeight}px - ${theme.spacing(6)})`}
    >
      {connectionList ? (
        <ConnectionListSidebar connectionList={connectionList} />
      ) : null}
      <Stack
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          borderWidth: {
            xs: "1px",
          },
          borderColor: "divider",
        }}
      >
        {hasSelectedItem ? (
          messagePanelContent
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <TypographyText type="secondary">
              {t("Select a conversation to begin reviewing.")}
            </TypographyText>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default MessagePanelStructure;
