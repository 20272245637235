import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps as BaseProps,
  SelectInput,
  SubmitButton,
  TextInput,
} from "@ameelio/ui";
import { Box, InputAdornment, Typography } from "@mui/material";
import { useEffect } from "react";
import { UseControllerProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type TextFormData = {
  value: string;
};

type InputType = "text" | "email" | "tel";

type Props = Omit<BaseProps, "open" | "onSubmit"> & {
  title: string;
  description?: string;
  placeholder?: string;
  label?: string;
  value: string;
  inputType?: InputType;
  endAdornment?: string;
  rules?: UseControllerProps<TextFormData>["rules"];
  errorMessages?: { [n: string]: string };
  cancelText?: string;
  confirmText?: string;
  selectOptions?: { value: string; name: string }[];
  onCancel: () => void;
  onSubmit: (value: string) => void | Promise<void>;
};

export default function InputDialog({
  title,
  description,
  placeholder,
  label,
  value,
  rules,
  errorMessages,
  cancelText,
  confirmText,
  selectOptions,
  inputType,
  endAdornment,
  onCancel,
  onSubmit,
  children,
  ...rest
}: Props) {
  const {
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    control,
    setFocus,
  } = useForm<TextFormData>({
    mode: "onChange",
    defaultValues: {
      value: value.toString(),
    },
  });
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setFocus("value");
    }, 0);
  }, [setFocus]);

  return (
    <Dialog title={title} {...rest} onClose={onCancel}>
      <form onSubmit={handleSubmit((data) => onSubmit(data.value))}>
        <DialogContent sx={{ pt: 0 }}>
          {description && <Typography>{description}</Typography>}
          {selectOptions ? (
            <Box sx={{ mt: 2 }}>
              <SelectInput
                aria-label={title}
                name={"value"}
                inputProps={{ "aria-label": title }}
                control={control}
                items={selectOptions}
              />
            </Box>
          ) : (
            <TextInput
              sx={{ mt: 2, width: 1 }}
              rules={rules}
              control={control}
              label={label}
              type={inputType || "text"}
              name="value"
              id={"value"}
              title={inputType || "text"}
              placeholder={placeholder}
              inputProps={{ "aria-label": title }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{endAdornment}</InputAdornment>
                ),
              }}
              helperText={
                errorMessages &&
                errors.value &&
                errors.value.type in errorMessages
                  ? errorMessages[errors.value.type]
                  : undefined
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={onCancel}>
            {t("Cancel")}
          </Button>
          <SubmitButton disabled={!isValid} submitting={isSubmitting}>
            {t("Save")}
          </SubmitButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
