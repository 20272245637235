import { Dialog, Skeleton, SkeletonProps, Stack } from "@mui/material";
import { ScreenTitle } from "./typography";

export function BaseSkeleton({ sx, ...rest }: SkeletonProps) {
  return (
    <Skeleton
      {...rest}
      sx={{
        ...(sx || {}),
        // NOTE: Light/Action/Hover (4p)
        backgroundColor: "rgb(0 0 0 / 0.04)",
        borderRadius: 4,
      }}
    />
  );
}

export function TableSkeleton() {
  return <BaseSkeleton variant="rectangular" height={400} />;
}

export function TitleSkeleton({ width }: { width?: number }) {
  return (
    <ScreenTitle skipPageTitle>
      <BaseSkeleton variant="text" width={width || 200} />
    </ScreenTitle>
  );
}

export function CardSkeleton({ height }: { height?: number | string }) {
  return <BaseSkeleton variant="rectangular" height={height || 120} />;
}

export function ContentSkeleton() {
  return (
    <Stack spacing={4}>
      <TitleSkeleton />
      <TableSkeleton />
    </Stack>
  );
}

export function ListSkeleton() {
  return (
    <>
      <TitleSkeleton />
      <Stack marginTop={4} maxWidth={600} marginX="auto" spacing={4}>
        <TitleSkeleton />
        <CardSkeleton />

        <TitleSkeleton />
        <CardSkeleton />

        <TitleSkeleton />
        <CardSkeleton />
      </Stack>
    </>
  );
}

export function DialogSkeleton() {
  return (
    <Dialog open>
      <CardSkeleton />
    </Dialog>
  );
}

export function IconSkeleton({ size }: { size?: number }) {
  return (
    <Skeleton
      variant="circular"
      width={size || 40}
      height={size || 40}
      sx={{
        backgroundColor: "rgb(0 0 0 / 0.04)",
        borderRadius: "50%",
      }}
    />
  );
}

export function ButtonSkeleton({ size }: { size?: number }) {
  return (
    <Skeleton
      variant="rectangular"
      width={size || 100}
      height={size || 40}
      sx={{
        backgroundColor: "rgb(0 0 0 / 0.04)",
        display: "inline-block",
        borderRadius: "8px",
      }}
    />
  );
}
