import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { StaffFragmentFragmentDoc } from '../../graphql/fragments/StaffFragment.generated';
export type DeactivateStaffMutationVariables = Types.Exact<{
  input: Types.DeactivateStaffInput;
}>;


export type DeactivateStaffMutation = { __typename: 'Mutation', deactivateStaff: { __typename: 'DeactivateStaffResponse', staff: { __typename: 'Staff', id: string, firstName: string, lastName: string, fullName: string, email: string, phone: string | null, staffPositions: Array<{ __typename: 'StaffPosition', id: string, facility: { __typename: 'Facility', id: string } }>, staffEntitlements: Array<{ __typename: 'StaffEntitlement', id: string, entitlement: Types.Entitlement }> } } };


export const DeactivateStaffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeactivateStaff"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeactivateStaffInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deactivateStaff"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"staff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"StaffFragment"}},{"kind":"Field","name":{"kind":"Name","value":"staffPositions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"staffEntitlements"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"entitlement"}}]}}]}}]}}]}},...StaffFragmentFragmentDoc.definitions]} as unknown as DocumentNode<DeactivateStaffMutation, DeactivateStaffMutationVariables>;