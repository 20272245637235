import { PasswordInput, SubmitButton, useSnackbarContext } from "@ameelio/ui";
import { Box, Card, Link as RawLink, Stack, Typography } from "@mui/material";
import { UserType } from "@src/api/graphql";
import { ReactComponent as Logo } from "@src/assets/logo.svg";
import { useAuthContext } from "@src/AuthProvider";
import TextInput from "@src/lib/TextInput";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Location, useLocation, useNavigate } from "react-router-dom";

type LocationState = {
  from: string;
};

function isLocationState(obj: unknown): obj is LocationState {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "from" in obj &&
    typeof (obj as { from: unknown })["from"] === "string"
  );
}

function getLocationState(location: Location): LocationState | undefined {
  if (isLocationState(location.state)) return location.state;
  return undefined;
}

type FormData = {
  email: string;
  password: string;
};

const RESET_PASSWORD_URL = import.meta.env.VITE_RESET_PASSWORD_URL;

function LoginPage(): ReactElement {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      email: import.meta.env.VITE_DEV_DOC_EMAIL,
      password: import.meta.env.VITE_DEV_DOC_PW,
    },
  });

  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();

  const { login } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const state = getLocationState(location);

  const onSubmit = async (data: FormData) => {
    const success = await login(
      data.email.trim(),
      data.password,
      UserType.Staff
    );
    if (success) {
      navigate(state?.from || "/", { replace: true });
    } else {
      snackbarContext.alert(
        "error",
        t("Could not log in. Please check your username and password.")
      );
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(240, 242, 245)",
      }}
    >
      <Box sx={{ width: 250, mb: 2 }}>
        <Logo />
      </Box>

      <Card variant="outlined" sx={{ maxWidth: 500, p: 4, width: "100%" }}>
        <Box mb={2}>
          <Typography variant="h2" component="h1">
            {t("Sign in to your account")}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} sx={{ my: 6 }}>
            <TextInput
              control={control}
              name="email"
              type="email"
              autoComplete="email"
              label={t("Email")}
              rules={{ required: true }}
              autoFocus
            />
            <Stack spacing={1}>
              <PasswordInput
                control={control}
                name="password"
                label={t("Password")}
                rules={{ required: true }}
              />

              <RawLink
                href={RESET_PASSWORD_URL}
                tabIndex={0}
                target="_blank"
                onClick={() =>
                  !RESET_PASSWORD_URL &&
                  snackbarContext.alert(
                    "error",
                    t("Reset password is not enabled for this environment.")
                  )
                }
              >
                {t("Forgot password?")}
              </RawLink>
            </Stack>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <SubmitButton submitting={isSubmitting}>{t("Log In")}</SubmitButton>
          </Box>
        </form>
      </Card>
    </Box>
  );
}

export default LoginPage;
