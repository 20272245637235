import { MicOff, PushPin, VideocamOff } from "@mui/icons-material";
import { Box, SxProps, Theme, Typography } from "@mui/material";

type Props = {
  name: string;
  isMuted?: boolean;
  isHidden?: boolean;
  onTogglePin?: () => void;
};

const iconSize: SxProps<Theme> = { width: 14, height: 14 };

export default function UserLabel({
  name,
  isMuted,
  isHidden,
  onTogglePin,
}: Props) {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.6)",
        borderTopRightRadius: 8,
        px: 1,
      }}
    >
      {isMuted && <MicOff sx={{ ...iconSize, color: "error.main", mr: 1 }} />}
      {isHidden && (
        <VideocamOff sx={{ ...iconSize, color: "error.main", mr: 1 }} />
      )}
      <Typography variant="caption" color="white">
        {name}
      </Typography>
      {onTogglePin && (
        <PushPin
          onClick={onTogglePin}
          sx={{
            cursor: "pointer",
            ...iconSize,
            color: "white",
            ml: 1,
          }}
        />
      )}
    </Box>
  );
}
