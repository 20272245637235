import { Button, Dialog, SelectInputBase, TextInputBase } from "@ameelio/ui";
import {
  Box,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { CallAlert } from "@src/typings/Call";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CALL_ALERTS, VOICE_CALL_ALERTS } from "../constants";

type Props = {
  type: "video" | "voice";
  onSend: (message: string) => void;
  onClose: () => void;
};

export default function SendAlertDialog({ type, onSend, onClose }: Props) {
  const { t } = useTranslation();
  const [alert, setAlert] = useState<CallAlert>();
  const [customAlert, setCustomAlert] = useState("");
  const alerts = type === "video" ? CALL_ALERTS : VOICE_CALL_ALERTS;
  return (
    <Dialog fullWidth title={t("Send alert")} onClose={onClose}>
      <DialogContent>
        <SelectInputBase
          size="small"
          aria-label={t("Alert type")}
          style={{ width: "auto" }}
          label={t("Alert type")}
          onChange={(event: SelectChangeEvent<unknown>) => {
            setAlert(alerts.find((a) => a.title === event.target.value));
          }}
          value={alert?.title || ""}
          items={alerts.map((a) => ({
            value: a.title,
            name: a.title,
          }))}
        />
        {alert && alert.title !== "Custom" ? (
          <Box mt={4}>
            <Typography variant="h3" color="text.primary" sx={{ mb: 1 }}>
              {t("Send the following alert message?")}
            </Typography>
            <Typography variant="body1" color="text.primary">
              {alert.body}
            </Typography>
          </Box>
        ) : alert?.title === "Custom" ? (
          <Box mt={4}>
            <Typography variant="h3" color="text.primary" sx={{ mb: 2 }}>
              {t("Please type your message")}
            </Typography>
            <TextInputBase
              fullWidth
              label={t("Message")}
              value={customAlert}
              onChange={(e) => {
                setCustomAlert(e.target.value);
              }}
            />
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!alert || (alert.title === "Custom" && !customAlert.trim())}
          onClick={() => {
            if (alert) {
              onSend(alert.body || customAlert.trim());
              onClose();
            }
          }}
        >
          {t("Send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
