/*
  Please notice that we've deliberately decided to use the Browser Locale to determine which date format should be used.
  This must be specified within the Intl.DateTimeFormat because the optional locale option would default to the _internal implementation_
  and not the browser local (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument)
*/

export function inferDateFormat(locale: string) {
  // Notice that it's actually "unusual" to extract dateFormat from a date.
  /*
    Process is
    - create a new date object for Dec 31st
    - parse to format using yearMonthDate(date) to return either 31/12/2023, or 12/31/2023
    - look whether the generated string starts with 31, thus being DD-MM-YYYY, or 12, thus being MM-DD-YYYY
  */
  const endOfYear = new Date(2023, 11, 31);
  const formattedDate = yearMonthDate(endOfYear);
  return formattedDate.startsWith("31") ? "DD-MM-YYYY" : "MM-DD-YYYY";
}

// Returns ex: Oct 10
export function shortMonthDate(d: Date | number) {
  return new Intl.DateTimeFormat(navigator.language, {
    month: "short",
    day: "numeric",
  }).format(d);
}

// Returns ex: 10/1/2023
export function yearMonthDate(d: Date | number) {
  return new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(d);
}

// Returns ex: Oct 1, 2023
export function yearShortMonthDate(d: Date | number) {
  return new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(d);
}

// Returns ex: 10/1/2023, 10:30 AM
export function yearMonthDateTime(d: Date | number) {
  return new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(d);
}

// Returns ex: 10:30 AM
export function hourMinutes(d: Date | number) {
  return new Intl.DateTimeFormat(navigator.language, {
    hour: "numeric",
    minute: "numeric",
  }).format(d);
}

// Returns ex: 10:30:00 AM
export function hourSeconds(d: Date | number) {
  return new Intl.DateTimeFormat(navigator.language, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(d);
}

export function formatDate(
  date: Date | number,
  type:
    | "date"
    | "time"
    | "timeseconds"
    | "yearshortmonthdate"
    | "datetime"
    | "dateyear"
    | "monthdayyear"
): string {
  if (type === "date") return yearMonthDate(date);
  if (type === "time") return hourMinutes(date);
  if (type === "timeseconds") return hourSeconds(date);
  if (type === "yearshortmonthdate") return yearShortMonthDate(date);
  if (type === "datetime") return yearMonthDateTime(date);
  if (type === "dateyear") return yearMonthDate(date);
  if (type === "monthdayyear") return yearShortMonthDate(date);
  return "";
}

export function formatTimeRange(d: Date | number, d2: Date | number) {
  return `${hourMinutes(d)} - ${hourMinutes(d2)}`;
}

/* Returns ex:
ms = 3600000 -> 1 hr
ms = 900000 -> 15 min
ms = 30000 -> 30 sec
ms = 9764562 -> 2 hr 42 min 44 sec
*/
export function hoursMinutesSecondsTextLabel(ms?: number | null): string {
  if (!ms) return "0 sec";
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const minutesRemaining = Math.floor(
    (ms - hours * 60 * 60 * 1000) / (60 * 1000)
  );
  const secondsRemaining = Math.floor(
    (ms - hours * 60 * 60 * 1000 - minutesRemaining * 60 * 1000) / 1000
  );
  if (hours === 0 && minutesRemaining === 0) return `${secondsRemaining} sec`;
  if (hours === 0)
    return `${minutesRemaining} min${
      secondsRemaining > 0 ? ` ${secondsRemaining} sec` : ""
    }`;
  const hoursFormatted = hours > 0 ? `${hours} hr` : "";
  const minutesFormatted =
    minutesRemaining > 0 ? `${minutesRemaining} min` : "";
  const secondsFormatted =
    secondsRemaining > 0 ? `${secondsRemaining} sec` : "";
  return `${hoursFormatted}${
    hoursFormatted && minutesFormatted ? " " : ""
  }${minutesFormatted}${
    (hoursFormatted || minutesFormatted) && secondsFormatted ? " " : ""
  }${secondsFormatted}`;
}

export function getDayLetters(): string[] {
  return ["S", "M", "T", "W", "T", "F", "S"];
}

/**
 * fromISODate takes a YYYY-MM-DD string and returns a Date object
 * that assumes the local client's time zone. this is necessary because
 * `Date.parse('YYYY-MM-DD')` assumes UTC.
 */
export function fromLocalISODate(s: string): Date {
  const [year, month, day] = s.split("-").map(Number);
  return new Date(year, month - 1, day, 0, 0, 0);
}

/**
 * toISODate takes a Date string and returns a YYYY-MM-DD ISO string
 * by truncating the time information.
 */
export function toLocalISODate(val: Date | number): string {
  const d = typeof val === "number" ? new Date(val) : val;
  return [
    d.getFullYear().toString().padStart(4, "0"),
    (d.getMonth() + 1).toString().padStart(2, "0"),
    d.getDate().toString().padStart(2, "0"),
  ].join("-");
}

// Returns ex:
// UTC -05:00
// UTC +00:00
// UTC +10:30 (see: https://www.timeanddate.com/time/time-zones-interesting.html)
export const utcOffset = (date: Date | number) => {
  const minsOffset = new Date(date).getTimezoneOffset();
  // be sure to round down in case the division is not whole
  const hoursOffset = parseInt((minsOffset / 60).toString(), 10);
  // this var takes care of computing/displaying a remainder
  const remainderMins = minsOffset % 60;
  return `UTC ${hoursOffset > 0 ? "-" : "+"}${String(
    Math.abs(hoursOffset)
  ).padStart(2, "0")}:${String(remainderMins).padStart(2, "0")}`;
};

// Returns ex:
// 9:00 AM - 2:00 PM (UTC -05:00)
// 09:00 - 14:00 (UTC -05:00)
export function formatTimeRangeWithZone(
  start: Date | number,
  end: Date | number
) {
  return `${formatTimeRange(start, end)} (${utcOffset(start)})`;
}

// Returns ex:
// 4/5/2001
// 05/04/2001
// 5/4/2001
// 5.4.2001
// 2001/4/5
export function getDisplayBirthday(birthday: string) {
  return yearMonthDate(fromLocalISODate(birthday));
}
