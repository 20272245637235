import { Chip } from "@ameelio/ui";
import { isMinor } from "@src/lib/Common";
import { useTranslation } from "react-i18next";

interface Props {
  dateOfBirth: string | null | undefined;
}

export default function AgeGroupTag({ dateOfBirth }: Props) {
  const { t } = useTranslation();

  if (isMinor({ dateOfBirth })) {
    // this color scheme is based on SystemRelationshipStatusTag for PENDING
    return <Chip color="orange" label={t("Minor")} />;
  }
  return <Chip color="grey" label={t("Adult")} />;
}
