import * as Types from '../../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { GroupFragmentFragmentDoc } from '../../../graphql/fragments/GroupFragment.generated';
import { KioskFragmentFragmentDoc } from '../../../graphql/fragments/KioskFragment.generated';
export type GetFacilityGroupsAndKiosksQueryVariables = Types.Exact<{
  facilityId: Types.Scalars['ID'];
}>;


export type GetFacilityGroupsAndKiosksQuery = { __typename: 'Query', facility: { __typename: 'Facility', id: string, groups: Array<{ __typename: 'Group', id: string, name: string, description: string }>, kiosks: Array<{ __typename: 'Kiosk', id: string, name: string, enabled: boolean, capacity: number, meetingType: Types.MeetingType }> } };


export const GetFacilityGroupsAndKiosksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFacilityGroupsAndKiosks"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"groups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"GroupFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"kiosks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"KioskFragment"}}]}}]}}]}},...GroupFragmentFragmentDoc.definitions,...KioskFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetFacilityGroupsAndKiosksQuery, GetFacilityGroupsAndKiosksQueryVariables>;