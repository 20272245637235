import InmateCell from "@src/lib/InmateCell";
import StaffCell from "@src/lib/StaffCell";
import VisitorCell from "@src/lib/VisitorCell";
import { GetStaffTimelineQuery } from "./GetStaffTimeline.generated";

type StaffTimelineEvent =
  GetStaffTimelineQuery["staff"]["timelineEvents"]["edges"][0]["node"];

const TimelineEventAffectedParties = ({
  event,
}: {
  event: StaffTimelineEvent;
}) => {
  const object = event.object;

  switch (object.__typename) {
    case "Message":
    case "ConnectionRestriction":
      return (
        <div className="flex gap-4">
          <InmateCell inmate={object.connection.inmate} />
          <VisitorCell visitor={object.connection.visitor} />
        </div>
      );
    case "Meeting":
      return (
        <div className="flex gap-4">
          {object.inmates.length > 0 && (
            <InmateCell inmate={object.inmates[0]} />
          )}
          {object.visitors.length > 0 && (
            <VisitorCell visitor={object.visitors[0]} />
          )}
        </div>
      );
    case "MessageKeyword":
      return <div />;
    case "Connection":
      return (
        <div className="flex gap-4">
          <InmateCell inmate={object.inmate} />
          <VisitorCell visitor={object.visitor} />
        </div>
      );
    case "StaffPosition":
      return <StaffCell staff={object.staff} />;
    case "StaffEntitlement":
      return <StaffCell staff={object.staff} />;
    case "SystemUser":
      return <VisitorCell visitor={object.user} />;
    case "Kiosk":
      return <div />;
    default:
      return <div />;
  }
};

export default TimelineEventAffectedParties;
