import { ConfirmDialog } from "@ameelio/ui";
import {
  DangerousOutlined,
  MessageOutlined,
  MoreVert,
  PushPinOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import Dropdown, { DropdownItem, DropdownList } from "@src/lib/Dropdown";
import useBoolean from "@src/lib/useBoolean";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  pinCall?: () => void;
  onToggleMute?: () => void;
  isMuted?: boolean;
  onToggleChat?: () => void;
  chatCollapsed?: boolean;
  onTerminate?: () => Promise<void>;
}

export default function VideoControlsMenu({
  pinCall,
  onToggleMute,
  isMuted,
  onToggleChat,
  chatCollapsed,
  onTerminate,
}: Props): ReactElement {
  const confirmTerminateDialog = useBoolean();
  const { t } = useTranslation();
  return (
    <>
      <Dropdown
        placement="below"
        menu={
          <DropdownList>
            {pinCall && (
              <DropdownItem
                image={<PushPinOutlined />}
                label={t("Pin")}
                onClick={pinCall}
              />
            )}
            {onToggleChat && (
              <DropdownItem
                image={<MessageOutlined />}
                label={chatCollapsed ? t("View chat") : t("Close chat")}
                onClick={onToggleChat}
              />
            )}
            {onToggleMute && (
              <DropdownItem
                image={isMuted ? <VolumeOffOutlined /> : <VolumeUpOutlined />}
                label={isMuted ? t("Turn sound on") : t("Turn sound off")}
                onClick={onToggleMute}
              />
            )}
            {onTerminate && (
              <DropdownItem
                image={<DangerousOutlined />}
                label={t("Terminate call")}
                onClick={confirmTerminateDialog.enable}
                color="error.main"
              />
            )}
          </DropdownList>
        }
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: "white",
            border: 1,
            borderColor: "divider",
            px: 2,
            py: 0.5,
            height: "100%",
          }}
        >
          <MoreVert sx={{ color: "action.active" }} />
        </Box>
      </Dropdown>
      {confirmTerminateDialog.current && (
        <ConfirmDialog
          title={t("Confirm")}
          description={t("Are you sure you want to terminate this video call?")}
          cancelText={t("No")}
          confirmText={t("Yes")}
          onConfirm={async () => {
            // Invoke the callback
            if (onTerminate) await onTerminate();
            // Then hide the dialog
            confirmTerminateDialog.disable();
          }}
          onCancel={confirmTerminateDialog.disable}
        />
      )}
    </>
  );
}
