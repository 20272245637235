import { FacilityFeature, FacilityService } from "@src/api/graphql";

const FacilityServiceToFacilityFeature: Record<
  FacilityService,
  FacilityFeature
> = {
  [FacilityService.Emessaging]: FacilityFeature.Emessaging,
  [FacilityService.InPersonVisit]: FacilityFeature.InPersonVisit,
  [FacilityService.VideoCall]: FacilityFeature.VideoCall,
  [FacilityService.VoiceCall]: FacilityFeature.VoiceCall,
  [FacilityService.Webinar]: FacilityFeature.Webinar,
};

export default function facilityServiceFeature(s: FacilityService) {
  return FacilityServiceToFacilityFeature[s];
}
