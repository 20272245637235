import { groupBy, sortByNumbers } from "@ameelio/core";
import { SelectInputBase } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, SelectChangeEvent } from "@mui/material";
import { MeetingStatus, MeetingType } from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import { alphabetize } from "@src/lib/Common";
import DateRangePicker from "@src/lib/DateRangePicker";
import errorReporter from "@src/lib/errorReporter";
import ExportScheduleButton from "@src/lib/ExportSchedule";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import featureForMeetingType, { meetingTypeTitle } from "@src/lib/meeting";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { Layout } from "antd";
import { endOfDay, startOfDay, startOfToday } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../lib/Header";
import { GetUpcomingMeetingsDocument } from "./GetUpcomingMeetings.generated";
import ScheduledMeetingsTable from "./ScheduledMeetingsTable";

export type Filters = {
  scheduledStartAfter: number;
  scheduledStartBefore: number | undefined;
  meetingStatus: MeetingStatus[];
  meetingType: MeetingType[] | undefined;
};

const scheduledMeetingTypes: MeetingType[] = [
  MeetingType.VideoCall,
  MeetingType.InPersonVisit,
  MeetingType.Webinar,
];

const LIMIT = 100;

const { history } = window;

export default function UpcomingMeetingsPage() {
  const { facility } = useGuaranteedFacilityContext();

  const { t } = useTranslation();

  const groupedMeetingTypes = groupBy(scheduledMeetingTypes, (mt) =>
    facility.features.includes(featureForMeetingType(mt))
      ? "enabled"
      : "disabled"
  );
  const sortedMeetingTypes = [
    ...alphabetize(groupedMeetingTypes.enabled || []),
    ...alphabetize(groupedMeetingTypes.disabled || []),
  ];

  // search filters are restored from page state for back navigation
  const [filters, setFilters] = useState<Filters>({
    meetingStatus: [MeetingStatus.Scheduled, MeetingStatus.Live],
    scheduledStartAfter:
      isObjectWithKey(history.state, "scheduledStartAfter") &&
      typeof history.state.scheduledStartAfter === "number"
        ? history.state.scheduledStartAfter
        : startOfToday().getTime(),
    scheduledStartBefore:
      isObjectWithKey(history.state, "scheduledStartBefore") &&
      typeof history.state.scheduledStartBefore === "number"
        ? history.state.scheduledStartBefore
        : undefined,
    meetingType:
      isObjectWithKey(history.state, "meetingType") &&
      Array.isArray(history.state.meetingType)
        ? (history.state.meetingType as Filters["meetingType"])
        : undefined,
  });
  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  const changeFilter = (newFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
  };

  const { loading, data, refetch } = useQuery(GetUpcomingMeetingsDocument, {
    variables: {
      id: facility.id,
      limit: LIMIT,
      ...filters,
    },
    onError: errorReporter,
  });
  const meetings = data
    ? sortByNumbers(
        data.facility.meetings.edges,
        (m) => m.node.interval.startAt
      ).map((e) => e.node)
    : [];

  useEffect(() => {
    refetch(filters);
  }, [refetch, filters]);

  return (
    <Layout.Content>
      <Header
        title={t("Upcoming meetings")}
        subtitle={t(
          "Review the upcoming schedule of visits, video calls, and webinars"
        )}
      >
        <Box
          display="flex"
          width="100%"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="flex-start"
        >
          <Box flex={1} sx={{ mb: { xs: 2, md: 0 } }}>
            <ResponsiveColumns>
              <SelectInputBase
                disabled={loading}
                size="small"
                aria-label={t("Filter by meeting type")}
                placeholder={t("Filter by meeting type")}
                label={t("Meeting type")}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  const value = event.target.value;
                  changeFilter({
                    meetingType:
                      value && value !== "All"
                        ? [value as MeetingType]
                        : undefined,
                  });
                }}
                value={filters.meetingType?.[0] || "All"}
                items={[
                  { value: "All", name: t("All") },
                  ...sortedMeetingTypes.map((mt) => ({
                    value: mt,
                    name: meetingTypeTitle([mt]),
                    disabled: !facility.features.includes(
                      featureForMeetingType(mt)
                    ),
                  })),
                ]}
              />
              <DateRangePicker
                onStartDateChange={(date) => {
                  const newDate = date
                    ? new Date(date)
                    : startOfDay(new Date());
                  changeFilter({
                    scheduledStartAfter: newDate.getTime(),
                  });
                }}
                onEndDateChange={(date) => {
                  const newDate = date ? endOfDay(date) : null;
                  changeFilter({
                    scheduledStartBefore: newDate?.getTime(),
                  });
                }}
                disabled={loading}
                startDate={filters.scheduledStartAfter || null}
                endDate={filters.scheduledStartBefore || null}
              />
            </ResponsiveColumns>
          </Box>
          <ExportScheduleButton
            facility={facility}
            filters={filters}
            disabled={loading}
          >
            {t("Export data")}
          </ExportScheduleButton>
        </Box>
      </Header>
      <Box p={3}>
        <ScheduledMeetingsTable meetings={meetings} loading={loading} />
        {data?.facility.meetings.pageInfo.hasNextPage && (
          <Alert severity="warning">
            {t(
              "Only the first {{limit}} results are shown. Please refine your search or export to view all results.",
              { limit: LIMIT }
            )}
          </Alert>
        )}
      </Box>
    </Layout.Content>
  );
}
