import Spinner from "@src/lib/Spinner";
import { useTranslation } from "react-i18next";

const ComponentLoader = (props: Record<string, never>) => {
  const { t } = useTranslation();
  return (
    <div className="w-full mx-auto grid items-center gap-2">
      <Spinner />
      <span className="text-center">{t("Loading...")}</span>
    </div>
  );
};

export default ComponentLoader;
