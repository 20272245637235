import { Media } from "@ameelio/ui";
import { Box, Link, Stack, Typography } from "@mui/material";
import Info from "@src/lib/Info";
import { useNavigate } from "react-router-dom";

type Props = {
  label: string;
  linkTo?: string;
  explanation?: string;
  icon: JSX.Element;
  value: string;
};

export function DashboardMetricRow(props: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        borderWidth: 1,
      }}
    >
      {props.children}
    </Box>
  );
}

export default function DashboardMetric(props: Props) {
  const navigate = useNavigate();
  const content = (
    <Typography variant="body2" color="text.secondary">
      {props.label}
      {props.explanation && <Info message={props.explanation} />}
    </Typography>
  );
  return (
    <Stack
      spacing={1}
      sx={{
        padding: 3,
        flex: 1,
        borderWidthRight: 1,
      }}
    >
      {props.linkTo ? (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <Link onClick={() => navigate(props.linkTo!)}>{content}</Link>
      ) : (
        content
      )}

      <Media image={props.icon} gap={1}>
        <Typography variant="h2" color="text.primary" component="p">
          {props.value}
        </Typography>
      </Media>
    </Stack>
  );
}
