import { AvatarGroup } from "@mui/material";
import Avatar from "@src/lib/Avatar";

type Props = {
  people: { fullName: string }[];
};

const AvatarFacepile = ({ people }: Props) => {
  return (
    <>
      <AvatarGroup>
        {people.map((p, idx) => (
          <Avatar key={`${idx}-${p.fullName}`} fallback={p.fullName} />
        ))}
      </AvatarGroup>
    </>
  );
};

export default AvatarFacepile;
