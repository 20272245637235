import { Button, Dialog, DialogActions, DialogContent } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Group, Search, TabletAndroid } from "@mui/icons-material";
import { Card, Divider, Stack } from "@mui/material";
import {
  Entitlement,
  FacilityFeature,
  MeetingType,
  PrivacyLevel,
} from "@src/api/graphql";
import { GetFacilityDocument } from "@src/graphql/GetFacility.generated";
import { GetFacilitySchedulesDocument } from "@src/graphql/GetFacilitySchedules.generated";
import { CardSkeleton, TitleSkeleton } from "@src/lib/closet";
import errorReporter from "@src/lib/errorReporter";
import {
  MonitoredPrivacyLabel,
  UnmonitoredPrivacyLabel,
} from "@src/lib/privacyLabels";
import ScheduleTable from "@src/lib/ScheduleTable";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import SettingField, {
  NumberSettingField,
  RadioSettingField,
} from "@src/lib/SettingField";
import { ScreenSubtitle, ScreenTitle, SectionTitle } from "@src/lib/typography";
import UnavailableSplashScreen from "@src/lib/UnavailableSplashScreen";
import useEntitlement from "@src/lib/useEntitlement";
import useUpdateFacilityProperty from "@src/lib/useUpdateFacilityProperty";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function VoiceCallsSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const { updateProperty } = useUpdateFacilityProperty();
  const [showPermissionsWarning, setShowPermissionsWarning] = useState(false);

  const canEdit = useEntitlement(Entitlement.ManageFacility);

  const { data: facilitySchedulesData } = useQuery(
    GetFacilitySchedulesDocument,
    {
      onError: errorReporter,
      variables: { facilityId: facility.id },
    }
  );

  const { data, error } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id || "" },
    onError: errorReporter,
    fetchPolicy: "cache-and-network",
  });

  if (error) throw error;
  if (!data)
    return (
      <>
        <TitleSkeleton />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <CardSkeleton />
        </Stack>
      </>
    );

  const { facility: loadedFacility } = data;

  if (!loadedFacility.features.includes(FacilityFeature.VoiceCall)) {
    return (
      <UnavailableSplashScreen
        header={t("Voice calls")}
        subHeader={t("Contact us about adding voice calls to the facility.")}
        features={[
          {
            icon: <Group />,
            text: t("Allow residents to call out to approved visitors"),
          },
          {
            icon: <Search />,
            text: t(
              "Monitor calls live and review recordings after they have happened"
            ),
          },
          {
            icon: <TabletAndroid />,
            text: t("Let residents make calls without the need of phones"),
          },
        ]}
        learnMoreText={t("Learn more about voice calls")}
        learnMoreLink={"https://www.ameelio.org/child-products/voice-calls"}
      />
    );
  }

  return (
    <>
      <ScreenTitle>{t("Voice calls")}</ScreenTitle>
      <ScreenSubtitle>
        {t(
          "Residents may call approved visitors who have Ameelio Connect on their phones."
        )}
      </ScreenSubtitle>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("Scheduling")}</SectionTitle>
            <SettingField
              label={t("Simultaneous voice calls")}
              description={t("How many voice calls can happen at one time.")}
              value={loadedFacility.maxVoiceCalls || 0}
              canEdit={canEdit}
            />
            <Divider />
            <NumberSettingField
              label={t("Voice call daily quota")}
              description={t(
                "Total amount of time allowed on voice calls each day."
              )}
              value={Math.ceil(
                loadedFacility.defaultVoiceCallTimeQuota / (60 * 1000)
              )}
              render={(value) => `${value} minute${value > 1 ? "s" : ""}`}
              endAdornment="minutes"
              onChange={(value) => {
                const ms = Number(value) * 60 * 1000;
                updateProperty("defaultVoiceCallTimeQuota", ms);
              }}
              validate={{
                min: (v) => v >= 0,
                max: (v) => v <= 1440,
                gteMaxCallDuration: (v) => {
                  const duration = loadedFacility.maxVoiceCallDuration || 0;
                  return v >= Math.ceil(duration / (60 * 1000));
                },
              }}
              errorMessages={{
                min: t("Cannot be negative."),
                max: t(
                  "Cannot be more than the number of minutes in an entire day (1,440)."
                ),
                gteMaxCallDuration: t(
                  "Cannot be less than the maximum call duration ({{minutes}} minutes)",
                  {
                    minutes:
                      Math.ceil(loadedFacility.maxVoiceCallDuration || 0) /
                      (60 * 1000),
                  }
                ),
              }}
              canEdit={canEdit}
            />
            <Divider />
            <NumberSettingField
              label={t("Maximum call duration")}
              description={t("The maximum length that a single call can be.")}
              render={(value) => `${value} minute${value > 1 ? "s" : ""}`}
              endAdornment="minutes"
              value={Math.ceil(
                (loadedFacility.maxVoiceCallDuration || 0) / (60 * 1000)
              )}
              validate={{
                min: (v) => v >= 0,
                max: (v) => v <= 60 * 6,
                lteDailyQuota: (v) => {
                  const quota = loadedFacility.defaultVoiceCallTimeQuota;
                  return v <= Math.ceil(quota / (60 * 1000));
                },
              }}
              errorMessages={{
                min: t("Cannot be negative."),
                max: t("Cannot be more than 6 hours (360 minutes)."),
                lteDailyQuota: t(
                  "Cannot be more than the voice call daily quota ({{minutes}} minutes).",
                  {
                    minutes: Math.ceil(
                      loadedFacility.defaultVoiceCallTimeQuota / (60 * 1000)
                    ),
                  }
                ),
              }}
              onChange={(value) => {
                const ms = Number(value) * 60 * 1000;
                updateProperty("maxVoiceCallDuration", ms);
              }}
              canEdit={canEdit}
            />
            <Divider />
            <NumberSettingField
              label={t("Rest time")}
              description={t(
                "The amount of time after a call before a resident can receive additional minutes."
              )}
              render={(value) => `${value} minute${value > 1 ? "s" : ""}`}
              endAdornment="minutes"
              value={Math.ceil(loadedFacility.voiceCallResetTime / (60 * 1000))}
              validate={{
                min: (v) => v >= 1,
                max: (v) => v <= 360, // mins in 6 hours
              }}
              errorMessages={{
                min: t("Must be at least 1 minute."),
                max: t("Cannot be more than 6 hours."),
              }}
              onChange={(value) =>
                updateProperty("voiceCallResetTime", Number(value) * 60 * 1000)
              }
              canEdit={canEdit}
            />
          </Stack>
        </Card>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("Privacy")}</SectionTitle>
            <RadioSettingField
              label={t("Voice call monitoring")}
              description={t("Whether facility staff can monitor calls")}
              value={loadedFacility.voiceCallPrivacyLevel}
              render={(value) =>
                value === PrivacyLevel.Monitored
                  ? t("Monitored")
                  : t("Unmonitored")
              }
              radioOptions={[
                {
                  value: PrivacyLevel.Monitored,
                  label: <MonitoredPrivacyLabel />,
                },
                {
                  value: PrivacyLevel.UnmonitoredLogged,
                  label: <UnmonitoredPrivacyLabel />,
                },
              ]}
              onChange={(value) => {
                if (value === PrivacyLevel.Monitored)
                  setShowPermissionsWarning(true);
                updateProperty("voiceCallPrivacyLevel", value);
              }}
              canEdit={canEdit}
            />
            {showPermissionsWarning && (
              <Dialog
                title={t("Assign permissions to staff")}
                onClose={() => setShowPermissionsWarning(false)}
                fullWidth
              >
                <DialogContent>
                  {t(
                    'In order for staff to monitor calls, you will need to assign that permission to some people. You can check to see if any staff have this permission by going to "Manage System" &gt; "All Staff".'
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => setShowPermissionsWarning(false)}
                  >
                    {t("Okay")}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Stack>
        </Card>
        {facilitySchedulesData && (
          <ScheduleTable
            title={t("Calling hours")}
            schedules={facilitySchedulesData.facility.schedules.filter(
              (s) =>
                s.meetingType === MeetingType.VoiceCall &&
                s.privacyLevels.includes(PrivacyLevel.Monitored)
            )}
            privacyLevel={PrivacyLevel.Monitored}
            meetingType={MeetingType.VoiceCall}
          />
        )}
      </Stack>
    </>
  );
}
