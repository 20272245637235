import { ApolloProvider } from "@apollo/client";
import getAuthenticatedClient from "@src/api/client";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";

type Props = {
  children: React.ReactNode;
};

export default function AuthenticatedClient({ children }: Props) {
  const navigate = useNavigate();
  const logout = useCallback(() => navigate("/logout"), [navigate]);
  const apolloClient = useMemo(() => getAuthenticatedClient(logout), [logout]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
