import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingFragmentFragmentDoc } from '../../graphql/fragments/MeetingFragment.generated';
import { VisitorFragmentFragmentDoc } from '../../graphql/fragments/VisitorFragment.generated';
export type UpdateScheduledMeetingMutationVariables = Types.Exact<{
  input: Types.UpdateScheduledMeetingInput;
}>;


export type UpdateScheduledMeetingMutation = { __typename: 'Mutation', updateScheduledMeeting: { __typename: 'UpdateScheduledMeetingResponse', meeting: { __typename: 'Meeting', id: string, schedulerId: string, unregisteredGuests: Array<string>, meetingType: Types.MeetingType, status: Types.MeetingStatus, statusDetails: string | null, privacyLevel: Types.PrivacyLevel, visitors: Array<{ __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string, malanId: string, phone: string | null, dateOfBirth: string | null, email: string }>, facility: { __typename: 'Facility', id: string, province: string }, call: { __typename: 'Call', id: string, rating: number | null, duration: number | null } | null, interval: { __typename: 'Interval', startAt: number, endAt: number, seconds: number | null } } } };


export const UpdateScheduledMeetingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateScheduledMeeting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateScheduledMeetingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateScheduledMeeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}}]}}]}}]}},...MeetingFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions]} as unknown as DocumentNode<UpdateScheduledMeetingMutation, UpdateScheduledMeetingMutationVariables>;