import { Box, Stack, Typography } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export default function DetailsStack({ children }: Props) {
  return <Stack spacing={1.5}>{children}</Stack>;
}

type Detail = {
  label: string;
  value?: string;
  children?: React.ReactNode;
  typographyVariant?: "body1" | "body2";
};

export function DetailsStackItem({
  label,
  value,
  children,
  typographyVariant,
}: Detail) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: { xs: "flex-start", md: "center" },
        flexDirection: { xs: "column", md: "row" },
      }}
      className="details-stack-item"
    >
      <Box sx={{ width: 160, minWidth: 160, flex: 0 }}>
        <Typography
          variant={typographyVariant || "body1"}
          color="text.secondary"
          className="details-stack-item-label"
        >
          {label}
        </Typography>
      </Box>

      {children || (
        <Typography variant={typographyVariant || "body1"} color="text.primary">
          {value}
        </Typography>
      )}
    </Box>
  );
}
