import { LockOutlined } from "@ant-design/icons";
import Result from "@src/lib/Result";
import React from "react";
import { useTranslation } from "react-i18next";

const NotAllowed: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Result
      icon={<LockOutlined />}
      title={t("Sorry, you are not able to access this page")}
      subTitle={t("This page is only viewable by select users.")}
    />
  );
};

export default NotAllowed;
