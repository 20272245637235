import { Button, Dialog, DialogActions, DialogContent } from "@ameelio/ui";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { MeetingStatus } from "@src/api/graphql";
import compact from "@src/lib/compact";
import { toCSVBlob } from "@src/lib/toCSV";
import { Facility } from "@src/typings/Facility";
import saveAs from "file-saver";
import i18n from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { meetingsToCsvLogs, ReportType } from "../Call";
import { meetingTypeTitle } from "../meeting";
import { yearMonthDate } from "../Time";
import useFullSchedule, { Filters } from "./useFullSchedule";

export type Props = {
  facility: Pick<Facility, "id" | "name">;
  filters: Filters;
  onClose: () => void;
};
const meetingStatusLabels: Record<MeetingStatus, string> = {
  [MeetingStatus.Cancelled]: i18n.t("Cancelled"),
  [MeetingStatus.Ended]: i18n.t("Ended"),
  [MeetingStatus.Live]: i18n.t("Live"),
  [MeetingStatus.NoShow]: i18n.t("No show"),
  [MeetingStatus.PendingApproval]: i18n.t("Pending approval"),
  [MeetingStatus.Rejected]: i18n.t("Declined"),
  [MeetingStatus.Scheduled]: i18n.t("Scheduled"),
  [MeetingStatus.Terminated]: i18n.t("Terminated"),
};

const RadioReportType = ({
  value,
  label,
}: {
  value: ReportType;
  label: string;
}) => <FormControlLabel value={value} control={<Radio />} label={label} />;

const ExportScheduleModal: React.FC<Props> = ({
  filters,
  facility,
  onClose,
}) => {
  const [reportType, setReportType] = useState<ReportType>("admin");
  const [generating, setGenerating] = useState(false);

  const { t } = useTranslation();

  const dateRange = compact([
    filters.scheduledStartAfter,
    filters.scheduledStartBefore,
  ])
    .map(yearMonthDate)
    .join("-");
  const fileName = `Logs ${facility.name} ${dateRange} - ${Date.now()}`;

  const { data, loading } = useFullSchedule({
    facilityId: facility.id,
    filters,
  });

  const saveAndClose = () => {
    setGenerating(true);
    const blob = toCSVBlob(meetingsToCsvLogs(data, reportType));
    saveAs(blob, `${fileName}.csv`);
    onClose();
  };

  return (
    <Dialog
      title={t("Export {{type}} logs", {
        type: filters.meetingType
          ? meetingTypeTitle(filters.meetingType)
          : "meeting",
      })}
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={2}>
          <Box sx={{ flexDirection: "row" }}>
            <label className="font-bold">{t("Dates")}</label>
            <div>
              {yearMonthDate(filters.scheduledStartAfter)}
              {" - "}
              {filters.scheduledStartBefore &&
                yearMonthDate(filters.scheduledStartBefore)}
            </div>
          </Box>
          <Box sx={{ flexDirection: "row" }}>
            <label className="font-bold">{t("Statuses")}</label>
            <div>
              {filters.meetingStatus.length > 0
                ? filters.meetingStatus
                    .map((s) => meetingStatusLabels[s])
                    .join(", ")
                : t("ALL")}
            </div>
          </Box>
          <Box sx={{ flexDirection: "row" }}>
            <label className="font-bold">{t("Report type")}</label>
            <div>
              <FormControl>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    setReportType(e.target.value as ReportType);
                  }}
                  value={reportType}
                >
                  <RadioReportType value="public" label={t("Public")} />
                  <RadioReportType value="admin" label={t("Admin")} />
                </RadioGroup>
              </FormControl>
            </div>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => (loading || generating ? null : saveAndClose())}
        >
          {loading
            ? t("Loading...")
            : generating
            ? t("Generating...")
            : t("Export")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportScheduleModal;
