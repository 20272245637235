import { Typography } from "@mui/material";
import {
  Connection,
  ConnectionRestriction,
  Organization,
  OrganizationMembership,
  Visitor,
} from "@src/api/graphql";
import connectionRelationshipLabel from "@src/lib/connectionRelationshipLabel";
import { useTranslation } from "react-i18next";
import AvatarCard from "./AvatarCard";
import { isMinor } from "./Common";

type Props = {
  connection: Pick<Connection, "id" | "relationship"> & {
    visitor: Pick<Visitor, "id" | "fullName" | "dateOfBirth">;
    organizationMembership?:
      | (Pick<OrganizationMembership, "id"> & {
          organization: Pick<Organization, "name">;
        })
      | null;
    restrictions?: Pick<ConnectionRestriction, "id" | "isActive">[];
  };
};

export default function VisitorContactCard({ connection }: Props) {
  const { t } = useTranslation();
  const { visitor, organizationMembership, restrictions } = connection;
  const hasActiveRestriction = restrictions?.some((r) => r.isActive);
  return (
    <AvatarCard to={`/visitors/${visitor.id}`} name={visitor.fullName}>
      <Typography component="p" variant="caption" color="text.secondary">
        {organizationMembership
          ? organizationMembership.organization.name
          : connectionRelationshipLabel(connection.relationship)}
        {hasActiveRestriction && (
          <Typography component="span" variant="caption" color="error.main">
            {" "}
            ({t("Restricted")})
          </Typography>
        )}
        {isMinor({ dateOfBirth: visitor.dateOfBirth }) && (
          <Typography component="span" variant="caption" color="error.main">
            {" "}
            ({t("Minor")})
          </Typography>
        )}
      </Typography>
    </AvatarCard>
  );
}
