import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  VideoHTMLAttributes,
} from "react";

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
  srcObject?: MediaStream;
  underlyingRef?: MutableRefObject<HTMLVideoElement | null>;
  isFadingOut?: boolean;
};

export default function Video({
  srcObject,
  isFadingOut,
  underlyingRef,
  ...props
}: PropsType) {
  const refVideo = useRef<HTMLVideoElement | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!refVideo.current) return;
    refVideo.current.srcObject = srcObject || null;
  }, [srcObject]);

  useEffect(() => {
    if (!refVideo.current) return;
    refVideo.current.addEventListener("loadeddata", () => setLoading(false));
  }, []);

  return (
    <video
      ref={(el) => {
        refVideo.current = el;
        if (underlyingRef) underlyingRef.current = el;
      }}
      {...props}
      style={{
        ...props.style,
        opacity: loading || isFadingOut ? 0 : 1,
      }}
    />
  );
}
