import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
  TextInputBase,
} from "@ameelio/ui";
import { Stack, Typography } from "@mui/material";
import { Radio } from "antd";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (payload: { tag: string; description: string }) => void;
  loading: boolean;
  artifactType: "recording" | "screenshot";
}

enum Tags {
  MissingVideo = "missing-video",
  MissingAudio = "missing-audio",
  Other = "other",
  NoScreenshots = "no-screenshots",
}

const tagsByArtifactType = {
  recording: [Tags.MissingVideo, Tags.MissingAudio, Tags.Other],
  screenshot: [Tags.NoScreenshots, Tags.Other],
};

function getTagLabel(tag: Tags): string {
  switch (tag) {
    case Tags.MissingVideo:
      return i18n.t("Missing video");
    case Tags.MissingAudio:
      return i18n.t("Missing audio");
    case Tags.Other:
      return i18n.t("Other");
    case Tags.NoScreenshots:
      return i18n.t("No screenshots");
  }
}

const toggleTag = (tagToToggle: Tags, list: Tags[]) => {
  if (list.includes(tagToToggle)) {
    return list.filter((tag) => tag !== tagToToggle);
  }
  return [...list, tagToToggle];
};

export default function ReportArtifactProblemModal({
  visible,
  onCancel,
  onSubmit,
  loading,
  artifactType,
}: Props) {
  const [tags, setTags] = useState<Tags[]>([]);
  const [detail, setDetail] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    if (!visible) {
      setTags([]);
      setDetail("");
    }
  }, [visible]);

  return visible ? (
    <Dialog onClose={onCancel} title={t("Report problem")}>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            {artifactType === "recording"
              ? t(
                  "Please note that the original file is intact, but occassionally problems occur when sharing it. We are here to help troubleshoot and get you the information you need."
                )
              : t(
                  "We are here to help troubleshoot and get you the information you need."
                )}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {t("What went wrong? (Select all that apply)")}
          </Typography>
          <Stack direction="row" spacing={2}>
            {tagsByArtifactType[artifactType].map((tag) => (
              <Radio.Group
                value={tags.includes(tag)}
                optionType="button"
                buttonStyle="solid"
                key={tag}
              >
                <Radio
                  key={tag}
                  value={true}
                  onClick={() => setTags(toggleTag(tag, tags))}
                >
                  {getTagLabel(tag)}
                </Radio>
              </Radio.Group>
            ))}
          </Stack>
          <TextInputBase
            fullWidth
            label={t("Details")}
            multiline
            minRows={2}
            maxRows={2}
            value={detail}
            placeholder={t("Provide as much detail as you can...")}
            onChange={(e) => {
              setDetail(e.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack spacing={1} direction="row">
          <Button variant="outlined" key="cancel" onClick={onCancel}>
            {t("Cancel")}
          </Button>
          <SubmitButton
            key="submit"
            submitting={loading}
            disabled={!tags.length && !detail.trim()}
            disabledReason={t(
              "Please select at least one option, or describe the details of the problem."
            )}
            onClick={() =>
              onSubmit({
                tag: (!tags.length ? [Tags.Other] : tags)
                  .map((tag) => tag.toString())
                  .join(", "),
                description: detail.trim(),
              })
            }
          >
            {t("Send")}
          </SubmitButton>
        </Stack>
      </DialogActions>
    </Dialog>
  ) : null;
}
