import { CallStatus, Peer } from "@ameelio/connect-call-client";
import { Button, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import { getFullNames } from "@src/lib/Call";
import { useCallRegistry } from "@src/lib/LiveCall/CallRegistry";
import SendAlertDialog from "@src/lib/LiveCall/SendAlertDialog";
import { TypographyText } from "@src/lib/typography";
import { openNotificationWithIcon } from "@src/lib/UI";
import useBoolean from "@src/lib/useBoolean";
import { Popconfirm } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarFacepile from "../../lib/AvatarFacepile";
import { VoiceCall } from "./VoiceCallAudio";

type Props = {
  vc: VoiceCall;
  onStopListening: () => void;
  onTerminate: () => Promise<void>;
  peers: Peer[];
  callStatus?: CallStatus;
};

const VoiceCallPlayer = ({
  vc,
  onTerminate,
  onStopListening,
  peers,
  callStatus,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const sendAlertDialog = useBoolean();
  const { t } = useTranslation();

  const participants = [...vc.inmates, ...vc.visitors].filter((p) =>
    peers.some((peer) => peer.user.id === p.id)
  );

  // figure out which calls are alive in both the API and CVH
  const { registry } = useCallRegistry();
  const onAlert = async (message: string) => {
    try {
      await registry[vc.id].sendMessage(message);
      openNotificationWithIcon(
        t("Alert successfully issued."),
        t("Both parties have been notified."),
        "success"
      );
    } catch (e) {
      openNotificationWithIcon(
        t("Alert could not be sent."),
        `${t("Error message")}: ${e}`,
        "error"
      );
    }
  };

  const handleTerminate = async () => {
    setLoading(true);
    try {
      await onTerminate();
      onStopListening();
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-2 p-8">
      {sendAlertDialog.current && (
        <SendAlertDialog
          type="voice"
          onClose={sendAlertDialog.disable}
          onSend={(message: string) => {
            if (!onAlert) return;
            onAlert(message);
          }}
        />
      )}
      <span className="font-bold">
        {participants.map((p) => getFullNames([p])).join(" & ")}
      </span>
      <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
        <div className="flex gap-2 items-center">
          <AvatarFacepile people={participants} />
          {participants.length > 0 ? (
            <TypographyText type="secondary">
              {participants.map((p) => getFullNames([p])).join(" & ")}
              {participants.length > 1 ? " are both " : " is "}
              {t("on the call")}
            </TypographyText>
          ) : (
            <TypographyText type="secondary">
              {t("Nobody has joined the call yet.")}
            </TypographyText>
          )}
        </div>
        {callStatus === CallStatus.live && (
          <div className="flex gap-2" style={{ alignItems: "center" }}>
            <Button variant="outlined" onClick={sendAlertDialog.enable}>
              {t("Send alert")}
            </Button>
            <Button onClick={onStopListening} variant="outlined" size="small">
              {t("Stop Listening")}
            </Button>
            <Popconfirm
              title={t("Are you sure you want to terminate this voice call?")}
              onConfirm={handleTerminate}
              okText={t("Yes")}
              cancelText={t("No")}
            >
              <Button
                variant="outlined"
                color="error"
                disabled={loading}
                size="small"
              >
                {t("Terminate")}
              </Button>
            </Popconfirm>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default VoiceCallPlayer;
