import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FacilityFragmentFragmentDoc } from './fragments/FacilityFragment.generated';
export type UpdateFacilityMutationVariables = Types.Exact<{
  input: Types.UpdateFacilityInput;
}>;


export type UpdateFacilityMutation = { __typename: 'Mutation', updateFacility: { __typename: 'UpdateFacilityResponse', facility: { __typename: 'Facility', id: string, name: string, state: Types.UsState, province: string, country: string, email: string, phone: string, timezone: string, policyLink: string, hasMessaging: boolean, features: Array<Types.FacilityFeature>, publicId: string, defaultCallQuota: number, defaultVisitQuota: number, videoQuotaResetDay: number, visitQuotaResetDay: number, videoNeedsApproval: boolean, visitNeedsApproval: boolean, personalVideoWindow: number, personalVideoCutoff: number, personalVisitWindow: number, personalVisitCutoff: number, providerVideoCutoff: number, providerVisitCutoff: number, providerVisitWindow: number, voiceCallPrivacyLevel: Types.PrivacyLevel, providerVideoWindow: number, visitationFormLink: string | null, defaultEmessageDelay: number, defaultVoiceCallTimeQuota: number, maxVoiceCallDuration: number | null, maxVoiceCalls: number | null, voiceCallResetTime: number, defaultNeedsMonitor: boolean, system: { __typename: 'System', id: string, recordingRetention: number } } } };


export const UpdateFacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFacility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFacilityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFacility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityFragment"}}]}}]}}]}},...FacilityFragmentFragmentDoc.definitions]} as unknown as DocumentNode<UpdateFacilityMutation, UpdateFacilityMutationVariables>;