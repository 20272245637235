export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: number;
};

export type AcceptMeetingInput = {
  meetingId: Scalars["ID"];
};

export type AcceptMeetingResponse = {
  __typename: "AcceptMeetingResponse";
  meeting: Meeting;
};

export type AcceptOrganizationInvitationInput = {
  inviteCode: Scalars["String"];
};

export type AcceptOrganizationInvitationResponse = {
  __typename: "AcceptOrganizationInvitationResponse";
  organizationMembership: OrganizationMembership;
};

export type Address = {
  __typename: "Address";
  city: Scalars["String"];
  country: Scalars["String"];
  lineOne: Scalars["String"];
  lineTwo?: Maybe<Scalars["String"]>;
  postalCode: Scalars["String"];
  province: Scalars["String"];
};

export type AdultInmatesResponse = {
  __typename: "AdultInmatesResponse";
  minorsOmitted: Scalars["Boolean"];
  results: Array<InmateSearchResult>;
};

export type ApproveMessageInput = {
  messageId: Scalars["ID"];
};

export type ApproveMessageResponse = {
  __typename: "ApproveMessageResponse";
  message: Message;
};

export type ApproveSystemVisitorInput = {
  systemVisitorId: Scalars["String"];
};

export type ApproveSystemVisitorResponse = {
  __typename: "ApproveSystemVisitorResponse";
  systemVisitor: SystemUser;
};

export type ArchiveConnectionInput = {
  connectionId: Scalars["ID"];
};

export type ArchiveConnectionResponse = {
  __typename: "ArchiveConnectionResponse";
  connection: Connection;
};

export type Call = {
  __typename: "Call";
  artifactsExpireAt?: Maybe<Scalars["Date"]>;
  ciscoJWT?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  /** @deprecated No longer supported */
  expiresAt?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  meeting: Meeting;
  messages: CallMessageConnection;
  rating?: Maybe<Scalars["Int"]>;
  recording?: Maybe<Recording>;
  screenshots: Array<Screenshot>;
  screenshotsExpireAt?: Maybe<Scalars["Date"]>;
  status: CallStatus;
  timelineEvents: Array<StaffTimelineEvent>;
  token?: Maybe<Scalars["String"]>;
  transcript?: Maybe<Transcript>;
  url?: Maybe<Scalars["String"]>;
};

export type CallMessage = {
  __typename: "CallMessage";
  contents: Scalars["String"];
  createdAt: Scalars["Date"];
  id: Scalars["ID"];
  sender: User;
};

export type CallMessageConnection = {
  __typename: "CallMessageConnection";
  edges: Array<CallMessageEdge>;
  pageInfo: PageInfo;
};

export type CallMessageEdge = {
  __typename: "CallMessageEdge";
  cursor: Scalars["String"];
  node: CallMessage;
};

export enum CallStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type CancelMeetingInput = {
  meetingId: Scalars["ID"];
  participantComment?: InputMaybe<ParticipantComment>;
};

export type CancelMeetingResponse = {
  __typename: "CancelMeetingResponse";
  meeting: Meeting;
};

export enum Client {
  Dashboard = "DASHBOARD",
  Mobile = "MOBILE",
  Web = "WEB",
}

export type Connection = {
  __typename: "Connection";
  activeRestrictions: Array<ConnectionRestriction>;
  confidential: Scalars["Boolean"];
  connectionCreatedTimelineEvent?: Maybe<ConnectionTimelineEvent>;
  contact: Correspondent;
  createdAt: Scalars["Date"];
  features: Array<ConnectionFeature>;
  fileGallery: MessageConnection;
  id: Scalars["ID"];
  inmate: Inmate;
  kioskAvailability: Array<KioskAvailability>;
  lastInteractionAt: Scalars["Date"];
  lastMessage?: Maybe<Message>;
  lastMessageCenterActivityAt: Scalars["Date"];
  meetingAvailability: Array<MeetingAvailability>;
  meetings: Array<Meeting>;
  messages: MessageConnection;
  organizationMembership?: Maybe<OrganizationMembership>;
  relationship: Scalars["String"];
  restrictions: Array<ConnectionRestriction>;
  status: ConnectionStatus;
  statusChangedAt: Scalars["Date"];
  statusDetails?: Maybe<Scalars["String"]>;
  timelineEvents: ConnectionTimelineEventConnection;
  visitor: Visitor;
  visitorSystemRelationship: SystemUser;
};

export type ConnectionFileGalleryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ConnectionKioskAvailabilityArgs = {
  meetingType?: InputMaybe<MeetingType>;
};

export type ConnectionMeetingAvailabilityArgs = {
  ignoreMeetingId?: InputMaybe<Scalars["ID"]>;
  meetingType?: InputMaybe<MeetingType>;
  showUntimely?: InputMaybe<Scalars["Boolean"]>;
};

export type ConnectionMeetingsArgs = {
  meetingType?: InputMaybe<Array<MeetingType>>;
  status?: InputMaybe<Array<MeetingStatus>>;
};

export type ConnectionTimelineEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export enum ConnectionFeature {
  ConfidentialInPersonVisit = "CONFIDENTIAL_IN_PERSON_VISIT",
  ConfidentialVideoCall = "CONFIDENTIAL_VIDEO_CALL",
  InPersonVisit = "IN_PERSON_VISIT",
  SendMessages = "SEND_MESSAGES",
  VideoCall = "VIDEO_CALL",
  VoiceCall = "VOICE_CALL",
  Webinar = "WEBINAR",
}

export type ConnectionRestriction = {
  __typename: "ConnectionRestriction";
  connection: Connection;
  createdAt: Scalars["Date"];
  createdBy: StaffPosition;
  expiresAt?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  reason: Scalars["String"];
  service: FacilityService;
};

export enum ConnectionStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type ConnectionTimelineEvent = {
  __typename: "ConnectionTimelineEvent";
  connection: Connection;
  createdAt: Scalars["Date"];
  id: Scalars["ID"];
  object: ConnectionTimelineEventObject;
  verb: ConnectionTimelineEventVerb;
};

export type ConnectionTimelineEventConnection = {
  __typename: "ConnectionTimelineEventConnection";
  edges: Array<ConnectionTimelineEventEdge>;
  pageInfo: PageInfo;
};

export type ConnectionTimelineEventEdge = {
  __typename: "ConnectionTimelineEventEdge";
  cursor: Scalars["String"];
  node: ConnectionTimelineEvent;
};

export type ConnectionTimelineEventObject =
  | Connection
  | ConnectionRestriction
  | Meeting
  | Message;

export enum ConnectionTimelineEventVerb {
  Create = "CREATE",
  Modify = "MODIFY",
}

export type Correspondent = {
  availableUploadTypes: Array<MessageType>;
  canReceiveVoiceCalls: Scalars["Boolean"];
  connections: Array<Connection>;
  correspondentFeatures: Array<CorrespondentFeature>;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  initials: Scalars["String"];
  languagePreference: Language;
  lastName: Scalars["String"];
  malanId: Scalars["ID"];
  meetings: MeetingConnection;
  notificationEvents: NotificationEventConnection;
  profileImageUrl?: Maybe<Scalars["String"]>;
  unavailableUntil?: Maybe<Scalars["Date"]>;
};

export type CorrespondentConnectionsArgs = {
  facilityId?: InputMaybe<Scalars["ID"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
};

export type CorrespondentMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  facilityId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  meetingType?: InputMaybe<Array<MeetingType>>;
  scheduledEndAfter?: InputMaybe<Scalars["Date"]>;
  status?: InputMaybe<Array<MeetingStatus>>;
};

export type CorrespondentNotificationEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export enum CorrespondentFeature {
  MakePhoneCalls = "MAKE_PHONE_CALLS",
  MakeVoiceCalls = "MAKE_VOICE_CALLS",
  RequestConnections = "REQUEST_CONNECTIONS",
  ScheduleMeetings = "SCHEDULE_MEETINGS",
}

export type CountGroup = {
  __typename: "CountGroup";
  count: Scalars["Int"];
  group: Scalars["String"];
};

export type CreateCallReportInput = {
  comment?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  meetingId: Scalars["ID"];
  rating: Scalars["Int"];
};

export type CreateCallReportResponse = {
  __typename: "CreateCallReportResponse";
  meeting: Meeting;
};

export type CreateConnectionRestrictionInput = {
  connectionId: Scalars["ID"];
  expiresAt?: InputMaybe<Scalars["Date"]>;
  reason: Scalars["String"];
  service: FacilityService;
};

export type CreateConnectionRestrictionResponse = {
  __typename: "CreateConnectionRestrictionResponse";
  connectionRestriction: ConnectionRestriction;
};

export type CreateConnectionRestrictionsInput = {
  connectionIds: Array<Scalars["ID"]>;
  expiresAt?: InputMaybe<Scalars["Date"]>;
  reason: Scalars["String"];
  services: Array<FacilityService>;
};

export type CreateConnectionRestrictionsResponse = {
  __typename: "CreateConnectionRestrictionsResponse";
  errors?: Maybe<Array<Scalars["String"]>>;
  restrictions: Array<ConnectionRestriction>;
};

export type CreateFacilityApprovedPhoneInput = {
  description: Scalars["String"];
  facilityId: Scalars["ID"];
  name: Scalars["String"];
  number: Scalars["String"];
  privacyLevel: PrivacyLevel;
};

export type CreateFacilityApprovedPhoneResponse = {
  __typename: "CreateFacilityApprovedPhoneResponse";
  facilityApprovedPhone: FacilityApprovedPhone;
};

export type CreateFacilityInput = {
  country: Scalars["String"];
  name: Scalars["String"];
  province: Scalars["String"];
  publicId: Scalars["String"];
  state: UsState;
  systemId: Scalars["String"];
  timezone: Scalars["String"];
  type: FacilityType;
};

export type CreateFacilityResponse = {
  __typename: "CreateFacilityResponse";
  facility: Facility;
};

export type CreateGroupInput = {
  description: Scalars["String"];
  facilityId: Scalars["ID"];
  name: Scalars["String"];
};

export type CreateGroupResponse = {
  __typename: "CreateGroupResponse";
  group: Group;
};

export type CreateInstantReplyMessageInput = {
  content: InstantReply;
  meetingId: Scalars["ID"];
};

export type CreateInstantReplyMessageResponse = {
  __typename: "CreateInstantReplyMessageResponse";
  event: ConnectionTimelineEvent;
  message: Message;
};

export type CreateKioskInput = {
  capacity: Scalars["Int"];
  enabled: Scalars["Boolean"];
  facilityId: Scalars["ID"];
  meetingType: MeetingType;
  name: Scalars["String"];
};

export type CreateKioskResponse = {
  __typename: "CreateKioskResponse";
  kiosk: Kiosk;
};

export type CreateLocalMessageKeywordInput = {
  category: MessageKeywordCategory;
  description: Scalars["String"];
  facilityId: Scalars["ID"];
  name: Scalars["String"];
  spellings: Array<Scalars["String"]>;
};

export type CreateLocalMessageKeywordResponse = {
  __typename: "CreateLocalMessageKeywordResponse";
  messageKeyword: MessageKeyword;
};

export type CreateMessageInput = {
  connectionId: Scalars["ID"];
  content?: InputMaybe<Scalars["String"]>;
  fileKey?: InputMaybe<Scalars["String"]>;
  fileName?: InputMaybe<Scalars["String"]>;
};

export type CreateMessageResponse = {
  __typename: "CreateMessageResponse";
  event: ConnectionTimelineEvent;
  message: Message;
};

export type CreateOrganizationInput = {
  addressLineOne: Scalars["String"];
  addressLineTwo?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  country: Scalars["String"];
  description: Scalars["String"];
  ein?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  legalName?: InputMaybe<Scalars["String"]>;
  logoImageKey: Scalars["String"];
  missionStatement?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  phone: Scalars["String"];
  postal: Scalars["String"];
  province: Scalars["String"];
  state: UsState;
  type: OrganizationType;
  websiteLink?: InputMaybe<Scalars["String"]>;
};

export type CreateOrganizationInvitationInput = {
  email: Scalars["String"];
  organizationId: Scalars["ID"];
  role: OrganizationMembershipRole;
};

export type CreateOrganizationInvitationResponse = {
  __typename: "CreateOrganizationInvitationResponse";
  organizationInvitation: OrganizationInvitation;
};

export type CreateOrganizationMembershipInput = {
  email: Scalars["String"];
  organizationId: Scalars["ID"];
};

export type CreateOrganizationMembershipResponse = {
  __typename: "CreateOrganizationMembershipResponse";
  organizationMembership: OrganizationMembership;
};

export type CreateOrganizationResponse = {
  __typename: "CreateOrganizationResponse";
  organization: Organization;
};

export type CreatePendingConnectionInput = {
  inmateId: Scalars["ID"];
  relationship: Scalars["String"];
};

export type CreatePendingConnectionResponse = {
  __typename: "CreatePendingConnectionResponse";
  connection: Connection;
};

export type CreatePendingProfessionalConnectionInput = {
  inmateId: Scalars["ID"];
  organizationMembershipId: Scalars["ID"];
};

export type CreatePendingProfessionalConnectionResponse = {
  __typename: "CreatePendingProfessionalConnectionResponse";
  connection: Connection;
};

export type CreateRecordingInput = {
  meetingId: Scalars["ID"];
};

export type CreateRecordingResponse = {
  __typename: "CreateRecordingResponse";
  call: Call;
  success: Scalars["Boolean"];
};

export type CreateScheduleInput = {
  endsOn?: InputMaybe<Scalars["String"]>;
  facilityId: Scalars["ID"];
  groupIds: Array<Scalars["ID"]>;
  kioskIds: Array<Scalars["ID"]>;
  meetingSlots: Array<Slot>;
  meetingType: MeetingType;
  name: Scalars["String"];
  privacyLevels: Array<PrivacyLevel>;
  startsOn?: InputMaybe<Scalars["String"]>;
};

export type CreateScheduleResponse = {
  __typename: "CreateScheduleResponse";
  schedule: Schedule;
};

export type CreateScheduledMeetingInput = {
  connectionIds?: InputMaybe<Array<Scalars["ID"]>>;
  facilityId?: InputMaybe<Scalars["ID"]>;
  inmateIds?: InputMaybe<Array<Scalars["ID"]>>;
  kioskId?: InputMaybe<Scalars["ID"]>;
  meetingType: MeetingType;
  notes?: InputMaybe<Scalars["String"]>;
  scheduledEnd: Scalars["Date"];
  scheduledStart: Scalars["Date"];
  title?: InputMaybe<Scalars["String"]>;
  unregisteredGuests?: InputMaybe<Array<Scalars["String"]>>;
  visitorIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CreateScheduledMeetingResponse = {
  __typename: "CreateScheduledMeetingResponse";
  meeting: Meeting;
};

export type CreateStaffInput = {
  email: Scalars["String"];
  entitlements: Array<Entitlement>;
  facilityIds: Array<Scalars["ID"]>;
  firstName: Scalars["String"];
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  systemId: Scalars["ID"];
};

export type CreateStaffResponse = {
  __typename: "CreateStaffResponse";
  staff: Staff;
};

export type CreateSurveyImpressionInput = {
  client: Client;
  code: Scalars["String"];
  id: Scalars["ID"];
  language: Language;
  screen: Scalars["String"];
  version: Scalars["Int"];
};

export type CreateSurveyImpressionResponse = {
  __typename: "CreateSurveyImpressionResponse";
  success: Scalars["Boolean"];
};

export type CreateSurveyResponseInput = {
  answers: Array<SurveyAnswer>;
  surveyImpressionId: Scalars["ID"];
};

export type CreateSurveyResponseResponse = {
  __typename: "CreateSurveyResponseResponse";
  success: Scalars["Boolean"];
};

export type CreateSystemInput = {
  ameelioStaffPassword: Scalars["String"];
  code: Scalars["String"];
  name: Scalars["String"];
  recordingRetention: Scalars["Int"];
};

export type CreateSystemResponse = {
  __typename: "CreateSystemResponse";
  system: System;
};

export type CreateTestCallResponse = {
  __typename: "CreateTestCallResponse";
  callId: Scalars["ID"];
  token: Scalars["String"];
  url: Scalars["String"];
};

export type CreateVisitorIdentityInput = {
  dateOfBirth: Scalars["String"];
  documentType: IdDocumentType;
  firstName: Scalars["String"];
  imageOneKey: Scalars["String"];
  imageThreeKey: Scalars["String"];
  imageTwoKey: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
};

export type CreateVisitorIdentityResponse = {
  __typename: "CreateVisitorIdentityResponse";
  /** @deprecated No longer supported */
  identity: VisitorIdentity;
  visitor: Visitor;
};

export type CreateVisitorInput = {
  dateOfBirth: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
};

export type CreateVisitorResponse = {
  __typename: "CreateVisitorResponse";
  authToken: Scalars["String"];
};

export type CreateVoiceCallInput = {
  connectionId: Scalars["ID"];
};

export type CreateVoiceCallResponse = {
  __typename: "CreateVoiceCallResponse";
  event: ConnectionTimelineEvent;
  meeting: Meeting;
};

export type CredentialsInput = {
  password: Scalars["String"];
  userType: UserType;
  username: Scalars["String"];
};

export type DeactivateConnectionInput = {
  connectionId: Scalars["ID"];
};

export type DeactivateConnectionResponse = {
  __typename: "DeactivateConnectionResponse";
  success: Scalars["Boolean"];
};

export type DeactivateFacilityInput = {
  facilityId: Scalars["ID"];
};

export type DeactivateFacilityResponse = {
  __typename: "DeactivateFacilityResponse";
  success: Scalars["Boolean"];
};

export type DeactivateLocalMessageKeywordInput = {
  messageKeywordId: Scalars["ID"];
};

export type DeactivateLocalMessageKeywordResponse = {
  __typename: "DeactivateLocalMessageKeywordResponse";
  messageKeyword: MessageKeyword;
};

export type DeactivateOrganizationMembershipInput = {
  organizationMembershipId: Scalars["ID"];
};

export type DeactivateOrganizationMembershipResponse = {
  __typename: "DeactivateOrganizationMembershipResponse";
  organizationMembership: OrganizationMembership;
};

export type DeactivateStaffInput = {
  staffId: Scalars["ID"];
  systemId: Scalars["ID"];
};

export type DeactivateStaffPositionInput = {
  staffPositionId: Scalars["ID"];
};

export type DeactivateStaffPositionResponse = {
  __typename: "DeactivateStaffPositionResponse";
  staffPosition: StaffPosition;
};

export type DeactivateStaffResponse = {
  __typename: "DeactivateStaffResponse";
  staff: Staff;
};

export type DeclineVoiceCallInput = {
  meetingId: Scalars["ID"];
};

export type DeclineVoiceCallResponse = {
  __typename: "DeclineVoiceCallResponse";
  meeting: Meeting;
};

export type DeleteGroupInput = {
  groupId: Scalars["ID"];
};

export type DeleteGroupResponse = {
  __typename: "DeleteGroupResponse";
  success: Scalars["Boolean"];
};

export type DeleteScheduleInput = {
  scheduleId: Scalars["ID"];
};

export type DeleteScheduleResponse = {
  __typename: "DeleteScheduleResponse";
  success: Scalars["Boolean"];
};

export type DeleteVisitorInput = {
  visitorId: Scalars["ID"];
};

export type DeleteVisitorResponse = {
  __typename: "DeleteVisitorResponse";
  scheduledDeletionAt: Scalars["Date"];
};

export type Device = {
  __typename: "Device";
  active: Scalars["Boolean"];
  appVersion?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Date"];
  id: Scalars["ID"];
  malanUserId: Scalars["ID"];
  type: DeviceType;
  updatedAt: Scalars["Date"];
};

export enum DeviceType {
  Android = "ANDROID",
  Ios = "IOS",
}

export type DownloadScreenshotsInput = {
  meetingId: Scalars["ID"];
};

export type DownloadScreenshotsResponse = {
  __typename: "DownloadScreenshotsResponse";
  url: Scalars["String"];
};

export type EndVoiceCallInput = {
  meetingId: Scalars["ID"];
  status?: InputMaybe<MeetingStatus>;
  userInitiated?: InputMaybe<Scalars["Boolean"]>;
};

export type EndVoiceCallResponse = {
  __typename: "EndVoiceCallResponse";
  meeting: Meeting;
};

export enum Entitlement {
  ManageFacility = "MANAGE_FACILITY",
  ManageStaff = "MANAGE_STAFF",
  ManageVisitorsAndMeetings = "MANAGE_VISITORS_AND_MEETINGS",
  MonitorLiveCalls = "MONITOR_LIVE_CALLS",
  ReviewEmessages = "REVIEW_EMESSAGES",
  ReviewRecordings = "REVIEW_RECORDINGS",
  ReviewScreenshots = "REVIEW_SCREENSHOTS",
  UploadSystemRoster = "UPLOAD_SYSTEM_ROSTER",
}

export type ExtendSessionResponse = {
  __typename: "ExtendSessionResponse";
  expiresAt?: Maybe<Scalars["String"]>;
  remainingDuration?: Maybe<Scalars["Int"]>;
};

export type Facility = {
  __typename: "Facility";
  /** @deprecated No longer supported */
  allInmates: Array<Inmate>;
  approvedPhones: Array<FacilityApprovedPhone>;
  availableKiosks: Array<Kiosk>;
  city: Scalars["String"];
  connectedOrganizations: Array<Organization>;
  country: Scalars["String"];
  defaultCallQuota: Scalars["Int"];
  defaultEmessageDelay: Scalars["Int"];
  defaultNeedsMonitor: Scalars["Boolean"];
  defaultVisitQuota: Scalars["Int"];
  defaultVoiceCallTimeQuota: Scalars["Int"];
  email: Scalars["String"];
  /** @deprecated No longer supported */
  facilityOrganizations: Array<FacilityOrganization>;
  features: Array<FacilityFeature>;
  /** @deprecated No longer supported */
  fullName: Scalars["String"];
  groups: Array<Group>;
  /** @deprecated No longer supported */
  hasMessaging: Scalars["Boolean"];
  hasProviders: Scalars["Boolean"];
  id: Scalars["ID"];
  inmates: InmateConnection;
  keywords: Array<MessageKeyword>;
  kiosks: Array<Kiosk>;
  maxVoiceCallDuration?: Maybe<Scalars["Int"]>;
  maxVoiceCalls?: Maybe<Scalars["Int"]>;
  meetings: MeetingConnection;
  minimumAgeIdRequired: Scalars["Int"];
  name: Scalars["String"];
  pendingConnectionRequests: Array<Connection>;
  pendingMessages: MessageConnection;
  personalVideoCutoff: Scalars["Int"];
  personalVideoWindow: Scalars["Int"];
  personalVisitCutoff: Scalars["Int"];
  personalVisitWindow: Scalars["Int"];
  phone: Scalars["String"];
  phoneCalls: PhoneCallConnection;
  policyLink: Scalars["String"];
  providerVideoCutoff: Scalars["Int"];
  providerVideoWindow: Scalars["Int"];
  providerVisitCutoff: Scalars["Int"];
  providerVisitWindow: Scalars["Int"];
  province: Scalars["String"];
  publicId: Scalars["String"];
  schedules: Array<Schedule>;
  /** @deprecated No longer supported */
  schedulingResetDay: Scalars["Int"];
  staffPositions: Array<StaffPosition>;
  state: UsState;
  system: System;
  timezone: Scalars["String"];
  unaccompaniedVisitAge: Scalars["Int"];
  videoNeedsApproval: Scalars["Boolean"];
  videoQuotaResetDay: Scalars["Int"];
  visitGuestRegistrationRequired: Scalars["Boolean"];
  visitNeedsApproval: Scalars["Boolean"];
  visitQuotaResetDay: Scalars["Int"];
  visitationFormLink?: Maybe<Scalars["String"]>;
  visitors: VisitorConnection;
  voiceCallPrivacyLevel: PrivacyLevel;
  voiceCallResetTime: Scalars["Int"];
  webinarAvailability: Array<WebinarAvailability>;
};

export type FacilityAvailableKiosksArgs = {
  capacity?: InputMaybe<Scalars["Int"]>;
  end: Scalars["Date"];
  meetingType: MeetingType;
  start: Scalars["Date"];
};

export type FacilityFacilityOrganizationsArgs = {
  status?: InputMaybe<FacilityOrganizationStatus>;
};

export type FacilityMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asc?: InputMaybe<Scalars["Boolean"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  meetingType?: InputMaybe<Array<MeetingType>>;
  scheduledStartAfter?: InputMaybe<Scalars["Date"]>;
  scheduledStartBefore?: InputMaybe<Scalars["Date"]>;
  status?: InputMaybe<Array<MeetingStatus>>;
};

export type FacilityPhoneCallsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asc?: InputMaybe<Scalars["Boolean"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  scheduledStartAfter?: InputMaybe<Scalars["Date"]>;
  scheduledStartBefore?: InputMaybe<Scalars["Date"]>;
};

export type FacilityStaffPositionsArgs = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
};

export type FacilityVisitorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<VisitorType>;
};

export type FacilityApprovedPhone = {
  __typename: "FacilityApprovedPhone";
  active: Scalars["Boolean"];
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  number: Scalars["String"];
  privacyLevel: PrivacyLevel;
};

export enum FacilityFeature {
  ConfidentialInPersonVisit = "CONFIDENTIAL_IN_PERSON_VISIT",
  ConfidentialVideoCall = "CONFIDENTIAL_VIDEO_CALL",
  Emessaging = "EMESSAGING",
  InPersonVisit = "IN_PERSON_VISIT",
  PhoneCall = "PHONE_CALL",
  /** @deprecated No longer supported */
  Providers = "PROVIDERS",
  VideoCall = "VIDEO_CALL",
  VoiceCall = "VOICE_CALL",
  Webinar = "WEBINAR",
}

export type FacilityOrganization = {
  __typename: "FacilityOrganization";
  facility: Facility;
  id: Scalars["ID"];
  organization: Organization;
  status: FacilityOrganizationStatus;
  statusChangedAt: Scalars["Date"];
  statusDetails?: Maybe<Scalars["String"]>;
};

export enum FacilityOrganizationStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Pending = "PENDING",
}

export enum FacilityService {
  Emessaging = "EMESSAGING",
  InPersonVisit = "IN_PERSON_VISIT",
  VideoCall = "VIDEO_CALL",
  VoiceCall = "VOICE_CALL",
  Webinar = "WEBINAR",
}

export enum FacilityType {
  Federal = "FEDERAL",
  Ice = "ICE",
  Jail = "JAIL",
  Juv = "JUV",
  State = "STATE",
}

export type Group = {
  __typename: "Group";
  /** @deprecated No longer supported */
  children: Array<Group>;
  currentVoiceCallSlot?: Maybe<Interval>;
  description: Scalars["String"];
  facility: Facility;
  hasVoiceCallSlots: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  nextVoiceCallSlot?: Maybe<Interval>;
  /** @deprecated No longer supported */
  parent?: Maybe<Group>;
};

export enum IdDocumentType {
  BirthCertificate = "BIRTH_CERTIFICATE",
  Cnic = "CNIC",
  DriversLicense = "DRIVERS_LICENSE",
  IdCard = "ID_CARD",
  Other = "OTHER",
  Passport = "PASSPORT",
  Undocumented = "UNDOCUMENTED",
}

export type Inmate = Correspondent & {
  __typename: "Inmate";
  availableUploadTypes: Array<MessageType>;
  backgroundImageUrl?: Maybe<Scalars["String"]>;
  callQuota: Scalars["Int"];
  canReceiveVoiceCalls: Scalars["Boolean"];
  connections: Array<Connection>;
  correspondentFeatures: Array<CorrespondentFeature>;
  dateOfBirth: Scalars["Date"];
  facility: Facility;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  group: Group;
  guest?: Maybe<InmateGuest>;
  hasFilledIntakeForm: Scalars["Boolean"];
  hasSentAnyMessages: Scalars["Boolean"];
  id: Scalars["ID"];
  initials: Scalars["String"];
  inmateIdentification: Scalars["String"];
  kioskAvailability: Array<KioskAvailability>;
  languagePreference: Language;
  lastName: Scalars["String"];
  malanId: Scalars["ID"];
  meetingAvailability: Array<MeetingAvailability>;
  meetings: MeetingConnection;
  needsApproval: Scalars["Boolean"];
  needsMonitor: Scalars["Boolean"];
  notificationEvents: NotificationEventConnection;
  profileImageUrl?: Maybe<Scalars["String"]>;
  remainingVoiceCallDuration: Scalars["Int"];
  remainingVoiceCallDurationResetTime?: Maybe<Scalars["Date"]>;
  remainingVoiceCallTimeQuota: Scalars["Int"];
  surveyActivity: Array<Maybe<SurveyActivity>>;
  unavailableUntil?: Maybe<Scalars["Date"]>;
  unreadMessageCount: Scalars["Int"];
  visitQuota: Scalars["Int"];
  /** @deprecated No longer supported */
  visitor?: Maybe<Visitor>;
  voiceCallTimeQuota: Scalars["Int"];
};

export type InmateConnectionsArgs = {
  facilityId?: InputMaybe<Scalars["ID"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
};

export type InmateGuestArgs = {
  email: Scalars["String"];
};

export type InmateKioskAvailabilityArgs = {
  meetingType?: InputMaybe<MeetingType>;
};

export type InmateMeetingAvailabilityArgs = {
  ignoreMeetingId?: InputMaybe<Scalars["ID"]>;
  meetingType?: InputMaybe<MeetingType>;
  showUntimely?: InputMaybe<Scalars["Boolean"]>;
  visitorIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type InmateMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  facilityId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  meetingType?: InputMaybe<Array<MeetingType>>;
  scheduledEndAfter?: InputMaybe<Scalars["Date"]>;
  status?: InputMaybe<Array<MeetingStatus>>;
};

export type InmateNotificationEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type InmateVisitorArgs = {
  email: Scalars["String"];
};

export type InmateConnection = {
  __typename: "InmateConnection";
  count: Scalars["Int"];
  edges: Array<InmateEdge>;
  pageInfo: PageInfo;
};

export type InmateEdge = {
  __typename: "InmateEdge";
  cursor: Scalars["String"];
  node: Inmate;
};

export type InmateGuest = {
  __typename: "InmateGuest";
  connectionId: Scalars["ID"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export type InmateSearchResult = {
  __typename: "InmateSearchResult";
  dateOfBirth: Scalars["Date"];
  facility: Facility;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  initials: Scalars["String"];
  inmateIdentification: Scalars["String"];
  lastName: Scalars["String"];
};

export type InmateSearchResultConnection = {
  __typename: "InmateSearchResultConnection";
  count: Scalars["Int"];
  edges: Array<InmateSearchResultEdge>;
  pageInfo: PageInfo;
};

export type InmateSearchResultEdge = {
  __typename: "InmateSearchResultEdge";
  cursor: Scalars["String"];
  node: InmateSearchResult;
};

export enum InstantReply {
  CallBackFiveMinutes = "CALL_BACK_FIVE_MINUTES",
  CallBackNow = "CALL_BACK_NOW",
  CallBackTwoMinutes = "CALL_BACK_TWO_MINUTES",
  CantTalkNow = "CANT_TALK_NOW",
}

export type Interval = {
  __typename: "Interval";
  duration?: Maybe<Scalars["Int"]>;
  endAt: Scalars["Date"];
  seconds?: Maybe<Scalars["Int"]>;
  startAt: Scalars["Date"];
};

export type Kiosk = {
  __typename: "Kiosk";
  capacity: Scalars["Int"];
  createdAt: Scalars["Date"];
  enabled: Scalars["Boolean"];
  facility: Facility;
  id: Scalars["ID"];
  meetingType: MeetingType;
  name: Scalars["String"];
};

export type KioskAvailability = {
  __typename: "KioskAvailability";
  interval: Interval;
  kiosk: Kiosk;
};

export enum Language {
  En = "EN",
  Es = "ES",
  It = "IT",
}

export type LeaveMeetingInput = {
  meetingId: Scalars["ID"];
};

export type LeaveMeetingResponse = {
  __typename: "LeaveMeetingResponse";
  success: Scalars["Boolean"];
};

export type LoginResponse = {
  __typename: "LoginResponse";
  authToken: Scalars["String"];
  expiresAt: Scalars["String"];
  remainingDuration: Scalars["Int"];
};

export type LogoutInput = {
  authToken: Scalars["String"];
};

export type LogoutResponse = {
  __typename: "LogoutResponse";
  success: Scalars["Boolean"];
};

export type MarkRecordingAsReviewedInput = {
  meetingId: Scalars["ID"];
};

export type MarkRecordingAsReviewedResponse = {
  __typename: "MarkRecordingAsReviewedResponse";
  success: Scalars["Boolean"];
};

export type MarkUnreadNotificationsAsReadResponse = {
  __typename: "MarkUnreadNotificationsAsReadResponse";
  currentCorrespondent: Correspondent;
  notificationEvents: Array<NotificationEvent>;
};

export type Meeting = {
  __typename: "Meeting";
  call?: Maybe<Call>;
  confidential: Scalars["Boolean"];
  connections: Array<Connection>;
  contacts: Array<Correspondent>;
  correspondents: Array<Correspondent>;
  facility: Facility;
  id: Scalars["ID"];
  inmates: Array<Inmate>;
  interval: Interval;
  isCisco: Scalars["Boolean"];
  kiosk?: Maybe<Kiosk>;
  meetingType: MeetingType;
  /** @deprecated No longer supported */
  minors: Array<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  participantComment?: Maybe<ParticipantComment>;
  /**
   * PrimaryVisitor may be null when staff schedule in-person visits without
   * any registered Ameelio Connect visitors.
   */
  primaryVisitor?: Maybe<Visitor>;
  privacyLevel: PrivacyLevel;
  reviewedBy?: Maybe<StaffPosition>;
  schedulerId: Scalars["String"];
  status: MeetingStatus;
  statusDetails?: Maybe<Scalars["String"]>;
  timelineEvents: Array<StaffTimelineEvent>;
  title?: Maybe<Scalars["String"]>;
  unregisteredGuests: Array<Scalars["String"]>;
  visitors: Array<Visitor>;
};

export type MeetingAvailability = {
  __typename: "MeetingAvailability";
  availableKiosks: Array<Kiosk>;
  conflict: Scalars["Boolean"];
  interval: Interval;
  privacyLevel: PrivacyLevel;
  timeliness: Timeliness;
  unavailableKiosks: Array<Kiosk>;
};

export type MeetingConnection = {
  __typename: "MeetingConnection";
  count: Scalars["Int"];
  countByDay: Array<CountGroup>;
  edges: Array<MeetingEdge>;
  pageInfo: PageInfo;
  ratings: Array<CountGroup>;
  uniqueInmates: Scalars["Int"];
  uniqueVisitors: Scalars["Int"];
};

export type MeetingEdge = {
  __typename: "MeetingEdge";
  cursor: Scalars["String"];
  node: Meeting;
};

export enum MeetingStatus {
  Cancelled = "CANCELLED",
  Ended = "ENDED",
  Live = "LIVE",
  NoShow = "NO_SHOW",
  PendingApproval = "PENDING_APPROVAL",
  Rejected = "REJECTED",
  Scheduled = "SCHEDULED",
  Terminated = "TERMINATED",
}

export enum MeetingType {
  InPersonVisit = "IN_PERSON_VISIT",
  VideoCall = "VIDEO_CALL",
  VoiceCall = "VOICE_CALL",
  Webinar = "WEBINAR",
}

export type Message = {
  __typename: "Message";
  availability: MessageAvailability;
  availableAt?: Maybe<Scalars["Date"]>;
  connection: Connection;
  content: Scalars["String"];
  createdAt: Scalars["Date"];
  detectedKeywords: Array<MessageDetectedKeyword>;
  fileUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  internalComments?: Maybe<Scalars["String"]>;
  isInstantReply: Scalars["Boolean"];
  isRead: Scalars["Boolean"];
  reviewedAt?: Maybe<Scalars["Date"]>;
  reviewedBy?: Maybe<StaffPosition>;
  sender: Correspondent;
  status: MessageStatus;
  statusDetails?: Maybe<Scalars["String"]>;
  type: MessageType;
  updatedAt: Scalars["Date"];
};

export enum MessageAvailability {
  Available = "AVAILABLE",
  Pending = "PENDING",
  ProcessingFailed = "PROCESSING_FAILED",
  Rejected = "REJECTED",
}

export type MessageConnection = {
  __typename: "MessageConnection";
  count: Scalars["Int"];
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars["Int"];
};

export type MessageDetectedKeyword = {
  __typename: "MessageDetectedKeyword";
  id: Scalars["ID"];
  keyword: MessageKeyword;
  message: Message;
  spelling: Scalars["String"];
};

export type MessageEdge = {
  __typename: "MessageEdge";
  cursor: Scalars["String"];
  node: Message;
};

export type MessageKeyword = {
  __typename: "MessageKeyword";
  category: MessageKeywordCategory;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<StaffPosition>;
  description: Scalars["String"];
  facility?: Maybe<Facility>;
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  isGlobal: Scalars["Boolean"];
  lastUpdatedBy?: Maybe<StaffPosition>;
  name: Scalars["String"];
  spellings: Array<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export enum MessageKeywordCategory {
  Drugs = "DRUGS",
  Escape = "ESCAPE",
  Hate = "HATE",
  Violence = "VIOLENCE",
}

export enum MessageStatus {
  Approved = "APPROVED",
  PendingReview = "PENDING_REVIEW",
  ProcessingFailed = "PROCESSING_FAILED",
  Queued = "QUEUED",
  Rejected = "REJECTED",
  SkippedReview = "SKIPPED_REVIEW",
}

export enum MessageType {
  Document = "DOCUMENT",
  Photo = "PHOTO",
  Text = "TEXT",
}

export type Mutation = {
  __typename: "Mutation";
  acceptMeeting: AcceptMeetingResponse;
  acceptOrganizationInvitation: AcceptOrganizationInvitationResponse;
  approveMessage: ApproveMessageResponse;
  approveSystemVisitor: ApproveSystemVisitorResponse;
  archiveConnection: ArchiveConnectionResponse;
  cancelMeeting: CancelMeetingResponse;
  createCallReport: CreateCallReportResponse;
  createConnectionRestriction: CreateConnectionRestrictionResponse;
  createConnectionRestrictions: CreateConnectionRestrictionsResponse;
  createFacility: CreateFacilityResponse;
  createFacilityApprovedPhone: CreateFacilityApprovedPhoneResponse;
  createGroup: CreateGroupResponse;
  createInstantReplyMessage: CreateInstantReplyMessageResponse;
  createKiosk: CreateKioskResponse;
  createLocalMessageKeyword: CreateLocalMessageKeywordResponse;
  createMessage: CreateMessageResponse;
  createOrganization: CreateOrganizationResponse;
  createOrganizationInvitation: CreateOrganizationInvitationResponse;
  createOrganizationMembership: CreateOrganizationMembershipResponse;
  createPendingConnection: CreatePendingConnectionResponse;
  createPendingProfessionalConnection: CreatePendingProfessionalConnectionResponse;
  createRecording: CreateRecordingResponse;
  createSchedule: CreateScheduleResponse;
  createScheduledMeeting: CreateScheduledMeetingResponse;
  createStaff: CreateStaffResponse;
  createSurveyImpression: CreateSurveyImpressionResponse;
  createSurveyResponse: CreateSurveyResponseResponse;
  createSystem: CreateSystemResponse;
  createTestCall: CreateTestCallResponse;
  createVisitor: CreateVisitorResponse;
  createVisitorIdentity: CreateVisitorIdentityResponse;
  createVoiceCall: CreateVoiceCallResponse;
  deactivateConnection: DeactivateConnectionResponse;
  deactivateFacility: DeactivateFacilityResponse;
  deactivateLocalMessageKeyword: DeactivateLocalMessageKeywordResponse;
  deactivateOrganizationMembership: DeactivateOrganizationMembershipResponse;
  deactivateStaff: DeactivateStaffResponse;
  declineVoiceCall: DeclineVoiceCallResponse;
  deleteGroup: DeleteGroupResponse;
  deleteSchedule: DeleteScheduleResponse;
  deleteVisitor: DeleteVisitorResponse;
  downloadScreenshots: DownloadScreenshotsResponse;
  endVoiceCall: EndVoiceCallResponse;
  extendSession: ExtendSessionResponse;
  leaveMeeting: LeaveMeetingResponse;
  logout: LogoutResponse;
  markUnreadNotificationsAsRead: MarkUnreadNotificationsAsReadResponse;
  reactivateLocalMessageKeyword: ReactivateLocalMessageKeywordResponse;
  registerDevice: RegisterDeviceResponse;
  rejectMeeting: RejectMeetingResponse;
  rejectMessage: RejectMessageResponse;
  rejectSystemVisitor: RejectSystemVisitorResponse;
  reportProblemWithRecording: ReportProblemWithArtifactResponse;
  reportProblemWithScreenshots: ReportProblemWithArtifactResponse;
  revokeOrganizationInvitation: RevokeOrganizationInvitationResponse;
  terminateCall: TerminateCallResponse;
  unregisterDevice: UnregisterDeviceResponse;
  updateCallStatus: UpdateCallStatusResponse;
  updateConnectionRelationship: UpdateConnectionRelationshipResponse;
  updateConnectionRestriction: UpdateConnectionRestrictionResponse;
  updateConnectionStatus: UpdateConnectionStatusResponse;
  updateFacility: UpdateFacilityResponse;
  updateFacilityApprovedPhone: UpdateFacilityApprovedPhoneResponse;
  updateFacilityFeatures: UpdateFacilityFeaturesResponse;
  updateGroup: UpdateGroupResponse;
  updateInmate: UpdateInmateResponse;
  updateKiosk: UpdateKioskResponse;
  updateLanguagePreference: UpdateLanguagePreferenceResponse;
  updateLocalMessageKeyword: UpdateLocalMessageKeywordResponse;
  updateMeetingParticipantComment: UpdateMeetingParticipantCommentResponse;
  updateMessageIsRead: UpdateMessageIsReadResponse;
  updateNotificationEventReadAt: UpdateNotificationEventReadAtResponse;
  updateOrganizationMembership: UpdateOrganizationMembershipResponse;
  updateSchedule: UpdateScheduleResponse;
  updateScheduledMeeting: UpdateScheduledMeetingResponse;
  updateStaff: UpdateStaffResponse;
  updateStaffPreferences: UpdateStaffPreferencesResponse;
  updateVisitor: UpdateVisitorResponse;
  updateVisitorEmail: UpdateVisitorEmailResponse;
  updateVisitorIdentity: UpdateVisitorIdentityResponse;
};

export type MutationAcceptMeetingArgs = {
  input: AcceptMeetingInput;
};

export type MutationAcceptOrganizationInvitationArgs = {
  input: AcceptOrganizationInvitationInput;
};

export type MutationApproveMessageArgs = {
  input: ApproveMessageInput;
};

export type MutationApproveSystemVisitorArgs = {
  input: ApproveSystemVisitorInput;
};

export type MutationArchiveConnectionArgs = {
  input: ArchiveConnectionInput;
};

export type MutationCancelMeetingArgs = {
  input: CancelMeetingInput;
};

export type MutationCreateCallReportArgs = {
  input: CreateCallReportInput;
};

export type MutationCreateConnectionRestrictionArgs = {
  input: CreateConnectionRestrictionInput;
};

export type MutationCreateConnectionRestrictionsArgs = {
  input: CreateConnectionRestrictionsInput;
};

export type MutationCreateFacilityArgs = {
  input: CreateFacilityInput;
};

export type MutationCreateFacilityApprovedPhoneArgs = {
  input: CreateFacilityApprovedPhoneInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateInstantReplyMessageArgs = {
  input: CreateInstantReplyMessageInput;
};

export type MutationCreateKioskArgs = {
  input: CreateKioskInput;
};

export type MutationCreateLocalMessageKeywordArgs = {
  input: CreateLocalMessageKeywordInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateOrganizationInvitationArgs = {
  input: CreateOrganizationInvitationInput;
};

export type MutationCreateOrganizationMembershipArgs = {
  input: CreateOrganizationMembershipInput;
};

export type MutationCreatePendingConnectionArgs = {
  input: CreatePendingConnectionInput;
};

export type MutationCreatePendingProfessionalConnectionArgs = {
  input: CreatePendingProfessionalConnectionInput;
};

export type MutationCreateRecordingArgs = {
  input: CreateRecordingInput;
};

export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};

export type MutationCreateScheduledMeetingArgs = {
  input: CreateScheduledMeetingInput;
};

export type MutationCreateStaffArgs = {
  input: CreateStaffInput;
};

export type MutationCreateSurveyImpressionArgs = {
  input: CreateSurveyImpressionInput;
};

export type MutationCreateSurveyResponseArgs = {
  input: CreateSurveyResponseInput;
};

export type MutationCreateSystemArgs = {
  input: CreateSystemInput;
};

export type MutationCreateVisitorArgs = {
  input: CreateVisitorInput;
};

export type MutationCreateVisitorIdentityArgs = {
  input: CreateVisitorIdentityInput;
};

export type MutationCreateVoiceCallArgs = {
  input: CreateVoiceCallInput;
};

export type MutationDeactivateConnectionArgs = {
  input: DeactivateConnectionInput;
};

export type MutationDeactivateFacilityArgs = {
  input: DeactivateFacilityInput;
};

export type MutationDeactivateLocalMessageKeywordArgs = {
  input: DeactivateLocalMessageKeywordInput;
};

export type MutationDeactivateOrganizationMembershipArgs = {
  input: DeactivateOrganizationMembershipInput;
};

export type MutationDeactivateStaffArgs = {
  input: DeactivateStaffInput;
};

export type MutationDeclineVoiceCallArgs = {
  input: DeclineVoiceCallInput;
};

export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};

export type MutationDeleteScheduleArgs = {
  input: DeleteScheduleInput;
};

export type MutationDeleteVisitorArgs = {
  input: DeleteVisitorInput;
};

export type MutationDownloadScreenshotsArgs = {
  input: DownloadScreenshotsInput;
};

export type MutationEndVoiceCallArgs = {
  input: EndVoiceCallInput;
};

export type MutationLeaveMeetingArgs = {
  input: LeaveMeetingInput;
};

export type MutationLogoutArgs = {
  input: LogoutInput;
};

export type MutationReactivateLocalMessageKeywordArgs = {
  input: ReactivateLocalMessageKeywordInput;
};

export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};

export type MutationRejectMeetingArgs = {
  input: RejectMeetingInput;
};

export type MutationRejectMessageArgs = {
  input: RejectMessageInput;
};

export type MutationRejectSystemVisitorArgs = {
  input: RejectSystemVisitorInput;
};

export type MutationReportProblemWithRecordingArgs = {
  input: ReportProblemWithArtifactInput;
};

export type MutationReportProblemWithScreenshotsArgs = {
  input: ReportProblemWithArtifactInput;
};

export type MutationRevokeOrganizationInvitationArgs = {
  input: RevokeOrganizationInvitationInput;
};

export type MutationTerminateCallArgs = {
  input: TerminateCallInput;
};

export type MutationUnregisterDeviceArgs = {
  input: UnregisterDeviceInput;
};

export type MutationUpdateCallStatusArgs = {
  input: UpdateCallStatusInput;
};

export type MutationUpdateConnectionRelationshipArgs = {
  input: UpdateConnectionRelationshipInput;
};

export type MutationUpdateConnectionRestrictionArgs = {
  input: UpdateConnectionRestrictionInput;
};

export type MutationUpdateConnectionStatusArgs = {
  input: UpdateConnectionStatusInput;
};

export type MutationUpdateFacilityArgs = {
  input: UpdateFacilityInput;
};

export type MutationUpdateFacilityApprovedPhoneArgs = {
  input: UpdateFacilityApprovedPhoneInput;
};

export type MutationUpdateFacilityFeaturesArgs = {
  input: UpdateFacilityFeaturesInput;
};

export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};

export type MutationUpdateInmateArgs = {
  input: UpdateInmateInput;
};

export type MutationUpdateKioskArgs = {
  input: UpdateKioskInput;
};

export type MutationUpdateLanguagePreferenceArgs = {
  input: UpdateLanguagePreferenceInput;
};

export type MutationUpdateLocalMessageKeywordArgs = {
  input: UpdateLocalMessageKeywordInput;
};

export type MutationUpdateMeetingParticipantCommentArgs = {
  input: UpdateMeetingParticipantCommentInput;
};

export type MutationUpdateMessageIsReadArgs = {
  input: UpdateMessageIsReadInput;
};

export type MutationUpdateNotificationEventReadAtArgs = {
  input: UpdateNotificationEventReadAtInput;
};

export type MutationUpdateOrganizationMembershipArgs = {
  input: UpdateOrganizationMembershipInput;
};

export type MutationUpdateScheduleArgs = {
  input: UpdateScheduleInput;
};

export type MutationUpdateScheduledMeetingArgs = {
  input: UpdateScheduledMeetingInput;
};

export type MutationUpdateStaffArgs = {
  input: UpdateStaffInput;
};

export type MutationUpdateStaffPreferencesArgs = {
  input: UpdateStaffPreferencesInput;
};

export type MutationUpdateVisitorArgs = {
  input: UpdateVisitorInput;
};

export type MutationUpdateVisitorEmailArgs = {
  input: UpdateVisitorEmailInput;
};

export type MutationUpdateVisitorIdentityArgs = {
  input: UpdateVisitorIdentityInput;
};

export type NotificationEvent = {
  __typename: "NotificationEvent";
  body: Scalars["String"];
  createdAt: Scalars["Date"];
  id: Scalars["ID"];
  isRead: Scalars["Boolean"];
  object: NotificationEventObject;
  params: Scalars["String"];
  sentAt?: Maybe<Scalars["Date"]>;
  title: Scalars["String"];
  type: Scalars["String"];
  verb: NotificationEventVerb;
};

export type NotificationEventConnection = {
  __typename: "NotificationEventConnection";
  edges: Array<NotificationEventEdge>;
  pageInfo: PageInfo;
  unreadNotificationCount: Scalars["Int"];
};

export type NotificationEventEdge = {
  __typename: "NotificationEventEdge";
  cursor: Scalars["String"];
  node: NotificationEvent;
};

export type NotificationEventObject =
  | Connection
  | Meeting
  | Message
  | Organization
  | SystemUser;

export enum NotificationEventVerb {
  Approve = "APPROVE",
  Call = "CALL",
  Cancel = "CANCEL",
  Create = "CREATE",
  Reject = "REJECT",
  Remind = "REMIND",
  Update = "UPDATE",
}

export type Organization = {
  __typename: "Organization";
  address: Address;
  allConnections: Array<Connection>;
  connectedFacilities: Array<Facility>;
  description: Scalars["String"];
  email: Scalars["String"];
  /** @deprecated No longer supported */
  facilityOrganizations: Array<FacilityOrganization>;
  id: Scalars["ID"];
  invitations: Array<OrganizationInvitation>;
  logoImageUrl: Scalars["String"];
  memberships: Array<OrganizationMembership>;
  name: Scalars["String"];
  phone: Scalars["String"];
  type: OrganizationType;
  websiteLink?: Maybe<Scalars["String"]>;
};

export type OrganizationFacilityOrganizationsArgs = {
  status?: InputMaybe<FacilityOrganizationStatus>;
};

export type OrganizationMembershipsArgs = {
  role?: InputMaybe<OrganizationMembershipRole>;
};

export type OrganizationConnection = {
  __typename: "OrganizationConnection";
  edges: Array<OrganizationEdge>;
  pageInfo: PageInfo;
};

export type OrganizationEdge = {
  __typename: "OrganizationEdge";
  cursor: Scalars["String"];
  node: Organization;
};

export type OrganizationInvitation = {
  __typename: "OrganizationInvitation";
  email: Scalars["String"];
  id: Scalars["ID"];
  inviteCode: Scalars["String"];
  organization: Organization;
  role: OrganizationMembershipRole;
  status: OrganizationInvitationStatus;
};

export enum OrganizationInvitationStatus {
  Accepted = "ACCEPTED",
  Expired = "EXPIRED",
  Pending = "PENDING",
}

export type OrganizationMembership = {
  __typename: "OrganizationMembership";
  id: Scalars["ID"];
  organization: Organization;
  role: OrganizationMembershipRole;
  status: OrganizationMembershipStatus;
  visitor: Visitor;
};

export enum OrganizationMembershipRole {
  Admin = "ADMIN",
  Member = "MEMBER",
}

export enum OrganizationMembershipStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum OrganizationType {
  Educational = "EDUCATIONAL",
  Healthcare = "HEALTHCARE",
  Legal = "LEGAL",
  Other = "OTHER",
}

export type PageInfo = {
  __typename: "PageInfo";
  endCursor: Scalars["String"];
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  startCursor: Scalars["String"];
};

export enum ParticipantComment {
  CallBackFiveMinutes = "CALL_BACK_FIVE_MINUTES",
  CallBackNow = "CALL_BACK_NOW",
  CallBackTwoMinutes = "CALL_BACK_TWO_MINUTES",
  CantMakeOnce = "CANT_MAKE_ONCE",
  CantMakeThisTimeAnyDay = "CANT_MAKE_THIS_TIME_ANY_DAY",
  CantMakeThisTimeThisWeekday = "CANT_MAKE_THIS_TIME_THIS_WEEKDAY",
  CantMakeThisTimeWeekdays = "CANT_MAKE_THIS_TIME_WEEKDAYS",
  CantTalkNow = "CANT_TALK_NOW",
}

export type PhoneCall = {
  __typename: "PhoneCall";
  end?: Maybe<Scalars["Date"]>;
  facilityApprovedPhone: FacilityApprovedPhone;
  id: Scalars["String"];
  inmate: Inmate;
  privacyLevel: PrivacyLevel;
  start: Scalars["Date"];
  status: PhoneCallStatus;
};

export type PhoneCallConnection = {
  __typename: "PhoneCallConnection";
  count: Scalars["Int"];
  edges: Array<PhoneCallEdge>;
  pageInfo: PageInfo;
};

export type PhoneCallEdge = {
  __typename: "PhoneCallEdge";
  cursor: Scalars["String"];
  node: PhoneCall;
};

export enum PhoneCallStatus {
  Busy = "BUSY",
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Initiated = "INITIATED",
  InProgress = "IN_PROGRESS",
  NoAnswer = "NO_ANSWER",
  Ringing = "RINGING",
}

export enum PrivacyLevel {
  Hidden = "HIDDEN",
  Monitored = "MONITORED",
  UnmonitoredLogged = "UNMONITORED_LOGGED",
}

export enum PrivateFileCategory {
  BackId = "BACK_ID",
  FrontId = "FRONT_ID",
  SelfieId = "SELFIE_ID",
}

export type Query = {
  __typename: "Query";
  adultInmates: AdultInmatesResponse;
  appInfo: AppInfoResponse;
  connection: Connection;
  connectionRestriction: ConnectionRestriction;
  currentCorrespondent?: Maybe<Correspondent>;
  /**
   * ISO-3166-1 Alpha 2 code designating a country, or null if no
   * country could be determined.
   */
  currentCountry?: Maybe<Scalars["String"]>;
  currentInmate?: Maybe<Inmate>;
  currentStaff?: Maybe<Staff>;
  currentVisitor?: Maybe<Visitor>;
  facilities: Array<Facility>;
  facility: Facility;
  facilityApprovedPhone: FacilityApprovedPhone;
  inmate: Inmate;
  kiosk: Kiosk;
  loginUser: LoginResponse;
  me?: Maybe<User>;
  meeting: Meeting;
  message: Message;
  minorInmate?: Maybe<InmateSearchResult>;
  organization: Organization;
  organizationInvitation: OrganizationInvitation;
  organizationInvitationByCode?: Maybe<OrganizationInvitation>;
  organizationInvitationByUser?: Maybe<OrganizationInvitation>;
  organizationMembership: OrganizationMembership;
  organizations: OrganizationConnection;
  ping: Scalars["String"];
  schedule: Schedule;
  staff: Staff;
  staffPosition: StaffPosition;
  system: System;
  systemByCode?: Maybe<System>;
  systems: Array<System>;
  twilioToken: Scalars["String"];
  uploadUrl: UploadUrl;
  visitor: Visitor;
  visitors: SearchVisitorsResponse;
};

export type QueryAdultInmatesArgs = {
  country?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  facilityId?: InputMaybe<Scalars["String"]>;
  inmateIds?: InputMaybe<Array<Scalars["ID"]>>;
  lastNameOrInmateId?: InputMaybe<Scalars["String"]>;
  province?: InputMaybe<Scalars["String"]>;
};

export type QueryConnectionArgs = {
  id: Scalars["ID"];
};

export type QueryConnectionRestrictionArgs = {
  id: Scalars["ID"];
};

export type QueryFacilityArgs = {
  id: Scalars["ID"];
};

export type QueryFacilityApprovedPhoneArgs = {
  id: Scalars["ID"];
};

export type QueryInmateArgs = {
  id: Scalars["ID"];
};

export type QueryKioskArgs = {
  id: Scalars["ID"];
};

export type QueryLoginUserArgs = {
  input: CredentialsInput;
};

export type QueryMeetingArgs = {
  id: Scalars["ID"];
};

export type QueryMessageArgs = {
  id: Scalars["ID"];
};

export type QueryMinorInmateArgs = {
  dateOfBirth: Scalars["String"];
  facilityId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type QueryOrganizationArgs = {
  id: Scalars["ID"];
};

export type QueryOrganizationInvitationArgs = {
  id: Scalars["ID"];
};

export type QueryOrganizationInvitationByCodeArgs = {
  inviteCode: Scalars["String"];
};

export type QueryOrganizationMembershipArgs = {
  id: Scalars["ID"];
};

export type QueryScheduleArgs = {
  id: Scalars["ID"];
};

export type QueryStaffArgs = {
  id: Scalars["ID"];
};

export type QueryStaffPositionArgs = {
  id: Scalars["ID"];
};

export type QuerySystemArgs = {
  id: Scalars["ID"];
};

export type QuerySystemByCodeArgs = {
  code: Scalars["String"];
};

export type QueryUploadUrlArgs = {
  contentType: Scalars["String"];
};

export type QueryVisitorArgs = {
  id: Scalars["ID"];
};

export type QueryVisitorsArgs = {
  email?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type ReactivateLocalMessageKeywordInput = {
  messageKeywordId: Scalars["ID"];
};

export type ReactivateLocalMessageKeywordResponse = {
  __typename: "ReactivateLocalMessageKeywordResponse";
  messageKeyword: MessageKeyword;
};

export type Recording = {
  __typename: "Recording";
  requester?: Maybe<Staff>;
  status: RecordingStatus;
  url?: Maybe<Scalars["String"]>;
};

export enum RecordingStatus {
  Available = "AVAILABLE",
  Expired = "EXPIRED",
  NotStarted = "NOT_STARTED",
  Pending = "PENDING",
}

export type RegisterDeviceInput = {
  appVersion?: InputMaybe<Scalars["String"]>;
  token: Scalars["String"];
  tokenType?: InputMaybe<TokenType>;
  type: DeviceType;
};

export type RegisterDeviceResponse = {
  __typename: "RegisterDeviceResponse";
  id: Scalars["String"];
  token: Scalars["String"];
  tokenType: TokenType;
  type: DeviceType;
};

export type RejectMeetingInput = {
  meetingId: Scalars["ID"];
  statusDetails?: InputMaybe<Scalars["String"]>;
};

export type RejectMeetingResponse = {
  __typename: "RejectMeetingResponse";
  meeting: Meeting;
};

export type RejectMessageInput = {
  internalComments: Scalars["String"];
  messageId: Scalars["ID"];
  statusDetails: Scalars["String"];
};

export type RejectMessageResponse = {
  __typename: "RejectMessageResponse";
  message: Message;
};

export type RejectSystemVisitorInput = {
  statusDetails: Scalars["String"];
  systemVisitorId: Scalars["String"];
};

export type RejectSystemVisitorResponse = {
  __typename: "RejectSystemVisitorResponse";
  systemVisitor: SystemUser;
};

export type ReportProblemWithArtifactInput = {
  description: Scalars["String"];
  meetingId: Scalars["ID"];
  tag: Scalars["String"];
};

export type ReportProblemWithArtifactResponse = {
  __typename: "ReportProblemWithArtifactResponse";
  success: Scalars["Boolean"];
};

export type RevokeOrganizationInvitationInput = {
  organizationInvitationId: Scalars["ID"];
};

export type RevokeOrganizationInvitationResponse = {
  __typename: "RevokeOrganizationInvitationResponse";
  organizationInvitation: OrganizationInvitation;
};

export type Schedule = {
  __typename: "Schedule";
  capacity: Scalars["Int"];
  endsOn?: Maybe<Scalars["String"]>;
  facility: Facility;
  groups: Array<Group>;
  id: Scalars["ID"];
  kiosks: Array<Kiosk>;
  meetingSlots: Array<ScheduleMeetingSlot>;
  meetingType: MeetingType;
  name: Scalars["String"];
  privacyLevels: Array<PrivacyLevel>;
  startsOn?: Maybe<Scalars["String"]>;
  /** @deprecated No longer supported */
  visitorTypes: Array<VisitorType>;
};

export type ScheduleMeetingSlot = {
  __typename: "ScheduleMeetingSlot";
  day: Scalars["Int"];
  duration: Scalars["Int"];
  hour: Scalars["Int"];
  id: Scalars["ID"];
  minute: Scalars["Int"];
};

export type Screenshot = {
  __typename: "Screenshot";
  createdAt: Scalars["Date"];
  url: Scalars["String"];
  user: VisitorOrInmate;
  viewedAt?: Maybe<Scalars["Date"]>;
  viewedBy?: Maybe<Scalars["String"]>;
};

export type SearchVisitorsResponse = {
  __typename: "SearchVisitorsResponse";
  results: Array<Visitor>;
};

export type SetVisitorIdentityInput = {
  idBackImageKey: Scalars["String"];
  idFrontImageKey: Scalars["String"];
  selfieImageKey: Scalars["String"];
};

export type SetVisitorIdentityResponse = {
  __typename: "SetVisitorIdentityResponse";
  identity: VisitorIdentity;
  visitor: Visitor;
};

export type Slot = {
  day: Scalars["Int"];
  duration: Scalars["Int"];
  hour: Scalars["Int"];
  minute: Scalars["Int"];
};

export type Staff = {
  __typename: "Staff";
  ameelio: Scalars["Boolean"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  initials: Scalars["String"];
  languagePreference?: Maybe<Language>;
  lastName: Scalars["String"];
  malanId: Scalars["ID"];
  phone?: Maybe<Scalars["String"]>;
  receiveDailyScheduleEmails: Scalars["Boolean"];
  receiveTaskReminderEmails: Scalars["Boolean"];
  staffEntitlements: Array<StaffEntitlement>;
  staffPositions: Array<StaffPosition>;
  surveyActivity: Array<Maybe<SurveyActivity>>;
  timelineEvents: StaffTimelineEventConnection;
};

export type StaffStaffPositionsArgs = {
  systemId?: InputMaybe<Scalars["ID"]>;
};

export type StaffTimelineEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAfter?: InputMaybe<Scalars["Date"]>;
  createdBefore?: InputMaybe<Scalars["Date"]>;
  facilityId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type StaffEntitlement = {
  __typename: "StaffEntitlement";
  entitlement: Entitlement;
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  staff: Staff;
};

export type StaffPosition = {
  __typename: "StaffPosition";
  facility: Facility;
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  reviewedConnections: Array<Connection>;
  reviewedMeetings: Array<Meeting>;
  staff: Staff;
  /** @deprecated No longer supported */
  timelineEvents: StaffTimelineEventConnection;
};

export type StaffPositionTimelineEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAfter?: InputMaybe<Scalars["Date"]>;
  createdBefore?: InputMaybe<Scalars["Date"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type StaffTimelineEvent = {
  __typename: "StaffTimelineEvent";
  createdAt: Scalars["Date"];
  facility?: Maybe<Facility>;
  id: Scalars["ID"];
  metadata?: Maybe<Scalars["String"]>;
  object: StaffTimelineEventObject;
  staff: Staff;
  verb: TimelineEventVerb;
};

export type StaffTimelineEventConnection = {
  __typename: "StaffTimelineEventConnection";
  edges: Array<StaffTimelineEventEdge>;
  pageInfo: PageInfo;
};

export type StaffTimelineEventEdge = {
  __typename: "StaffTimelineEventEdge";
  cursor: Scalars["String"];
  node: StaffTimelineEvent;
};

export type StaffTimelineEventObject =
  | Call
  | Connection
  | ConnectionRestriction
  | Facility
  | FacilityApprovedPhone
  | Kiosk
  | Meeting
  | Message
  | MessageKeyword
  | StaffEntitlement
  | StaffPosition
  | SystemUser;

export type SurveyActivity = {
  __typename: "SurveyActivity";
  code: Scalars["String"];
  impressions: Scalars["Int"];
  responses: Scalars["Int"];
};

export type SurveyAnswer = {
  answer: Scalars["String"];
  code: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  version: Scalars["Int"];
};

export type System = {
  __typename: "System";
  code: Scalars["String"];
  facilities: Array<Facility>;
  id: Scalars["String"];
  name: Scalars["String"];
  recordingRetention: Scalars["Int"];
  showPPTOS: Scalars["Boolean"];
  staff: Array<Staff>;
};

export type SystemUser = {
  __typename: "SystemUser";
  connectedFacilities: Array<Facility>;
  id: Scalars["ID"];
  status: SystemUserStatus;
  statusDetails?: Maybe<Scalars["String"]>;
  system: System;
  user: Visitor;
};

export type SystemUserConnectedFacilitiesArgs = {
  connectionStatuses?: InputMaybe<Array<ConnectionStatus>>;
};

export enum SystemUserStatus {
  Approved = "APPROVED",
  Inactive = "INACTIVE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type TerminateCallInput = {
  meetingId: Scalars["ID"];
  reason?: InputMaybe<Scalars["String"]>;
};

export type TerminateCallResponse = {
  __typename: "TerminateCallResponse";
  success: Scalars["Boolean"];
};

export enum TimelineEventVerb {
  Approve = "APPROVE",
  Cancel = "CANCEL",
  Create = "CREATE",
  Disable = "DISABLE",
  Enable = "ENABLE",
  Read = "READ",
  Reject = "REJECT",
  Update = "UPDATE",
}

export enum Timeliness {
  JustRight = "JUST_RIGHT",
  NotAllowed = "NOT_ALLOWED",
  TooLate = "TOO_LATE",
  TooSoon = "TOO_SOON",
}

export enum TokenType {
  Apnvoip = "APNVOIP",
  Fcm = "FCM",
}

export type Transcript = {
  __typename: "Transcript";
  content?: Maybe<Scalars["String"]>;
  status: TranscriptStatus;
};

export enum TranscriptStatus {
  Available = "AVAILABLE",
  Expired = "EXPIRED",
  NoTranscript = "NO_TRANSCRIPT",
  Pending = "PENDING",
}

export enum UsState {
  Ak = "AK",
  Al = "AL",
  Ar = "AR",
  Az = "AZ",
  Ca = "CA",
  Co = "CO",
  Ct = "CT",
  Dc = "DC",
  De = "DE",
  Fl = "FL",
  Ga = "GA",
  Hi = "HI",
  Ia = "IA",
  Id = "ID",
  Il = "IL",
  In = "IN",
  Ks = "KS",
  Ky = "KY",
  La = "LA",
  Ma = "MA",
  Md = "MD",
  Me = "ME",
  Mi = "MI",
  Mn = "MN",
  Mo = "MO",
  Ms = "MS",
  Mt = "MT",
  Nc = "NC",
  Nd = "ND",
  Ne = "NE",
  Nh = "NH",
  Nj = "NJ",
  Nm = "NM",
  Nv = "NV",
  Ny = "NY",
  Oh = "OH",
  Ok = "OK",
  Or = "OR",
  Pa = "PA",
  Ri = "RI",
  Sc = "SC",
  Sd = "SD",
  Tn = "TN",
  Tx = "TX",
  Ut = "UT",
  Va = "VA",
  Vt = "VT",
  Wa = "WA",
  Wi = "WI",
  Wv = "WV",
  Wy = "WY",
}

export type UnregisterDeviceInput = {
  token: Scalars["String"];
};

export type UnregisterDeviceResponse = {
  __typename: "UnregisterDeviceResponse";
  active: Scalars["Boolean"];
  id: Scalars["String"];
  token: Scalars["String"];
};

export type UpdateCallStatusInput = {
  unavailableUntil?: InputMaybe<Scalars["Date"]>;
};

export type UpdateCallStatusResponse = {
  __typename: "UpdateCallStatusResponse";
  visitor: Visitor;
};

export type UpdateConnectionInput = {
  connectionId: Scalars["ID"];
  status: ConnectionStatus;
  statusDetails?: InputMaybe<Scalars["String"]>;
};

export type UpdateConnectionRelationshipInput = {
  connectionId: Scalars["ID"];
  relationship: Scalars["String"];
};

export type UpdateConnectionRelationshipResponse = {
  __typename: "UpdateConnectionRelationshipResponse";
  connection: Connection;
};

export type UpdateConnectionResponse = {
  __typename: "UpdateConnectionResponse";
  connection: Connection;
};

export type UpdateConnectionRestrictionInput = {
  connectionRestrictionId: Scalars["ID"];
  expiresAt?: InputMaybe<Scalars["Date"]>;
  reason?: InputMaybe<Scalars["String"]>;
};

export type UpdateConnectionRestrictionResponse = {
  __typename: "UpdateConnectionRestrictionResponse";
  connectionRestriction: ConnectionRestriction;
};

export type UpdateConnectionStatusInput = {
  connectionId: Scalars["ID"];
  status: ConnectionStatus;
  statusDetails?: InputMaybe<Scalars["String"]>;
};

export type UpdateConnectionStatusResponse = {
  __typename: "UpdateConnectionStatusResponse";
  connection: Connection;
};

export type UpdateFacilityApprovedPhoneInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  facilityApprovedPhoneId: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  privacyLevel?: InputMaybe<PrivacyLevel>;
};

export type UpdateFacilityApprovedPhoneResponse = {
  __typename: "UpdateFacilityApprovedPhoneResponse";
  facilityApprovedPhone: FacilityApprovedPhone;
};

export type UpdateFacilityFeaturesInput = {
  facilityId: Scalars["ID"];
  hasInPersonVisits?: InputMaybe<Scalars["Boolean"]>;
  hasMessaging?: InputMaybe<Scalars["Boolean"]>;
  hasPhoneCalls?: InputMaybe<Scalars["Boolean"]>;
  hasProviders?: InputMaybe<Scalars["Boolean"]>;
  hasVideoCalls?: InputMaybe<Scalars["Boolean"]>;
  hasVoiceCalls?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateFacilityFeaturesResponse = {
  __typename: "UpdateFacilityFeaturesResponse";
  facility: Facility;
};

export type UpdateFacilityInput = {
  defaultCallQuota?: InputMaybe<Scalars["Int"]>;
  defaultEmessageDelay?: InputMaybe<Scalars["Int"]>;
  defaultNeedsMonitor?: InputMaybe<Scalars["Boolean"]>;
  defaultVisitQuota?: InputMaybe<Scalars["Int"]>;
  defaultVoiceCallTimeQuota?: InputMaybe<Scalars["Int"]>;
  email?: InputMaybe<Scalars["String"]>;
  facilityId: Scalars["ID"];
  maxVoiceCallDuration?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  personalVideoCutoff?: InputMaybe<Scalars["Int"]>;
  personalVideoWindow?: InputMaybe<Scalars["Int"]>;
  personalVisitCutoff?: InputMaybe<Scalars["Int"]>;
  personalVisitWindow?: InputMaybe<Scalars["Int"]>;
  phone?: InputMaybe<Scalars["String"]>;
  policyLink?: InputMaybe<Scalars["String"]>;
  providerVideoCutoff?: InputMaybe<Scalars["Int"]>;
  providerVideoWindow?: InputMaybe<Scalars["Int"]>;
  providerVisitCutoff?: InputMaybe<Scalars["Int"]>;
  providerVisitWindow?: InputMaybe<Scalars["Int"]>;
  videoNeedsApproval?: InputMaybe<Scalars["Boolean"]>;
  videoQuotaResetDay?: InputMaybe<Scalars["Int"]>;
  visitNeedsApproval?: InputMaybe<Scalars["Boolean"]>;
  visitQuotaResetDay?: InputMaybe<Scalars["Int"]>;
  visitationFormLink?: InputMaybe<Scalars["String"]>;
  voiceCallPrivacyLevel?: InputMaybe<PrivacyLevel>;
  voiceCallResetTime?: InputMaybe<Scalars["Int"]>;
};

export type UpdateFacilityResponse = {
  __typename: "UpdateFacilityResponse";
  facility: Facility;
};

export type UpdateGroupInput = {
  description?: InputMaybe<Scalars["String"]>;
  groupId: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateGroupResponse = {
  __typename: "UpdateGroupResponse";
  group: Group;
};

export type UpdateInmateInput = {
  hasFilledIntakeForm?: InputMaybe<Scalars["Boolean"]>;
  inmateId: Scalars["ID"];
  profileImageUrl?: InputMaybe<Scalars["String"]>;
};

export type UpdateInmateResponse = {
  __typename: "UpdateInmateResponse";
  inmate: Inmate;
};

export type UpdateKioskInput = {
  capacity?: InputMaybe<Scalars["Int"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  kioskId: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateKioskResponse = {
  __typename: "UpdateKioskResponse";
  kiosk: Kiosk;
};

export type UpdateLanguagePreferenceInput = {
  language: Language;
};

export type UpdateLanguagePreferenceResponse = {
  __typename: "UpdateLanguagePreferenceResponse";
  user: User;
};

export type UpdateLocalMessageKeywordInput = {
  category?: InputMaybe<MessageKeywordCategory>;
  description?: InputMaybe<Scalars["String"]>;
  messageKeywordId: Scalars["ID"];
  spellings?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdateLocalMessageKeywordResponse = {
  __typename: "UpdateLocalMessageKeywordResponse";
  messageKeyword: MessageKeyword;
};

export type UpdateMeetingParticipantCommentInput = {
  meetingId: Scalars["ID"];
  participantComment?: InputMaybe<ParticipantComment>;
};

export type UpdateMeetingParticipantCommentResponse = {
  __typename: "UpdateMeetingParticipantCommentResponse";
  meeting: Meeting;
};

export type UpdateMessageIsReadInput = {
  messageId: Scalars["ID"];
};

export type UpdateMessageIsReadResponse = {
  __typename: "UpdateMessageIsReadResponse";
  message: Message;
};

export type UpdateNotificationEventReadAtInput = {
  notificationEventId: Scalars["ID"];
};

export type UpdateNotificationEventReadAtResponse = {
  __typename: "UpdateNotificationEventReadAtResponse";
  notificationEvent: NotificationEvent;
};

export type UpdateOrganizationMembershipInput = {
  organizationMembershipId: Scalars["ID"];
  role: OrganizationMembershipRole;
};

export type UpdateOrganizationMembershipResponse = {
  __typename: "UpdateOrganizationMembershipResponse";
  organizationMembership: OrganizationMembership;
};

export type UpdateScheduleInput = {
  capacity?: InputMaybe<Scalars["Int"]>;
  endsOn?: InputMaybe<Scalars["String"]>;
  groupIds?: InputMaybe<Array<Scalars["ID"]>>;
  kioskIds?: InputMaybe<Array<Scalars["ID"]>>;
  meetingSlots?: InputMaybe<Array<Slot>>;
  name?: InputMaybe<Scalars["String"]>;
  privacyLevels?: InputMaybe<Array<PrivacyLevel>>;
  scheduleId: Scalars["ID"];
  startsOn?: InputMaybe<Scalars["String"]>;
};

export type UpdateScheduleResponse = {
  __typename: "UpdateScheduleResponse";
  schedule: Schedule;
};

export type UpdateScheduledMeetingInput = {
  connectionIds?: InputMaybe<Array<Scalars["ID"]>>;
  inmateIds?: InputMaybe<Array<Scalars["ID"]>>;
  kioskId?: InputMaybe<Scalars["ID"]>;
  meetingId: Scalars["ID"];
  scheduledEnd?: InputMaybe<Scalars["Date"]>;
  scheduledStart?: InputMaybe<Scalars["Date"]>;
  title?: InputMaybe<Scalars["String"]>;
  unregisteredGuests?: InputMaybe<Array<Scalars["String"]>>;
  visitorIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type UpdateScheduledMeetingResponse = {
  __typename: "UpdateScheduledMeetingResponse";
  meeting: Meeting;
};

export type UpdateStaffInput = {
  email: Scalars["String"];
  entitlements: Array<Entitlement>;
  facilityIds: Array<Scalars["ID"]>;
  firstName: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  staffId: Scalars["ID"];
  systemId?: InputMaybe<Scalars["ID"]>;
};

export type UpdateStaffPreferencesInput = {
  receiveDailyScheduleEmails?: InputMaybe<Scalars["Boolean"]>;
  receiveTaskReminderEmails?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateStaffPreferencesResponse = {
  __typename: "UpdateStaffPreferencesResponse";
  staff: Staff;
};

export type UpdateStaffResponse = {
  __typename: "UpdateStaffResponse";
  staff: Staff;
};

export type UpdateVisitorEmailInput = {
  email: Scalars["String"];
  id: Scalars["ID"];
};

export type UpdateVisitorEmailResponse = {
  __typename: "UpdateVisitorEmailResponse";
  visitor: Visitor;
};

export type UpdateVisitorIdentityInput = {
  dateOfBirth: Scalars["String"];
  documentType?: InputMaybe<IdDocumentType>;
  firstName: Scalars["String"];
  imageOneKey?: InputMaybe<Scalars["String"]>;
  imageThreeKey?: InputMaybe<Scalars["String"]>;
  imageTwoKey?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type UpdateVisitorIdentityResponse = {
  __typename: "UpdateVisitorIdentityResponse";
  visitor: Visitor;
};

export type UpdateVisitorInput = {
  phone?: InputMaybe<Scalars["String"]>;
  /**
   * When present with a null value, delete the image.
   * When present with an upload URL, use the image.
   * When missing, make no changes.
   */
  profileImageKey?: InputMaybe<Scalars["String"]>;
  visitorId: Scalars["ID"];
};

export type UpdateVisitorResponse = {
  __typename: "UpdateVisitorResponse";
  visitor: Visitor;
};

export type UploadUrl = {
  __typename: "UploadUrl";
  key: Scalars["String"];
  url: Scalars["String"];
};

export type User = Inmate | Staff | Visitor;

export enum UserType {
  Inmate = "INMATE",
  Staff = "STAFF",
  User = "USER",
}

export type Visitor = Correspondent & {
  __typename: "Visitor";
  availableUploadTypes: Array<MessageType>;
  canReceiveVoiceCalls: Scalars["Boolean"];
  connection: Connection;
  connections: Array<Connection>;
  correspondentFeatures: Array<CorrespondentFeature>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  devices: Array<Device>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  identity?: Maybe<VisitorIdentity>;
  initials: Scalars["String"];
  languagePreference: Language;
  lastName: Scalars["String"];
  malanId: Scalars["ID"];
  meetings: MeetingConnection;
  notificationEvents: NotificationEventConnection;
  organizationMemberships: Array<OrganizationMembership>;
  phone?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  surveyActivity: Array<Maybe<SurveyActivity>>;
  systemRelationship?: Maybe<SystemUser>;
  systemRelationships: Array<SystemUser>;
  unavailableUntil?: Maybe<Scalars["Date"]>;
};

export type VisitorConnectionArgs = {
  inmateId: Scalars["ID"];
};

export type VisitorConnectionsArgs = {
  facilityId?: InputMaybe<Scalars["ID"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
  organizationMembershipId?: InputMaybe<Scalars["ID"]>;
};

export type VisitorMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  facilityId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  meetingType?: InputMaybe<Array<MeetingType>>;
  scheduledEndAfter?: InputMaybe<Scalars["Date"]>;
  status?: InputMaybe<Array<MeetingStatus>>;
};

export type VisitorNotificationEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VisitorSystemRelationshipArgs = {
  systemId: Scalars["ID"];
};

export type VisitorConnection = {
  __typename: "VisitorConnection";
  edges: Array<VisitorEdge>;
  pageInfo: PageInfo;
};

export type VisitorEdge = {
  __typename: "VisitorEdge";
  cursor: Scalars["String"];
  node: Visitor;
};

export type VisitorIdentity = {
  __typename: "VisitorIdentity";
  documentType: IdDocumentType;
  id: Scalars["ID"];
  idImageOneUrl?: Maybe<Scalars["String"]>;
  idImageThreeUrl?: Maybe<Scalars["String"]>;
  idImageTwoUrl?: Maybe<Scalars["String"]>;
  lastUploaded: Scalars["Date"];
  status: VisitorIdentityStatus;
};

export enum VisitorIdentityStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type VisitorOrInmate = Inmate | Visitor;

export enum VisitorType {
  Personal = "PERSONAL",
  Professional = "PROFESSIONAL",
}

export type WebinarAvailability = {
  __typename: "WebinarAvailability";
  interval: Interval;
};

export type AppInfoResponse = {
  __typename: "appInfoResponse";
  mobileAppMinimumSupportedVersion: Scalars["String"];
  mobileAppVersion: Scalars["String"];
};
