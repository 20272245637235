import ScreenSection from "@src/lib/ScreenSection";
import { useTranslation } from "react-i18next";

export default function TranscriptModule() {
  const { t } = useTranslation();
  return (
    <ScreenSection
      title={t("Call transcript")}
      sx={{ mb: 2 }}
      titlePosition="inner"
    >
      {t("Call transcript is not available yet")}
    </ScreenSection>
  );
}
