import { DateInputBase } from "@src/lib/DateInput";
import { fromLocalISODate, toLocalISODate } from "@src/lib/Time";
import { useTranslation } from "react-i18next";

interface Props {
  startDate?: number | null;
  disabled?: boolean;
  endDate?: number | null;
  onStartDateChange: (startDate: number | null) => void;
  onEndDateChange: (endDate: number | null) => void;
}

const DateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <DateInputBase
        size="small"
        disabled={disabled}
        value={startDate ? toLocalISODate(startDate) : null}
        onChange={(event) => {
          const date = event.target.value
            ? fromLocalISODate(event.target.value)
            : undefined;

          if (!date) {
            onStartDateChange(null);
            return;
          }

          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          onStartDateChange(date.getTime());
        }}
        label={t("From")}
      />
      <DateInputBase
        size="small"
        disabled={disabled}
        value={endDate ? toLocalISODate(endDate) : null}
        onChange={(event) => {
          const date = event.target.value
            ? fromLocalISODate(event.target.value)
            : undefined;

          if (!date) {
            onEndDateChange(null);
            return;
          }

          date.setHours(23);
          date.setMinutes(59);
          date.setSeconds(59);
          onEndDateChange(date.getTime());
        }}
        label={t("To")}
      />
    </>
  );
};

export default DateRangePicker;
