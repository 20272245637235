import { ltrTheme } from "@ameelio/ui";
import {
  Chat,
  ChatOutlined,
  Computer,
  ComputerOutlined,
  Group,
  ListAlt,
  ListAltOutlined,
  MapsHomeWork,
  MapsHomeWorkOutlined,
  PhoneEnabled,
  PhoneEnabledOutlined,
  PresentToAll,
  PresentToAllOutlined,
  Router,
  RouterOutlined,
  Videocam,
  VideocamOutlined,
} from "@mui/icons-material";
import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FacilityFeature } from "@src/api/graphql";
import featureEnabled from "@src/lib/featureEnabled";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import FacilityLayout from "./FacilityLayout";

const SettingsTabs = [
  "settings-facility",
  "settings-kiosks",
  "settings-in-person-visits",
  "settings-confidential-in-person-visits",
  "settings-voice-calls",
  "settings-video-calls",
  "settings-phone-calls",
  "settings-messages",
  "settings-confidential-video-calls",
  "settings-network",
  "settings-webinars",
] as const;
export type SettingsTab = typeof SettingsTabs[number];

type Props = {
  children: React.ReactNode;
  selectedTab: SettingsTab;
};

type MenuLinkProps = {
  current: boolean;
  to: string;
  label: string;
  icon: [React.ReactNode, React.ReactNode];
  active?: boolean;
};
function MenuLink({ current, to, icon, label, active }: MenuLinkProps) {
  const { t } = useTranslation();
  return (
    <Link
      tabIndex={0}
      sx={{
        textDecorationLine: "none",
        color: "black",
        backgroundColor: current ? grey[200] : undefined,
        borderRadius: 2,
        paddingY: 0.5,
        padding: 1,
        alignItems: "center",
        ":hover": {
          color: "text.primary",
          backgroundColor: grey[200],
        },
      }}
      to={to}
      component={RouterLink}
    >
      <Stack direction="row" alignItems="center" sx={{ height: 42 }}>
        {current ? icon[0] : icon[1]}
        <Box sx={{ marginLeft: 2 }}>
          <Box>{label}</Box>
          {active !== undefined && (
            <Typography variant="caption" color="text.secondary">
              {active ? t("Active") : t("Unavailable")}
            </Typography>
          )}
        </Box>
      </Stack>
    </Link>
  );
}
export default function SettingsLayout({ children, selectedTab }: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();

  const showLateralMenu = !featureEnabled("NESTED_SETTINGS_MENU");

  return (
    <FacilityLayout selectedTab={showLateralMenu ? "settings" : selectedTab}>
      <Box sx={{ display: "flex", height: 1 }}>
        {showLateralMenu && (
          <Paper
            sx={{
              minWidth: 220,
              maxWidth: 220,
              display: "flex",
              flexDirection: "column",
              maxHeight: "100%",
              overflow: "auto",
              padding: 0,
            }}
          >
            <Box sx={{ pr: 2, pl: 2, pt: 2 }}>
              <Typography variant="h3" component="div">
                {facility.publicId}
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Stack spacing={1} component="nav">
                <MenuLink
                  current={selectedTab === "settings-facility"}
                  to={"/settings/facility"}
                  label={t("Facility")}
                  icon={[<MapsHomeWork />, <MapsHomeWorkOutlined />]}
                />
                <MenuLink
                  current={selectedTab === "settings-kiosks"}
                  to={"/settings/kiosks"}
                  label={t("Kiosks")}
                  icon={[<Computer />, <ComputerOutlined />]}
                />
                {facility.features.includes(FacilityFeature.PhoneCall) && (
                  <MenuLink
                    current={selectedTab === "settings-phone-calls"}
                    to={"/settings/phone-calls"}
                    label={t("Outside services")}
                    icon={[<ListAlt />, <ListAltOutlined />]}
                  />
                )}
                {featureEnabled("TEST_CALLS") && (
                  <MenuLink
                    current={selectedTab === "settings-network"}
                    to={"/settings/network"}
                    label={t("Network")}
                    icon={[<Router />, <RouterOutlined />]}
                  />
                )}
                {facility.features.includes(FacilityFeature.InPersonVisit) && (
                  <MenuLink
                    current={selectedTab === "settings-in-person-visits"}
                    to={"/settings/in-person-visits"}
                    label={t("In-person visits")}
                    icon={[<Group />, <Group />]}
                  />
                )}
                {facility.features.includes(
                  FacilityFeature.ConfidentialInPersonVisit
                ) && (
                  <MenuLink
                    current={
                      selectedTab === "settings-confidential-in-person-visits"
                    }
                    to={"/settings/confidential-in-person-visits"}
                    label={t("Confidential in-person visits")}
                    icon={[<Group />, <Group />]}
                  />
                )}
                {facility.features.includes(FacilityFeature.VideoCall) && (
                  <MenuLink
                    current={selectedTab === "settings-video-calls"}
                    to={"/settings/video-calls"}
                    label={t("Video calls")}
                    icon={[<Videocam />, <VideocamOutlined />]}
                  />
                )}
                {facility.features.includes(
                  FacilityFeature.ConfidentialVideoCall
                ) && (
                  <MenuLink
                    current={
                      selectedTab === "settings-confidential-video-calls"
                    }
                    to={"/settings/confidential-video-calls"}
                    label={t("Confidential calls")}
                    icon={[<Videocam />, <VideocamOutlined />]}
                  />
                )}
                {facility.features.includes(FacilityFeature.Webinar) && (
                  <MenuLink
                    current={selectedTab === "settings-webinars"}
                    to={"/settings/webinars"}
                    label={t("Webinars")}
                    icon={[<PresentToAll />, <PresentToAllOutlined />]}
                  />
                )}
                {facility.features.includes(FacilityFeature.VoiceCall) && (
                  <MenuLink
                    current={selectedTab === "settings-voice-calls"}
                    to={"/settings/voice-calls"}
                    label={t("Voice calls")}
                    icon={[<PhoneEnabled />, <PhoneEnabledOutlined />]}
                  />
                )}
                {facility.features.includes(FacilityFeature.Emessaging) && (
                  <MenuLink
                    current={selectedTab === "settings-messages"}
                    to={"/settings/messages"}
                    label={t("Messages")}
                    icon={[<Chat />, <ChatOutlined />]}
                  />
                )}
              </Stack>
            </Box>
          </Paper>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            marginX: showLateralMenu ? 10 : 3,
            paddingTop: 2,
            paddingBottom: 2,
            maxWidth: `calc(100% - ${ltrTheme.spacing(6)})`,
          }}
        >
          {children}
        </Box>
      </Box>
    </FacilityLayout>
  );
}
