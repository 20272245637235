import {
  Button,
  TableNoData,
  TablePagination,
  usePagination,
} from "@ameelio/ui";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Call,
  Entitlement,
  Facility,
  Inmate,
  Interval,
  Meeting,
  MeetingStatus,
  MeetingType,
  Visitor,
} from "@src/api/graphql";
import getCallEndTime from "@src/lib/getCallEndTime";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useEntitlement from "@src/lib/useEntitlement";
import DeclineMeetingButton from "@src/pages/MeetingRequestsPage/DeclineMeetingButton";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import EndMeetingButton from "../EndMeetingButton";
import InmateCell from "../InmateCell";
import { labelMeetingType } from "../meeting";
import MeetingStatusTag from "../MeetingStatusTag";
import VisitorList from "../VisitorList";

export type MeetingsTableMeeting = Pick<
  Meeting,
  "id" | "status" | "meetingType" | "unregisteredGuests"
> & {
  facility: Pick<Facility, "province">;
  interval: Pick<Interval, "startAt" | "endAt">;
  call: Pick<Call, "duration"> | null;
  inmates: Pick<Inmate, "id" | "inmateIdentification" | "fullName">[];
  visitors: Pick<Visitor, "id" | "fullName">[];
};

type Props = {
  meetings: MeetingsTableMeeting[];
  viewingProfileOf: "inmate" | "visitor";
};

export default function MeetingsTable({ meetings, viewingProfileOf }: Props) {
  const navigate = useAppNavigate();
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );
  const { t } = useTranslation();
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: meetings,
  });

  return (
    <>
      <Table aria-label={t("Meetings table")}>
        <TableHead>
          <TableRow>
            <TableCell>{t("Time")}</TableCell>
            <TableCell>{t("Type")}</TableCell>
            <TableCell>{t("Contact(s)")}</TableCell>
            <TableCell>{t("Status")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {meetings.length === 0 && <TableNoData colSpan={4} />}
          {meetings.length > 0 &&
            currentPageData.map((meeting) => {
              return (
                <TableRow key={meeting.id}>
                  <TableCell>
                    <div className="flex flex-col">
                      <span>
                        {formatDate(new Date(meeting.interval.startAt), "date")}
                      </span>
                      <span>
                        {formatDate(new Date(meeting.interval.startAt), "time")}{" "}
                        - {""}
                        {getCallEndTime(
                          meeting.status,
                          meeting.meetingType,
                          meeting.interval,
                          meeting.call?.duration,
                          meeting.meetingType === MeetingType.VoiceCall
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-col">
                      {labelMeetingType(meeting.meetingType, {
                        titleCase: true,
                      })}
                    </div>
                  </TableCell>
                  {viewingProfileOf === "inmate" && (
                    <TableCell>
                      <VisitorList
                        visitors={meeting.visitors}
                        unregisteredGuests={meeting.unregisteredGuests}
                        combineMinorGuests={
                          meeting.facility.province === "Iowa"
                        }
                      />
                    </TableCell>
                  )}
                  {viewingProfileOf === "visitor" && (
                    <TableCell>
                      <Space direction="vertical">
                        {meeting.inmates.map((inmate) => (
                          <InmateCell key={inmate.id} inmate={inmate} />
                        ))}
                      </Space>
                    </TableCell>
                  )}
                  <TableCell>
                    <MeetingStatusTag
                      status={meeting.status}
                      meetingType={meeting.meetingType}
                    />
                  </TableCell>
                  <TableCell>
                    <Space>
                      {meeting.status === MeetingStatus.Scheduled && (
                        <DeclineMeetingButton
                          size="small"
                          meeting={meeting}
                          disabled={!canManageVisitorsAndMeetings}
                        />
                      )}
                      {meeting.status === MeetingStatus.Live && (
                        <EndMeetingButton
                          size="small"
                          meeting={meeting}
                          disabled={!canManageVisitorsAndMeetings}
                        />
                      )}
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => navigate(`/meetings/${meeting.id}`)}
                      >
                        {t("See details")}
                      </Button>
                    </Space>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        totalCount={meetings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </>
  );
}
