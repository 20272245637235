import { Line } from "react-chartjs-2";

const COLOR = "rgba(0, 137, 255, 1)";
const BG = "rgba(0, 137, 255, 0.4)";
const DARKER_COLOR = "rgba(2, 117, 216, 1)";

interface Props {
  labels: string[];
  label: string;
  data: number[];
}

const LineChart = ({ label, labels, data }: Props) => {
  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            label,
            backgroundColor: BG,
            borderColor: COLOR,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: COLOR,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 5,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: COLOR,
            pointHoverBorderColor: DARKER_COLOR,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data,
          },
        ],
      }}
    />
  );
};

export default LineChart;
