import { useSnackbarContext } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { GetSystemFacilitiesDocument } from "@src/graphql/GetSystemFacilities.generated";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import errorReporter from "@src/lib/errorReporter";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { WRAPPER_STYLE } from "@src/styles/styles";
import { Layout, PageHeader, Tabs } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SystemFacilitiesPage from "./SystemFacilities";
import SystemStaffPage from "./SystemStaff";

const { Content } = Layout;

const SystemPage = () => {
  const { system, facility } = useGuaranteedFacilityContext();
  const { loading, data } = useQuery(GetSystemFacilitiesDocument, {
    variables: {
      systemId: system.id,
    },
    onError: errorReporter,
  });
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<"facilities" | "staff">(
    "facilities"
  );

  const snackbarContext = useSnackbarContext();

  const tabs = [
    {
      label: t("All Facilities"),
      key: "facilities",
      children: <SystemFacilitiesPage />,
    },
    { label: t("All Staff"), key: "staff", children: <SystemStaffPage /> },
  ];

  if (loading || !data) return <PageLoader />;

  return (
    <Content>
      <PageHeader
        ghost={false}
        title=""
        breadcrumb={
          <Breadcrumbs
            onClick={(path) => {
              if (path === "/")
                snackbarContext.alert(
                  "info",
                  t("Now viewing {{facilityName}} dashboard", {
                    facilityName: facility.name,
                  })
                );
            }}
            paths={[
              {
                path: "/",
                label: t("Dashboard"),
              },
              {
                path: "/system",
                label: t("System Management"),
              },
            ]}
          />
        }
      ></PageHeader>
      <div style={WRAPPER_STYLE}>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(key) => setActiveTab(key as "facilities" | "staff")}
          items={tabs}
        />
      </div>
    </Content>
  );
};

export default SystemPage;
