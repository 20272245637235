import { useParams } from "react-router-dom";
import ScheduleEventPage from "./index";

export function EditScheduleEventPage() {
  const { id: inmateId, meetingId } =
    useParams<{ id: string; meetingId: string }>();
  if (!inmateId) throw new Error("missing inmateId param");
  if (!meetingId) throw new Error("missing meetingId param");
  return <ScheduleEventPage inmateId={inmateId} meetingId={meetingId} isEdit />;
}
