import { Check, Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const MonitoredPrivacyLabel = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: 1 }}>
      <Typography variant="subtitle1">{t("Monitored")}</Typography>
      <Box>
        <Typography variant="body2">
          <Check sx={{ color: "success.light" }} />
          {t("Live monitoring of voice calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Check sx={{ color: "success.light" }} />
          {t("Recordings of calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Check sx={{ color: "success.light" }} />
          {t("See who a resident talked with")}
        </Typography>
      </Box>
    </Box>
  );
};

export const UnmonitoredPrivacyLabel = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: 1 }}>
      <Typography variant="subtitle1">{t("Unmonitored")}</Typography>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("Live monitoring of voice calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("Recordings of calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Check sx={{ color: "success.light" }} />
          {t("See who a resident talked with")}
        </Typography>
      </Box>
    </Box>
  );
};

export const HiddenPrivacyLabel = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: 1 }}>
      <Typography variant="subtitle1">{t("Hidden")}</Typography>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("Live monitoring of voice calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("Recordings of calls")}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <Close sx={{ color: "error.light" }} />
          {t("See who a resident talked with")}
        </Typography>
      </Box>
    </Box>
  );
};
