/* eslint-disable react/function-component-definition */
import { TextField as Base, TextFieldProps as BaseProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { ForwardedRef } from "react";
import withController from "./withController";

export type TextInputProps = BaseProps & {
  onChange: Required<BaseProps>["onChange"];
  value: Required<BaseProps>["value"];
};

/**
 * TextInputBase defines any custom behaviors and styles of our standard text input.
 */
function TextInput(
  { value, variant, ...rest }: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const variantProps: Partial<BaseProps> =
    variant === "filled"
      ? {
          variant,
          InputProps: {
            disableUnderline: true,
            sx: {
              backgroundColor: grey[50],
              borderRadius: 2,
            },
          },
        }
      : {
          variant,
        };

  return (
    <Base inputRef={ref} {...rest} value={value || ""} {...variantProps} />
  );
}

export const TextInputBase = React.forwardRef(TextInput) as typeof TextInput;

export default withController(TextInputBase);
