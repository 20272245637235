import {
  CheckOutlined,
  DescriptionOutlined,
  DoNotDisturbOutlined,
  ScheduleOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { InstantReply, MessageStatus, MessageType } from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";
import {
  getMessageSenderType,
  getMessageStatusColor,
  getMessageStatusLabel,
  Message,
} from "@src/lib/Message";
import { formatDate } from "@src/lib/Time";
import { TypographyText } from "@src/lib/typography";
import { makeBold } from "@src/lib/UI";
import parse from "html-react-parser";
import React from "react";

interface Props {
  message: Message;
  className?: string;
}

// adds support for parsing instant reply enum values
// into human-readable strings
const parseMessage = (message: string) => {
  if (message === InstantReply.CallBackFiveMinutes)
    return i18n.t("Can you call back in 5 minutes?");
  if (message === InstantReply.CallBackNow)
    return i18n.t("I'm free now, call me back.");
  if (message === InstantReply.CallBackTwoMinutes)
    return i18n.t("Can you call back in 2 minutes?");
  if (message === InstantReply.CantTalkNow)
    return i18n.t("Sorry can't talk now.");
  return message;
};

const getFilenameFromUrl = (url: string) => {
  const pathname = new URL(url).pathname;
  const index = pathname.lastIndexOf("/");
  return pathname.substring(index + 1); // if index === -1 then index+1 will be 0
};

const MessagePreview = ({ message }: Props) => {
  switch (message.type) {
    case MessageType.Document:
      return message.fileUrl ? (
        <div className="flex gap-2 items-center">
          <DescriptionOutlined />
          <TypographyText>
            <a href={message.fileUrl} target={"_blank"} rel={"noreferrer"}>
              {getFilenameFromUrl(message.fileUrl)}
            </a>
          </TypographyText>
        </div>
      ) : null;
    case MessageType.Photo:
      /* eslint-disable jsx-a11y/img-redundant-alt */
      return message.fileUrl ? (
        <div>
          <img
            src={message.fileUrl || ""}
            alt={i18n.t("Something went wrong loading this image.")}
            style={{ maxWidth: "400px", maxHeight: "400px", width: "100%" }}
          />
        </div>
      ) : null;
    /* eslint-enable */
  }
  return null;
};

const MessageCard: React.FC<Props> = ({ message, className }: Props) => {
  const senderType = getMessageSenderType(message);

  const senderName =
    senderType === "inmate"
      ? message.connection.inmate.fullName
      : message.connection.visitor.fullName;

  const MessageStatusIcon = () => {
    switch (message.status) {
      case MessageStatus.Queued:
        return (
          <Typography color={getMessageStatusColor(message.status)}>
            <ScheduleOutlined />
          </Typography>
        );
      case MessageStatus.PendingReview:
        return (
          <Typography color={getMessageStatusColor(message.status)}>
            <WarningAmberOutlined />
          </Typography>
        );
      case MessageStatus.Rejected:
        return (
          <Typography color={getMessageStatusColor(message.status)}>
            <DoNotDisturbOutlined />
          </Typography>
        );
      case MessageStatus.Approved:
        return (
          <Typography color={getMessageStatusColor(message.status)}>
            <CheckOutlined />
          </Typography>
        );
      default:
        return <div className="hidden" />;
    }
  };

  return (
    <div
      className={`grid gap-1 ${
        senderType === "inmate" ? "" : "justify-items-end	"
      } ${className || ""}`}
    >
      <div
        className={`flex gap-2 items-center ${
          senderType === "inmate" ? "" : "flex-row-reverse"
        }`}
      >
        <MessageStatusIcon />
        <div className={`border border-gray rounded bg-white grid gap-2 p-2`}>
          <TypographyText>
            {message.detectedKeywords.length > 0
              ? parse(
                  makeBold(
                    message.content,
                    message.detectedKeywords.map(
                      (detection) => detection.spelling
                    )
                  )
                )
              : parseMessage(message.content)}
          </TypographyText>
          <MessagePreview message={message} />
          <TypographyText type="secondary" className="text-xs ml-auto">
            {formatDate(message.createdAt, "datetime")}
          </TypographyText>
        </div>
      </div>
      <div className="flex gap-1">
        <TypographyText type="secondary">{senderName}</TypographyText>
      </div>

      <TypographyText
        type="secondary"
        className="text-xs"
        color={getMessageStatusColor(message.status)}
      >
        {getMessageStatusLabel(message.status)}
      </TypographyText>
    </div>
  );
};

export default MessageCard;
