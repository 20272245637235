import { InlineBadge } from "@ameelio/ui";
import { UserOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box";
import { blue, grey } from "@mui/material/colors";
import { Message } from "@src/lib/Message";
import { formatDate } from "@src/lib/Time";
import { TypographyText } from "@src/lib/typography";
import { differenceInHours, differenceInMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import { TConnectionListItem } from "./ConnectionList";

interface Props {
  onSelect: () => void;
  isSelected?: boolean;
  connection: TConnectionListItem;
  totalFlaggedMessages?: number;
  oldestMessage: Message;
}

const ConnectionListItem = ({
  connection,
  onSelect,
  isSelected,
  totalFlaggedMessages,
  oldestMessage,
}: Props) => {
  const { t } = useTranslation();
  const getTimestamp = (): string => {
    // messages are sorted by created at
    const hoursSince = differenceInHours(new Date(), oldestMessage.createdAt);

    if (hoursSince < 24) {
      if (hoursSince <= 1) {
        return t("{{count}} minutes ago", {
          count: differenceInMinutes(new Date(), oldestMessage.createdAt),
        });
      }
      return t("{{count}} hours ago", {
        count: hoursSince,
      });
    }
    if (hoursSince < 48) {
      return t("Yesterday");
    } else {
      return formatDate(oldestMessage.createdAt, "date");
    }
  };

  return (
    <Box
      onClick={onSelect}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        px: 2,
        py: 1,
        backgroundColor: isSelected ? blue[50] : "white",
        "&:hover": {
          backgroundColor: grey[50],
        },
        width: {
          xs: "100%",
          md: "300px",
        },
      }}
    >
      <div className="grid gap-2">
        <TypographyText>
          {connection.inmate.fullName}
          {totalFlaggedMessages && (
            <InlineBadge badgeContent={totalFlaggedMessages} sx={{ ml: 1 }} />
          )}
        </TypographyText>
        <div className="flex items-center gap-2">
          <UserOutlined className="text-gray-500" />
          <TypographyText type="secondary">
            {connection.visitor.fullName}
          </TypographyText>
        </div>
      </div>
      <TypographyText type="secondary">{getTimestamp()}</TypographyText>
    </Box>
  );
};

export default ConnectionListItem;
