import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { VisitorFragmentFragment } from "@src/graphql/fragments/VisitorFragment.generated";
import Avatar from "@src/lib/Avatar";

interface Props {
  visitor: VisitorFragmentFragment;
}

const MessagesReadOnlyHeaderContent = ({ visitor }: Props) => {
  return (
    <Box
      p={1}
      sx={{
        backgroundColor: grey[50],
        display: "flex",
        flexDirection: { lg: "row", xs: "column" },
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "64px",
      }}
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <Avatar fallback={visitor.fullName} />
        <Typography variant="body2" fontWeight="bold">
          {visitor.fullName}
        </Typography>
      </Stack>
    </Box>
  );
};

export default MessagesReadOnlyHeaderContent;
