import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import Avatar from "@src/lib/Avatar";
import { TypographyText } from "@src/lib/typography";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";
import { GetOrganizationQuery } from "./GetOrganization.generated";

type Membership = GetOrganizationQuery["organization"]["memberships"][0];

interface Props {
  memberships: Membership[];
  loading?: boolean;
}

const OrganizationMembersTable = ({ memberships, loading }: Props) => {
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: memberships,
  });

  return (
    <>
      <TableContainer>
        <Table aria-label={t("Organization members table")}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Role")}</TableCell>
              <TableCell>{t("Email")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {memberships.length === 0 && <TableNoData colSpan={3} />}
            {memberships.length > 0 &&
              currentPageData.map((membership) => {
                return (
                  <TableRow key={membership.id}>
                    <TableCell>
                      <Avatar fallback={membership.visitor.fullName} />
                    </TableCell>
                    <TableCell>
                      <TypographyText>
                        {membership.visitor.fullName}
                      </TypographyText>
                    </TableCell>
                    <TableCell>{membership.role.toLowerCase()}</TableCell>
                    <TableCell>
                      <TypographyText>
                        {membership.visitor.email}
                      </TypographyText>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          navigate(`/visitors/${membership.visitor.id}`)
                        }
                      >
                        {t("See details")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        totalCount={memberships.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default OrganizationMembersTable;
