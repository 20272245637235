import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingFragmentFragmentDoc } from '../../graphql/fragments/MeetingFragment.generated';
export type CreateScheduledMeetingMutationVariables = Types.Exact<{
  input: Types.CreateScheduledMeetingInput;
}>;


export type CreateScheduledMeetingMutation = { __typename: 'Mutation', createScheduledMeeting: { __typename: 'CreateScheduledMeetingResponse', meeting: { __typename: 'Meeting', id: string, schedulerId: string, unregisteredGuests: Array<string>, meetingType: Types.MeetingType, status: Types.MeetingStatus, statusDetails: string | null, privacyLevel: Types.PrivacyLevel, facility: { __typename: 'Facility', id: string, province: string }, call: { __typename: 'Call', id: string, rating: number | null, duration: number | null } | null, interval: { __typename: 'Interval', startAt: number, endAt: number, seconds: number | null } } } };


export const CreateScheduledMeetingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateScheduledMeeting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateScheduledMeetingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createScheduledMeeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingFragment"}}]}}]}}]}},...MeetingFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CreateScheduledMeetingMutation, CreateScheduledMeetingMutationVariables>;