import { Button, ButtonProps } from "@ameelio/ui";
import {
  Call,
  Meeting,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
} from "@src/api/graphql";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";

type Props = ButtonProps & {
  meeting: Pick<Meeting, "id" | "status" | "privacyLevel" | "meetingType"> & {
    call?: Pick<
      Call,
      "id" | "screenshotsExpireAt" | "artifactsExpireAt"
    > | null;
  };
};

export default function ViewRecordsButton({ meeting, ...rest }: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const now = new Date().getTime();
  let disabledReason = "";

  const expirationDate =
    meeting.privacyLevel === PrivacyLevel.UnmonitoredLogged &&
    meeting.meetingType === MeetingType.VideoCall
      ? meeting.call?.screenshotsExpireAt
      : meeting.call?.artifactsExpireAt;

  if (
    !meeting.call ||
    [MeetingStatus.Cancelled, MeetingStatus.Rejected].includes(meeting.status)
  ) {
    disabledReason = t("This meeting never took place.");
  } else if (expirationDate && expirationDate <= now) {
    disabledReason = t(
      "These records are no longer available. The records have expired on {{date}}.",
      { date: formatDate(expirationDate, "datetime") }
    );
  }

  return (
    <Button
      variant="outlined"
      disabled={Boolean(disabledReason)}
      disabledReason={disabledReason}
      onClick={() => {
        navigate(`/records/${meeting.id}`);
      }}
      {...rest}
    >
      {t("View records")}
    </Button>
  );
}
