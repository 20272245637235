import { MeetingStatus, MeetingType } from "@src/api/graphql";
import { t } from "i18next";

export default function getMeetingStatusLabelByType(
  status: MeetingStatus,
  meetingType?: MeetingType
) {
  let value;
  switch (status) {
    case MeetingStatus.Cancelled:
      value = t("Canceled");
      break;
    case MeetingStatus.Live:
      value = t("Live");
      break;
    case MeetingStatus.PendingApproval:
      value = t("Pending Approval");
      break;
    case MeetingStatus.Rejected:
      value = t("Declined");
      break;
    case MeetingStatus.Terminated:
      value = t("Terminated");
      break;
    case MeetingStatus.Scheduled:
      value = t("Scheduled");
      break;
    case MeetingStatus.Ended:
      value = t("Ended");
      break;
    case MeetingStatus.NoShow:
      value = t("No Show");
      break;
  }
  if (meetingType === MeetingType.VoiceCall) {
    if (status === MeetingStatus.Scheduled) value = t("Calling");
    if (status === MeetingStatus.NoShow) value = t("No Answer");
    if (status === MeetingStatus.Ended) value = t("Call Ended");
  }
  return value.toLocaleUpperCase();
}
