import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MessageKeywordFragmentFragmentDoc } from '../../graphql/fragments/MessageKeywordFragment.generated';
export type CreateLocalMessageKeywordMutationVariables = Types.Exact<{
  input: Types.CreateLocalMessageKeywordInput;
}>;


export type CreateLocalMessageKeywordMutation = { __typename: 'Mutation', createLocalMessageKeyword: { __typename: 'CreateLocalMessageKeywordResponse', messageKeyword: { __typename: 'MessageKeyword', id: string, name: string, description: string, spellings: Array<string>, category: Types.MessageKeywordCategory, isGlobal: boolean, isActive: boolean, createdAt: number | null, updatedAt: number | null, createdBy: { __typename: 'StaffPosition', id: string, staff: { __typename: 'Staff', id: string, firstName: string, lastName: string, fullName: string } } | null, lastUpdatedBy: { __typename: 'StaffPosition', id: string, staff: { __typename: 'Staff', id: string, firstName: string, lastName: string, fullName: string } } | null } } };


export const CreateLocalMessageKeywordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateLocalMessageKeyword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateLocalMessageKeywordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createLocalMessageKeyword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messageKeyword"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageKeywordFragment"}}]}}]}}]}},...MessageKeywordFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CreateLocalMessageKeywordMutation, CreateLocalMessageKeywordMutationVariables>;