import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RejectSystemVisitorMutationVariables = Types.Exact<{
  input: Types.RejectSystemVisitorInput;
}>;


export type RejectSystemVisitorMutation = { __typename: 'Mutation', rejectSystemVisitor: { __typename: 'RejectSystemVisitorResponse', systemVisitor: { __typename: 'SystemUser', id: string, status: Types.SystemUserStatus, statusDetails: string | null } } };


export const RejectSystemVisitorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RejectSystemVisitor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RejectSystemVisitorInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rejectSystemVisitor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"systemVisitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"statusDetails"}}]}}]}}]}}]} as unknown as DocumentNode<RejectSystemVisitorMutation, RejectSystemVisitorMutationVariables>;