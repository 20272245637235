import { groupBy } from "@ameelio/core";
import { MeetingStatus } from "@src/api/graphql";
import { formatDate } from "@src/lib/Time";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GetUpcomingMeetingsQuery } from "../UpcomingMeetingsPage/GetUpcomingMeetings.generated";

type GroupKey = MeetingStatus.Live | number;

const useSortedGroupedMeetings = (data?: GetUpcomingMeetingsQuery) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!data?.facility.meetings.edges) return [];

    const meetings = data.facility.meetings.edges
      .map(({ node }) => node)
      .sort((a, b) => {
        if (a.status === MeetingStatus.Live && b.status !== MeetingStatus.Live)
          return -1;
        if (a.status !== MeetingStatus.Live && b.status === MeetingStatus.Live)
          return 1;
        return (
          a.interval.startAt - b.interval.startAt ||
          (a.kiosk?.name ?? "").localeCompare(b.kiosk?.name ?? "")
        );
      });

    const grouped = groupBy(
      meetings,
      (meeting): GroupKey =>
        meeting.status === MeetingStatus.Live
          ? MeetingStatus.Live
          : meeting.interval.startAt
    );

    return Object.entries(grouped)
      .map(([key, meetings]) => ({
        timeSlot:
          key === MeetingStatus.Live.toString()
            ? t("Happening now")
            : formatDate(new Date(parseInt(key)), "time"),
        isLive: key === MeetingStatus.Live.toString(),
        meetings,
      }))
      .sort((a, b) => {
        if (a.isLive) return -1;
        if (b.isLive) return 1;
        const aTime = a.meetings ? a.meetings[0].interval.startAt : 0;
        const bTime = b.meetings ? b.meetings[0].interval.startAt : 0;
        return aTime - bTime;
      });
  }, [data, t]);
};

export default useSortedGroupedMeetings;
