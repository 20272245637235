import Box from "@mui/material/Box";
import { Message } from "@src/lib/Message";
import MessagePanelStructure from "@src/pages/MessageReviewPage/MessageReviewPanel/MessagePanelStructure";
import ConnectionList, {
  ConnectionFlaggedMessagesMap,
  TConnectionListItem,
} from "./ConnectionListSidebar/ConnectionList";
import MessageReviewControlPanel from "./MessageReviewControlPanel";
import MessagesList from "./MessagesList";
import MessagesListHeader from "./MessagesListHeader";
import MessagesListHeaderContent from "./MessagesListHeaderContent";

interface Props {
  flaggedMessageMap: ConnectionFlaggedMessagesMap;
  selected?: {
    messages: Message[];
    connection: TConnectionListItem;
    flaggedMessage: Message;
    totalFlaggedMessages: number;
  };
  onSelect: (connection: TConnectionListItem) => void;
  onApprove: () => void;
  onReject: (reason: string, internalComment: string) => void;
  loading?: boolean;
}

const MessagesReviewPanel = ({
  flaggedMessageMap,
  selected,
  onSelect,
  onApprove,
  onReject,
  loading,
}: Props) => {
  return (
    <Box
      sx={{
        p: 3,
        width: "100%",
      }}
    >
      <MessagePanelStructure
        connectionList={
          <ConnectionList
            flaggedMessageMap={flaggedMessageMap}
            selectedConnection={selected?.connection}
            onSelectConnection={onSelect}
          />
        }
        hasSelectedItem={Boolean(selected)}
        messagePanelContent={
          selected && (
            <>
              <MessagesListHeader>
                <MessagesListHeaderContent connection={selected.connection} />
              </MessagesListHeader>
              <MessagesList
                messages={selected.messages}
                focus={selected.flaggedMessage}
              />
              <MessageReviewControlPanel
                onApprove={onApprove}
                onReject={onReject}
                message={selected.flaggedMessage}
                totalFlaggedMessages={selected.totalFlaggedMessages}
                loading={loading}
              />
            </>
          )
        }
      />
    </Box>
  );
};

export default MessagesReviewPanel;
