import { toLocalISODate } from "@src/lib/Time";
import React, { ForwardedRef } from "react";
import { TextInputBase, TextInputProps } from "./TextInput";
import withController from "./withController";

type DateInputProps = Omit<TextInputProps, "type"> & {
  disablePast?: boolean;
  disableFuture?: boolean;
};

function DateInput(
  { disablePast, disableFuture, ...rest }: DateInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <TextInputBase
      ref={ref}
      {...rest}
      type="date"
      inputProps={{
        min: disablePast ? toLocalISODate(new Date()) : undefined,
        max: disableFuture ? toLocalISODate(new Date()) : undefined,

        // make the `mm/dd/yyyy` placeholder disappear when the field
        // is empty and not focused
        sx: {
          ":not(:focus)": {
            color: !rest.value ? "transparent" : undefined,
            opacity: !rest.value && rest.disabled ? 0 : undefined,
          },
        },
      }}
    />
  );
}

export const DateInputBase = React.forwardRef(DateInput) as typeof DateInput;

export default withController(DateInputBase);
