const KEY = "token";

export type Storage = {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
};

export function memoryStorage(): Storage {
  const data: Record<string, string> = {};
  return {
    getItem: (k) => data[k],
    setItem: (k, v) => {
      data[k] = v;
    },
    removeItem: (k) => delete data[k],
  };
}

// if the browser does not allow access to sessionStorage
// then degrade to javascript memory storage
let storage: Storage;
try {
  sessionStorage.getItem("test");
  storage = sessionStorage;
} catch (e) {
  storage = memoryStorage();
}

export const getToken = (): string => storage.getItem(KEY) || "";

export const storeToken = (value: string | undefined) =>
  value ? storage.setItem(KEY, value) : storage.removeItem(KEY);
