import { Box } from "@mui/material";
import { ScreenTitle } from "@src/lib/typography";
import { useTranslation } from "react-i18next";

export default function NotFoundSplashScreen() {
  const { t } = useTranslation();
  return (
    <Box sx={{ paddingTop: 10, paddingLeft: 10 }}>
      <ScreenTitle skipPageTitle>{t("Page not found")}</ScreenTitle>
    </Box>
  );
}
