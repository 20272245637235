import { Chip, ChipCustomColors } from "@ameelio/ui";
import { PhoneCallStatus } from "@src/api/graphql";

interface Props {
  status: PhoneCallStatus;
}

const StatusColors: Record<PhoneCallStatus, ChipCustomColors> = {
  [PhoneCallStatus.Initiated]: "green",
  [PhoneCallStatus.Ringing]: "green",
  [PhoneCallStatus.InProgress]: "green",
  [PhoneCallStatus.Completed]: "green",
  [PhoneCallStatus.NoAnswer]: "grey",
  [PhoneCallStatus.Busy]: "grey",
  [PhoneCallStatus.Canceled]: "orange",
  [PhoneCallStatus.Failed]: "red",
};

export default function PhoneCallStatusTag({ status }: Props) {
  return <Chip color={StatusColors[status]} label={status} />;
}
