import { Box } from "@mui/material";

type Props = {
  children: React.ReactNode;
};

// https://css-tricks.com/equal-columns-with-flexbox-its-more-complicated-than-you-might-think/
export default function ResponsiveColumns({ children }: Props) {
  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: {
          xs: "repeat(auto-fit, minmax(100px, 300px))",
          md: "repeat(auto-fit, minmax(100px, 250px))",
        },
      }}
    >
      {children}
    </Box>
  );
}
