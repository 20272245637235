/* eslint-disable react/function-component-definition */
import { IconButton } from "@ameelio/ui";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  SelectProps as BaseProps,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import ConditionalTooltip from "../../ConditionalTooltip";
import { parseTime, parseTimeslot } from "../../parseTime";
import withController from "../../withController";

export type IdTimeslot = {
  id: string;
  start: string;
  end: string;
};

type ManagedProps = "select" | "children" | "multiple";
export type Props = Omit<BaseProps, ManagedProps> & {
  onChange: (v: IdTimeslot[]) => void;
  value: IdTimeslot[];
  disabled?: boolean;
  disabledText?: string;
};

function setAtIndex<T>(arr: T[], i: number, v: T) {
  return arr
    .slice(0, i)
    .concat([v])
    .concat(arr.slice(i + 1));
}

function immutableSplice<T>(arr: T[], i: number, n: number) {
  return arr.slice(0, i).concat(arr.slice(i + n));
}

function TimeSlotsInput({ onChange, value, disabled, disabledText }: Props) {
  const [newId, setNewId] = useState<string>(uuid());

  const { t } = useTranslation();

  // Three validations:
  // 1. Each input should house a valid time
  const timeErrors = useMemo(() => {
    const errors: Record<string, { start: boolean; end: boolean }> = {};
    value.forEach(({ id, start, end }) => {
      errors[id] = { start: !parseTime(start), end: !parseTime(end) };
    });
    return errors;
  }, [value]);

  // 2. Each start should be less than its end
  const rangeErrors = useMemo(() => {
    const errors: Record<string, boolean> = {};
    value.forEach(({ id, start, end }) => {
      const slot = parseTimeslot(start, end);
      errors[id] = !!slot && slot.duration <= 0;
    });
    return errors;
  }, [value]);

  // 3. No two intervals should overlap
  const overlapErrors = useMemo(() => {
    const errors: Record<string, boolean> = {};
    const slots = value
      .map(({ id, start, end }) => {
        const startTime = parseTime(start);
        const endTime = parseTime(end);

        if (!startTime || !endTime) return null;

        return {
          id,
          start: startTime[0] * 60 + startTime[1],
          end: endTime[0] * 60 + endTime[1],
        };
      })
      .filter((x): x is { id: string; start: number; end: number } => !!x);

    if (slots.length === 1) return {};

    slots.sort((a, b) => a.start - b.start);

    for (let i = 1; i < slots.length; i++) {
      if (slots[i].start < slots[i - 1].end) {
        errors[slots[i].id] = true;
        errors[slots[i - 1].id] = true;
      }
    }

    return errors;
  }, [value]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 24px",
        gap: (theme) => theme.spacing(2),
        columnGap: (theme) => theme.spacing(1.5),
      }}
    >
      {value
        .concat(disabled ? [] : [{ id: newId, start: "", end: "" }])
        .map(({ id, start, end }, i) => {
          return (
            <React.Fragment key={id}>
              <ConditionalTooltip
                active={!!disabled}
                title={disabledText || ""}
              >
                <TextField
                  key={`start-${id}`}
                  defaultValue={start}
                  label={t("Start time")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={disabled}
                  type="time"
                  error={
                    (id in timeErrors && timeErrors[id].start) ||
                    rangeErrors[id] ||
                    overlapErrors[id]
                  }
                  helperText={
                    id in timeErrors && timeErrors[id].start
                      ? t("Invalid time")
                      : undefined
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (i < value.length) {
                      onChange(
                        setAtIndex(value, i, {
                          id,
                          start: event.target.value,
                          end,
                        })
                      );
                    } else {
                      if (event.target.value !== "") {
                        setNewId(uuid());
                        onChange(
                          value.concat([
                            { id, start: event.target.value, end: "" },
                          ])
                        );
                      }
                    }
                  }}
                />
              </ConditionalTooltip>
              <ConditionalTooltip
                active={!!disabled}
                title={disabledText || ""}
              >
                <TextField
                  key={`end-${id}`}
                  defaultValue={end}
                  label={t("End time")}
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={disabled}
                  error={
                    (id in timeErrors && timeErrors[id].end) ||
                    rangeErrors[id] ||
                    overlapErrors[id]
                  }
                  helperText={
                    id in timeErrors && timeErrors[id].end
                      ? t("Invalid time")
                      : undefined
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (i < value.length) {
                      onChange(
                        setAtIndex(value, i, {
                          id,
                          start,
                          end: event.target.value,
                        })
                      );
                    } else {
                      if (event.target.value !== "") {
                        setNewId(uuid());
                        onChange(
                          value.concat([
                            { id, start: "", end: event.target.value },
                          ])
                        );
                      }
                    }
                  }}
                />
              </ConditionalTooltip>
              <Box display="flex" alignItems="center" justifyContent="center">
                {!disabled && (
                  <IconButton
                    key={`del-${id}`}
                    ariaLabel={t("Clear")}
                    onClick={() => {
                      onChange(immutableSplice(value, i, 1));
                    }}
                    sx={{
                      width: (theme) => theme.spacing(3),
                      height: (theme) => theme.spacing(3),
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
              {rangeErrors[id] && (
                <Typography
                  color="error.main"
                  variant="caption"
                  sx={{ gridColumn: "1 / 4" }}
                >
                  {" "}
                  {t("End time must be after start time.")}
                </Typography>
              )}
              {overlapErrors[id] && (
                <Typography
                  color="error.main"
                  variant="caption"
                  sx={{ gridColumn: "1 / 4" }}
                >
                  {" "}
                  {t("Time slots in the same schedule cannot overlap.")}
                </Typography>
              )}
            </React.Fragment>
          );
        })}
    </Box>
  );
}

export default withController(TimeSlotsInput);
