import { Entitlement } from "@src/api/graphql";

const entitlementLabel = (entitlement: Entitlement) => {
  const LABELS: Record<Entitlement | string, string> = {
    [Entitlement.ManageFacility]: "Manage Facility",
    [Entitlement.ManageStaff]: "Manage Staff",
    [Entitlement.ManageVisitorsAndMeetings]: "Manage Visitors and Meetings",
    [Entitlement.MonitorLiveCalls]: "Monitor Live Calls",
    [Entitlement.ReviewEmessages]: "Review Emessages",
    [Entitlement.ReviewRecordings]: "Review Recordings",
    [Entitlement.UploadSystemRoster]: "Upload System Roster",
    [Entitlement.ReviewScreenshots]: "Review Screenshots",
  };
  return LABELS[entitlement];
};

export default entitlementLabel;
