import { sortByStrings } from "@ameelio/core";
import { Button, MultiSelectChipInput, TextArrayInput } from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import {
  ConnectionFeature,
  ConnectionStatus,
  FacilityFeature,
  FacilityService,
  Inmate,
  MeetingType,
  OrganizationType,
} from "@src/api/graphql";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { theme } from "@src/theme";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GetInmateQuery } from "../InmatePage/GetInmate.generated";
import { Data, GuestListData, RegisteredGuest } from "./types";

type Props = {
  inmate: Pick<Inmate, "id" | "firstName"> & {
    connections: GetInmateQuery["inmate"]["connections"];
  };
  data: Data;
  onBack: () => void;
  onSubmit: ({ registeredGuests, unregisteredGuests }: GuestListData) => void;
};

type FormData = {
  registeredGuestIds: string[];
  unregisteredGuestNames: string[];
};

export default function AddGuestListStep({
  inmate,
  data,
  onBack,
  onSubmit,
}: Props) {
  // meeting type is required, type narrowing
  if (!data.meetingType) throw new Error("invalid meeting type");
  const { meetingType } = data;
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();

  // Registered guests
  const { control, watch } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      registeredGuestIds: data.registeredGuests?.map((rg) => rg.id) ?? [],
      unregisteredGuestNames:
        data.unregisteredGuests?.map((ug) => ug.name) ?? [],
    },
  });
  const registeredGuestIds = watch("registeredGuestIds");
  const unregisteredGuestNames = watch("unregisteredGuestNames");
  const activeConnections = inmate.connections.filter(
    (c) => c.status === ConnectionStatus.Active
  );
  // understanding addition of org members
  const orgMemberConnectionAdded = activeConnections?.filter(
    (c) => !!c.organizationMembership && c.visitor.id === registeredGuestIds[0]
  )[0];
  const orgAdded =
    orgMemberConnectionAdded?.organizationMembership?.organization;

  // understanding professional status of visitors
  const personalVisitorAdded = activeConnections
    ?.filter((c) => !c.organizationMembership)
    .map((c) => c.visitor.id)
    .includes(registeredGuestIds[0]);

  const hasAddedGuestsToCall =
    meetingType === MeetingType.VideoCall
      ? !!registeredGuestIds.length
      : !!(registeredGuestIds.length || unregisteredGuestNames.length);

  // used to produce the helper text rendered underneath the select input
  const disabledHelperText = (() => {
    if (
      orgAdded?.type === OrganizationType.Healthcare &&
      ((meetingType === MeetingType.VideoCall &&
        !facility.features.includes(FacilityFeature.ConfidentialVideoCall)) ||
        (meetingType === MeetingType.InPersonVisit &&
          !facility.features.includes(
            FacilityFeature.ConfidentialInPersonVisit
          )))
    ) {
      return t(
        "Meetings with healthcare organization members must be confidential and the facility does not support confidential {{meetingType}}",
        {
          meetingType:
            meetingType === MeetingType.VideoCall
              ? t("video calls")
              : t("in-person visits"),
        }
      );
    }
    return "";
  })();

  // used to produce the helper text rendered within the select option
  // for a visitor in the event the option is disabled
  const disabledOptionHelperText = (
    connection: GetInmateQuery["inmate"]["connections"][0]
  ) => {
    return !!orgMemberConnectionAdded && !connection.organizationMembership
      ? t("{{visitorName}} is not a member of an organization", {
          visitorName: connection.visitor.firstName,
        })
      : personalVisitorAdded && connection.organizationMembership
      ? t("{{visitorName}} is not a friends or family visitor", {
          visitorName: connection.visitor.firstName,
        })
      : (meetingType === MeetingType.VideoCall &&
          connection.restrictions.some(
            (r) => r.service === FacilityService.VideoCall && r.isActive
          )) ||
        (meetingType === MeetingType.InPersonVisit &&
          connection.restrictions.some(
            (r) => r.service === FacilityService.InPersonVisit && r.isActive
          ))
      ? t(
          "{{visitorName}} is currently restricted from conducting {{meetingType}} with {{inmateName}}",
          {
            visitorName: connection.visitor.firstName,
            meetingType:
              meetingType === MeetingType.VideoCall
                ? t("video calls")
                : t("in-person visits"),
            inmateName: inmate.firstName,
          }
        )
      : !!orgMemberConnectionAdded &&
        orgAdded?.id !== connection.organizationMembership?.organization.id
      ? t("{{visitorName}} is a member of a different organization", {
          visitorName: connection.visitor.firstName,
        })
      : "";
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h2">{t("Step 2 of 4: Add visitors")}</Typography>
        <Typography variant="body1">
          {meetingType === MeetingType.VideoCall
            ? t(
                "Add at least one approved visitor who has an Ameelio Connect account."
              )
            : t(
                "Add approved visitors with Ameelio Connect accounts or other approved visitors."
              )}
        </Typography>
        <MultiSelectChipInput
          name="registeredGuestIds"
          label={t("Visitors with accounts")}
          control={control}
          items={sortByStrings(
            activeConnections?.map((c) => ({
              value: c.visitor.id,
              name: c.visitor.fullName,
              disabled: !!disabledOptionHelperText(c),
              helperText: disabledOptionHelperText(c),
            })) ?? [],
            (c) => c.name
          )}
          rules={{
            required: meetingType === MeetingType.VideoCall,
          }}
          helperText={disabledHelperText}
          sx={{ backgroundColor: theme.palette.common.white }}
        />
        <TextArrayInput
          control={control}
          name="unregisteredGuestNames"
          label={t("Other visitors")}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.common.white,
            borderRadius: 2,
          }}
          autoComplete="off"
        />
      </Stack>
      <Box mt={3} gap={1} display="flex" justifyContent="flex-end">
        <Button variant="outlined" onClick={onBack}>
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const registeredGuests: RegisteredGuest[] =
              activeConnections
                ?.filter((c) => registeredGuestIds.includes(c.visitor.id))
                .map((c) => ({
                  id: c.visitor.id,
                  fullName: c.visitor.fullName,
                  email: c.visitor.email,
                  connectionId: c.id,
                  organizationMembership: c.organizationMembership,
                })) ?? [];
            const unregisteredGuests = unregisteredGuestNames.map((name) => ({
              name,
            }));
            const willBeConfidential = (() => {
              const meetingConnections = activeConnections.filter((c) =>
                registeredGuests?.map((rg) => rg.connectionId).includes(c.id)
              );
              return (
                (meetingType === MeetingType.VideoCall &&
                  meetingConnections.every((c) =>
                    c.features.includes(ConnectionFeature.ConfidentialVideoCall)
                  )) ||
                (meetingType === MeetingType.InPersonVisit &&
                  meetingConnections.every((c) =>
                    c.features.includes(
                      ConnectionFeature.ConfidentialInPersonVisit
                    )
                  ))
              );
            })();
            onSubmit({
              registeredGuests,
              unregisteredGuests,
              confidential: willBeConfidential,
            });
          }}
          disabled={!hasAddedGuestsToCall || !!disabledHelperText}
        >
          {t("Next")}
        </Button>
      </Box>
    </>
  );
}
