import { ChevronRight } from "@mui/icons-material";
import {
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  SxProps,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
// Note: @mui/material _also_ has an exported Theme,
// but it is not the same type as @mui/system/Theme.
// This caused some type issues in the past.
import { onlyMobile } from "@ameelio/ui";
import { Theme } from "@mui/system";
import Tooltip from "@src/lib/Tooltip";

export const DropdownList = MenuList;

type DropdownItemProps = {
  image?: JSX.Element;
  label: string;
  onClick: () => void;
  color?: string;
};

export function DropdownItem({
  label,
  image,
  onClick,
  color,
}: DropdownItemProps) {
  return (
    <MenuItem key={label} onClick={onClick} tabIndex={0}>
      {image && <ListItemIcon sx={{ color, mr: 0.5 }}>{image}</ListItemIcon>}
      <Typography variant="body1" color={color} noWrap>
        {label}
      </Typography>
    </MenuItem>
  );
}

type SubmenuItemProps = {
  image?: JSX.Element;
  label: string;
  menu: React.ReactElement;
  sx?: SxProps<Theme>;
  disabled?: boolean;
};

export function SubmenuItem({
  label,
  image,
  menu,
  sx,
  disabled,
}: SubmenuItemProps) {
  const isMobile = onlyMobile(measureScreenWidth);
  const placement = isMobile ? "below" : "beside";

  return (
    <Dropdown placement={placement} menu={menu} sx={sx}>
      <MenuItem key={label} tabIndex={0} disabled={disabled}>
        {image && <ListItemIcon sx={{ mr: 0.5 }}>{image}</ListItemIcon>}
        <Typography variant="body1">{label}</Typography>
        <ChevronRight
          fontSize="small"
          sx={{ color: "action.active", ml: "auto" }}
        />
      </MenuItem>
    </Dropdown>
  );
}

type Props = {
  placement: "below" | "beside";
  children: React.ReactElement;
  menu: React.ReactElement;
  sx?: SxProps<Theme>;
};

export default function Dropdown({ placement, children, menu, sx }: Props) {
  return (
    <Tooltip
      placement={placement === "beside" ? "right-start" : "bottom-start"}
      supportClickAway={false}
      title={
        <Paper variant="elevation" elevation={8}>
          {menu}
        </Paper>
      }
      slotProps={{
        tooltip: {
          sx: {
            ...((sx || {}) as SxProps<Theme>),
            margin: 0,
            padding: 0,
            // override the inline style that defines a gap
            [placement === "beside" ? "marginLeft" : "marginTop"]:
              "8px !important",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
}
