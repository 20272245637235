import { Box, Link } from "@mui/material";
import ConnectionDetailDrawer from "@src/lib/Profiles/ConnectionDetailDrawer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TConnectionListItem } from "./ConnectionListSidebar/ConnectionList";

interface Props {
  connection: TConnectionListItem;
}

const MessagesListHeaderContent = ({ connection }: Props) => {
  const [detailDrawerIsOpen, setDetailDrawerIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: { lg: "row", xs: "column" },
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        minHeight: "64px",
        height: "auto",
      }}
    >
      <Box className="font-bold text-lg">
        {connection.inmate.fullName} {"&"} {connection.visitor.fullName}{" "}
      </Box>
      <Link onClick={() => setDetailDrawerIsOpen(true)} sx={{ lineHeight: 1 }}>
        {t("See connection")}
      </Link>
      <ConnectionDetailDrawer
        connectionId={connection.id}
        isOpen={detailDrawerIsOpen}
        onClose={() => setDetailDrawerIsOpen(false)}
      />
    </Box>
  );
};

export default MessagesListHeaderContent;
