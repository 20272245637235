import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Entitlement } from "@src/api/graphql";
import { isSubstring } from "@src/lib/Common";
import entitlementLabel from "@src/lib/entitlementLabel";
import errorReporter from "@src/lib/errorReporter";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { AntTooltip as Tooltip } from "@src/lib/Tooltip";
import { TypographyText } from "@src/lib/typography";
import { Card, Cascader, Collapse, Row } from "antd";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectableFieldStringFilter from "../../lib/SelectableFieldStringFilter";
import {
  GetSystemStaffDocument,
  GetSystemStaffQuery,
} from "./GetSystemStaff.generated";

const { Panel } = Collapse;

export type Staff = GetSystemStaffQuery["system"]["staff"][0];

export type QueryField = "fullName";

export type Filters = {
  facilities: string[];
  entitlements: Entitlement[];
  activeQueryField: QueryField;
  queryString: string;
};
const ENTITLEMENT_FILTER_OPTIONS: {
  value: string;
  label: string;
}[] = Object.values(Entitlement).map((e) => ({
  label: entitlementLabel(e),
  value: e,
}));

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

interface Props {
  renderActionButtons: (b: Staff) => ReactNode;
  headerExtra?: ReactNode[];
}

const StaffTable = ({ renderActionButtons, headerExtra }: Props) => {
  const { system } = useGuaranteedFacilityContext();
  const { loading, data } = useQuery(GetSystemStaffDocument, {
    variables: {
      systemId: system.id,
    },
    onError: errorReporter,
  });

  const { t } = useTranslation();

  const FILTER_TO_LABEL_MAP: Record<QueryField, string> = {
    fullName: t("Name"),
  };

  const deactivatedStaff = data
    ? data.system.staff.filter((s) => s.staffPositions.length === 0)
    : [];

  const filterSelectOptions: Option[] = [
    {
      label: t("All Facilities"),
      value: "facilities",
      children: data?.system.facilities.map((f) => ({
        label: f.publicId,
        value: f.id,
      })),
    },
    {
      label: t("All Entitlements"),
      value: "entitlements",
      children: ENTITLEMENT_FILTER_OPTIONS,
    },
  ];

  const [selections, setSelections] = useState<string[][]>([]);

  const [filters, setFilters] = useState<Filters>({
    facilities: [],
    entitlements: [],
    queryString: "",
    activeQueryField: "fullName",
  });

  useEffect(() => {
    // if a parent (All facilities or All eventTypes) is selected, it appears as ['facilities'] or ['eventTypes']
    // if some but not all children of a parent are selected, they each appear as ['facilities', facilityId]
    setFilters((filters) => ({
      ...filters,
      facilities: selections.some(
        (selection) => selection.length === 1 && selection[0] === "facilities"
      )
        ? [] // If the parent is selected, don't filter on facility ids
        : selections
            .filter((entry) => entry.length === 2 && entry[0] === "facilities")
            .map((entry) => entry[1]),
      entitlements: selections.some(
        (selection) => selection.length === 1 && selection[0] === "entitlements"
      )
        ? [] // If the parent is selected, don't filter on entitlements
        : selections
            .filter(
              (entry) => entry.length === 2 && entry[0] === "entitlements"
            )
            .map((entry) => entry[1] as Entitlement),
    }));
  }, [selections]);

  const filteredStaff = useMemo(
    () =>
      (data ? data.system.staff.filter((s) => s.staffPositions.length > 0) : [])
        .filter(
          (s) =>
            filters.entitlements.length === 0 ||
            filters.entitlements.some((e) =>
              s.staffEntitlements.some((se) => se.entitlement === e)
            )
        )
        .filter(
          (s) =>
            filters.facilities.length === 0 ||
            filters.facilities.some((fId) =>
              s.staffPositions.some((sp) => sp.facility.id === fId)
            )
        )
        .filter(
          (s) =>
            filters.queryString.length === 0 ||
            isSubstring(filters.queryString, s[filters.activeQueryField])
        ),
    [data, filters]
  );

  useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      facilities: [],
    }));
  }, [setFilters]);

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: filteredStaff,
    resetOnChange: [filters],
  });

  const { currentPageData: deactivatedStaffPageData } = usePagination({
    data: deactivatedStaff,
  });

  const renderTableBody = (
    tableData: typeof filteredStaff | typeof deactivatedStaff,
    currentData: typeof currentPageData | typeof deactivatedStaffPageData
  ) => (
    <TableBody>
      {tableData.length === 0 && <TableNoData colSpan={6} />}
      {tableData.length > 0 &&
        currentData.map((staff) => {
          const renderedEntitlements =
            staff.staffEntitlements.length > 3
              ? [
                  ...staff.staffEntitlements
                    .slice(0, 2)
                    .map((se) => entitlementLabel(se.entitlement)),
                  `+${staff.staffEntitlements.length - 2} more`,
                ]
              : staff.staffEntitlements.map((se) =>
                  entitlementLabel(se.entitlement)
                );
          return (
            <TableRow key={staff.id}>
              <TableCell>{staff.fullName}</TableCell>
              <TableCell>{staff.email}</TableCell>
              <TableCell>
                {staff.staffPositions.map((sp) => (
                  <Chip
                    style={{ margin: 3 }}
                    color="grey"
                    key={sp.facility.id}
                    label={sp.facility.publicId}
                  />
                ))}
              </TableCell>
              <TableCell>
                {renderedEntitlements.map((re, i) => (
                  <Tooltip
                    key={`${staff.id}_${re}`}
                    title={
                      // This tooltip appears on the "+X more" chip if there are >3 entitlements.
                      staff.staffEntitlements.length > 3 && i > 1
                        ? `${staff.staffEntitlements
                            .slice(2)
                            .map((se) => entitlementLabel(se.entitlement))
                            .join(", ")}`
                        : null
                    }
                  >
                    <Chip color="grey" style={{ margin: 3 }} label={re} />
                  </Tooltip>
                ))}
              </TableCell>
              <TableCell>{renderActionButtons(staff)}</TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );

  if (loading || !data) return <PageLoader />;

  return (
    <div>
      <Card title={t("All Staff")} extra={headerExtra}>
        <Row style={{ paddingBottom: 4 }}>
          <Box mr={1}>
            <SelectableFieldStringFilter
              selectStyle={{ width: 125 }}
              onSelectChange={(value) =>
                setFilters((filters) => ({
                  ...filters,
                  activeQueryField: value as QueryField,
                }))
              }
              onTextChange={(value) =>
                setFilters((filters) => ({ ...filters, queryString: value }))
              }
              selected={filters.activeQueryField}
              text={filters.queryString}
              filterToLabelMap={FILTER_TO_LABEL_MAP}
              key="selectableStringFilter"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TypographyText
              style={{ paddingTop: 4, paddingLeft: 20, paddingRight: 10 }}
            >
              {t("Filters:")}
            </TypographyText>
            <Cascader
              options={filterSelectOptions}
              onChange={(selections) =>
                setSelections(selections as unknown as string[][])
              }
              value={selections}
              multiple
              maxTagCount="responsive"
              placeholder={"by Facility or Entitlement"}
            />
          </Box>
        </Row>
        <Box sx={{ overflow: "auto" }}>
          <Table aria-label={t("System staff table")}>
            <TableHead>
              <TableRow>
                <TableCell>{t("Name")}</TableCell>
                <TableCell>{t("Email")}</TableCell>
                <TableCell>{t("Facilities")}</TableCell>
                <TableCell>{t("Entitlements")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            {renderTableBody(filteredStaff, currentPageData)}
          </Table>
          <TablePagination
            key="filteredStaff-pagination"
            totalCount={filteredStaff.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        </Box>
      </Card>
      {deactivatedStaff && deactivatedStaff.length > 0 && (
        <Collapse>
          <Panel header={t("Deactivated Staff")} key="deactivate-panel">
            <TableContainer>
              <Table aria-label={t("Deactivated system staff table")}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Name")}</TableCell>
                    <TableCell>{t("Email")}</TableCell>
                    <TableCell>{t("Facilities")}</TableCell>
                    <TableCell>{t("Entitlements")}</TableCell>
                    <TableCell>{t("Actions")}</TableCell>
                  </TableRow>
                </TableHead>
                {renderTableBody(deactivatedStaff, deactivatedStaffPageData)}
              </Table>
            </TableContainer>
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default StaffTable;
