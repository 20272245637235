import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import Avatar from "@src/lib/Avatar";
import { isSubstring } from "@src/lib/Common";
import Header from "@src/lib/Header";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import PageLoader from "@src/lib/PageLoader";
import { searchEachBeginningRegex } from "@src/lib/regex";
import SelectableFieldStringFilter from "@src/lib/SelectableFieldStringFilter";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { Layout } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetFacilityInmatesDocument } from "./GetFacilityInmates.generated";

const { Content } = Layout;

const Filters = ["fullName", "inmateIdentification"] as const;
type TFilter = typeof Filters[number];

function isFilter(value: unknown): value is TFilter {
  return typeof value === "string" && Filters.includes(value as TFilter);
}

const { history } = window;

export default function InmatesPage() {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();
  const { data, loading } = useQuery(GetFacilityInmatesDocument, {
    fetchPolicy: "cache-first",
    variables: { id: facility.id },
  });

  const FILTER_TO_LABEL_MAP: Record<TFilter, string> = {
    fullName: t("Name"),
    inmateIdentification: t("Inmate ID"),
  };

  // search filters are restored from page state for back navigation
  const [searchQuery, setSearchQuery] = useState<string>(
    isObjectWithKey(history.state, "searchQuery") &&
      typeof history.state.searchQuery === "string"
      ? history.state.searchQuery
      : ""
  );
  const [activeSearchFilter, setActiveSearchFilter] = useState<TFilter>(
    isObjectWithKey(history.state, "activeSearchFilter") &&
      isFilter(history.state.activeSearchFilter)
      ? history.state.activeSearchFilter
      : "fullName"
  );
  useEffect(() => {
    history.replaceState({ activeSearchFilter, searchQuery }, "");
  }, [activeSearchFilter, searchQuery]);

  const filteredInmates = useMemo(() => {
    const inmates = data?.facility.allInmates || [];
    const regex = searchEachBeginningRegex(searchQuery); // searchEachWordRegex(searchQuery); for searching everywhere

    switch (activeSearchFilter) {
      case "fullName":
        return inmates.filter((v) => regex.test(v.fullName));
      case "inmateIdentification":
        return inmates.filter((v) =>
          isSubstring(searchQuery, v.inmateIdentification)
        );
      default:
        return inmates;
    }
  }, [activeSearchFilter, searchQuery, data]);

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: filteredInmates,
    resetOnChange: [activeSearchFilter, searchQuery],
  });

  return (
    <Content>
      <Header
        title={t("Residents")}
        subtitle={t(
          "Manage the residents of your facility, access detailed information, and edit their info as needed."
        )}
        extra={[
          <SelectableFieldStringFilter
            loading={loading}
            onSelectChange={(value) => setActiveSearchFilter(value as TFilter)}
            onTextChange={(value) => setSearchQuery(value)}
            selected={activeSearchFilter}
            text={searchQuery}
            filterToLabelMap={FILTER_TO_LABEL_MAP}
            key="selectableStringFilter"
          />,
        ]}
      />
      <Box p={3}>
        {loading && <PageLoader />}
        {!loading && (
          <>
            <TableContainer>
              <Table aria-label={t("Residents table")}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{t("Unique ID")}</TableCell>
                    <TableCell>{t("Name")}</TableCell>
                    <TableCell>{t("Location")}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.map(
                    ({ id, fullName, group, inmateIdentification }) => (
                      <TableRow key={id}>
                        <TableCell>
                          <Avatar fallback={fullName} />
                        </TableCell>
                        <TableCell>{inmateIdentification}</TableCell>
                        <TableCell component="th" scope="row">
                          {fullName}
                        </TableCell>
                        <TableCell>{group.name}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => navigate(`/inmates/${id}`)}
                          >
                            {t("See details")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              totalCount={filteredInmates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
            />
          </>
        )}
      </Box>
    </Content>
  );
}
