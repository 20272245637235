import { ObservableQuery } from "@apollo/client";
import { PageInfo } from "@src/api/graphql";
import { useEffect } from "react";

type ForwardPageInfo = Pick<PageInfo, "endCursor" | "hasNextPage">;
type BackwardPageInfo = Pick<PageInfo, "startCursor" | "hasPreviousPage">;

/**
 * useFetchAll will exhaustively fetch a paginated collection by feeding
 * the `endCursor` back to `fetchMore` until there is no `hasNextPage`.
 *
 * 1. add `pageInfo { hasNextPage, endCursor }` to your connected field
 * 2. pass `after: $cursor` in your query to the connection
 * 3. configure InMemoryCache with a merge policy for the field:
 *    ```
 *    typePolicies: {
 *      ParentType: {
 *        fields: {
 *          children: relayStylePagination()
 *        }
 *      }
 *    }
 *    ```
 */
export default function useFetchAll<TVariables extends { cursor: string }>({
  pageInfo,
  fetchMore,
  onComplete,
}: {
  pageInfo: ForwardPageInfo | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchMore: ObservableQuery<any, TVariables>["fetchMore"];
  onComplete?: () => void;
}) {
  useEffect(() => {
    if (pageInfo?.hasNextPage === false) {
      if (onComplete) onComplete();
      return;
    }
    fetchMore({
      variables: { cursor: pageInfo?.endCursor },
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);
}

export function useReverseFetchAll<TVariables extends { cursor: string }>({
  pageInfo,
  fetchMore,
  onComplete,
}: {
  pageInfo: BackwardPageInfo | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchMore: ObservableQuery<any, TVariables>["fetchMore"];
  onComplete?: () => void;
}) {
  useEffect(() => {
    if (pageInfo?.hasPreviousPage === false) {
      if (onComplete) onComplete();
      return;
    }
    fetchMore({
      variables: { cursor: pageInfo?.startCursor },
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetchMore, pageInfo?.startCursor, pageInfo?.hasPreviousPage]);
}
