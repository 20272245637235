import Box from "@mui/material/Box";
import { getMessageSenderType, Message } from "@src/lib/Message";
import { useCallback } from "react";
import MessageCard from "./MessageCard";
import ReviewTooltip from "./ReviewTooltip";

interface Props {
  messages: Message[];
  focus?: Message;
}

const MessagesList = ({ messages, focus }: Props) => {
  const scrollToFocus = useCallback((node) => {
    node &&
      node.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, []);

  return (
    <Box className="bg-gray-100 flex flex-col overflow-y-auto gap-4">
      {messages.map((message) => {
        const senderType = getMessageSenderType(message);
        const offset = senderType === "inmate" ? "" : "ml-auto";
        const isFocused = focus?.id === message.id;
        return (
          <div
            key={message.id}
            className={`${isFocused ? "bg-yellow-50" : ""}`}
          >
            <div
              className={`p-4 ${offset}`}
              ref={isFocused ? scrollToFocus : undefined}
            >
              <ReviewTooltip message={message}>
                <MessageCard
                  message={message}
                  className={`max-w-lg ${offset}`}
                />
              </ReviewTooltip>
            </div>
          </div>
        );
      })}
    </Box>
  );
};

export default MessagesList;
