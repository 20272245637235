import { Dialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { FacilityApprovedPhone } from "@src/api/graphql";
import errorReporter from "@src/lib/errorReporter";
import { useTranslation } from "react-i18next";
import { UpdateFacilityApprovedPhoneDocument } from "./AddEditPhoneNumberModal/UpdateFacilityApprovedPhone.generated";

type PhoneNumber = Omit<FacilityApprovedPhone, "facility">;

type Props = {
  phoneNumber: PhoneNumber;
  onClose: () => void;
};

export default function DeletePhoneNumberModal({
  phoneNumber,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const [deletePhoneNumber] = useMutation(UpdateFacilityApprovedPhoneDocument, {
    onError: errorReporter,
    refetchQueries: ["GetFacilityPhoneNumbers"],
    variables: {
      input: {
        facilityApprovedPhoneId: phoneNumber.id,
        active: false,
      },
    },
    onCompleted: onClose,
  });

  return (
    <Dialog
      title={t("Delete {{name}}?", { name: phoneNumber.name })}
      fullWidth
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={1}>
          <Typography>
            {t("Residents will no longer be able to call {{number}}.", {
              number: phoneNumber.number,
            })}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={() => onClose()}>
          {t("Cancel")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => deletePhoneNumber()}
          color="error"
        >
          {t("Yes, delete phone number")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
