import { Typography } from "@mui/material";
import {
  ConnectionRestriction,
  Facility,
  Group,
  Inmate,
} from "@src/api/graphql";
import { getFullNameWithId } from "@src/lib/Common";
import { useTranslation } from "react-i18next";
import AvatarCard from "./AvatarCard";

export type Props = {
  inmate: Pick<Inmate, "id" | "fullName" | "inmateIdentification"> & {
    group: Pick<Group, "name">;
    facility: Pick<Facility, "publicId">;
  };
  restrictions?: Pick<ConnectionRestriction, "id" | "isActive">[];
};

export default function InmateContactCard({ inmate, restrictions }: Props) {
  const { t } = useTranslation();
  const hasActiveRestriction = restrictions?.some((r) => r.isActive);
  return (
    <AvatarCard to={`/inmates/${inmate.id}`} name={getFullNameWithId(inmate)}>
      <Typography component="p" variant="caption" color="text.secondary">
        {inmate.facility.publicId}
        {" / "} {inmate.group.name}
        {hasActiveRestriction && (
          <Typography component="span" variant="caption" color="error.main">
            {" "}
            ({t("Restricted")})
          </Typography>
        )}
      </Typography>
    </AvatarCard>
  );
}
