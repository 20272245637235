import { MeetingType } from "@src/api/graphql";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useLiveCalls from "@src/lib/useLiveCalls";
import React from "react";
import Dashboard from "./Dashboard";

const DashboardPage: React.FC = () => {
  const { facility } = useGuaranteedFacilityContext();
  const liveCalls = useLiveCalls({
    facility,
    meetingTypes: [MeetingType.VideoCall],
  });

  return <Dashboard calls={liveCalls} facility={facility} />;
};

export default DashboardPage;
