import { Box, Typography } from "@mui/material";

type EmptyProps = {
  description: string;
};

export default function EmptyPage({ description }: EmptyProps) {
  return (
    <Box
      sx={{
        flex: 1,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1" align="center" color="text.secondary">
        {description}
      </Typography>
    </Box>
  );
}
