import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { Link, Stack } from "@mui/material";
import {
  Call,
  Entitlement,
  FacilityApprovedPhone,
  Inmate,
  Interval,
  Kiosk,
  Meeting,
  MeetingStatus,
  PhoneCall,
  PrivacyLevel,
  RecordingStatus,
  Staff,
  StaffPosition,
  Visitor,
} from "@src/api/graphql";
import EndMeetingButton from "@src/lib/EndMeetingButton";
import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";
import InmateCell from "@src/lib/InmateCell";
import mayHaveRecording from "@src/lib/mayHaveRecording";
import mayHaveScreenshots from "@src/lib/mayHaveScreenshots";
import MeetingStatusTag from "@src/lib/MeetingStatusTag";
import PageLoader from "@src/lib/PageLoader";
import PhoneCallStatusTag from "@src/lib/PhoneCallStatusTag";
import RequestRecordingButton from "@src/lib/RequestRecordingButton";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useEntitlement from "@src/lib/useEntitlement";
import ViewRecordsButton from "@src/lib/ViewRecordsButton";
import VisitorList from "@src/lib/VisitorList";
import { Col, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DeclineMeetingButton from "../MeetingRequestsPage/DeclineMeetingButton";

type MeetingRecord = Pick<
  Meeting,
  "id" | "meetingType" | "status" | "unregisteredGuests" | "privacyLevel"
> & {
  interval: Pick<Interval, "startAt">;
  inmates: Pick<Inmate, "id" | "inmateIdentification" | "fullName">[];
  visitors: Pick<Visitor, "id" | "fullName">[];
  reviewedBy?:
    | (Pick<StaffPosition, "id"> & {
        staff: Pick<Staff, "id" | "fullName">;
      })
    | null;
  call?:
    | (Pick<Call, "id" | "status"> & {
        recording?: {
          status: RecordingStatus;
          url?: string | null;
          requesters?: Pick<Staff, "id" | "fullName">[] | null;
        } | null;
      })
    | null;
  kiosk?: Pick<Kiosk, "id" | "name"> | null;
};

type PhoneCallRecord = Pick<PhoneCall, "id" | "privacyLevel" | "status"> & {
  interval: Pick<Interval, "startAt">;
  inmates: Pick<Inmate, "id" | "inmateIdentification" | "fullName">[];
  facilityApprovedPhone: Pick<FacilityApprovedPhone, "id" | "name" | "number">;
};

function isPhoneCallRecord(
  record: PhoneCallRecord | MeetingRecord
): record is PhoneCallRecord {
  return "facilityApprovedPhone" in record;
}

interface Props {
  meetings: (PhoneCallRecord | MeetingRecord)[];
  loading?: boolean;
}

export default function ScheduledMeetingsTable({ meetings, loading }: Props) {
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: meetings,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <TableContainer>
        <Table aria-label={t("Scheduled meetings table")}>
          <TableHead>
            <TableRow>
              <TableCell>{t("Date")}</TableCell>
              <TableCell>{t("Start time")}</TableCell>
              <TableCell>{t("Residents")}</TableCell>
              <TableCell>{t("Visitors")}</TableCell>
              <TableCell>{t("Status")}</TableCell>
              <TableCell>{t("Location/Device")}</TableCell>
              <TableCell>{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meetings.length === 0 && <TableNoData colSpan={7} />}
            {meetings.length > 0 &&
              currentPageData.map((meeting) => {
                return (
                  <TableRow key={meeting.id}>
                    <TableCell>
                      {formatDate(new Date(meeting.interval.startAt), "date")}
                    </TableCell>
                    <TableCell>
                      {formatDate(new Date(meeting.interval.startAt), "time")}
                    </TableCell>
                    <TableCell>
                      <Space direction="vertical">
                        {meeting.inmates.map((inmate) => (
                          <InmateCell key={inmate.id} inmate={inmate} />
                        ))}
                      </Space>
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) ? (
                        <>
                          {meeting.facilityApprovedPhone.name} (
                          {meeting.facilityApprovedPhone.number})
                        </>
                      ) : (
                        <VisitorList
                          visitors={meeting.visitors}
                          unregisteredGuests={meeting.unregisteredGuests}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) ? (
                        <PhoneCallStatusTag status={meeting.status} />
                      ) : meeting.status === MeetingStatus.Live &&
                        meeting.privacyLevel === PrivacyLevel.Monitored ? (
                        <Link
                          onClick={() => navigate(`/visitations`)}
                        >{`${getMeetingStatusLabelByType(
                          meeting.status
                        ).toUpperCase()} - ${t("Monitor now")}`}</Link>
                      ) : (
                        <MeetingStatusTag
                          status={meeting.status}
                          meetingType={meeting.meetingType}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) || !meeting.kiosk
                        ? "-"
                        : meeting.kiosk.name}
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) ? null : (
                        <SearchMeetingsTableActionButtons meeting={meeting} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        totalCount={meetings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </>
  );
}

const SearchMeetingsTableActionButtons: React.FC<{
  meeting: MeetingRecord;
}> = ({ meeting }) => {
  const canReviewRecordings = useEntitlement(Entitlement.ReviewRecordings);
  const canReviewScreenshots = useEntitlement(Entitlement.ReviewScreenshots);
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const canManageMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      {mayHaveScreenshots(meeting) && canReviewScreenshots && (
        <Col>
          <ViewRecordsButton meeting={meeting} size="small" />
        </Col>
      )}
      {mayHaveRecording(meeting) && canReviewRecordings && (
        <Col>
          <RequestRecordingButton meeting={meeting} size="small" showView />
        </Col>
      )}
      {meeting.status === MeetingStatus.Scheduled && canManageMeetings && (
        <Col>
          <DeclineMeetingButton meeting={meeting} size="small" />
        </Col>
      )}
      {meeting.status === MeetingStatus.Live && canManageMeetings && (
        <Col>
          <EndMeetingButton meeting={meeting} size="small" />
        </Col>
      )}
      <Col>
        <Button
          size="small"
          variant="outlined"
          onClick={() => navigate(`/meetings/${meeting.id}`)}
          sx={{ whiteSpace: "nowrap" }}
        >
          {t("See details")}
        </Button>
      </Col>
    </Stack>
  );
};
