import { ConfirmDialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DeactivateLocalMessageKeywordDocument } from "./DeactivateLocalMessageKeyword.generated";
import { GetMessageKeywordsQuery } from "./GetMessageKeywords.generated";

type Keyword = GetMessageKeywordsQuery["facility"]["keywords"][0];

type Props = {
  keyword: Keyword;
  onClose: () => void;
};

export default function DeleteKeywordModal({ keyword, onClose }: Props) {
  const { t } = useTranslation();
  const [deleteKeyword] = useMutation(DeactivateLocalMessageKeywordDocument, {
    update: () => {
      onClose();
    },
    variables: {
      input: {
        messageKeywordId: keyword.id,
      },
    },
  });

  return (
    <ConfirmDialog
      title={t("Delete keyword")}
      description={t(
        'Are you sure you want to delete the keyword "{{keyword}}"?',
        { keyword: keyword.name }
      )}
      cancelText={t("Cancel")}
      confirmText={t("Yes, delete keyword")}
      variant="remove"
      onConfirm={() => {
        deleteKeyword();
      }}
      onCancel={() => onClose()}
    />
  );
}
