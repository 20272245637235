import { OrganizationType } from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";

const OrganizationTypeLabels: Record<OrganizationType, string> = {
  EDUCATIONAL: i18n.t("Education services"),
  HEALTHCARE: i18n.t("Healthcare services"),
  LEGAL: i18n.t("Legal services"),
  OTHER: i18n.t("Other services"),
};

export default function organizationTypeLabel(type: OrganizationType) {
  return OrganizationTypeLabels[type];
}
