import { ContextMenu } from "@ameelio/ui";
import { Box, Card, MenuItem, Stack, Typography } from "@mui/material";
import {
  Entitlement,
  FacilityApprovedPhone,
  PrivacyLevel,
} from "@src/api/graphql";
import useEntitlement from "@src/lib/useEntitlement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddEditPhoneNumberModal from "./AddEditPhoneNumberModal";
import DeletePhoneNumberModal from "./DeletePhoneNumberModal";

type PhoneNumber = Omit<FacilityApprovedPhone, "facility">;

type Props = {
  phoneNumber: PhoneNumber;
};
export default function PhoneNumberCard({ phoneNumber }: Props) {
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const canEdit = useEntitlement(Entitlement.ManageFacility);

  const { t } = useTranslation();

  const { name } = phoneNumber;

  const [isDeletingPhoneNumber, setIsDeletingPhoneNumber] = useState(false);

  return (
    <Card
      variant="outlined"
      sx={{
        paddingX: 2,
        paddingY: 1.5,
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="subtitle1">{name}</Typography>
        </Box>
        <ContextMenu id={`${phoneNumber.id}-actions`}>
          {canEdit
            ? [
                <MenuItem
                  key="edit"
                  onClick={() => {
                    setIsEditingPhoneNumber(true);
                  }}
                >
                  {t("Edit phone number")}
                </MenuItem>,
                <MenuItem
                  key="delete"
                  onClick={() => {
                    setIsDeletingPhoneNumber(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.error.main,
                  }}
                >
                  {t("Delete phone number")}
                </MenuItem>,
              ]
            : [
                <MenuItem
                  key="details"
                  onClick={() => {
                    setIsEditingPhoneNumber(true);
                  }}
                >
                  {t("Details")}
                </MenuItem>,
              ]}
        </ContextMenu>
      </Box>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="overline">{t("Phone number")}</Typography>
          <Typography>{phoneNumber.number}</Typography>
        </Box>
        <Box>
          <Typography variant="overline">{t("Description")}</Typography>
          <Typography>{phoneNumber.description}</Typography>
        </Box>
        <Box>
          <Typography variant="overline">{t("Privacy")}</Typography>
          <Typography>
            {phoneNumber.privacyLevel === PrivacyLevel.Monitored
              ? t("Monitored")
              : phoneNumber.privacyLevel === PrivacyLevel.UnmonitoredLogged
              ? t("Unmonitored")
              : t("Hidden")}
          </Typography>
        </Box>
      </Stack>
      {isDeletingPhoneNumber && (
        <DeletePhoneNumberModal
          phoneNumber={phoneNumber}
          onClose={() => setIsDeletingPhoneNumber(false)}
        />
      )}
      {isEditingPhoneNumber && (
        <AddEditPhoneNumberModal
          phoneNumber={phoneNumber}
          onClose={() => setIsEditingPhoneNumber(false)}
        />
      )}
    </Card>
  );
}
