import { SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import Countdown, { CountdownRendererFn } from "react-countdown";

const keyMinutes = [10, 5, 2];

export function mapCountdownTimeToStyle(
  hours: number,
  minutes: number,
  seconds: number
): SxProps<Theme> {
  // highlight key minutes in the last hour
  if (
    hours === 0 &&
    (keyMinutes.some((val) => val === minutes && seconds === 0) ||
      keyMinutes.some((val) => val === minutes + 1 && seconds >= 56))
  )
    return { color: "black", backgroundColor: "white" };
  // highlight the last minute
  if (hours === 0 && (minutes === 0 || (minutes === 1 && seconds === 0)))
    return { color: "white", backgroundColor: "primary.main" };
  return { color: "white" };
}

interface Props {
  endTime: number;
  style?: React.CSSProperties;
  className?: string;
}
// Renderer callback with condition
const renderer: CountdownRendererFn = ({
  formatted,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  return completed ? (
    <div />
  ) : (
    <Typography
      variant="body1"
      sx={mapCountdownTimeToStyle(hours, minutes, seconds)}
    >
      {formatted.hours ? `${formatted.hours}:` : ""}
      {formatted.minutes}:{formatted.seconds}
    </Typography>
  );
};

const Timer = ({ endTime, style, className }: Props) => {
  return (
    <div style={style} className={className}>
      <Countdown date={endTime} renderer={renderer} zeroPadTime={2} />{" "}
    </div>
  );
};

export default Timer;
