import { ContextMenu } from "@ameelio/ui";
import {
  Box,
  Button,
  Card,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Entitlement, Kiosk as GqlKiosk, MeetingType } from "@src/api/graphql";
import { SectionTitle } from "@src/lib/typography";
import useEntitlement from "@src/lib/useEntitlement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConditionalTooltip from "../../lib/ConditionalTooltip";
import AddEditKioskModal from "./AddEditKioskModal";
import DisableEnableKioskModal from "./DisableEnableKioskModal";

type Kiosk = Pick<
  GqlKiosk,
  "id" | "name" | "enabled" | "capacity" | "meetingType"
>;
type Props = {
  isInPerson: boolean;
  kiosks: Kiosk[];
};

type KioskRowProps = {
  kiosk: Kiosk;
};

function KioskRow({ kiosk }: KioskRowProps) {
  const [editingKiosk, setEditingKiosk] = useState(false);
  const [disablingKiosk, setDisablingKiosk] = useState(false);
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const isInPerson = [MeetingType.InPersonVisit].includes(kiosk.meetingType);
  const { t } = useTranslation();

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body2">{kiosk.name}</Typography>
        </TableCell>
        <TableCell style={{ width: "0px" }}>
          <Typography variant="body2">
            {kiosk.enabled ? t("Enabled") : t("Disabled")}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: 3 }}>
          <ContextMenu id={`${kiosk.id}-actions`}>
            {canEdit
              ? [
                  <MenuItem
                    key="edit"
                    onClick={() => {
                      setEditingKiosk(true);
                    }}
                  >
                    {isInPerson ? t("Edit table") : t("Edit computer")}
                  </MenuItem>,
                  <MenuItem
                    key="manage"
                    onClick={() => {
                      setDisablingKiosk(true);
                    }}
                    sx={
                      kiosk.enabled
                        ? { color: (theme) => theme.palette.error.main }
                        : {}
                    }
                  >
                    {kiosk.enabled ? "Disable" : "Enable"}{" "}
                    {isInPerson ? "table" : "computer"}
                  </MenuItem>,
                ]
              : [
                  <MenuItem
                    key="details"
                    onClick={() => {
                      setEditingKiosk(true);
                    }}
                  >
                    {t("Details")}
                  </MenuItem>,
                ]}
          </ContextMenu>
        </TableCell>
      </TableRow>
      {disablingKiosk && (
        <DisableEnableKioskModal
          kiosk={kiosk}
          onClose={() => setDisablingKiosk(false)}
        />
      )}
      {editingKiosk && (
        <AddEditKioskModal
          kiosk={kiosk}
          onClose={() => setEditingKiosk(false)}
          isInPerson={isInPerson}
        />
      )}
    </>
  );
}

export default function KiosksTable({ kiosks, isInPerson }: Props) {
  const [isAddingKiosk, setIsAddingKiosk] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  return (
    <>
      <Card
        variant="outlined"
        sx={{ paddingX: 2.5, paddingY: 2, borderRadius: "8px" }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <SectionTitle>
              {isInPerson ? t("Tables") : t("Computers")}
            </SectionTitle>
          </Box>
          <ConditionalTooltip
            active={!canEdit}
            title={t("You do not have permission to add kiosks.")}
          >
            <Button
              onClick={() => {
                setIsAddingKiosk(true);
              }}
              disabled={!canEdit}
              variant="outlined"
            >
              {isInPerson ? t("Add table") : t("Add computer")}
            </Button>
          </ConditionalTooltip>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {isInPerson ? t("Table name") : t("Computer name")}
                </TableCell>
                <TableCell style={{ width: "0px" }}>{t("Status")}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {kiosks
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map((k) => (
                  <KioskRow kiosk={k} key={k.id} />
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ border: "none" }}
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={kiosks.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(
                    _: React.MouseEvent<HTMLButtonElement> | null,
                    newPage: number
                  ) => setPage(newPage)}
                  onRowsPerPageChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => {
                    setRowsPerPage(Number(event.target.value));
                    setPage(0);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
      {isAddingKiosk && (
        <AddEditKioskModal
          onClose={() => setIsAddingKiosk(false)}
          isInPerson={isInPerson}
        />
      )}
    </>
  );
}
