import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";
import statusDetailsLabel from "@src/lib/statusDetailsLabel";
import { formatDate, yearMonthDate } from "@src/lib/Time";
import { t } from "i18next";
import { compact } from "lodash";
import { GetFacilityPhoneCallsQuery } from "./GetFacilityPhoneCalls.generated";
import { GetMeetingsHistoryQuery } from "./GetMeetingsHistory.generated";

export const writeMeeting = (
  meeting: GetMeetingsHistoryQuery["facility"]["meetings"]["edges"][0]["node"]
) => {
  const row: Record<string, unknown> = {};

  row[t("Date")] = formatDate(new Date(meeting.interval.startAt), "date");
  row[t("Start")] = formatDate(new Date(meeting.interval.startAt), "time");
  row[t("End")] = formatDate(new Date(meeting.interval.endAt), "time");
  row[t("Residents")] = meeting.inmates
    .map((i) => `${i.fullName} (${i.inmateIdentification})`)
    .join(", ");
  row[t("Visitors")] = [
    ...meeting.visitors.map((v) => v.fullName),
    ...meeting.unregisteredGuests,
  ].join(", ");
  row[t("Status")] = getMeetingStatusLabelByType(meeting.status);
  row[t("Status Details")] = meeting.statusDetails
    ? statusDetailsLabel(meeting.statusDetails)
    : "–";

  return row;
};

export const writePhoneCall = (
  call: GetFacilityPhoneCallsQuery["facility"]["phoneCalls"]["edges"][0]["node"]
) => {
  const row: Record<string, unknown> = {};

  row[t("Date")] = formatDate(new Date(call.start), "date");
  row[t("Start")] = `${formatDate(new Date(call.start), "time")}`;
  row[t("End")] = `${call.end ? formatDate(new Date(call.end), "time") : "-"}`;
  row[
    t("Resident")
  ] = `${call.inmate.fullName} (${call.inmate.inmateIdentification})`;
  row[
    t("Phone")
  ] = `${call.facilityApprovedPhone.name} (${call.facilityApprovedPhone.number})`;
  row[t("Status")] = call.status;

  return row;
};

export const exportDateRange = (
  after: number | undefined,
  before: number | undefined
) => {
  return compact([after, before]).map(yearMonthDate).join("_");
};
