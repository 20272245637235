import Result from "@src/lib/Result";
import Joi from "joi";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  errors: Joi.ValidationError[];
};

const ErrorResult: React.FC<Props> = ({ errors }) => {
  const { t } = useTranslation();
  return (
    <Result
      status="error"
      title={t("Validation Failed")}
      subTitle={t("Please address the following feedback before resubmitting")}
    >
      <ol className="max-h-52 overflow-y-scroll">
        {errors.slice(0, 26).map((error, idx) =>
          idx < 25 ? (
            <li key={idx} className="mb-4">
              {idx + 1}. {error.details.map((d) => d.message).join(", ")}
              <pre>{JSON.stringify(error._original)}</pre>
            </li>
          ) : (
            <li key={idx}>
              {t("+ {{number}} more", { number: errors.length - 25 })}
            </li>
          )
        )}
      </ol>
    </Result>
  );
};

export default ErrorResult;
