import { Chip, ChipCustomColors } from "@ameelio/ui";
import { SystemUserStatus } from "@src/api/graphql";
import i18n from "i18next";

interface Props {
  status: SystemUserStatus;
}

const StatusColors: Record<SystemUserStatus, ChipCustomColors> = {
  APPROVED: "green",
  PENDING: "orange",
  REJECTED: "red",
  INACTIVE: "grey",
};

const StatusMap: Record<SystemUserStatus, string> = {
  APPROVED: i18n.t("Approved"),
  PENDING: i18n.t("Needs Review"),
  REJECTED: i18n.t("Rejected"),
  INACTIVE: i18n.t("Inactive"),
};

const SystemRelationshipStatusTag = ({ status }: Props) => {
  return <Chip color={StatusColors[status]} label={StatusMap[status]} />;
};

export default SystemRelationshipStatusTag;
