import {
  PhoneOutlined,
  TeamOutlined,
  VideoCameraFilled,
} from "@ant-design/icons";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import { MeetingType } from "@src/api/graphql";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  type: MeetingType;
}

export default function VisitTypeIcon({ type }: Props): ReactElement {
  const { t } = useTranslation();
  switch (type) {
    case MeetingType.VideoCall:
      return (
        <div className="flex space-x-4 ">
          <div className="flex items-center">
            <VideoCameraFilled />
          </div>
          <span>{t("Video Call")}</span>
        </div>
      );
    case MeetingType.InPersonVisit:
      return (
        <div className="flex space-x-4 ">
          <div className="flex items-center">
            <TeamOutlined />
          </div>
          <span>{t("In-Person")}</span>
        </div>
      );
    case MeetingType.VoiceCall:
      return (
        <div className="flex space-x-4 ">
          <div className="flex items-center">
            <PhoneOutlined />
          </div>
          <span>{t("Voice Call")}</span>
        </div>
      );
    case MeetingType.Webinar:
      return (
        <div className="flex space-x-4 ">
          <div className="flex items-center">
            <PresentToAllIcon />
          </div>
          <span>{t("Webinar")}</span>
        </div>
      );
    default:
      return <div />;
  }
}
