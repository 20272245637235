import { useQuery } from "@apollo/client";
import { GroupsOutlined, Security } from "@mui/icons-material";
import { Card, Stack, Typography } from "@mui/material";
import { FacilityFeature, MeetingType, PrivacyLevel } from "@src/api/graphql";
import { GetFacilityDocument } from "@src/graphql/GetFacility.generated";
import { GetFacilitySchedulesDocument } from "@src/graphql/GetFacilitySchedules.generated";
import { CardSkeleton, TitleSkeleton } from "@src/lib/closet";
import errorReporter from "@src/lib/errorReporter";
import ScheduleTable from "@src/lib/ScheduleTable";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { ScreenSubtitle, ScreenTitle } from "@src/lib/typography";
import UnavailableSplashScreen from "@src/lib/UnavailableSplashScreen";
import { useTranslation } from "react-i18next";

export default function WebinarsSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();

  const { data } = useQuery(GetFacilitySchedulesDocument, {
    onError: errorReporter,
    variables: { facilityId: facility.id },
  });

  const { data: facilityData } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id },
  });

  if (!data || !facilityData)
    return (
      <>
        <TitleSkeleton />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <CardSkeleton />
          <CardSkeleton />
        </Stack>
      </>
    );

  const { facility: loadedFacility } = facilityData;

  if (!loadedFacility.features.includes(FacilityFeature.Providers)) {
    return (
      <UnavailableSplashScreen
        header={t("Webinars")}
        subHeader={t("Contact us about adding webinars to the facility.")}
        features={[
          {
            icon: <GroupsOutlined />,
            text: t(
              "Offer video calls that support multiple participants, such as for classes or court proceedings"
            ),
          },
          {
            icon: <Security />,
            text: t(
              "Customize security preferences based on your facility’s needs"
            ),
          },
        ]}
        // learnMoreText and learnMoreLink props removed per comments here:
        // https://app.shortcut.com/ameelio/story/6009/new-page-on-the-ameelio-website-advertising-webinars
      />
    );
  }

  return (
    <>
      <ScreenTitle>{t("Webinars")}</ScreenTitle>
      <ScreenSubtitle>
        {t(
          "Eligible visitors may request educational webinars, with co-hosts and multiple students."
        )}
      </ScreenSubtitle>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <Typography variant="h3" component="label">
              {t("Scheduling")}
            </Typography>
            <Typography variant="body1">
              {t("Webinars use the standard video call scheduling window.")}
            </Typography>
          </Stack>
        </Card>

        <ScheduleTable
          title={t("Webinar schedules")}
          schedules={data.facility.schedules.filter(
            (s) =>
              [MeetingType.Webinar].includes(s.meetingType) &&
              s.privacyLevels.includes(PrivacyLevel.Monitored)
          )}
          privacyLevel={PrivacyLevel.Monitored}
          meetingType={MeetingType.Webinar}
        />
      </Stack>
    </>
  );
}
