import { InfoCircleOutlined } from "@ant-design/icons";

import Result from "@src/lib/Result";
import { useTranslation } from "react-i18next";

export default function NotEnabled() {
  const { t } = useTranslation();
  return (
    <Result
      icon={<InfoCircleOutlined />}
      title={t("This feature is not enabled")}
      subTitle={t("Contact Ameelio to learn more.")}
    />
  );
}
