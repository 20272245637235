import { Button } from "@ameelio/ui";
import { ButtonProps } from "@mui/material";
import { MeetingStatus } from "@src/api/graphql";
import useBoolean from "@src/lib/useBoolean";
import { useTranslation } from "react-i18next";
import DeclineMeetingModal, { MeetingToReject } from "./DeclineMeetingModal";

type Props = Omit<ButtonProps, "variant" | "color" | "onClick"> & {
  meeting: MeetingToReject;
};

export default function DeclineMeetingButton({ meeting, ...rest }: Props) {
  const { t } = useTranslation();
  const rejecting = useBoolean();

  return (
    <>
      {rejecting.current && (
        <DeclineMeetingModal meeting={meeting} onClose={rejecting.disable} />
      )}
      <Button
        variant="outlined"
        color="error"
        onClick={rejecting.enable}
        {...rest}
      >
        {meeting.status === MeetingStatus.PendingApproval
          ? t("Decline")
          : t("Cancel")}
      </Button>
    </>
  );
}
