import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConnectionFragmentFragmentDoc } from './fragments/ConnectionFragment.generated';
import { VisitorFragmentFragmentDoc } from './fragments/VisitorFragment.generated';
export type GetInmateConnectionsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetInmateConnectionsQuery = { __typename: 'Query', inmate: { __typename: 'Inmate', id: string, fullName: string, connections: Array<{ __typename: 'Connection', id: string, status: Types.ConnectionStatus, relationship: string, createdAt: number, statusDetails: string | null, visitor: { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string, malanId: string, phone: string | null, dateOfBirth: string | null, email: string } }> } };


export const GetInmateConnectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInmateConnections"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inmate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"connections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}}]}}]}}]}},...ConnectionFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetInmateConnectionsQuery, GetInmateConnectionsQueryVariables>;