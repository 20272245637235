// Intended to match malan validation
export default function isEmail(value: string): boolean {
  const email = String(value).toLowerCase();
  if (email.length < 6 || email.length > 150) return false;
  if (/@.*@/.test(email)) return false;
  if (/^\|/.test(email)) return false;
  return /^[!#$%&'*+-/=?^_`{|}~A-Za-z0-9]{1,64}@[.-A-Za-z0-9]{1,63}\.[A-Za-z]{2,25}$/.test(
    email
  );
}
