// escape user input to be used as string literals in a regexp
// from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

// This function search for multiple words, anywhere in a text
// e.g. searching for "oh oe" in John Doe returns true.
export const searchEachWordRegex = (query: string) =>
  new RegExp(escapeRegExp(query).replace(/\s+/g, ".*"), "i"); // Replace spaces with '.*' for multiple words

// This function search for multiple words, only at the beginning of a word
// e.g. searching for "oh oe" in John Doe returns false, but searching for "jo do" returns true
export const searchEachBeginningRegex = (query: string) =>
  new RegExp(
    escapeRegExp(query)
      .split(" ")
      .map((word) => `\\b${word}\\w*`)
      .join(" "),
    "i"
  ); // Match at the beginning of each word
