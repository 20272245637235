import { PlayCircleFilled } from "@ant-design/icons";
import Avatar from "@src/lib/Avatar";
import { generateBgColor } from "@src/lib/Common";
import React from "react";
import "./RecordingPlaceholder.css";

type Props = {
  names: string[];
  style?: React.CSSProperties;
  onClick?: () => void;
};

export default function RecordingPlaceholder({ names, style, onClick }: Props) {
  return (
    <div className="container" style={style} onClick={onClick}>
      <div className="participants">
        {names.map((name, idx) => (
          <div key={`${name}_${idx}`} className="participant">
            <div className="participant-inner">
              <div className="participant-bg">
                <Avatar
                  fallback={name}
                  style={{
                    width: "56.25%",
                    height: "100%",
                    position: "absolute",
                    backgroundColor: generateBgColor(name),
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: "75px",
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="play-container">
        <PlayCircleFilled className="play-button" />
      </div>
    </div>
  );
}
