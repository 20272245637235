import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReportProblemWithScreenshotsMutationVariables = Types.Exact<{
  input: Types.ReportProblemWithArtifactInput;
}>;


export type ReportProblemWithScreenshotsMutation = { __typename: 'Mutation', reportProblemWithScreenshots: { __typename: 'ReportProblemWithArtifactResponse', success: boolean } };


export const ReportProblemWithScreenshotsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ReportProblemWithScreenshots"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReportProblemWithArtifactInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reportProblemWithScreenshots"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<ReportProblemWithScreenshotsMutation, ReportProblemWithScreenshotsMutationVariables>;