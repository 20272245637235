import * as Types from '../../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ScheduleFragmentFragmentDoc } from '../../../graphql/fragments/ScheduleFragment.generated';
export type CreateScheduleMutationVariables = Types.Exact<{
  input: Types.CreateScheduleInput;
}>;


export type CreateScheduleMutation = { __typename: 'Mutation', createSchedule: { __typename: 'CreateScheduleResponse', schedule: { __typename: 'Schedule', id: string, name: string, meetingType: Types.MeetingType, privacyLevels: Array<Types.PrivacyLevel>, startsOn: string | null, endsOn: string | null, meetingSlots: Array<{ __typename: 'ScheduleMeetingSlot', id: string, day: number, hour: number, minute: number, duration: number }>, groups: Array<{ __typename: 'Group', id: string, name: string }>, kiosks: Array<{ __typename: 'Kiosk', id: string, name: string }> } } };


export const CreateScheduleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateSchedule"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateScheduleInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createSchedule"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"schedule"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ScheduleFragment"}},{"kind":"Field","name":{"kind":"Name","value":"meetingSlots"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"day"}},{"kind":"Field","name":{"kind":"Name","value":"hour"}},{"kind":"Field","name":{"kind":"Name","value":"minute"}},{"kind":"Field","name":{"kind":"Name","value":"duration"}}]}},{"kind":"Field","name":{"kind":"Name","value":"groups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"kiosks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}},...ScheduleFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CreateScheduleMutation, CreateScheduleMutationVariables>;