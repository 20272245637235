import { Layout } from "antd";
import { Outlet } from "react-router-dom";

export default function SystemLayout() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Outlet />
    </Layout>
  );
}
