import { parse } from "date-fns";

export function parseTimeslot(start: string, end: string) {
  const startTime = parseTime(start);
  const endTime = parseTime(end);

  if (!startTime || !endTime) return null;

  const totalMinutesStart = startTime[0] * 60 + startTime[1];
  const totalMinutesEnd = endTime[0] * 60 + endTime[1];

  return {
    hour: startTime[0],
    minute: startTime[1],
    duration: (totalMinutesEnd - totalMinutesStart) * 60 * 1000,
  };
}

export function parseTime(s: string): [number, number] | null {
  const d = parse(s, "HH:mm", new Date());
  if (isNaN(d.getTime())) return null;
  return [d.getHours(), d.getMinutes()];
}
