import { ConfirmDialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { ConnectionRestriction, Staff } from "@src/api/graphql";
import errorReporter from "@src/lib/errorReporter";
import facilityServiceLabel from "@src/lib/facilityServiceLabel";
import { useTranslation } from "react-i18next";
import { UpdateConnectionRestrictionDocument } from "./UpdateConnectionRestriction.generated";

type Props = {
  restriction: Pick<ConnectionRestriction, "id" | "service"> & {
    createdBy: {
      staff: Pick<Staff, "fullName">;
    };
  };
  onClose: () => void;
};

export default function RemoveRestrictionDialog({
  restriction,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const [updateConnectionRestrictionMutation] = useMutation(
    UpdateConnectionRestrictionDocument,
    {
      onError: errorReporter,
    }
  );

  return (
    <ConfirmDialog
      variant="remove"
      description={t(
        "Are you sure you wish to remove the {{serviceName}} restriction? Please ask {{staffName}} if you have any questions.",
        {
          serviceName: facilityServiceLabel(restriction.service),
          staffName: restriction.createdBy.staff.fullName,
        }
      )}
      onCancel={onClose}
      onConfirm={() => {
        updateConnectionRestrictionMutation({
          variables: {
            input: {
              connectionRestrictionId: restriction.id,
              expiresAt: Date.now(),
            },
          },
        });
      }}
    />
  );
}
