import { ApolloCache, Reference } from "@apollo/client";
import { ConnectionStatus } from "@src/api/graphql";
import { UpdateConnectionStatusMutation } from "@src/graphql/UpdateConnectionStatus.generated";

export function removeCachedPendingConnectionRequest(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  { data }: { data?: UpdateConnectionStatusMutation | null | undefined }
) {
  const updatedConnection = data?.updateConnectionStatus.connection;
  if (!updatedConnection) return;
  cache.modify({
    id: cache.identify({
      __typename: "Facility",
      id: updatedConnection.inmate.facility.id,
    }),
    fields: {
      pendingConnectionRequests(
        existingConnectionRequests: Reference[],
        { readField }
      ) {
        return existingConnectionRequests.filter((connectionRef) => {
          return readField("id", connectionRef) !== updatedConnection.id;
        });
      },
    },
  });
}

const CONNECTION_STATUS_LABELS: Record<ConnectionStatus, string> = {
  [ConnectionStatus.Active]: "approved",
  [ConnectionStatus.Inactive]: "deactivated",
  [ConnectionStatus.Pending]: "pending review",
  [ConnectionStatus.Rejected]: "rejected",
};

export function labelConnectionStatus(status: ConnectionStatus) {
  return CONNECTION_STATUS_LABELS[status];
}
