import { AspectRatio, Button, Dialog, DialogActions } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, Typography } from "@mui/material";
import {
  Entitlement,
  SystemUser,
  SystemUserStatus,
  Visitor,
  VisitorIdentity,
} from "@src/api/graphql";
import { GetVisitorPhotosDocument } from "@src/graphql/GetVisitorPhotos.generated";
import AgeGroupTag from "@src/lib/AgeGroupTag";
import { ButtonSkeleton, CardSkeleton } from "@src/lib/closet";
import { isMinorWithoutID } from "@src/lib/Common";
import errorReporter from "@src/lib/errorReporter";
import IdentityImage from "@src/lib/IdentityImage";
import { ImageNumber } from "@src/lib/imageLabelForDocumentType";
import SystemRelationshipStatusTag from "@src/lib/SystemRelationshipStatusTag";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import { useTranslation } from "react-i18next";
import { ApproveSystemVisitorDocument } from "../../graphql/ApproveSystemVisitor.generated";

interface Props {
  visitor: Pick<Visitor, "id" | "fullName" | "dateOfBirth"> & {
    systemRelationship: Pick<
      SystemUser,
      "id" | "status" | "statusDetails"
    > | null;
    identity: Pick<VisitorIdentity, "id"> | null;
  };
  onCancel: () => void;
  onReject: () => void;
}

export default function VisitorPhotosModal({
  visitor,
  onCancel,
  onReject,
}: Props) {
  const staff = useCurrentStaff();
  const entitlements = staff.staffEntitlements
    .filter((se) => se.isActive)
    .map((se) => se.entitlement);

  const { data, loading } = useQuery(GetVisitorPhotosDocument, {
    variables: { visitorId: visitor.id },
    onError: errorReporter,
    fetchPolicy: "no-cache", // Done so that the query result is not stored in the cache
  });
  const identity = data?.visitor.identity;
  const { t } = useTranslation();

  const [approveSystemVisitor, { loading: approveSubmitting }] = useMutation(
    ApproveSystemVisitorDocument,
    {
      onError: errorReporter,
    }
  );

  const imageNumbers = [
    ImageNumber.ImageOne,
    ImageNumber.ImageTwo,
    ImageNumber.ImageThree,
  ];

  return (
    <Dialog
      title={t("ID for {{visitorName}}", {
        visitorName: visitor.fullName,
      })}
      onClose={onCancel}
    >
      {visitor.systemRelationship && (
        <Box px={3} mb={2}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
            mb={1}
          >
            <Typography variant="body2" color="text.primary">
              {t("Current status:")}
            </Typography>
            {isMinorWithoutID(visitor) ? (
              <AgeGroupTag dateOfBirth={visitor.dateOfBirth} />
            ) : (
              <SystemRelationshipStatusTag
                status={visitor.systemRelationship.status}
              />
            )}
          </Box>
          {visitor.systemRelationship.status === SystemUserStatus.Rejected && (
            <Typography variant="body2">
              {`${
                visitor.systemRelationship?.statusDetails ||
                t("Reason for rejection not specified")
              }`}
            </Typography>
          )}
        </Box>
      )}
      <hr />
      <Grid
        container
        sx={(th) => ({
          textAlign: "center",
          [th.breakpoints.up("md")]: { minWidth: 600 },
        })}
        p={2}
      >
        {loading ? (
          imageNumbers.map((num) => (
            <Grid key={num} item xs={12} md={4} p={1}>
              <AspectRatio ratio={1}>
                <CardSkeleton height="100%" />
              </AspectRatio>
            </Grid>
          ))
        ) : !identity ? (
          <Grid item xs={12} p={1}>
            <Typography variant="body1">{t("No document provided")}</Typography>
          </Grid>
        ) : (
          imageNumbers.map((num) => (
            <Grid key={num} item xs={12} md={4} p={1}>
              <IdentityImage identity={identity} imageNumber={num} />
            </Grid>
          ))
        )}
      </Grid>
      <DialogActions>
        {data &&
        visitor.systemRelationship?.status === SystemUserStatus.Approved ? (
          <>
            <Button
              variant="outlined"
              color="error"
              disabled={
                !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
              }
              disabledReason={
                !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                  ? t("You do not have permission to perform this function.")
                  : ""
              }
              onClick={onReject}
            >
              {t("Reject ID")}
            </Button>
            <Button variant="contained" onClick={onCancel}>
              {t("Close")}
            </Button>
          </>
        ) : data ? (
          <>
            <Button variant="outlined" onClick={onCancel}>
              {t("Close")}
            </Button>
            <Button
              variant="contained"
              onClick={async () =>
                await approveSystemVisitor({
                  variables: {
                    input: {
                      systemVisitorId: visitor.systemRelationship?.id || "",
                    },
                  },
                })
              }
              disabled={
                approveSubmitting ||
                !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
              }
              disabledReason={
                !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                  ? t("You do not have permission to perform this function.")
                  : ""
              }
            >
              {t("Approve ID")}
            </Button>
          </>
        ) : (
          <ButtonSkeleton />
        )}
      </DialogActions>
    </Dialog>
  );
}
