import { MessageStatus } from "@src/api/graphql";
import { Message } from "@src/lib/Message";
import { formatDate } from "@src/lib/Time";
import { AntTooltip as Tooltip } from "@src/lib/Tooltip";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  message: Message;
  children?: React.ReactNode | React.ReactNode[];
}

const ReviewTooltip = ({ message, children }: Props) => {
  const MESSAGE_AUTO_DELAY_MINUTES = 30;
  const { t } = useTranslation();
  const reviewer = message.reviewedBy
    ? `${message.reviewedBy.staff.fullName}`
    : t("Unknown"); // This only occurs from a data error.

  const keywordShortList =
    message.detectedKeywords &&
    message.detectedKeywords.map((k, index) => (
      <div key={k.keyword.name}>
        Keyword(s): {k.keyword.name}{" "}
        {index < message.detectedKeywords.length - 1 && ", "}
      </div>
    ));

  const tooltipContent = (() => {
    if (message.status === MessageStatus.Approved) {
      if (message.reviewedBy) {
        return (
          <div>
            {keywordShortList}
            <div>
              {t("Approved by")}: {reviewer}
            </div>
            <div>
              {t("Time")}:{" "}
              {
                message.reviewedAt &&
                  formatDate(
                    message.reviewedAt,
                    "datetime"
                  ) /* reviewedAt will only be blank here due to data error */
              }
            </div>
          </div>
        );
      } else {
        return null; // Approved but reviewer information is missing due to data error. This shouldn't happen but let's treat like a skipped-review message
      }
    } else if (message.status === MessageStatus.SkippedReview) {
      if (message.availableAt && new Date(message.availableAt) > new Date()) {
        return (
          <div>
            {t(
              "No Keywords detected. Auto-delay of {{minutes}} minutes applied.",
              { minutes: MESSAGE_AUTO_DELAY_MINUTES }
            )}
          </div>
        );
      } else {
        return null;
      }
    } else if (message.status === MessageStatus.Rejected) {
      return (
        <div>
          {keywordShortList}
          <div>
            {t("Rejected by")}: {reviewer}
          </div>
          <div>
            {t("Time")}:
            {message.reviewedAt && formatDate(message.reviewedAt, "datetime")}
          </div>
          <div>
            {t("Note to sender")}: {message.statusDetails}
          </div>
          <div>
            {t("Internal comments")}: {message.internalComments}
          </div>
        </div>
      );
    } else if (
      message.status === MessageStatus.PendingReview &&
      message.detectedKeywords &&
      message.detectedKeywords.length > 0
    ) {
      return message.detectedKeywords.map((k, index) => (
        <div key={index}>
          <div>
            {t("Keyword")}: {k.keyword.name}
          </div>
          <div>
            {t("Tag")}: {k.keyword.category}
          </div>
          <div>
            {t("Alternative Spellings")}: {k.keyword.spellings.join(", ")}
          </div>
          <div>
            {t("Context")}: {k.keyword.description}
          </div>
          {index < message.detectedKeywords.length - 1 && <div>—</div>}
        </div>
      ));
    } else return null; // if skipped review; or pending review but there are no keywords (data error)
  })();

  const overlayInnerStyle =
    message.status === MessageStatus.Rejected ? { width: "400px" } : {}; // Rejected message needs more space for text.

  if (tooltipContent) {
    return (
      <Tooltip
        title={tooltipContent}
        trigger="click"
        overlayInnerStyle={overlayInnerStyle}
        className="cursor-pointer"
      >
        {
          " " /* The tooltip doesn't show up unless I... add this invisible space. what the heck?*/
        }
        {children}
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};

export default ReviewTooltip;
