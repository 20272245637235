import { Chip } from "@mui/material";
import { blue } from "@mui/material/colors";
import { theme } from "@src/theme";
import { AvailableKiosk } from "./types";

type Props = {
  kiosk: AvailableKiosk;
  selected: boolean;
  onSelect: (kiosk?: AvailableKiosk) => void;
};

export default function SelectableKiosk({ kiosk, selected, onSelect }: Props) {
  return (
    <Chip
      clickable
      label={kiosk.name}
      onClick={() => onSelect(selected ? undefined : kiosk)}
      sx={{
        backgroundColor: selected ? blue[100] : theme.palette.common.white,
        border: selected
          ? `1px solid ${blue[900]}`
          : `1px solid rgba(0 0 0 / .12)`,
        color: selected ? blue[900] : theme.palette.text.primary,
      }}
    />
  );
}
