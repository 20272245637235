import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MessageKeywordFragmentFragmentDoc } from '../../graphql/fragments/MessageKeywordFragment.generated';
export type GetMessageKeywordsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetMessageKeywordsQuery = { __typename: 'Query', facility: { __typename: 'Facility', id: string, keywords: Array<{ __typename: 'MessageKeyword', id: string, name: string, description: string, spellings: Array<string>, category: Types.MessageKeywordCategory, isGlobal: boolean, isActive: boolean, createdAt: number | null, updatedAt: number | null, createdBy: { __typename: 'StaffPosition', id: string, staff: { __typename: 'Staff', id: string, firstName: string, lastName: string, fullName: string } } | null, lastUpdatedBy: { __typename: 'StaffPosition', id: string, staff: { __typename: 'Staff', id: string, firstName: string, lastName: string, fullName: string } } | null }> } };


export const GetMessageKeywordsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMessageKeywords"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"keywords"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageKeywordFragment"}}]}}]}}]}},...MessageKeywordFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetMessageKeywordsQuery, GetMessageKeywordsQueryVariables>;