import { useApolloClient } from "@apollo/client";
import { CurrentStaffDocument } from "@src/graphql/CurrentStaff.generated";

const useCurrentStaff = () => {
  const client = useApolloClient();
  const result = client.readQuery({ query: CurrentStaffDocument });
  if (!result?.currentStaff) throw new Error("Not logged in");
  return result.currentStaff;
};

export default useCurrentStaff;
