import { message, notification } from "antd";
import { ConfigOptions } from "antd/lib/message";
import { v4 as uuid } from "uuid";

export const AUTO_HIDE_MESSAGE_DURATION = 3;

export const openNotificationWithIcon = (
  message: string,
  description: string,
  type: "success" | "info" | "error" | "warning"
) => {
  notification[type]({
    message,
    description,
  });
};

export const showToast = (
  content: string,
  type: "success" | "error" | "warning" | "loading" | "info",
  duration = AUTO_HIDE_MESSAGE_DURATION,
  config?: ConfigOptions
) => {
  const key = uuid();
  if (config) message.config(config);
  switch (type) {
    case "success":
      message.success({ content, key, duration });
      break;
    case "error":
      message.error({ content, key, duration });
      break;
    case "warning":
      message.warning({ content, key, duration });
      break;
    case "loading":
      message.loading({ content, key, duration });
      break;
    default:
      message.info({ content, key, duration });
      break;
  }
};

export const makeBold = (item: string, keywords: string[]) => {
  const re = new RegExp(keywords.join("|"), "g");
  return item.replace(re, (keyword) => "<b>" + keyword + "</b>");
};
