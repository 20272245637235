import { LockOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  Entitlement,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
} from "@src/api/graphql";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import Header from "@src/lib/Header";
import NotAllowed from "@src/lib/NotAllowed";
import PageLoader from "@src/lib/PageLoader";
import Result from "@src/lib/Result";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import { GetCallWithRecordingDocument } from "@src/pages/ArtifactsPage/GetCallWithRecording.generated";
import { GetCallWithScreenshotsDocument } from "@src/pages/ArtifactsPage/GetCallWithScreenshots.generated";
import InCallChatModule from "@src/pages/ArtifactsPage/modules/InCallChatModule";
import RecordingModule from "@src/pages/ArtifactsPage/modules/RecordingModule";
import ScreenshotsModule from "@src/pages/ArtifactsPage/modules/ScreenshotsModule";
import TranscriptModule from "@src/pages/ArtifactsPage/modules/TranscriptModule";
import ReportProblemWithArtifactButton from "@src/pages/ArtifactsPage/ReportProblemWithArtifactButton";
import {
  GetMeetingDetailsDocument,
  GetMeetingDetailsQuery,
} from "@src/pages/MeetingDetailsPage/GetMeetingDetails.generated";
import { Layout, PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

type MeetingInfo = GetMeetingDetailsQuery["meeting"];

type MeetingInfoProps = {
  modules: string[];
  canReviewRecords: boolean;
  query: any;
  expirationDate?: number | null;
};

enum RecordModule {
  Recordings = "recordings",
  InCallChat = "in-call-chat",
  Screenshots = "screenshots",
  Transcript = "transcript",
}

function useMeetingInfo(meeting?: MeetingInfo): MeetingInfoProps {
  const canReviewRecordings = useEntitlement(Entitlement.ReviewRecordings);
  const canReviewScreenshots = useEntitlement(Entitlement.ReviewScreenshots);
  switch (meeting?.privacyLevel) {
    case PrivacyLevel.UnmonitoredLogged:
      return {
        modules: canReviewScreenshots ? [RecordModule.Screenshots] : [],
        canReviewRecords: canReviewScreenshots,
        query: GetCallWithScreenshotsDocument,
        expirationDate: meeting?.call?.screenshotsExpireAt,
      };
    case PrivacyLevel.Monitored:
      return {
        modules: [RecordModule.Recordings, RecordModule.InCallChat],
        canReviewRecords: canReviewRecordings,
        query: GetCallWithRecordingDocument,
        expirationDate: meeting?.call?.artifactsExpireAt,
      };
    default:
      return {
        modules: [],
        canReviewRecords: false,
        query: GetCallWithRecordingDocument,
        expirationDate: meeting?.call?.artifactsExpireAt,
      };
  }
}

export default function RecordsPage() {
  const navigate = useAppNavigate();
  const { meetingId } = useParams<{ meetingId: string }>();
  if (!meetingId) throw new Error("missing meetingId param");
  const { t } = useTranslation();
  const { staffPositions } = useCurrentStaff();

  const { loading, error, data } = useQuery(GetMeetingDetailsDocument, {
    variables: { id: meetingId },
  });

  const { modules, canReviewRecords, query, expirationDate } = useMeetingInfo(
    data?.meeting
  );

  const allowedToAccessRecords =
    canReviewRecords &&
    data?.meeting &&
    staffPositions.some((sp) => sp.facility.id === data.meeting.facility.id);

  // Get data for the appropriate query
  const recordsQuery = useQuery(query, {
    skip: !allowedToAccessRecords,
    variables: { id: meetingId },
  });
  const {
    loading: recordsLoading,
    error: recordsError,
    data: recordsData,
  } = recordsQuery;

  if (error) throw error;
  if (recordsError) throw recordsError;
  if (loading || recordsLoading || !data?.meeting) return <PageLoader />;

  const { meeting } = data;

  if (
    meeting.meetingType !== MeetingType.VideoCall &&
    meeting.meetingType !== MeetingType.VoiceCall
  ) {
    throw new Error(`${meeting.meetingType} meetings cannot have records.`);
  }

  if (!canReviewRecords) {
    return (
      <Layout>
        <Header
          title={t("Not permitted")}
          subtitle={t("You do not have permission to review records.")}
        />
        <NotAllowed />
      </Layout>
    );
  }
  const isExpired = expirationDate && expirationDate <= new Date().getTime();
  return (
    <>
      <PageHeader
        ghost={false}
        className={"multiline"}
        title={
          <Stack>
            <Typography variant="h4" component="h1">
              {t("Records") +
                `:${meeting.inmates.map((r) => r.fullName).join(", ")} &
              ${meeting.visitors.map((v) => v.fullName).join(", ")} on
              ${formatDate(new Date(meeting.interval.startAt), "datetime")}`}
            </Typography>
            {expirationDate && (
              <Typography variant="body1" className="subtitle">
                {t(`Records are available until {{expirationDate}}`, {
                  expirationDate: formatDate(
                    new Date(expirationDate),
                    "datetime"
                  ),
                })}
              </Typography>
            )}
          </Stack>
        }
        breadcrumb={
          <Breadcrumbs
            paths={[
              { path: `/meetings/${meetingId}`, label: t("Meeting") },
              { path: `/records/${meetingId}`, label: t("View records") },
            ]}
          />
        }
        onBack={() => navigate(`/meetings/${meetingId}`)}
        extra={
          !isExpired
            ? [
                <ReportProblemWithArtifactButton
                  artifactType={
                    modules.includes(RecordModule.Recordings)
                      ? "recording"
                      : "screenshot"
                  }
                  meetingId={meetingId}
                  key="1"
                />,
              ]
            : null
        }
      />
      {isExpired ? (
        <Result
          icon={<LockOutlined />}
          title={t("Sorry, you are not able to access this page")}
          subTitle={t("All records are expired.")}
        />
      ) : (
        <>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            p={3}
            className="printable-region"
          >
            {recordsData?.meeting && modules.includes(RecordModule.Recordings) && (
              <Box flex={2}>
                <RecordingModule meeting={recordsData?.meeting} />
              </Box>
            )}
            {modules.includes(RecordModule.InCallChat) &&
              meeting.call &&
              [
                MeetingStatus.Live,
                MeetingStatus.Ended,
                MeetingStatus.Terminated,
                MeetingStatus.NoShow,
              ].includes(meeting.status) && (
                <Box flex={1}>
                  <InCallChatModule
                    meeting={meeting}
                    canReviewRecordings={canReviewRecords}
                    allowedToAccessRecordings={allowedToAccessRecords}
                    artifactsQuery={recordsQuery}
                  />
                </Box>
              )}
            {modules.includes(RecordModule.Screenshots) && (
              <Box flex={1}>
                {recordsData?.meeting?.call?.screenshots && (
                  <ScreenshotsModule
                    meetingId={meetingId}
                    screenshots={recordsData?.meeting.call.screenshots}
                  />
                )}
              </Box>
            )}
          </Stack>
          {modules.includes("transcript") && (
            <Stack px={3} pb={3}>
              <Box flex={1}>
                <TranscriptModule />
              </Box>
            </Stack>
          )}
        </>
      )}
    </>
  );
}
