import Spinner from "@src/lib/Spinner";
import { Layout } from "antd";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

export default function PageLoader(): ReactElement {
  const { t } = useTranslation();
  return (
    <Content className="h-full w-full flex">
      <Spinner title={t("Loading page...")} className="m-auto" />
    </Content>
  );
}
