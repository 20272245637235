import { Box, Link, Stack, Typography } from "@mui/material";
import {
  Organization,
  OrganizationMembership,
  Visitor,
} from "@src/api/graphql";
import { isMinor } from "@src/lib/Common";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";

type MemberOrganization = Pick<Organization, "logoImageUrl">;

type MeetingOrganizationMemberships = Pick<OrganizationMembership, "id"> & {
  organization: MemberOrganization;
};

export type MeetingVisitor = Pick<
  Visitor,
  "id" | "fullName" | "dateOfBirth"
> & {
  organizationMemberships?: MeetingOrganizationMemberships[];
};

interface Props {
  visitors: MeetingVisitor[];
  combineMinorGuests?: boolean;
  unregisteredGuests: string[];
}

/**
 * VisitorList displays the complete list of visitors, including
 * the primary visitor and all registered or unregistered guests.
 */
export default function VisitorList({
  visitors,
  unregisteredGuests,
  combineMinorGuests,
}: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  const adultVisitors = visitors.filter((v) => !isMinor(v));
  const minorVisitors = visitors.filter((v) => isMinor(v));

  const primaryVisitor = adultVisitors[0];
  const otherAdults = adultVisitors.slice(1);
  const hasGuests =
    otherAdults.length || minorVisitors.length || unregisteredGuests.length;
  const hasMinorsOrUnregisteredGuests =
    minorVisitors.length || unregisteredGuests.length;

  return (
    <Stack>
      {primaryVisitor ? (
        <Stack
          key={primaryVisitor.id}
          alignItems="center"
          direction="row"
          spacing={1}
        >
          {primaryVisitor.organizationMemberships &&
            primaryVisitor.organizationMemberships.length > 0 && (
              <img
                src={
                  primaryVisitor.organizationMemberships[0].organization
                    .logoImageUrl
                }
                style={{ maxWidth: 45, maxHeight: 45, display: "inline" }}
                alt={t("logo")}
              />
            )}
          <Link onClick={() => navigate(`/visitors/${primaryVisitor.id}`)}>
            {primaryVisitor.fullName}
          </Link>
        </Stack>
      ) : null}
      {hasGuests && (!combineMinorGuests || otherAdults.length) ? (
        <Typography variant="caption" sx={{ mt: 1 }}>
          {t("GUESTS")}
        </Typography>
      ) : null}
      {otherAdults.map((adult) => (
        <Box sx={{ whiteSpace: "nowrap" }} key={adult.id}>
          {adult.organizationMemberships &&
            adult.organizationMemberships.length > 0 && (
              <img
                src={adult.organizationMemberships[0].organization.logoImageUrl}
                style={{ maxWidth: 45, maxHeight: 45, display: "inline" }}
                alt={t("logo")}
              />
            )}{" "}
          <Link onClick={() => navigate(`/visitors/${adult.id}`)}>
            {adult.fullName}
          </Link>
        </Box>
      ))}
      {combineMinorGuests && hasMinorsOrUnregisteredGuests ? (
        <Typography variant="caption" sx={{ mt: 1 }}>
          {t("MINORS")}
        </Typography>
      ) : null}
      {minorVisitors.map((m) => (
        <div key={m.id}>
          <Link onClick={() => navigate(`/visitors/${m.id}`)}>
            {m.fullName}
          </Link>
        </div>
      ))}
      {unregisteredGuests.map((guest, idx) => (
        <div key={idx}>{guest}</div>
      ))}
    </Stack>
  );
}
