import { FacilityFeature } from "@src/api/graphql";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";

const useFacilityFeature = (feature: FacilityFeature) => {
  const { facility } = useGuaranteedFacilityContext();

  if (!facility.features) return false;
  return facility.features.includes(feature);
};

export default useFacilityFeature;
