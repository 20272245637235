import { Button, Dialog, DialogActions, DialogContent } from "@ameelio/ui";
import { Stack } from "@mui/material";
import { Facility, Inmate, Visitor } from "@src/api/graphql";
import { TypographyText } from "@src/lib/typography";
import { Radio, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../Alert";

export type ConnectionRejectionReason = {
  title: string;
  description: string;
  key: "bad_id" | "not_approved_visitor" | "invalid_info" | "other";
};

interface Props {
  onSubmit: (feedback: string) => void;
  onClose: () => void;
  connection: {
    inmate: Pick<Inmate, "fullName">;
  } & {
    visitor: Pick<Visitor, "fullName" | "dateOfBirth">;
  };
  facility: Pick<Facility, "publicId" | "visitationFormLink">;
}

const DisableConnectionModal = ({
  onSubmit,
  onClose,
  connection,
  facility,
}: Props) => {
  const [reason, setReason] = useState<ConnectionRejectionReason>();
  const [customReason, setCustomReason] = useState("");
  const isCustomReason = reason?.key === "other";

  const { t } = useTranslation();

  const CUSTOM_HEADER = t(
    "Unfortunately, we must remove approval for the following reason:"
  );

  const DEFAULT_CONNECTION_REJECTION_REASONS: ConnectionRejectionReason[] = [
    {
      title: "Not an approved visitor",
      description: `In order to visit ${
        connection.inmate.fullName
      }, you need to fill out a visitor application. ${
        facility.publicId
      }’s records indicate that you haven’t completed this application or been approved. Once you are approved as a visitor, you will be able to submit a contact request. ${
        facility.visitationFormLink
          ? `You can find more information on how to do this at this link: ${facility.visitationFormLink}`
          : ""
      }`,
      key: "not_approved_visitor",
    },
    {
      title: "Inaccurate/incomplete information",
      description:
        "You need to fill out all of the information on the visitor application (including fields like your DOB and phone number). Please fill in all of this information and resubmit the contact request.",
      key: "invalid_info",
    },
    {
      title: "Other",
      description: "",
      key: "other",
    },
  ];

  return (
    <Dialog title={t("Remove approval?")} onClose={onClose}>
      <DialogContent sx={{ pt: 0 }}>
        <Stack spacing={2}>
          <Alert severity="warning">
            {t(
              "This visitor approval will become inactive and may only be restored by a new request from the visitor."
            )}
          </Alert>
          <TypographyText>
            {t(
              "Please provide a reason that will inform {{inmateFullName}} and {{visitorFullName}} about the change:",
              {
                inmateFullName: connection.inmate.fullName,
                visitorFullName: connection.visitor.fullName,
              }
            )}
          </TypographyText>

          <Radio.Group value={reason?.title}>
            <Stack spacing={1}>
              {DEFAULT_CONNECTION_REJECTION_REASONS.map((reason) => (
                <Radio
                  key={reason.key}
                  value={reason.title}
                  onClick={() => setReason(reason)}
                >
                  {reason.title}
                </Radio>
              ))}
            </Stack>
          </Radio.Group>

          {reason && (
            <Space direction="vertical">
              {isCustomReason && (
                <TypographyText>{CUSTOM_HEADER}</TypographyText>
              )}
              <TextArea
                disabled={!isCustomReason}
                rows={3}
                value={isCustomReason ? customReason : reason.description}
                onChange={(e) => setCustomReason(e.target.value)}
                autoFocus={isCustomReason}
              />
              <TypographyText type="secondary">
                {t(
                  "The message will be shared with {{inmateFullName}} and {{visitorFullName}}.",
                  {
                    inmateFullName: connection.inmate.fullName,
                    visitorFullName: connection.visitor.fullName,
                  }
                )}
              </TypographyText>
            </Space>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="outlined"
          disabled={!reason || (isCustomReason && !customReason.length)}
          color="error"
          onClick={() => {
            if (!reason) return;
            if (isCustomReason && !customReason.length) return;
            isCustomReason
              ? onSubmit(`${CUSTOM_HEADER} ${customReason}`)
              : onSubmit(reason.description);
            onClose();
          }}
        >
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableConnectionModal;
