import isObjectWithKey from "@src/lib/isObjectWithKey";
import { useEffect, useState } from "react";

type Filters = {
  residentName: string;
};

const { history } = window;

export default function useMeetingFiltersState() {
  const [filters, setFilters] = useState<Filters>({
    residentName:
      isObjectWithKey(history.state, "residentName") &&
      typeof history.state.residentName === "string"
        ? history.state.residentName
        : "",
  });

  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  return [filters, setFilters] as const;
}
