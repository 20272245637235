import { SubmitButton, SubmitButtonProps } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Meeting } from "@src/api/graphql";
import errorReporter from "@src/lib/errorReporter";
import { useTranslation } from "react-i18next";
import { AcceptMeetingDocument } from "./AcceptMeeting.generated";

type Props = Omit<SubmitButtonProps, "submitting" | "onClick"> & {
  meeting: Pick<Meeting, "id">;
};

export default function AcceptMeetingButton({ meeting, ...rest }: Props) {
  const { t } = useTranslation();

  const [acceptMeeting, { loading }] = useMutation(AcceptMeetingDocument, {
    onError: errorReporter,
  });

  return (
    <SubmitButton
      submitting={loading}
      onClick={() =>
        acceptMeeting({
          variables: {
            input: {
              meetingId: meeting.id,
            },
          },
        })
      }
      {...rest}
    >
      {t("Accept")}
    </SubmitButton>
  );
}
