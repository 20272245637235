import { TimelineEventVerb } from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";
import entitlementLabel from "@src/lib/entitlementLabel";
import facilityServiceLabel from "@src/lib/facilityServiceLabel";
import { labelMeetingType } from "@src/lib/meeting";
import { TypographyText } from "@src/lib/typography";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GetStaffTimelineQuery } from "./GetStaffTimeline.generated";

const settingPropertyMap: Record<string, string> = {
  name: "Facility name",
  email: "Email",
  phone: "Phone number",
  policyLink: "Policy URL",
  visitationFormLink: "Visitation form URL",
  defaultVisitQuota: "Default personal in-person visit quota",
  defaultCallQuota: "Default personal video call quota",
  defaultVoiceCallTimeQuota: "Default voice call time quota",
  maxVoiceCallDuration: "Maximum voice call duration",
  schedulingResetDay: "Personal video scheduling reset day",
  voiceCallResetDay: "Voice call quota reset day",
  ipvResetDay: "Personal in-person visit scheduling reset day",
  defaultEmessageDelay: "Default e-message delay",
  defaultNeedsApproval: "Default video meeting approval requirement",
  defaultIpvNeedsAppproval: "Default in-person visit approval requirement",
  defaultNeedsMonitor: "Default monitoring requirement",
  schedulingCutoff: "Personal video call scheduling cutoff",
  providerSchedulingCutoff: "Professional video scheduling cutoff",
  ipvSchedulingCutoff: "Personal in-person visit scheduling cutoff",
  scheduleWeeksAvailable: "Personal video scheduling weeks available",
  providerWeeksAvailable: "Professional video scheduling weeks available",
  ipvWeeksAvailable: "Personal in-person visit scheduling weeks available",
};
function formatSettingProperty(prop: string) {
  if (prop in settingPropertyMap) return settingPropertyMap[prop];
  return prop;
}

const facilityApprovedPhonePropertyMap: Record<string, string> = {
  name: "Name",
  description: "Description",
  privacyLevel: "Privacy level",
};
function formatFacilityApprovedPhoneProperty(prop: string) {
  if (prop in facilityApprovedPhonePropertyMap)
    return facilityApprovedPhonePropertyMap[prop];
  return prop;
}

const labelTimelineEventVerb = (verb: TimelineEventVerb): string => {
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved");
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled");
    case TimelineEventVerb.Create:
      return i18n.t("Scheduled");
    case TimelineEventVerb.Reject:
      return i18n.t("Rejected");
    case TimelineEventVerb.Update:
      return i18n.t("Updated");
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled");
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled");
    default:
      return verb;
  }
};

type StaffTimelineEvent =
  GetStaffTimelineQuery["staff"]["timelineEvents"]["edges"][0]["node"];

interface Props {
  event: StaffTimelineEvent;
}
const TimelineEventObjectDescription = ({ event }: Props) => {
  const { t } = useTranslation();
  const verb = labelTimelineEventVerb(event.verb);
  const object = event.object;
  const metadata = event.metadata ? JSON.parse(event.metadata) : null;

  switch (object.__typename) {
    case "Message":
      return <TypographyText>{verb} message.</TypographyText>;
    case "Meeting":
      return (
        <TypographyText>
          {verb} {labelMeetingType(object.meetingType)}
        </TypographyText>
      );
    case "MessageKeyword":
      return (
        <TypographyText>
          {verb} keyword '{object.name}'
        </TypographyText>
      );
    case "Connection":
      return <TypographyText>{verb} request</TypographyText>;
    case "ConnectionRestriction":
      return (
        <TypographyText>
          {verb} restriction on {facilityServiceLabel(object.service)} between
          two parties
        </TypographyText>
      );
    case "StaffPosition":
      return (
        <TypographyText>
          {verb} staff position at {object.facility.publicId}
        </TypographyText>
      );
    case "StaffEntitlement":
      return (
        <TypographyText>
          {verb} staff entitlement: {entitlementLabel(object.entitlement)}
        </TypographyText>
      );
    case "Kiosk":
      return <TypographyText>{verb} kiosk</TypographyText>;
    case "FacilityApprovedPhone":
      return (
        <TypographyText>
          {verb} {object.number}{" "}
          {event.verb === TimelineEventVerb.Update &&
            Object.keys(metadata)
              .map(formatFacilityApprovedPhoneProperty)
              .join(", ")}
        </TypographyText>
      );
    case "Call":
      if (metadata?.detail === "accessed_recording") {
        return (
          <TypographyText>
            Accessed <Link to={`/meetings/${object.meeting.id}`}>Call</Link>{" "}
            Recording
          </TypographyText>
        );
      } else {
        return <TypographyText>{verb}</TypographyText>;
      }
    case "Facility":
      if (metadata.changedFrom) {
        return (
          <TypographyText>
            Changed properties:{" "}
            {Object.keys(metadata.changedFrom)
              .map(formatSettingProperty)
              .join(", ")}
          </TypographyText>
        );
      } else if (metadata?.detail === "uploaded_roster") {
        return <TypographyText>{t("Uploaded facility roster")}</TypographyText>;
      } else {
        return null;
      }
    case "SystemUser":
      return <TypographyText>{verb} ID</TypographyText>;
    default:
      return null;
  }
};

export default TimelineEventObjectDescription;
