import { Link } from "@mui/material";
import { useAppNavigate } from "@src/lib/useAppNavigate";
interface Props {
  visitor: {
    id: string;
    fullName: string;
  };
}

const VisitorCell = ({ visitor }: Props) => {
  const navigate = useAppNavigate();
  return (
    <div className="flex gap-x-4">
      <Link onClick={() => navigate(`/visitors/${visitor.id}`)}>
        {visitor.fullName}
      </Link>
    </div>
  );
};

export default VisitorCell;
