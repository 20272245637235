import i18n from "@src/i18n/i18nConfig";
import { CallAlert } from "@src/typings/Call";

enum WeekdayMap {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const WEEKDAYS = [
  WeekdayMap.Sunday,
  WeekdayMap.Monday,
  WeekdayMap.Tuesday,
  WeekdayMap.Wednesday,
  WeekdayMap.Thursday,
  WeekdayMap.Friday,
  WeekdayMap.Saturday,
];

export const WEEKDAY_NAMES = [
  i18n.t("Sunday"),
  i18n.t("Monday"),
  i18n.t("Tuesday"),
  i18n.t("Wednesday"),
  i18n.t("Thursday"),
  i18n.t("Friday"),
  i18n.t("Saturday"),
];

// 30 min
export const DEFAULT_DURATION_MS = 1500000;

// 5 min
export const DEFAULT_CALL_SLOT_INTERVAL = 300000;

// The alerts do NOT currently support translations since
// the exact string is sent as the message to call participants.
// Until we can support translating the alerts in other clients
// into the user's preferred language, just using English is safest.
export const CALL_ALERTS: CallAlert[] = [
  {
    title: "Unallowed visitor",
    body: "All participants on the call must have been registered and pre-approved ahead of time. The presence of unallowed visitors may lead to call termination.",
  },
  {
    title: "Inappropriate clothing",
    body: "All visitors must be fully dressed in clothing that is not unduly provocative, suggestive, or revealing and does not resemble offender attire. Clothing cannot be sleeveless, have spaghetti straps, contain holes or rips, nor be see-through. Outer garments worn on the bottom half of the body must be no shorter than the knee while standing.",
  },
  {
    title: "Resident declined to participate",
    body: "The person you scheduled with has declined to participate in the call.",
  },
  {
    title: "Sexually suggestive actions",
    body: "Showing or demonstrating any sexual behaviors is not allowed. The presentation of any sexual objects and gestures will result in the termination of the call.",
  },
  {
    title: "Gang affiliation symbols",
    body: "Gang affilitation symbols are not allowed.",
  },
  {
    title: "Alcohol/drugs",
    body: "Any use or display of drugs, alcohol, related paraphernalia, or intoxication is strictly prohibited.",
  },
  {
    title: "Profanity/hostile language",
    body: "Any ongoing use of profanity or loud/hostile communication is prohibited.",
  },
  {
    title: "Show photo ID",
    body: "To visitors on the call — please show your photo ID to the camera and ensure your face is visible. This is required for the call to continue. Thank you.",
  },
  {
    title: "Standby: call beginning soon",
    body: "Your contact will be joining shortly. Thank you for your patience.",
  },
  {
    title: "Standby: technical difficulties",
    body: "Facility staff is working to fix some technical difficulties we detected. We apologize for the inconvenience, and your call will resume shortly.",
  },
  {
    title: "Custom",
    body: "",
  },
];

export const VOICE_CALL_ALERTS: CallAlert[] = [
  {
    title: "Unapproved visitor",
    body: "All visitors on the call must be approved ahead of time. Unapproved visitors may result in termination of the call.",
  },
  {
    title: "Sexual language",
    body: "Sexual or suggestive language is not allowed and may result in termination of the call.",
  },
  {
    title: "Gang language",
    body: "Gang affiliated language is not allowed and may result in termination of the call.",
  },
  {
    title: "Alcohol/drugs",
    body: "Use or discussion of drugs, alcohol, or related paraphernalia is prohibited and may result in termination of the call.",
  },
  {
    title: "Profanity/hostile language",
    body: "Use of profanity or loud/hostile language is prohibited and may result in termination of the call.",
  },
  {
    title: "Custom",
    body: "",
  },
];

export const FACILITY_SUPPORT_ADDRESS = "facilitysupport@ameelio.org";
