import * as Sentry from "@sentry/react";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { client } from "./api/client";
import { UserType } from "./api/graphql";
import { getToken, storeToken } from "./lib/authToken";
import { storeSelectedFacility } from "./lib/selectedFacility";
import { LoginDocument } from "./Login.generated";
import { LogoutDocument } from "./Logout.generated";

export type AuthContextType = {
  isLoggedIn: boolean;
  token: string;
  login: (
    username: string,
    password: string,
    type: UserType
  ) => Promise<boolean>;
  logout: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export function useAuthContext() {
  return useContext(AuthContext);
}

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [token, setToken] = useState<string>(() => getToken());

  useEffect(() => {
    storeToken(token);
  }, [token]);

  const authContext = useMemo(
    (): AuthContextType => ({
      isLoggedIn: !!token,
      token,
      login: async (
        username: string,
        password: string,
        type = UserType.User
      ): Promise<boolean> => {
        try {
          const result = await client.query({
            fetchPolicy: "no-cache",
            query: LoginDocument,
            variables: {
              input: {
                username,
                password,
                userType: type,
              },
            },
          });
          setToken(result.data.loginUser.authToken);
          return true;
        } catch (e) {
          return false;
        }
      },
      logout: async (): Promise<void> => {
        if (!token) return;
        setToken("");
        storeSelectedFacility(undefined);
        await client.clearStore();
        try {
          await client.mutate({
            mutation: LogoutDocument,
            variables: { input: { authToken: token } },
          });
        } catch (e) {
          // report but do not fail
          Sentry.captureException(e);
        }
      },
    }),
    [token]
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}
