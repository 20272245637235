import { Box, Link, Stack, Typography } from "@mui/material";
import {
  Organization,
  OrganizationMembership,
  Visitor,
} from "@src/api/graphql";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";

type MemberOrganization = Pick<Organization, "logoImageUrl">;

type MeetingOrganizationMemberships = Pick<OrganizationMembership, "id"> & {
  organization: MemberOrganization;
};

export type MeetingVisitor = Pick<
  Visitor,
  "id" | "fullName" | "dateOfBirth"
> & {
  organizationMemberships?: MeetingOrganizationMemberships[];
};

interface Props {
  registeredGuests: MeetingVisitor[];
  unregisteredGuests: string[];
}

/**
 * GuestList displays only the registered and unregistered
 * guests, excluding the primary visitor.
 */
export default function GuestList({
  registeredGuests,
  unregisteredGuests,
}: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  return (
    <Stack>
      {registeredGuests.map((guest) => (
        <Box sx={{ whiteSpace: "nowrap" }} key={guest.id}>
          {guest.organizationMemberships &&
            guest.organizationMemberships.length > 0 && (
              <img
                src={guest.organizationMemberships[0].organization.logoImageUrl}
                style={{ maxWidth: 45, maxHeight: 45, display: "inline" }}
                alt={t("logo")}
              />
            )}{" "}
          <Link onClick={() => navigate(`/visitors/${guest.id}`)}>
            {guest.fullName}
          </Link>
        </Box>
      ))}
      {unregisteredGuests.map((guest, idx) => (
        <Typography variant="body1" key={idx}>
          {guest}
        </Typography>
      ))}
    </Stack>
  );
}
