import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CallFragmentFragmentDoc } from '../../graphql/fragments/CallFragment.generated';
import { CallMessageFragmentFragmentDoc } from '../../graphql/fragments/CallMessageFragment.generated';
import { RecordingFragmentFragmentDoc } from '../../graphql/fragments/RecordingFragment.generated';
export type GetCallArtifactsQueryVariables = Types.Exact<{
  meetingId: Types.Scalars['ID'];
}>;


export type GetCallArtifactsQuery = { __typename: 'Query', meeting: { __typename: 'Meeting', id: string, meetingType: Types.MeetingType, privacyLevel: Types.PrivacyLevel, status: Types.MeetingStatus, call: { __typename: 'Call', id: string, status: Types.CallStatus, rating: number | null, artifactsExpireAt: number | null, screenshotsExpireAt: number | null, messages: { __typename: 'CallMessageConnection', edges: Array<{ __typename: 'CallMessageEdge', node: { __typename: 'CallMessage', id: string, createdAt: number, contents: string, sender: { __typename: 'Inmate', id: string, firstName: string, lastName: string, fullName: string } | { __typename: 'Staff' } | { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string } } }> }, recording: { __typename: 'Recording', status: Types.RecordingStatus, requester: { __typename: 'Staff', id: string, firstName: string, lastName: string, fullName: string } | null } | null } | null } };


export const GetCallArtifactsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCallArtifacts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"meetingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"meetingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"meetingType"}},{"kind":"Field","name":{"kind":"Name","value":"privacyLevel"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"call"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CallFragment"}},{"kind":"Field","name":{"kind":"Name","value":"messages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CallMessageFragment"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"recording"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RecordingFragment"}}]}}]}}]}}]}},...CallFragmentFragmentDoc.definitions,...CallMessageFragmentFragmentDoc.definitions,...RecordingFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetCallArtifactsQuery, GetCallArtifactsQueryVariables>;