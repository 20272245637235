import { Chip, ChipCustomColors } from "@ameelio/ui";
import { ConnectionStatus } from "@src/api/graphql";

interface Props {
  status: ConnectionStatus;
}

const StatusColors: Record<ConnectionStatus, ChipCustomColors> = {
  [ConnectionStatus.Active]: "green",
  [ConnectionStatus.Inactive]: "grey",
  [ConnectionStatus.Pending]: "orange",
  [ConnectionStatus.Rejected]: "red",
};

const ConnectionStatusTag = ({ status }: Props) => {
  return <Chip color={StatusColors[status]} label={status} />;
};

export default ConnectionStatusTag;
