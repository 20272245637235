import { useConnectCall } from "@ameelio/connect-call-client";
import { useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import errorReporter, { reportError } from "@src/lib/errorReporter";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Audio } from "../../lib/LiveCall";
import { TerminateCallDocument } from "../LiveCallPage/TerminateMeeting.generated";
import { GetFacilityCallsQuery } from "./GetFacilityCalls.generated";
import VoiceCallPlayer from "./VoiceCallPlayer";

export type VoiceCall =
  GetFacilityCallsQuery["facility"]["meetings"]["edges"][0]["node"];

interface Props {
  vc: VoiceCall;
  user: { id: string };
  onStopListening: () => void;
}

const VoiceCallAudioPlayer = ({ vc, user, onStopListening }: Props) => {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  if (!vc.call || !vc.call.url || !vc.call.token) {
    throw new Error("Missing call information");
  }

  const [terminateMeeting] = useMutation(TerminateCallDocument, {
    onError: errorReporter,
  });

  const { callStatus, error, peers } = useConnectCall({
    call: {
      id: vc.id,
      url: vc.call.url,
      token: vc.call.token,
    },
    user,
  });

  useEffect(() => {
    if (!error) return;
    reportError(error);
  }, [error]);

  useEffect(() => {
    if (!callStatus) return;

    switch (callStatus) {
      case "terminated":
        snackbarContext.alert(
          "info",
          t("The call was {{callStatus}} by a staff monitor.", { callStatus })
        );
        break;
      case "ended":
        snackbarContext.alert("info", t("The call ended."));
        break;
    }
  }, [callStatus, t, snackbarContext]);

  async function onTerminateCall() {
    await terminateMeeting({
      variables: {
        input: {
          meetingId: vc.id,
        },
      },
    });
  }

  return (
    <div className="bg-white fixed bottom-0 w-full">
      {Object.values(peers).map((p) => (
        <Audio
          key={p.user.id}
          srcObject={p.consumers.audio?.stream}
          autoPlay={true}
        />
      ))}
      <VoiceCallPlayer
        vc={vc}
        onTerminate={onTerminateCall}
        onStopListening={onStopListening}
        peers={Object.values(peers)}
        callStatus={callStatus}
      />
    </div>
  );
};

export default VoiceCallAudioPlayer;
