import { useQuery } from "@apollo/client";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useFetchAll from "@src/lib/useFetchAll";
import { useMemo } from "react";
import { GetVisitorsDocument } from "./GetVisitors.generated";
import Visitors from "./Visitors";

const VisitorsPage = () => {
  const { facility } = useGuaranteedFacilityContext();

  const { data, loading, error, fetchMore } = useQuery(GetVisitorsDocument, {
    variables: {
      facilityId: facility.id,
      cursor: undefined,
    },
  });
  if (error) throw error;

  useFetchAll({
    pageInfo: data?.facility.visitors.pageInfo,
    fetchMore,
  });

  const visitors = useMemo(
    () => data?.facility.visitors.edges.map((e) => e.node) || [],
    [data]
  );

  return <Visitors visitors={visitors} loading={loading} />;
};

export default VisitorsPage;
