import { belowLargeTablet, Chip, useSnackbarContext } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { useMediaQuery as measureScreenWidth } from "@mui/material";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import entitlementLabel from "@src/lib/entitlementLabel";
import errorReporter from "@src/lib/errorReporter";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { WRAPPER_STYLE } from "@src/styles/styles";
import { Descriptions, Layout, PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { GetStaffDocument } from "./GetStaff.generated";
import StaffTimeline from "./StaffTimeline";

interface Props {
  staffId: string;
}

const StaffDetail = ({ staffId }: Props) => {
  const { facility, system } = useGuaranteedFacilityContext();
  const { loading, data } = useQuery(GetStaffDocument, {
    variables: {
      id: staffId,
      systemId: system.id,
    },
    onError: errorReporter,
  });
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  if (loading || !data) return <PageLoader />;

  const { staff } = data;

  return (
    <Layout>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={staff.fullName}
        breadcrumb={
          <Breadcrumbs
            onClick={(path) => {
              if (path === "/")
                snackbarContext.alert(
                  "info",
                  t("Now viewing {{facilityName}} dashboard", {
                    facilityName: facility.name,
                  })
                );
            }}
            paths={[
              {
                path: "/",
                label: t("Dashboard"),
              },

              {
                path: "/system",
                label: t("System Staff"),
              },
              {
                path: `/system/staff/${staff.id}`,
                label: staff.fullName,
              },
            ]}
          />
        }
      >
        <Descriptions size="small" column={isMobileOrSmallTablet ? 1 : 3}>
          <Descriptions.Item label={t("Full name")}>
            {staff.fullName}
          </Descriptions.Item>
          <Descriptions.Item label={t("Email")}>
            {staff.email}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions size="small">
          <Descriptions.Item
            label={t("Facilities")}
            labelStyle={{
              alignItems: "center",
            }}
          >
            {staff.staffPositions.map((sp) => (
              <Chip
                color="grey"
                style={{ marginRight: 3 }}
                key={sp.id}
                label={sp.facility.publicId}
              />
            ))}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions size="small">
          <Descriptions.Item
            label={t("Entitlements")}
            labelStyle={{
              alignItems: "center",
            }}
            style={{
              maxWidth: "100%",
              overflow: "auto",
            }}
          >
            {staff.staffEntitlements.map((se) => (
              <Chip
                color="grey"
                style={{ marginRight: 3 }}
                key={se.id}
                label={entitlementLabel(se.entitlement)}
              />
            ))}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <div style={WRAPPER_STYLE}>
        <StaffTimeline staffId={staffId} systemId={facility.system.id} />
      </div>
    </Layout>
  );
};

export default StaffDetail;
