import { sortByNumbers } from "@ameelio/core";
import * as gql from "@src/api/graphql";
import { getFullNameWithId } from "./Common";
import { labelMeetingType } from "./meeting";
import { formatDate, formatTimeRange } from "./Time";

export function mondayMorning(date: Date): Date {
  const day = date.getDay();
  date.setDate(date.getDate() - day + 1);
  date.setHours(0, 0, 0, 0);
  return date;
}

export type CSVMeeting = Pick<
  gql.Meeting,
  "id" | "statusDetails" | "notes" | "meetingType" | "unregisteredGuests"
> & {
  kiosk?: Pick<gql.Kiosk, "name"> | null;
  interval: Pick<gql.Interval, "startAt" | "endAt">;
  visitors: Pick<gql.Visitor, "id" | "email" | "phone" | "fullName">[];
  inmates: (Pick<gql.Inmate, "id" | "inmateIdentification" | "fullName"> & {
    group: Pick<gql.Group, "name">;
  })[];
};

const reportType = ["public", "admin"] as const;
export type ReportType = typeof reportType[number];

// should match: ameelio-api:src/services/sendFacilitySchedules.ts
export const meetingsToCsvLogs = (meetings: CSVMeeting[], type: ReportType) => {
  return sortByNumbers(meetings, (m) => m.interval.startAt).flatMap(
    (meeting) => {
      return meeting.inmates.map((i) => {
        const baseLogs = {
          resident: getFullNameWithId(i),
          group: i.group.name,
          kiosk: meeting.kiosk?.name || "",
          date: formatDate(new Date(meeting.interval.startAt), "date"),
          time: formatTimeRange(
            meeting.interval.startAt,
            meeting.interval.endAt
          ),
          meetingType: labelMeetingType(meeting.meetingType),
        };

        const adminLogs = {
          meetingNotes: meeting.notes || meeting.statusDetails,
          visitors: meeting.visitors.map((v) => v.fullName).join(", "),
          minors: meeting.unregisteredGuests?.join(", "),
        };

        if (type === "admin") {
          return { ...baseLogs, ...adminLogs };
        }
        return baseLogs;
      });
    }
  );
};

export function getFullNames(
  people: {
    fullName: string;
    inmateIdentification?: string;
  }[]
) {
  return people.map((p) => p.fullName).join(", ");
}

export function getFirstNames(people: { firstName: string }[]) {
  return people.map((person) => person.firstName).join(", ");
}
