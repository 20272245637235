import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FacilityFragmentFragmentDoc } from './fragments/FacilityFragment.generated';
export type CurrentStaffQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentStaffQuery = { __typename: 'Query', currentStaff: { __typename: 'Staff', id: string, email: string, ameelio: boolean, firstName: string, lastName: string, fullName: string, languagePreference: Types.Language | null, receiveDailyScheduleEmails: boolean, receiveTaskReminderEmails: boolean, staffPositions: Array<{ __typename: 'StaffPosition', id: string, isActive: boolean, facility: { __typename: 'Facility', id: string, name: string, state: Types.UsState, province: string, country: string, email: string, phone: string, timezone: string, policyLink: string, hasMessaging: boolean, features: Array<Types.FacilityFeature>, publicId: string, defaultCallQuota: number, defaultVisitQuota: number, videoQuotaResetDay: number, visitQuotaResetDay: number, videoNeedsApproval: boolean, visitNeedsApproval: boolean, personalVideoWindow: number, personalVideoCutoff: number, personalVisitWindow: number, personalVisitCutoff: number, providerVideoCutoff: number, providerVisitCutoff: number, providerVisitWindow: number, voiceCallPrivacyLevel: Types.PrivacyLevel, providerVideoWindow: number, visitationFormLink: string | null, defaultEmessageDelay: number, defaultVoiceCallTimeQuota: number, maxVoiceCallDuration: number | null, maxVoiceCalls: number | null, voiceCallResetTime: number, defaultNeedsMonitor: boolean, system: { __typename: 'System', id: string, recordingRetention: number } } }>, staffEntitlements: Array<{ __typename: 'StaffEntitlement', id: string, entitlement: Types.Entitlement, isActive: boolean }> } | null };


export const CurrentStaffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentStaff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentStaff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"ameelio"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"staffPositions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityFragment"}},{"kind":"Field","name":{"kind":"Name","value":"system"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"recordingRetention"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}}]}},{"kind":"Field","name":{"kind":"Name","value":"staffEntitlements"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"entitlement"}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}}]}},{"kind":"Field","name":{"kind":"Name","value":"languagePreference"}},{"kind":"Field","name":{"kind":"Name","value":"receiveDailyScheduleEmails"}},{"kind":"Field","name":{"kind":"Name","value":"receiveTaskReminderEmails"}}]}}]}},...FacilityFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CurrentStaffQuery, CurrentStaffQueryVariables>;