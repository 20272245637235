import { Link } from "@mui/material";
import { Staff } from "@src/api/graphql";
import { useAppNavigate } from "@src/lib/useAppNavigate";

interface Props {
  staff: Pick<Staff, "id" | "fullName">;
}

const StaffCell = ({ staff }: Props) => {
  const navigate = useAppNavigate();
  if (!staff) return null;
  return (
    <div className="flex gap-x-4">
      <Link onClick={() => navigate(`/system/staff/${staff.id}`)}>
        {staff.fullName}
      </Link>
    </div>
  );
};

export default StaffCell;
