import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Reference,
  StoreObject,
} from "@apollo/client";
import { Modifier } from "@apollo/client/cache/core/types/common";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import fragmentMatcher from "@src/api/fragment-matcher.json";
import { getToken } from "@src/lib/authToken";

const { possibleTypes } = fragmentMatcher;

type Logout = () => void;

const errorLink = (logout: Logout) =>
  onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const tokenError = graphQLErrors
        // according to types, `message` should exist. however we've seen
        // sentry reports that clearly demonstrate it can be undefined.
        .filter((e) => e.message)
        .find(
          (err) =>
            err.message.includes("API token is expired or revoked") ||
            err.message.includes("API token is invalid or missing")
        );

      if (tokenError) {
        logout();
      }
    }

    return forward(operation);
  });

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_API_ORIGIN}/api/graphql`,
});

export const cacheConfiguration = {
  possibleTypes,
  typePolicies: {
    Meeting: {
      fields: {
        interval: {
          merge: true,
        },
      },
    },
    Inmate: {
      fields: {
        meetings: relayStylePagination([
          "meetingType",
          "meetingStatus",
          "scheduledStartAfter",
          "scheduledStartBefore",
        ]),
      },
    },
    Facility: {
      fields: {
        meetings: relayStylePagination([
          "meetingType",
          "meetingStatus",
          "scheduledStartAfter",
          "scheduledStartBefore",
        ]),
        visitors: relayStylePagination(["type"]),
        pendingMessages: {
          merge: true,
        },
      },
    },
    Visitor: {
      fields: {
        meetings: relayStylePagination([
          "meetingType",
          "meetingStatus",
          "scheduledStartAfter",
          "scheduledStartBefore",
        ]),
      },
    },
  },
};
const cache = new InMemoryCache(cacheConfiguration);

export const client = new ApolloClient({
  connectToDevTools: import.meta.env.DEV,
  link: ApolloLink.from([httpLink]),
  cache,
});

const getAuthenticatedClient = (logout: Logout) =>
  new ApolloClient({
    connectToDevTools: import.meta.env.DEV,
    link: ApolloLink.from([errorLink(logout), authLink, httpLink]),
    cache,
  });

export default getAuthenticatedClient;

/**
 * appendItem will return a cache modifier that adds an object to the end
 * of a list.
 */
export function appendItem<T extends { id: string }>(
  object: T
): Modifier<Reference[]> {
  return (existing, { toReference, readField }) => {
    const objectRef = toReference(object, true);
    if (!objectRef)
      throw new Error(
        "Attempted to insert an object with a faulty or missing id"
      );
    return [
      ...existing.filter((e) => object.id !== readField("id", e)),
      objectRef,
    ];
  };
}

export function evictItem<T extends StoreObject & { id: string }>(object: T) {
  cache.evict({ id: cache.identify(object) });
}
