import { Box } from "@mui/system";
import React from "react";

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const MessagesListHeader: React.FC<Props> = ({ children }: Props) => {
  return <Box sx={{ backgroundColor: "white" }}>{children}</Box>;
};

export default MessagesListHeader;
