import { useAppNavigate } from "@src/lib/useAppNavigate";
import { Typography } from "antd";

interface Props {
  organization: {
    id: string;
    name: string;
  };
}

const OrganizationCell = ({ organization }: Props) => {
  const navigate = useAppNavigate();
  return (
    <Typography.Link
      onClick={() => navigate(`/organizations/${organization.id}`)}
    >
      {organization.name}
    </Typography.Link>
  );
};

export default OrganizationCell;
