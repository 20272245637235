import { useAuthContext } from "@src/AuthProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function LogoutScreen() {
  const { logout, isLoggedIn } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) return;
    logout().then(() => navigate("/"));
  }, [isLoggedIn, logout, navigate]);

  return <div />;
}
