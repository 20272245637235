import { FacilityFeature, Inmate, MeetingType } from "@src/api/graphql";
import useFacilityFeature from "./useFacilityFeature";

const filterOptions = [
  "all",
  "video",
  "voice",
  "visits",
  "webinars",
  "messages",
] as const;
export type FilterOption = typeof filterOptions[number];
export const FilterLabels: Record<FilterOption, string> = {
  all: "All meetings",
  video: "Video Calls",
  voice: "Voice Calls",
  visits: "In Person Visits",
  webinars: "Webinars",
  messages: "Messages",
};

export const FilterTypes: Record<FilterOption, MeetingType[] | undefined> = {
  all: [
    MeetingType.VideoCall,
    MeetingType.VoiceCall,
    MeetingType.InPersonVisit,
    MeetingType.Webinar,
  ],
  video: [MeetingType.VideoCall],
  voice: [MeetingType.VoiceCall],
  visits: [MeetingType.InPersonVisit],
  webinars: [MeetingType.Webinar],
  messages: undefined,
};

// used to determine what filters should be available on the card
// based on what features are available at the facility
const useAvailableFilters = (inmate?: Pick<Inmate, "__typename">) => {
  const hasVideoCalls = useFacilityFeature(FacilityFeature.VideoCall);
  const hasVoiceCalls = useFacilityFeature(FacilityFeature.VoiceCall);
  const hasVisits = useFacilityFeature(FacilityFeature.InPersonVisit);
  const hasWebinars = useFacilityFeature(FacilityFeature.Webinar);
  const hasMessaging = useFacilityFeature(FacilityFeature.Emessaging);
  return filterOptions.filter((o) => {
    if (o === "all")
      return hasVideoCalls || hasVoiceCalls || hasVisits || hasWebinars;
    if (o === "video") return hasVideoCalls;
    if (o === "voice") return hasVoiceCalls;
    if (o === "visits") return hasVisits;
    if (o === "webinars") return hasWebinars;
    if (o === "messages") return hasMessaging && inmate;
    return o;
  });
};

export default useAvailableFilters;
