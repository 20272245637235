import { IdDocumentType } from "@src/api/graphql";

const LABELS: Record<IdDocumentType, string> = {
  [IdDocumentType.BirthCertificate]: "Birth certificate",
  [IdDocumentType.DriversLicense]: "Driver's license",
  [IdDocumentType.IdCard]: "ID Card",
  [IdDocumentType.Other]: "Other identity document",
  [IdDocumentType.Passport]: "Passport",
  [IdDocumentType.Cnic]: "CNIC",
  [IdDocumentType.Undocumented]: "Undocumented",
};

export default function identityDocumentTypeLabel(type: IdDocumentType) {
  return LABELS[type];
}
