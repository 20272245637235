import { Typography } from "@mui/material";
import { Organization } from "@src/api/graphql";
import organizationTypeLabel from "@src/lib/organizationTypeLabel";
import { addressLastLine, AddressProps } from "./AddressLine";
import AvatarCard from "./AvatarCard";

export type Props = {
  organization: Pick<Organization, "id" | "name" | "type" | "logoImageUrl"> &
    AddressProps;
};

export default function OrganizationCard({ organization }: Props) {
  return (
    <AvatarCard
      to={`/organizations/${organization.id}`}
      name={organization.name}
      avatarUrl={organization.logoImageUrl}
    >
      <Typography component="p" variant="caption" color="text.secondary">
        {organizationTypeLabel(organization.type)}
      </Typography>
      <Typography component="p" variant="caption" color="text.secondary">
        {addressLastLine(organization.address)}
      </Typography>
    </AvatarCard>
  );
}
