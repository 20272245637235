import { IdDocumentType } from "@src/api/graphql";

export enum ImageNumber {
  ImageOne,
  ImageTwo,
  ImageThree,
}

const LABELS: Record<IdDocumentType, Record<ImageNumber, string>> = {
  [IdDocumentType.BirthCertificate]: {
    [ImageNumber.ImageOne]: "Front page",
    [ImageNumber.ImageTwo]: "Back page",
    [ImageNumber.ImageThree]: "Selfie with birth certificate",
  },
  [IdDocumentType.DriversLicense]: {
    [ImageNumber.ImageOne]: "Front of ID",
    [ImageNumber.ImageTwo]: "Back of ID",
    [ImageNumber.ImageThree]: "Selfie with ID",
  },
  [IdDocumentType.Passport]: {
    [ImageNumber.ImageOne]: "Photo page",
    [ImageNumber.ImageTwo]: "Signature page",
    [ImageNumber.ImageThree]: "Selfie with passport",
  },
  [IdDocumentType.IdCard]: {
    [ImageNumber.ImageOne]: "Front of ID",
    [ImageNumber.ImageTwo]: "Back of ID",
    [ImageNumber.ImageThree]: "Selfie with ID",
  },
  [IdDocumentType.Other]: {
    [ImageNumber.ImageOne]: "Front of ID",
    [ImageNumber.ImageTwo]: "Back of ID",
    [ImageNumber.ImageThree]: "Selfie with ID",
  },
  [IdDocumentType.Cnic]: {
    [ImageNumber.ImageOne]: "Front of CNIC",
    [ImageNumber.ImageTwo]: "Back of CNIC",
    [ImageNumber.ImageThree]: "Selfie with CNIC",
  },
  [IdDocumentType.Undocumented]: {
    [ImageNumber.ImageOne]: "",
    [ImageNumber.ImageTwo]: "",
    [ImageNumber.ImageThree]: "",
  },
};

export default function imageLabelForDocumentType(
  type: IdDocumentType,
  imageNumber: ImageNumber
) {
  return LABELS[type][imageNumber];
}
