import { Media } from "@ameelio/ui";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { ScreenTitle } from "@src/lib/typography";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  header: string;
  subHeader: string;
  features: {
    icon: React.ReactElement;
    text: string;
  }[];
  learnMoreText?: string;
  learnMoreLink?: string;
};
export default function UnavailableSplashScreen({
  header,
  subHeader,
  features,
  learnMoreText,
  learnMoreLink,
}: Props) {
  const { t } = useTranslation();
  return (
    <Box sx={{ paddingTop: 10, paddingLeft: 10 }}>
      <ScreenTitle skipPageTitle>{header}</ScreenTitle>
      <Typography variant="body1" paddingY={1}>
        {subHeader}{" "}
      </Typography>
      <Stack spacing={1}>
        {features.map(({ icon, text }) => (
          <Media key={text} image={icon}>
            {text}
          </Media>
        ))}
      </Stack>
      {learnMoreText && learnMoreLink && (
        <Typography sx={{ pt: 2 }} variant="subtitle1">
          <Link href={learnMoreLink} target="_blank" rel="noopener">
            {learnMoreText} <OpenInNewIcon fontSize="inherit" />{" "}
          </Link>
        </Typography>
      )}
      <Button
        variant="contained"
        href="mailto:sales@ameelio.org"
        sx={{ mt: 2 }}
      >
        {t("Contact us")}
      </Button>
    </Box>
  );
}
