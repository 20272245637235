import {
  ClickAwayListener,
  Tooltip as BaseMUITooltip,
  TooltipProps as MUITooltipProps,
} from "@mui/material";
import {
  Tooltip as BaseAntTooltip,
  TooltipProps as AntTooltipProps,
} from "antd";
import { useState } from "react";

/**
 * @deprecated prefer Tooltip from @ameelio/ui
 */
export default function Tooltip({
  children,
  supportClickAway = true,
  ...rest
}: MUITooltipProps & { supportClickAway?: boolean }) {
  const [open, setOpen] = useState<boolean>(false);
  const supportsHover = window.matchMedia("(hover: hover)").matches;
  if (supportsHover) {
    return <BaseMUITooltip {...rest}>{children}</BaseMUITooltip>;
  }
  // On devices that don't emit hover events (phones, tablets), we need
  // to enable the click listener and disable the other listeners
  const ClickableTooltip = (
    <BaseMUITooltip
      open={open}
      disableHoverListener
      disableFocusListener
      disableTouchListener
      onClick={() => setOpen(!open)}
      {...rest}
    >
      {children}
    </BaseMUITooltip>
  );

  return supportClickAway ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      {ClickableTooltip}
    </ClickAwayListener>
  ) : (
    ClickableTooltip
  );
}

export function AntTooltip({ children, ...rest }: AntTooltipProps) {
  const supportsHover = window.matchMedia("(hover: hover)").matches;
  return (
    <BaseAntTooltip {...(!supportsHover ? { trigger: "click" } : {})} {...rest}>
      {children}
    </BaseAntTooltip>
  );
}
