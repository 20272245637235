import { Box } from "@mui/material";
import featureEnabled from "@src/lib/featureEnabled";
import Menu from "@src/lib/Menu";
import ResponsiveMenuLayout from "@src/lib/ResponsiveMenuLayout";
import React from "react";
import { MenuTab } from "./routes";

type Props = {
  children: React.ReactNode;
  selectedTab: MenuTab;
};

export default function FacilityLayout({ selectedTab, children }: Props) {
  return featureEnabled("RESPONSIVE_LAYOUT") ? (
    <ResponsiveMenuLayout currentTab={selectedTab}>
      {children}
    </ResponsiveMenuLayout>
  ) : (
    <Box sx={{ display: "flex", minHeight: "100vh", overflow: "auto" }}>
      <Menu selectedTab={selectedTab} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#f0f2f5",
          maxHeight: "100%",
          minWidth: "900px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
