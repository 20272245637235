import { partition } from "@ameelio/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  LockOutlined,
  SearchOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import {
  Entitlement,
  FacilityFeature,
  MeetingStatus,
  PrivacyLevel,
} from "@src/api/graphql";
import errorReporter from "@src/lib/errorReporter";
import Header from "@src/lib/Header";
import InmateCell from "@src/lib/InmateCell";
import NotAllowed from "@src/lib/NotAllowed";
import NotEnabled from "@src/lib/NotEnabled";
import PageLoader from "@src/lib/PageLoader";
import { searchEachBeginningRegex } from "@src/lib/regex";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { TextInputBase } from "@src/lib/TextInput";
import { formatDate, formatTimeRange } from "@src/lib/Time";
import Tooltip from "@src/lib/Tooltip";
import useEntitlement from "@src/lib/useEntitlement";
import useFacilityFeature from "@src/lib/useFacilityFeature";
import useMountedPolling from "@src/lib/useMountedPolling";
import VisitorList from "@src/lib/VisitorList";
import VisitTypeIcon from "@src/lib/VisitTypeIcon";
import { Layout, Space } from "antd";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import AcceptMeetingButton from "./AcceptMeetingButton";
import DeclineMeetingButton from "./DeclineMeetingButton";
import {
  GetMeetingRequestsDocument,
  GetMeetingRequestsQuery,
} from "./GetMeetingRequests.generated";
import useMeetingFiltersState from "./useMeetingFiltersState";

type Meeting =
  GetMeetingRequestsQuery["facility"]["meetings"]["edges"][0]["node"];

type Props = {
  showProfessional: boolean;
};

const MeetingRequestsPage = ({ showProfessional }: Props) => {
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );

  const { t } = useTranslation();

  const personalTitle = t("Meeting Requests");
  const personalSubtitle = t(
    "Review all meeting requests between residents at your facility and their loved ones on the outside."
  );
  const professionalTitle = t("Professional Video Call Meeting Requests");
  const professionalSubtitle = t(
    "Review all meeting requests between residents at your facility and their professional contacts on the outside"
  );

  const { facility } = useGuaranteedFacilityContext();
  const { data, loading, startPolling, stopPolling } = useQuery(
    GetMeetingRequestsDocument,
    {
      fetchPolicy: "cache-and-network",
      onError: errorReporter,
      variables: { facilityId: facility.id },
    }
  );
  useMountedPolling(30000, startPolling, stopPolling);

  const [filters, setFilters] = useMeetingFiltersState();

  // only show appropriate meetings, and make sure that new meeting
  // requests appear at the end of the list
  const seenMeetingIds = useRef<string[]>([]);
  const meetings = useMemo(() => {
    if (!data) return [];
    const requirements: ((m: Meeting) => boolean)[] = [
      (m) => m.status === MeetingStatus.PendingApproval,
      (m) => {
        if (showProfessional) {
          return m.visitors.some(
            (v) =>
              v.organizationMemberships.length &&
              facility.features.includes(FacilityFeature.Providers)
          );
        } else {
          return m.visitors.every(
            (v) =>
              !v.organizationMemberships.length ||
              !facility.features.includes(FacilityFeature.Providers)
          );
        }
      },
    ];
    if (filters.residentName) {
      const regex = searchEachBeginningRegex(filters.residentName);
      requirements.push((m) => m.inmates.some((i) => regex.test(i.fullName)));
    }

    const meetings = data.facility.meetings.edges
      .map((e) => e.node)
      .reverse()
      .filter((m) => requirements.every((r) => r(m)));

    // sort new requests to the end
    const [seen, unseen] = partition(meetings, (m) =>
      seenMeetingIds.current.includes(m.id)
    );
    return [
      ...seen.sort(
        (a, b) =>
          seenMeetingIds.current.indexOf(a.id) -
          seenMeetingIds.current.indexOf(b.id)
      ),
      ...unseen,
    ];
  }, [data, showProfessional, filters, facility.features]);

  // track seen meetings so we can identify new ones in a future loop
  useEffect(() => {
    seenMeetingIds.current = meetings.map((m) => m.id);
  }, [meetings]);

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: meetings,
    resetOnChange: [filters],
  });

  const hasProviders = useFacilityFeature(FacilityFeature.Providers);
  if (showProfessional && !hasProviders) return <NotEnabled />;

  if (loading) return <PageLoader />;

  return (
    <Layout.Content>
      <Header
        title={showProfessional ? professionalTitle : personalTitle}
        subtitle={showProfessional ? professionalSubtitle : personalSubtitle}
        extra={[
          <TextInputBase
            size="small"
            label={t("Resident name")}
            value={filters.residentName}
            onChange={(e) =>
              setFilters((current) => ({
                ...current,
                residentName: e.target.value,
              }))
            }
            InputProps={{
              endAdornment: <SearchOutlined sx={{ color: "text.secondary" }} />,
            }}
          />,
        ]}
      />
      {!canManageVisitorsAndMeetings ? (
        <NotAllowed />
      ) : (
        <Box p={3}>
          <TableContainer>
            <Table aria-label={t("Meeting requests table")}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Time")}</TableCell>
                  <TableCell>{t("Visitors")}</TableCell>
                  <TableCell>{t("Resident")}</TableCell>
                  {showProfessional && <TableCell>{t("Privacy")}</TableCell>}
                  <TableCell>{t("Kiosk")}</TableCell>
                  {!showProfessional && (
                    <TableCell>{t("Meeting type")}</TableCell>
                  )}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {meetings.length === 0 && <TableNoData colSpan={6} />}
                {meetings.length > 0 &&
                  currentPageData.map((meeting) => {
                    return (
                      <TableRow key={meeting.id}>
                        <TableCell>
                          <Typography variant="body2" component="span">
                            {formatDate(
                              new Date(meeting.interval.startAt),
                              "dateyear"
                            )}
                            <br />
                            {formatTimeRange(
                              meeting.interval.startAt,
                              meeting.interval.endAt
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <VisitorList
                            visitors={meeting.visitors}
                            unregisteredGuests={meeting.unregisteredGuests}
                            combineMinorGuests={
                              data?.facility.province === "Iowa"
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Space direction="vertical">
                            {meeting.inmates.map((inmate) => (
                              <InmateCell key={inmate.id} inmate={inmate} />
                            ))}
                          </Space>
                        </TableCell>
                        {showProfessional && (
                          <TableCell>
                            <>
                              {meeting.privacyLevel === PrivacyLevel.Hidden && (
                                <Tooltip
                                  title={t(
                                    "This video call cannot be monitored by facility staff and no records will be kept"
                                  )}
                                >
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      width: "fit-content",
                                    }}
                                  >
                                    <LockOutlined />{" "}
                                    <span style={{ verticalAlign: "middle" }}>
                                      {t("Confidential")}
                                    </span>
                                  </Box>
                                </Tooltip>
                              )}
                              {meeting.privacyLevel ===
                                PrivacyLevel.Monitored && (
                                <Tooltip
                                  title={t("This video call will be monitored")}
                                >
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      width: "fit-content",
                                    }}
                                  >
                                    <VideoCameraFrontOutlined />{" "}
                                    <span style={{ verticalAlign: "middle" }}>
                                      {t("Monitored")}
                                    </span>
                                  </Box>
                                </Tooltip>
                              )}
                              {meeting.privacyLevel ===
                                PrivacyLevel.UnmonitoredLogged && (
                                <Tooltip
                                  title={t(
                                    "This video call cannot be monitored by facility staff, but some records will be kept"
                                  )}
                                >
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      width: "fit-content",
                                    }}
                                  >
                                    <LockOutlined />{" "}
                                    <span style={{ verticalAlign: "middle" }}>
                                      {t("Unmonitored")}
                                    </span>
                                  </Box>
                                </Tooltip>
                              )}
                            </>
                          </TableCell>
                        )}
                        <TableCell>
                          <span>{meeting.kiosk?.name}</span>
                        </TableCell>
                        {!showProfessional && (
                          <TableCell>
                            <VisitTypeIcon type={meeting.meetingType} />
                          </TableCell>
                        )}
                        <TableCell>
                          <Space>
                            <DeclineMeetingButton meeting={meeting} />
                            <AcceptMeetingButton meeting={meeting} />
                          </Space>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            totalCount={meetings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </Layout.Content>
  );
};

export default MeetingRequestsPage;
