import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Entitlement, FacilityFeature } from "@src/api/graphql";
import { GetSystemFacilitiesDocument } from "@src/graphql/GetSystemFacilities.generated";
import i18n from "@src/i18n/i18nConfig";
import errorReporter from "@src/lib/errorReporter";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import { Card, Layout } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ImportModal from "./ImportRoster";

const facilityFeaturesInfo: Record<
  FacilityFeature,
  { pretty: string; detail: string }
> = {
  [FacilityFeature.Emessaging]: {
    pretty: i18n.t("Messaging"),
    // 2023.03.29 - Tooltip descriptions have been removed in render function below (per request of product)
    // leaving in case we ever want to resurrect
    detail: i18n.t(
      "People who are connected can send text-based messages to each other"
    ),
  },
  [FacilityFeature.InPersonVisit]: {
    pretty: i18n.t("In-person visits"),
    detail: i18n.t("People who are connected can have in-person meetings"),
  },
  [FacilityFeature.ConfidentialInPersonVisit]: {
    pretty: i18n.t("Confidential in-person visits"),
    detail: i18n.t("Lawyers can have confidential in-person meetings"),
  },
  [FacilityFeature.VideoCall]: {
    pretty: i18n.t("Video calls"),
    detail: i18n.t("People who are connected can request video calls"),
  },
  [FacilityFeature.ConfidentialVideoCall]: {
    pretty: i18n.t("Confidential video calls"),
    detail: i18n.t(
      "Lawyers can video chat confidentially with their approved contacts"
    ),
  },
  [FacilityFeature.VoiceCall]: {
    pretty: i18n.t("Voice calls"),
    detail: i18n.t("People who are connected can talk with voice calls"),
  },
  [FacilityFeature.Webinar]: {
    pretty: i18n.t("Webinars"),
    detail: i18n.t("Residents can participate in educational webinars"),
  },
  [FacilityFeature.PhoneCall]: {
    pretty: i18n.t("Phone calls"),
    detail: i18n.t(
      "Residents can make calls to approved phone numbers on the public phone network"
    ),
  },
  [FacilityFeature.Providers]: {
    pretty: i18n.t("Providers"),
    detail: i18n.t(
      "Establish partnerships with service provider organizations"
    ),
  },
};

const { Content } = Layout;

const SystemFacilities = () => {
  const user = useCurrentStaff();
  const canUploadSystemRoster = useEntitlement(Entitlement.UploadSystemRoster);

  const { t } = useTranslation();

  const navigate = useAppNavigate();
  const { selectFacility, system } = useGuaranteedFacilityContext();
  const [showImportModal, setShowImportModal] = useState(false);

  const { loading, data } = useQuery(GetSystemFacilitiesDocument, {
    variables: { systemId: system.id },
    onError: errorReporter,
  });

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: data?.system.facilities || [],
  });

  if (loading || !data) return <PageLoader />;

  return (
    <Content>
      {showImportModal && (
        <ImportModal
          systemId={system.id}
          onClose={() => setShowImportModal(false)}
        />
      )}
      <div>
        <Card
          title={t("All Facilities")}
          extra={[
            <div key="facility-buttons" className="flex gap-x-4">
              {
                <Button
                  variant="contained"
                  disabled={!canUploadSystemRoster}
                  disabledReason={t(
                    "You do not have permission to perform this function."
                  )}
                  onClick={() => setShowImportModal(true)}
                >
                  {t("Import Roster")}
                </Button>
              }
            </div>,
          ]}
        >
          <Box sx={{ overflow: "auto" }}>
            <Table aria-label={t("System facilities table")}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Name")}</TableCell>
                  <TableCell>{t("Public ID")}</TableCell>
                  <TableCell>{t("Enabled Features")}</TableCell>
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.system.facilities.length === 0 && (
                  <TableNoData colSpan={4} />
                )}
                {data?.system.facilities.length > 0 &&
                  currentPageData.map((facility) => {
                    const hasActivePositionAtFacility =
                      user.staffPositions.some(
                        (sp) => sp.facility.id === facility.id
                      );
                    return (
                      <TableRow key={facility.id}>
                        <TableCell>{facility.name}</TableCell>
                        <TableCell>{facility.publicId}</TableCell>
                        <TableCell>
                          {facility.features
                            .slice()
                            .sort((a, b) => a.localeCompare(b))
                            .map((feature) => (
                              // 2023.03.29 - Tooltip descriptions have been removed per request of product
                              <Chip
                                color="grey"
                                key={feature}
                                style={{ margin: 3 }}
                                label={facilityFeaturesInfo[feature]?.pretty}
                              />
                            ))}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            disabledReason={t(
                              "Staff may only view settings at facilities where they have an active position."
                            )}
                            disabled={!hasActivePositionAtFacility}
                            onClick={() => {
                              selectFacility(facility);
                              navigate("/settings/facility");
                            }}
                          >
                            {t("Open Settings")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              totalCount={data.system.facilities.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
            />
          </Box>
        </Card>
      </div>
    </Content>
  );
};
export default SystemFacilities;
