import { ProducerLabel, useConnectCall } from "@ameelio/connect-call-client";
import { Dialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  Cancel,
  CheckCircle,
  CheckCircleOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import useChange from "@react-hook/change";
import { FACILITY_SUPPORT_ADDRESS } from "@src/lib/constants";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StartTestCallDocument } from "./StartTestCall.generated";

const CONNECT_TIMEOUT = 15000;
const ECHO_TIMEOUT = 15000;

type Props = {
  onClose: () => void;
};

type VideoProps = {
  call: {
    id: string;
    token: string;
    url: string;
  };
  onConnect: () => void;
  onEcho: () => void;
};

const TEST_HEIGHT = 50;
const TEST_WIDTH = 50;

const TestCallVideoComponent = ({ call, onConnect, onEcho }: VideoProps) => {
  const user = useCurrentStaff();

  const inputCanvasRef = useRef<HTMLCanvasElement>(null);

  // Use connect call
  const { clientStatus, produceTrack, peers } = useConnectCall({
    call,
    user,
  });

  const peer = peers[0];

  const [alreadyProduced, setAlreadyProduced] = useState(false);

  useChange(clientStatus, () => {
    if (clientStatus === "connected") {
      onConnect();
      if (!alreadyProduced) {
        if (!inputCanvasRef.current) return;
        const canvas = inputCanvasRef.current;
        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        const stream = canvas.captureStream(60);

        const track = stream.getVideoTracks()[0];
        void produceTrack(track, ProducerLabel.video);
        setAlreadyProduced(true);

        // For some reason, captureStream only sends frames on repaint.
        // Repainting with the same image works, so just repaint the canvas
        // every so often to ensure that data gets sent to the server.
        setInterval(() => {
          ctx.fillStyle = "#f00";
          ctx.fillRect(0, 0, canvas.width / 2, canvas.height);
          ctx.fillStyle = "#00f";
          ctx.fillRect(canvas.width / 2, 0, canvas.width / 2, canvas.height);
        }, 500);
      }
    }
  });

  const video = useRef<HTMLVideoElement>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!peer?.consumers.video) return;
    if (!video.current) return;
    if (video.current.srcObject === peer.consumers.video?.stream) return;
    if (!canvasRef.current) return;

    video.current.srcObject = peer.consumers.video?.stream;

    const testCanvas = canvasRef.current;
    const testContext = testCanvas.getContext("2d");

    if (!testContext) return () => undefined;

    let clearedInternally = false;

    const checkInterval = setInterval(() => {
      if (!video.current) return;

      testContext.drawImage(video.current, 0, 0, TEST_WIDTH, TEST_HEIGHT);
      const imageData = testContext.getImageData(
        0,
        0,
        TEST_WIDTH,
        TEST_HEIGHT
      ).data;

      // We'll test to see when our image gets echoed back.
      // We can't test exactly because the image will be lossy-compressed
      // on the way to and from the server, so instead do approximate color checks
      // of a couple pixels.
      if (
        // Test that received image is reddish in the upper-left corner
        imageData[0] > 192 &&
        imageData[1] < 64 &&
        imageData[2] < 64 &&
        imageData[3] === 255 &&
        // And bluish in the upper-right corner
        imageData[4 * (TEST_WIDTH - 2) + 0] < 64 &&
        imageData[4 * (TEST_WIDTH - 2) + 1] < 64 &&
        imageData[4 * (TEST_WIDTH - 2) + 2] > 192 &&
        imageData[4 * (TEST_WIDTH - 2) + 3] === 255
      ) {
        onEcho();
        clearInterval(checkInterval);
        clearedInternally = true;
      }
    }, 500);

    return () => {
      if (!clearedInternally) {
        clearInterval(checkInterval);
      }
    };
  }, [peer, video, onEcho]);

  return (
    <>
      <video
        ref={video}
        autoPlay
        width={`{TEST_HEIGHT}px`}
        height={`{TEST_WIDTH}px`}
        style={{
          width: `{TEST_WIDTH}px`,
          height: `{TEST_HEIGHT}px`,
          display: "none",
        }}
      />
      <canvas
        ref={inputCanvasRef}
        width={TEST_HEIGHT}
        height={TEST_WIDTH}
        style={{ border: "1px solid black", display: "none" }}
      />
      <canvas
        ref={canvasRef}
        width={TEST_HEIGHT}
        height={TEST_WIDTH}
        style={{ border: "1px solid black", display: "none" }}
      />
    </>
  );
};

type SuccessMarkerProps = {
  begin: boolean;
  end: boolean;
  fail: boolean;
};

const SuccessMarker = ({ begin, end, fail }: SuccessMarkerProps) => {
  if (begin && !end && !fail) {
    return <CircularProgress size="24px" />;
  }
  if (end) {
    return (
      <CheckCircle sx={{ color: (theme) => theme.palette.success.main }} />
    );
  }
  if (fail) {
    return <Cancel sx={{ color: (theme) => theme.palette.error.main }} />;
  }
  return <></>;
};

const TestCallModal = ({ onClose }: Props) => {
  const [startTestCall, { data, error }] = useMutation(
    StartTestCallDocument,
    {}
  );

  const { t } = useTranslation();

  const [started, setStarted] = useState(false);
  const [connectSuccess, setConnectSuccess] = useState(false);
  const [echoSuccess, setEchoSuccess] = useState(false);

  const [connectTimeoutExpired, setConnectTimeoutExpired] = useState(false);
  const [echoTimeoutExpired, setEchoTimeoutExpired] = useState(false);

  const [connectTimeout, setConnectTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [echoTimeout, setEchoTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const failed =
    !!error ||
    (echoTimeoutExpired && !echoSuccess) ||
    (connectTimeoutExpired && !connectSuccess);

  function beginEchoTimeout() {
    if (echoTimeout) clearTimeout(echoTimeout);
    setEchoTimeout(
      setTimeout(() => {
        setEchoTimeoutExpired(true);
      }, ECHO_TIMEOUT)
    );
  }

  useChange(data, () => {
    if (data) {
      if (connectTimeout) clearTimeout(connectTimeout);
      setConnectTimeout(
        setTimeout(() => {
          setConnectTimeoutExpired(true);
        }, CONNECT_TIMEOUT)
      );
    }
  });

  function onConnect() {
    setConnectSuccess(true);
    beginEchoTimeout();
  }

  function onEcho() {
    setEchoSuccess(true);
  }

  const description = (
    <Typography variant="body1">
      {t("To check your network accurately, you must:")}
      <List sx={{ listStyleType: "disc", pl: 3 }}>
        <ListItem sx={{ display: "list-item", p: 0 }}>
          {t("Run this test in your facility's calling location(s), and")}
        </ListItem>
        <ListItem sx={{ display: "list-item", p: 0 }}>
          {t("Ensure your device is connected to the network used for calls")}
        </ListItem>
      </List>
    </Typography>
  );

  return (
    <Dialog title={t("Network test")} fullWidth onClose={onClose}>
      <DialogContent>
        {!started && description}
        {started && (
          <>
            <Box
              sx={{
                border: `1px solid rgba(0, 0, 0, 0.12)`,
                borderRadius: (theme) => theme.spacing(1),
                padding: (theme) => theme.spacing(2),
              }}
            >
              {description}
            </Box>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t("Requesting for scheduling server to start call")}
                  </TableCell>
                  <TableCell>
                    <SuccessMarker
                      begin={started}
                      end={!!data}
                      fail={!!error}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Connecting to video call server")}</TableCell>
                  <TableCell>
                    <SuccessMarker
                      begin={!!data}
                      end={connectSuccess}
                      fail={!!error || connectTimeoutExpired}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("Streaming video to & from video call server")}
                  </TableCell>
                  <TableCell>
                    <SuccessMarker
                      begin={connectSuccess}
                      end={echoSuccess}
                      fail={
                        !!error || connectTimeoutExpired || echoTimeoutExpired
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box
              sx={{
                p: 2,
                borderRadius: (theme) => theme.spacing(2),
                background: (theme) =>
                  started && !failed && !connectSuccess
                    ? "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2E47BD"
                    : failed
                    ? "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #DE3535"
                    : "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #12826C",
              }}
            >
              {started && !failed && !connectSuccess ? (
                <>
                  <InfoOutlined color="primary" />{" "}
                  {t(
                    "Running network test. Do not close this modal while running."
                  )}
                </>
              ) : failed ? (
                <Box display="flex" alignItems="center">
                  <Stack
                    width={0.7}
                    alignItems="center"
                    spacing={1}
                    direction="row"
                  >
                    <InfoOutlined color="error" />
                    <Typography variant="body2">
                      {t(
                        "Network test failed. For support, please email {{supportAddress}} with a screenshot.",
                        { supportAddress: FACILITY_SUPPORT_ADDRESS }
                      )}
                    </Typography>
                  </Stack>
                  <Box width={0.3}>
                    <Button href="mailto:connect-support@ameelio.org">
                      {t("Contact support")}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>
                  <CheckCircleOutlined color="success" />{" "}
                  {t("Network successfully passed all tests.")}
                </>
              )}
            </Box>
          </>
        )}
        {data ? (
          <TestCallVideoComponent
            call={{
              id: data.createTestCall.callId,
              url: data.createTestCall.url,
              token: data.createTestCall.token,
            }}
            onConnect={onConnect}
            onEcho={onEcho}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t("Close")}</Button>
        <Button
          onClick={() => {
            // Reset state
            setStarted(true);
            if (connectTimeout) {
              clearTimeout(connectTimeout);
              setConnectTimeout(null);
            }
            if (echoTimeout) {
              clearTimeout(echoTimeout);
              setConnectTimeout(null);
            }
            setConnectTimeoutExpired(false);
            setEchoTimeoutExpired(false);

            setConnectSuccess(false);
            setEchoSuccess(false);

            // Start test call again
            startTestCall();
          }}
          variant="contained"
        >
          {started ? "Rerun test" : "Run test"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestCallModal;
