import { partition } from "@ameelio/core";
import { useQuery } from "@apollo/client";
import {
  CalendarMonth,
  CalendarMonthOutlined,
  CalendarToday,
  CalendarTodayOutlined,
  Chat,
  ChatOutlined,
  Computer,
  ComputerOutlined,
  Group,
  GroupOutlined,
  History,
  HistoryOutlined,
  ListAlt,
  ListAltOutlined,
  MapsHomeWork,
  MapsHomeWorkOutlined,
  PhoneEnabled,
  PhoneEnabledOutlined,
  PresentToAll,
  PresentToAllOutlined,
  Router,
  RouterOutlined,
  Videocam,
  VideocamOutlined,
} from "@mui/icons-material";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import PresentToAllOutlinedIcon from "@mui/icons-material/PresentToAllOutlined";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import Box from "@mui/material/Box";
import MenuListBase from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import {
  ConnectionStatus,
  Entitlement,
  FacilityFeature,
  MeetingStatus,
  MeetingType,
  SystemUserStatus,
} from "@src/api/graphql";
import { GetBadgeDataDocument } from "@src/graphql/GetBadgeData.generated";
import Dropdown, { DropdownItem, SubmenuItem } from "@src/lib/Dropdown";
import errorReporter from "@src/lib/errorReporter";
import featureEnabled from "@src/lib/featureEnabled";
import hasScheduling from "@src/lib/hasScheduling";
import {
  MenuGroup,
  MenuLink,
  MenuList,
  MenuPaper,
} from "@src/lib/NavigationMenus";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useLiveCalls from "@src/lib/useLiveCalls";
import { MenuTab } from "@src/pages/routes";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "./Avatar";
import useMountedPolling from "./useMountedPolling";

function FacilityAvatar({ publicId }: { publicId: string }) {
  return (
    <Avatar fallback={publicId} showFull style={{ fontSize: "inherit" }} />
  );
}

function SystemMenu({ children }: { children: React.ReactElement }) {
  const navigate = useAppNavigate();
  const { facility: selectedFacility, selectFacility } =
    useGuaranteedFacilityContext();
  const user = useCurrentStaff();

  const { t } = useTranslation();

  return (
    <Dropdown
      key={selectedFacility.id} // causes tooltip to disappear on select
      menu={
        <MenuListBase>
          {user.staffPositions.length > 1 && (
            <SubmenuItem
              label={t("Switch facility")}
              sx={{ maxWidth: { xs: "100%", sm: "600px" } }}
              menu={
                <MenuListBase>
                  {user.staffPositions.map((sp) => (
                    <DropdownItem
                      key={sp.facility.id}
                      onClick={() => selectFacility(sp.facility)}
                      image={<FacilityAvatar publicId={sp.facility.publicId} />}
                      label={sp.facility.name}
                    />
                  ))}
                </MenuListBase>
              }
            />
          )}
          <DropdownItem
            label={t("Manage system")}
            onClick={() => navigate("/system")}
          />
          <DropdownItem
            label={t("My preferences")}
            onClick={() => navigate("/preferences")}
          />
          <DropdownItem
            label={t("Log out")}
            onClick={() => navigate("/logout")}
          />
        </MenuListBase>
      }
      placement="below"
      sx={{ width: "300px" }}
    >
      {children}
    </Dropdown>
  );
}

export default function Menu({
  selectedTab,
  onClick,
}: {
  selectedTab: MenuTab;
  onClick?: () => void;
}) {
  const { facility: selectedFacility } = useGuaranteedFacilityContext();
  const facilityFeatures = selectedFacility.features;

  const user = useCurrentStaff();
  const entitlements = user.staffEntitlements
    .filter((se) => se.isActive)
    .map((se) => se.entitlement);

  const { t } = useTranslation();

  const liveCalls = useLiveCalls({
    facility: selectedFacility,
  });

  const { data, startPolling, stopPolling } = useQuery(GetBadgeDataDocument, {
    fetchPolicy: "cache-and-network",
    onError: errorReporter,
    variables: {
      id: selectedFacility.id,
      systemId: selectedFacility.system.id,
    },
  });
  useMountedPolling(30000, startPolling, stopPolling);

  // calculate badge counts for review menu
  const reviewCounts = useMemo(() => {
    // must match ConnectionRequestsPage component
    const pendingConnections =
      data?.facility.pendingConnectionRequests.filter(
        (c) =>
          c.status === ConnectionStatus.Pending &&
          c.visitor.systemRelationship?.status !== SystemUserStatus.Rejected
      ) || [];
    const [personalConnections, professionalConnections] = partition(
      pendingConnections,
      (c) =>
        !c.organizationMembership ||
        !facilityFeatures.includes(FacilityFeature.Providers)
    );

    const pendingMeetings =
      data?.facility.meetings.edges
        .map((e) => e.node)
        .filter((m) => m.status === MeetingStatus.PendingApproval) || [];
    const [personalMeetings, professionalMeetings] = partition(
      pendingMeetings,
      (m) =>
        !m.visitors.find((v) => v.organizationMemberships.length > 0) ||
        !facilityFeatures.includes(FacilityFeature.Providers)
    );

    return {
      personalConnections: personalConnections.length,
      professionalConnections: professionalConnections.length,
      personalMeetings: personalMeetings.length,
      professionalMeetings: professionalMeetings.length,
      messages: data?.facility.pendingMessages.count || 0,
    };
  }, [data, facilityFeatures]);

  const showNestedSettingMenu = featureEnabled("NESTED_SETTINGS_MENU");
  const useResponsiveMenu = featureEnabled("RESPONSIVE_LAYOUT");
  const canAccessDailySchedule =
    hasScheduling(facilityFeatures) &&
    (selectedFacility.country === "PK" || featureEnabled("DAILY_SCHEDULE"));

  return (
    <MenuPaper>
      <SystemMenu>
        <Box
          m={2}
          mr={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <FacilityAvatar publicId={selectedFacility.publicId} />
          <Box ml={1} sx={{ flex: 1, minWidth: 0 }}>
            <Typography
              variant="h3"
              color="common.white"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              component="p"
            >
              {selectedFacility.name}
            </Typography>
            {/* dark.text.secondary */}
            <Typography variant="body1" color="rgba(255,255,255,0.70)">
              {user.fullName}
            </Typography>
          </Box>
          <KeyboardArrowDown sx={{ color: "common.white" }} fontSize="small" />
        </Box>
      </SystemMenu>

      <MenuList>
        <MenuLink
          icon={<DashboardOutlinedIcon fontSize="small" />}
          selectedIcon={<DashboardIcon fontSize="small" />}
          path="/"
          tab="dashboard"
          selectedTab={selectedTab}
          onClick={onClick}
        >
          {t("Dashboard")}
        </MenuLink>
        <MenuGroup
          icon={<GraphicEqOutlinedIcon fontSize="small" />}
          selectedIcon={<GraphicEqIcon fontSize="small" />}
          label={t("Monitor")}
          containedTabs={[
            "live-video-calls",
            "live-voice-calls",
            "live-webinars",
            "meetings-upcoming",
            "meetings-history",
          ]}
          selectedTab={selectedTab}
          showBelow={useResponsiveMenu}
          badgeCount={liveCalls.length}
        >
          {facilityFeatures.includes(FacilityFeature.VideoCall) && (
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<VideocamOutlinedIcon fontSize="small" />}
              selectedIcon={<VideocamIcon fontSize="small" />}
              path="/visitations"
              tab="live-video-calls"
              selectedTab={selectedTab}
              disabledReason={
                !entitlements.includes(Entitlement.MonitorLiveCalls)
                  ? t("You do not have permission to use this")
                  : undefined
              }
              badgeCount={
                liveCalls.filter((c) => c.meetingType === MeetingType.VideoCall)
                  .length
              }
              onClick={onClick}
            >
              {t("Live video calls")}
            </MenuLink>
          )}
          {facilityFeatures.includes(FacilityFeature.VoiceCall) && (
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<PhoneInTalkOutlinedIcon fontSize="small" />}
              selectedIcon={<PhoneInTalkIcon fontSize="small" />}
              path="/live-voice-calls"
              tab="live-voice-calls"
              selectedTab={selectedTab}
              disabledReason={
                !entitlements.includes(Entitlement.MonitorLiveCalls)
                  ? t("You do not have permission to use this")
                  : undefined
              }
              badgeCount={
                liveCalls.filter((c) => c.meetingType === MeetingType.VoiceCall)
                  .length
              }
              onClick={onClick}
            >
              {t("Live voice calls")}
            </MenuLink>
          )}
          {facilityFeatures.includes(FacilityFeature.Webinar) && (
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<PresentToAllOutlinedIcon fontSize="small" />}
              selectedIcon={<PresentToAllIcon fontSize="small" />}
              path="/live/webinars"
              tab="live-webinars"
              selectedTab={selectedTab}
              disabledReason={
                !entitlements.includes(Entitlement.MonitorLiveCalls)
                  ? t("You do not have permission to use this")
                  : undefined
              }
              badgeCount={
                liveCalls.filter((c) => c.meetingType === MeetingType.Webinar)
                  .length
              }
              onClick={onClick}
            >
              {t("Education webinars")}
            </MenuLink>
          )}
          {hasScheduling(facilityFeatures) && (
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<CalendarMonthOutlined fontSize="small" />}
              selectedIcon={<CalendarMonth fontSize="small" />}
              path="/meetings/upcoming"
              tab="meetings-upcoming"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Upcoming meetings")}
            </MenuLink>
          )}
          {canAccessDailySchedule && (
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<CalendarTodayOutlined fontSize="small" />}
              selectedIcon={<CalendarToday fontSize="small" />}
              path="/meetings/daily"
              tab="meetings-daily"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Daily schedule")}
            </MenuLink>
          )}
          <MenuLink
            submenu={useResponsiveMenu}
            icon={<HistoryOutlined fontSize="small" />}
            selectedIcon={<History fontSize="small" />}
            path="/meetings/history"
            tab="meetings-history"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("History")}
          </MenuLink>
        </MenuGroup>
        <MenuGroup
          icon={<FactCheckOutlinedIcon fontSize="small" />}
          selectedIcon={<FactCheckIcon fontSize="small" />}
          label={t("Review")}
          containedTabs={[
            "visitor-meeting-requests",
            "visitor-connection-requests",
            "visitor-pending-messages",
            "professional-meeting-requests",
            "professional-connection-requests",
          ]}
          selectedTab={selectedTab}
          badgeCount={Object.values(reviewCounts).reduce((a, b) => a + b, 0)}
          showBelow={useResponsiveMenu}
        >
          <MenuLink
            submenu={useResponsiveMenu}
            icon={<AddReactionOutlinedIcon fontSize="small" />}
            selectedIcon={<AddReactionIcon fontSize="small" />}
            path="/connection-requests/personal"
            tab="visitor-connection-requests"
            selectedTab={selectedTab}
            disabledReason={
              !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                ? t("You do not have permission to use this")
                : undefined
            }
            badgeCount={reviewCounts.personalConnections}
            onClick={onClick}
          >
            {t("Visitor contacts")}
          </MenuLink>
          {(facilityFeatures.includes(FacilityFeature.VideoCall) ||
            facilityFeatures.includes(FacilityFeature.InPersonVisit)) && (
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<EventAvailableOutlinedIcon fontSize="small" />}
              selectedIcon={<EventAvailableIcon fontSize="small" />}
              path="/meeting-requests/personal"
              tab="visitor-meeting-requests"
              selectedTab={selectedTab}
              disabledReason={
                !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                  ? t("You do not have permission to use this")
                  : undefined
              }
              badgeCount={reviewCounts.personalMeetings}
              onClick={onClick}
            >
              {t("Visitor meetings")}
            </MenuLink>
          )}
          {facilityFeatures.includes(FacilityFeature.Emessaging) && (
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<MarkChatReadOutlinedIcon fontSize="small" />}
              selectedIcon={<MarkChatReadIcon fontSize="small" />}
              path="/message-review"
              tab="visitor-pending-messages"
              selectedTab={selectedTab}
              disabledReason={
                !entitlements.includes(Entitlement.ReviewEmessages)
                  ? t("You do not have permission to use this")
                  : undefined
              }
              badgeCount={reviewCounts.messages}
              onClick={onClick}
            >
              {t("Pending messages")}
            </MenuLink>
          )}
          {facilityFeatures.includes(FacilityFeature.ConfidentialVideoCall) && (
            <Box>
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<PersonAddAltOutlinedIcon fontSize="small" />}
                selectedIcon={<PersonAddAlt1Icon fontSize="small" />}
                path="/connection-requests/professional"
                tab="professional-connection-requests"
                selectedTab={selectedTab}
                disabledReason={
                  !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                    ? t("You do not have permission to use this")
                    : undefined
                }
                badgeCount={reviewCounts.professionalConnections}
                onClick={onClick}
              >
                {t("Professional contacts")}
              </MenuLink>
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<EventAvailableOutlinedIcon fontSize="small" />}
                selectedIcon={<EventAvailableIcon fontSize="small" />}
                path="/meeting-requests/professional"
                tab="professional-meeting-requests"
                selectedTab={selectedTab}
                disabledReason={
                  !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                    ? t("You do not have permission to use this")
                    : undefined
                }
                badgeCount={reviewCounts.professionalMeetings}
                onClick={onClick}
              >
                {t("Professional meetings")}
              </MenuLink>
            </Box>
          )}
        </MenuGroup>
        <MenuGroup
          icon={<ListAltIcon fontSize="small" />}
          selectedIcon={<ListAltIcon fontSize="small" />}
          label={t("Directory")}
          containedTabs={["inmates", "visitors", "professional-partners"]}
          selectedTab={selectedTab}
          showBelow={useResponsiveMenu}
        >
          <MenuLink
            submenu={useResponsiveMenu}
            icon={<PersonOutlineOutlinedIcon fontSize="small" />}
            selectedIcon={<PersonIcon fontSize="small" />}
            path="/inmates"
            tab="inmates"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("Residents")}
          </MenuLink>
          <MenuLink
            submenu={useResponsiveMenu}
            icon={<SentimentSatisfiedAltIcon fontSize="small" />}
            selectedIcon={<SentimentSatisfiedAltIcon fontSize="small" />}
            path="/visitors"
            tab="visitors"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("Visitors")}
          </MenuLink>
          {facilityFeatures.includes(FacilityFeature.Providers) && (
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<BusinessIcon fontSize="small" />}
              selectedIcon={<BusinessIcon fontSize="small" />}
              path="/organizations"
              tab="professional-partners"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Organizations")}
            </MenuLink>
          )}
        </MenuGroup>
        {showNestedSettingMenu && (
          <MenuGroup
            icon={<SettingsOutlinedIcon fontSize="small" />}
            selectedIcon={<SettingsIcon fontSize="small" />}
            label={t("Facility settings")}
            containedTabs={[
              "settings-facility",
              "settings-kiosks",
              "settings-phone-calls",
              "settings-network",
              "settings-confidential-in-person-visits",
              "settings-in-person-visits",
              "settings-video-calls",
              "settings-confidential-video-calls",
              "settings-webinars",
              "settings-voice-calls",
              "settings-messages",
            ]}
            selectedTab={selectedTab}
            showBelow={useResponsiveMenu}
          >
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<MapsHomeWorkOutlined fontSize="small" />}
              selectedIcon={<MapsHomeWork fontSize="small" />}
              path="/settings/facility"
              tab="settings-facility"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Facility")}
            </MenuLink>
            <MenuLink
              submenu={useResponsiveMenu}
              icon={<ComputerOutlined fontSize="small" />}
              selectedIcon={<Computer fontSize="small" />}
              path="/settings/kiosks"
              tab="settings-kiosks"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Kiosks")}
            </MenuLink>
            {selectedFacility.features.includes(FacilityFeature.PhoneCall) && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<ListAltOutlined fontSize="small" />}
                selectedIcon={<ListAlt fontSize="small" />}
                path="/settings/phone-calls"
                tab="settings-phone-calls"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("Outside services")}
              </MenuLink>
            )}
            {featureEnabled("TEST_CALLS") && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<RouterOutlined fontSize="small" />}
                selectedIcon={<Router fontSize="small" />}
                path="/settings/network"
                tab="settings-network"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("Network")}
              </MenuLink>
            )}
            {selectedFacility.features.includes(
              FacilityFeature.InPersonVisit
            ) && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<GroupOutlined fontSize="small" />}
                selectedIcon={<Group fontSize="small" />}
                path="/settings/in-person-visits"
                tab="settings-in-person-visits"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("In-person visits")}
              </MenuLink>
            )}
            {selectedFacility.features.includes(
              FacilityFeature.ConfidentialInPersonVisit
            ) && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<GroupOutlined fontSize="small" />}
                selectedIcon={<Group fontSize="small" />}
                path="/settings/confidential-in-person-visits"
                tab="settings-confidential-in-person-visits"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("Confidential in-person visits")}
              </MenuLink>
            )}
            {selectedFacility.features.includes(FacilityFeature.VideoCall) && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<VideocamOutlined fontSize="small" />}
                selectedIcon={<Videocam fontSize="small" />}
                path="/settings/video-calls"
                tab="settings-video-calls"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("Video calls")}
              </MenuLink>
            )}
            {selectedFacility.features.includes(
              FacilityFeature.ConfidentialVideoCall
            ) && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<VideocamOutlined fontSize="small" />}
                selectedIcon={<Videocam fontSize="small" />}
                path="/settings/confidential-video-calls"
                tab="settings-confidential-video-calls"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("Confidential calls")}
              </MenuLink>
            )}
            {selectedFacility.features.includes(FacilityFeature.Webinar) && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<PresentToAllOutlined fontSize="small" />}
                selectedIcon={<PresentToAll fontSize="small" />}
                path="/settings/webinars"
                tab="settings-webinars"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("Webinars")}
              </MenuLink>
            )}
            {selectedFacility.features.includes(FacilityFeature.VoiceCall) && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<PhoneEnabledOutlined fontSize="small" />}
                selectedIcon={<PhoneEnabled fontSize="small" />}
                path="/settings/voice-calls"
                tab="settings-voice-calls"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("Voice calls")}
              </MenuLink>
            )}
            {selectedFacility.features.includes(FacilityFeature.Emessaging) && (
              <MenuLink
                submenu={useResponsiveMenu}
                icon={<ChatOutlined fontSize="small" />}
                selectedIcon={<Chat fontSize="small" />}
                path="/settings/messages"
                tab="settings-messages"
                selectedTab={selectedTab}
                onClick={onClick}
              >
                {t("Messages")}
              </MenuLink>
            )}
          </MenuGroup>
        )}
        {!showNestedSettingMenu && (
          <MenuLink
            icon={<SettingsOutlinedIcon fontSize="small" />}
            selectedIcon={<SettingsIcon fontSize="small" />}
            path="/settings/facility"
            tab="settings"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("Facility settings")}
          </MenuLink>
        )}
        <MenuLink
          icon={<HelpOutlineOutlinedIcon fontSize="small" />}
          selectedIcon={<HelpIcon fontSize="small" />}
          tab="help"
          href="https://ameeliofordoc.zendesk.com/hc/en-us"
          rel="noopener noreferrer"
          target="_blank"
          selectedTab={selectedTab}
          onClick={onClick}
        >
          {t("Help")}
        </MenuLink>
      </MenuList>
    </MenuPaper>
  );
}
