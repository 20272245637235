import {
  Button,
  ButtonProps,
  ConfirmDialog,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Meeting } from "@src/api/graphql";
import errorReporter from "@src/lib/errorReporter";
import { TerminateCallDocument } from "@src/pages/LiveCallPage/TerminateMeeting.generated";
import { useTranslation } from "react-i18next";
import useBoolean from "./useBoolean";

type Props = ButtonProps & {
  meeting: Pick<Meeting, "id" | "status">;
};

export default function EndMeetingButton({ meeting, ...rest }: Props) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const endMeetingDialog = useBoolean();

  const [terminateCall, { loading: submitting }] = useMutation(
    TerminateCallDocument,
    {
      onError: errorReporter,
      onCompleted: () => {
        snackbarContext.alert(
          "success",
          t("The meeting has been successfully ended.")
        );
      },
    }
  );

  async function endMeeting() {
    await terminateCall({
      variables: {
        input: {
          meetingId: meeting.id,
          reason: t("Meeting ended early by facility staff."),
        },
      },
    });
  }

  return (
    <>
      {endMeetingDialog.current && (
        <ConfirmDialog
          variant="remove"
          description={t(
            "This will immediately end the meeting for all participants and send notifications."
          )}
          onCancel={endMeetingDialog.disable}
          confirmText={t("Yes, end meeting")}
          onConfirm={async () => {
            // Invoke the callback
            await endMeeting();
            // Then hide the dialog
            endMeetingDialog.disable();
          }}
        />
      )}
      <Button
        color="error"
        variant="outlined"
        disabled={submitting}
        onClick={endMeetingDialog.enable}
        {...rest}
      >
        {t("End meeting")}
      </Button>
    </>
  );
}
