import { Chip, ChipCustomColors } from "@ameelio/ui";
import { MeetingStatus, MeetingType } from "@src/api/graphql";
import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";

interface Props {
  status: MeetingStatus;
  meetingType: MeetingType;
}

const StatusColors: Record<MeetingStatus, ChipCustomColors> = {
  [MeetingStatus.Cancelled]: "grey",
  [MeetingStatus.Ended]: "green",
  [MeetingStatus.Live]: "green",
  [MeetingStatus.NoShow]: "grey",
  [MeetingStatus.PendingApproval]: "orange",
  [MeetingStatus.Rejected]: "grey",
  [MeetingStatus.Scheduled]: "green",
  [MeetingStatus.Terminated]: "red",
};

const MeetingStatusTag = ({ status, meetingType }: Props) => {
  return (
    <Chip
      color={StatusColors[status]}
      label={getMeetingStatusLabelByType(status, meetingType)}
    />
  );
};

export default MeetingStatusTag;
