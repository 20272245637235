import Spinner from "@src/lib/Spinner";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  height: number | string;
}

export default function VideoConnecting({ height }: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <div
      style={{ width: "100%", height }}
      className="bg-gray-900 rounded p-4 flex gap-4 justify-center items-center"
    >
      <p className="text-white text-lg">{t("Connecting to room...")}</p>
      <Spinner />
    </div>
  );
}
