import * as Types from '../../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FacilityApprovedPhoneFragmentFragmentDoc } from '../../../graphql/fragments/FacilityApprovedPhoneFragment.generated';
export type CreateFacilityApprovedPhoneMutationVariables = Types.Exact<{
  input: Types.CreateFacilityApprovedPhoneInput;
}>;


export type CreateFacilityApprovedPhoneMutation = { __typename: 'Mutation', createFacilityApprovedPhone: { __typename: 'CreateFacilityApprovedPhoneResponse', facilityApprovedPhone: { __typename: 'FacilityApprovedPhone', id: string, number: string, name: string, description: string, privacyLevel: Types.PrivacyLevel, active: boolean } } };


export const CreateFacilityApprovedPhoneDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateFacilityApprovedPhone"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateFacilityApprovedPhoneInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createFacilityApprovedPhone"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facilityApprovedPhone"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityApprovedPhoneFragment"}}]}}]}}]}},...FacilityApprovedPhoneFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CreateFacilityApprovedPhoneMutation, CreateFacilityApprovedPhoneMutationVariables>;