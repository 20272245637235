import { range } from "@ameelio/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SelectInput,
  SubmitButton,
} from "@ameelio/ui";
import { Stack, Typography } from "@mui/material";
import { Entitlement } from "@src/api/graphql";
import { pluralize } from "@src/lib";
import SettingField from "@src/lib/SettingField";
import { formatDate } from "@src/lib/Time";
import { addDays } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LightbulbCard from "./LightbulbCard";
import useEntitlement from "./useEntitlement";

type Data = {
  cutoffDays: number;
  windowWeeks: number;
};

type Props = {
  value: Data;
  onChange: (d: Data) => Promise<void>;
};

type DialogProps = Props & {
  onClose: () => Promise<void> | void;
};

function getCurrentSchedulingWindow({ cutoffDays, windowWeeks }: Data) {
  return {
    dateFrom: formatDate(addDays(Date.now(), cutoffDays), "date"),
    dateTo: formatDate(
      addDays(Date.now(), cutoffDays + windowWeeks * 7),
      "date"
    ),
  };
}

function SchedulingWindowEditorDialog({
  value,
  onClose,
  onChange,
}: DialogProps) {
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
  } = useForm<Data>({
    mode: "onChange",
    defaultValues: { ...value },
  });

  const { t } = useTranslation();

  const cutoff = watch("cutoffDays");
  const window = watch("windowWeeks");

  return (
    <Dialog title={t("Scheduling window")} fullWidth onClose={onClose}>
      <form
        onSubmit={handleSubmit(async (data) => {
          await onChange(data);
          onClose();
        })}
      >
        <DialogContent>
          <Stack gap={3} sx={{ pt: 0 }}>
            <Typography variant="body1" color="text.secondary">
              {t("The days visitors can schedule, relative to today.")}
            </Typography>
            <SelectInput
              control={control}
              name="cutoffDays"
              label={t("Start of window")}
              helperText={t(
                "The first day that people can schedule, relative to today."
              )}
              items={range(7).map((i) => ({
                value: i,
                name: pluralize(i, "day"),
              }))}
            />
            <SelectInput
              control={control}
              name="windowWeeks"
              label={t("End of window")}
              helperText={t(
                "The last day that people can schedule, relative to today."
              )}
              items={range(4).map((i) => ({
                value: i + 1,
                name: pluralize(cutoff + (i + 1) * 7, "day"),
              }))}
            />
            <LightbulbCard
              info={t(
                "Today, people can schedule between {{dateFrom}} and {{dateTo}}",
                getCurrentSchedulingWindow({
                  cutoffDays: cutoff,
                  windowWeeks: window,
                })
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={onClose}>
            {t("Cancel")}
          </Button>
          <SubmitButton disabled={!isValid} submitting={isSubmitting}>
            {t("Save")}
          </SubmitButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function SchedulingWindowSettingField({
  value,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const canEdit = useEntitlement(Entitlement.ManageFacility);

  return (
    <SettingField
      label={t("Scheduling window")}
      description={t("The days visitors can schedule, relative to today.")}
      currentDescription={t(
        "Today, people can schedule between {{dateFrom}} and {{dateTo}}",
        getCurrentSchedulingWindow(value)
      )}
      value={
        <>
          {t("{{from}} - {{to}} days away", {
            from: value.cutoffDays,
            to: value.cutoffDays + value.windowWeeks * 7,
          })}
        </>
      }
      canEdit={canEdit}
    >
      {({ onClose }) => (
        <SchedulingWindowEditorDialog
          value={{
            cutoffDays: value.cutoffDays,
            windowWeeks: value.windowWeeks,
          }}
          onChange={onChange}
          onClose={onClose}
        />
      )}
    </SettingField>
  );
}
