import { partition, sortByNumbers } from "@ameelio/core";
import {
  Button,
  Dialog,
  MultiSelectChipInputBase,
  SelectInputBase,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, CircularProgress, SelectChangeEvent } from "@mui/material";
import {
  Entitlement,
  FacilityFeature,
  MeetingStatus,
  MeetingType,
} from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import { alphabetize } from "@src/lib/Common";
import DateRangePicker from "@src/lib/DateRangePicker";
import errorReporter from "@src/lib/errorReporter";
import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import featureForMeetingType, { meetingTypeTitle } from "@src/lib/meeting";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { downloadCSV } from "@src/lib/toCSV";
import useEntitlement from "@src/lib/useEntitlement";
import { useFetchAllPages } from "@src/lib/useFetchAllPages";
import { Layout } from "antd";
import { endOfDay, endOfYesterday } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../lib/Header";
import ScheduledMeetingsTable from "../UpcomingMeetingsPage/ScheduledMeetingsTable";
import { exportDateRange, writeMeeting, writePhoneCall } from "./export";
import { GetFacilityPhoneCallsDocument } from "./GetFacilityPhoneCalls.generated";
import { GetMeetingsHistoryDocument } from "./GetMeetingsHistory.generated";

type Filters = {
  scheduledStartAfter: number | undefined;
  scheduledStartBefore: number;
  meetingStatus: MeetingStatus[];
  meetingType: MeetingType | "PHONE_CALLS";
};

const LIMIT = 100;

const scheduledMeetingStatuses = [
  MeetingStatus.Rejected,
  MeetingStatus.Cancelled,
  MeetingStatus.Ended,
  MeetingStatus.NoShow,
  MeetingStatus.Terminated,
];
const unscheduledMeetingStatuses = [
  MeetingStatus.Ended,
  MeetingStatus.NoShow,
  MeetingStatus.Terminated,
];
const meetingStatusFilters: Record<MeetingType, MeetingStatus[]> = {
  [MeetingType.InPersonVisit]: scheduledMeetingStatuses,
  [MeetingType.VideoCall]: scheduledMeetingStatuses,
  [MeetingType.VoiceCall]: unscheduledMeetingStatuses,
  [MeetingType.Webinar]: scheduledMeetingStatuses,
};

function featureForMeetingTypeFilter(
  meetingType: Filters["meetingType"]
): FacilityFeature {
  return meetingType === "PHONE_CALLS"
    ? FacilityFeature.PhoneCall
    : featureForMeetingType(meetingType);
}

const { history } = window;

export default function MeetingsHistoryPage() {
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();
  const canReviewRecordings = useEntitlement(Entitlement.ReviewRecordings);

  const [enabledMeetingTypes, disabledMeetingTypes] = partition(
    [...Object.values(MeetingType), "PHONE_CALLS"] as const,
    (mt) => facility.features.includes(featureForMeetingTypeFilter(mt))
  );
  const sortedMeetingTypes = [
    ...alphabetize(enabledMeetingTypes),
    ...alphabetize(disabledMeetingTypes),
  ];

  // search filters are restored from page state for back navigation
  const [filters, setFilters] = useState<Filters>({
    meetingStatus:
      isObjectWithKey(history.state, "meetingStatus") &&
      Array.isArray(history.state.meetingStatus)
        ? history.state.meetingStatus
        : [],
    scheduledStartAfter:
      isObjectWithKey(history.state, "scheduledStartAfter") &&
      typeof history.state.scheduledStartAfter === "number"
        ? history.state.scheduledStartAfter
        : undefined,
    scheduledStartBefore:
      isObjectWithKey(history.state, "scheduledStartBefore") &&
      typeof history.state.scheduledStartBefore === "number"
        ? history.state.scheduledStartBefore
        : endOfYesterday().getTime(),
    meetingType:
      isObjectWithKey(history.state, "meetingType") &&
      typeof history.state.meetingType === "string"
        ? (history.state.meetingType as Filters["meetingType"])
        : sortedMeetingTypes[0],
  });

  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  const {
    loading: loadingMeetings,
    data: meetingData,
    refetch: refetchMeetings,
  } = useQuery(GetMeetingsHistoryDocument, {
    skip: filters.meetingType === "PHONE_CALLS",
    variables: {
      cursor: undefined,
      id: facility.id,
      last: LIMIT,
      wantRecordings: canReviewRecordings,
      ...filters,
      // we coerce here because the incompatible meeting type is
      // skipped above and typescript doesn't know
      meetingType: filters.meetingType as MeetingType,
    },
    onError: errorReporter,
  });
  const meetings = meetingData
    ? sortByNumbers(
        meetingData.facility.meetings.edges,
        (m) => m.node.interval.startAt * -1
      ).map((e) => e.node)
    : [];

  const {
    loading: loadingPhoneCalls,
    data: phoneData,
    refetch: refetchPhoneCalls,
  } = useQuery(GetFacilityPhoneCallsDocument, {
    skip: filters.meetingType !== "PHONE_CALLS",
    variables: {
      cursor: undefined,
      id: facility.id,
      last: LIMIT,
      scheduledStartAfter: filters.scheduledStartAfter,
      scheduledStartBefore: filters.scheduledStartBefore,
    },
    onError: errorReporter,
  });
  const phoneCalls = phoneData
    ? sortByNumbers(
        phoneData.facility.phoneCalls.edges,
        (m) => m.node.start * -1
      ).map((e) => ({
        ...e.node,
        interval: { startAt: e.node.start },
        inmates: [e.node.inmate],
      }))
    : [];

  const changeFilter = (newFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
  };

  useEffect(() => {
    const { meetingType, ...rest } = filters;
    if (meetingType === "PHONE_CALLS") refetchPhoneCalls({ ...rest });
    else refetchMeetings({ ...rest, meetingType });
  }, [refetchMeetings, refetchPhoneCalls, filters]);

  const exportName = () =>
    `${facility.name.replace(" ", "_")}_${
      filters.meetingType
    }_${filters.meetingStatus.join("_")}_${exportDateRange(
      filters.scheduledStartAfter,
      filters.scheduledStartBefore
    )}`;

  const { working: fetchingMeetings, fetchAllPages: exportMeetings } =
    useFetchAllPages({
      query: GetMeetingsHistoryDocument,
      variables: {
        cursor: undefined,
        id: facility.id,
        last: LIMIT,
        wantRecordings: false,
        ...filters,
        // we coerce here because the incompatible meeting type is
        // skipped above and typescript doesn't know
        meetingType: filters.meetingType as MeetingType,
      },
      getQueryData: (result) => result.facility.meetings,
      onComplete: (meetings) =>
        downloadCSV(
          sortByNumbers(meetings, (m) => m.interval.startAt)
            .reverse()
            .map(writeMeeting),
          exportName()
        ),
    });

  const { working: fetchingPhoneCalls, fetchAllPages: exportPhoneCalls } =
    useFetchAllPages({
      query: GetFacilityPhoneCallsDocument,
      variables: {
        cursor: undefined,
        id: facility.id,
        last: LIMIT,
        scheduledStartAfter: filters.scheduledStartAfter,
        scheduledStartBefore: filters.scheduledStartBefore,
      },
      getQueryData: (r) => r.facility.phoneCalls,
      onComplete: (calls) =>
        downloadCSV(
          sortByNumbers(calls, (c) => c.start)
            .reverse()
            .map(writePhoneCall),
          exportName()
        ),
    });

  const loading = loadingMeetings || loadingPhoneCalls;

  return (
    <Layout.Content>
      <Header
        title={t("History")}
        subtitle={t(
          "Review the history of visits, video calls, voice calls, and more. You can search by changing criteria in the fields below."
        )}
      >
        <Box
          display="flex"
          width="100%"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
        >
          <Box flex={1} sx={{ mb: { xs: 2, md: 0 } }}>
            <ResponsiveColumns>
              <SelectInputBase
                size="small"
                disabled={loading}
                aria-label={t("Filter by meeting type")}
                style={{ width: "auto" }}
                placeholder={t("Filter by meeting type")}
                label={t("Meeting type")}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  changeFilter({
                    meetingType: event.target.value as MeetingType,
                    meetingStatus: [],
                  });
                }}
                value={filters.meetingType}
                items={sortedMeetingTypes.map((mt) => ({
                  value: mt,
                  key: mt,
                  name:
                    mt === "PHONE_CALLS"
                      ? t("Outside services")
                      : meetingTypeTitle([mt]),
                  disabled: !facility.features.includes(
                    featureForMeetingTypeFilter(mt)
                  ),
                }))}
              />
              {filters.meetingType !== "PHONE_CALLS" && (
                <MultiSelectChipInputBase
                  size="small"
                  disabled={loading}
                  label={t("Status")}
                  aria-label={t("Filter by status")}
                  value={filters.meetingStatus}
                  onChange={(event) => {
                    setFilters((filters) => ({
                      ...filters,
                      meetingStatus: event.target.value as MeetingStatus[],
                    }));
                  }}
                  items={meetingStatusFilters[filters.meetingType].map(
                    (status) => ({
                      value: status,
                      key: status,
                      name: getMeetingStatusLabelByType(
                        status,
                        filters.meetingType as MeetingType
                      ),
                    })
                  )}
                />
              )}
              <DateRangePicker
                onStartDateChange={(date) => {
                  changeFilter({
                    scheduledStartAfter: date || undefined,
                  });
                }}
                onEndDateChange={(date) => {
                  const newDate = date ? endOfDay(date) : new Date();
                  changeFilter({
                    scheduledStartBefore: newDate.getTime(),
                  });
                }}
                disabled={loading}
                startDate={filters.scheduledStartAfter || null}
                endDate={filters.scheduledStartBefore || null}
              />
            </ResponsiveColumns>
          </Box>
          <Button
            key="filter-meeting-export"
            variant="outlined"
            disabled={
              loading ||
              (filters.meetingType === "PHONE_CALLS" &&
                phoneCalls.length === 0) ||
              (filters.meetingType !== "PHONE_CALLS" && meetings.length === 0)
            }
            onClick={async () =>
              filters.meetingType === "PHONE_CALLS"
                ? await exportPhoneCalls()
                : await exportMeetings()
            }
            sx={{ ml: 2 }}
          >
            {t("Export data")}
          </Button>
        </Box>
      </Header>
      <Box p={3}>
        <ScheduledMeetingsTable
          loading={loading}
          meetings={
            filters.meetingType === "PHONE_CALLS" ? phoneCalls : meetings
          }
        />
        {(meetingData?.facility.meetings.pageInfo.hasPreviousPage ||
          phoneData?.facility.phoneCalls.pageInfo.hasPreviousPage) && (
          <Alert severity="warning">
            {t(
              "Only the most recent {{limit}} results are shown. Please refine your search or export to view all results.",
              { limit: LIMIT }
            )}
          </Alert>
        )}
        {(fetchingMeetings || fetchingPhoneCalls) && (
          <Dialog
            title={t("Export in progress...")}
            style={{ padding: "16px" }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={2}
            >
              <CircularProgress />
            </Box>
          </Dialog>
        )}
      </Box>
    </Layout.Content>
  );
}
