import { lightPalette } from "@ameelio/ui";
import { CheckCircleFilled } from "@ant-design/icons";
import { Result as Base, ResultProps } from "antd";

const Result = (props: ResultProps) => {
  const icon =
    props.status === "success" ? (
      <CheckCircleFilled style={{ color: lightPalette.info.main }} />
    ) : undefined;
  return <Base icon={icon} {...props} />;
};

export default Result;
