import { notMissing } from "@ameelio/core";
import { Inmate, Visitor } from "@src/api/graphql";
import { Message } from "@src/lib/Message";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";
import ConnectionListItem from "./ConnectionListItem";

export type TConnectionListItem = {
  id: string;
  inmate: Pick<Inmate, "id" | "fullName">;
  visitor: Pick<Visitor, "id" | "fullName">;
};

export type ConnectionFlaggedMessagesMap = Partial<Record<string, Message[]>>;

interface Props {
  flaggedMessageMap: ConnectionFlaggedMessagesMap;
  selectedConnection: TConnectionListItem | undefined;
  onSelectConnection: (connection: TConnectionListItem) => void;
  className?: string;
}

const ConnectionList = ({
  flaggedMessageMap,
  onSelectConnection,
  selectedConnection,
}: Props) => {
  // type narrowing tomfoolery
  const messageGroups = Object.values(flaggedMessageMap).filter(notMissing);
  const { t } = useTranslation();

  if (!messageGroups.length) {
    return (
      <div className="flex h-full">
        <Empty
          className="m-auto p-8 text-gray-500"
          description={t(
            "No conversations to review! Conversations appear here when a message containing a keyword requires review."
          )}
        />
      </div>
    );
  }

  return (
    <>
      {messageGroups.map((messages) => {
        const { connection } = messages[0];
        return (
          <ConnectionListItem
            connection={connection}
            isSelected={selectedConnection?.id === connection.id}
            onSelect={() => onSelectConnection(connection)}
            totalFlaggedMessages={messages?.length}
            key={connection.id}
            oldestMessage={messages[0]}
          />
        );
      })}
    </>
  );
};

export default ConnectionList;
