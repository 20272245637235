import { Path } from "@src/pages/routes";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { NavigateOptions } from "react-router-dom";

export function useAppNavigate(): (
  path: Path,
  options?: NavigateOptions
) => void {
  const navigate = useNavigate();
  return useCallback(
    (path: Path, options?: NavigateOptions) => navigate(path, options),
    [navigate]
  );
}
