import { Button, Media } from "@ameelio/ui";
import { Box, Card, Stack, Typography } from "@mui/material";
import { MeetingType } from "@src/api/graphql";
import { getFullNameWithId } from "@src/lib/Common";
import EmptyPage from "@src/lib/EmptyPage";
import Header from "@src/lib/Header";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useLiveCalls from "@src/lib/useLiveCalls";
import { WRAPPER_STYLE } from "@src/styles/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function LiveWebinarsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const webinars = useLiveCalls({
    facility,
    meetingTypes: [MeetingType.Webinar],
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        pb: 3,
      }}
    >
      <Header
        title={`${t("Live Education webinars")} (${webinars.length})`}
        subtitle={t(
          "Monitor, send alerts and terminate calls if needed. All in real-time."
        )}
      />
      {webinars.length === 0 ? (
        <EmptyPage
          description={t("There currently aren't any live education webinars.")}
        />
      ) : (
        <div style={WRAPPER_STYLE}>
          <Card variant="outlined" sx={{ px: 2, py: 1.5 }}>
            <Stack spacing={2}>
              {webinars.map((m) => {
                const { organization } =
                  m.visitors[0].organizationMemberships[0];
                return (
                  <Card
                    key={m.id}
                    variant="outlined"
                    sx={{ px: 2, py: 1.5, cursor: "pointer" }}
                    onClick={() => navigate(`/live/webinars/${m.id}`)}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Media
                        image={
                          <img
                            src={organization.logoImageUrl}
                            alt={`Logo for ${organization.name}`}
                            style={{
                              maxWidth: 40,
                              maxHeight: 40,
                              objectFit: "contain",
                            }}
                          />
                        }
                      >
                        <Typography variant="caption" color="text.secondary">
                          {organization.name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.primary">
                          {m.title}
                        </Typography>
                      </Media>

                      <Button
                        onClick={() => navigate(`/live/webinars/${m.id}`)}
                        variant="text"
                        color="primary"
                        size="small"
                      >
                        {t("Monitor")}
                      </Button>
                    </Box>

                    <Box py={1.5}>
                      <Typography variant="overline" color="text.secondary">
                        {t("HOST(S)")}
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {m.visitors.map((v) => v.fullName).join(" · ")}
                      </Typography>
                    </Box>

                    <Typography variant="overline" color="text.secondary">
                      {t("RESIDENTS")}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      {m.inmates.map((i) => getFullNameWithId(i)).join(" · ")}
                    </Typography>
                  </Card>
                );
              })}
            </Stack>
          </Card>
        </div>
      )}
    </Box>
  );
}
