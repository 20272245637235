import { useMutation } from "@apollo/client";
import { Box, Card } from "@mui/material";
import { UpdateStaffPreferencesInput } from "@src/api/graphql";
import errorReporter from "@src/lib/errorReporter";
import Header from "@src/lib/Header";
import { BooleanSettingField } from "@src/lib/SettingField";
import { SectionTitle } from "@src/lib/typography";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { UpdateStaffPreferencesDocument } from "./UpdateStaffPreferences.generated";

export default function StaffPreferencesPage() {
  const { t } = useTranslation();
  const staff = useCurrentStaff();

  const [update] = useMutation(UpdateStaffPreferencesDocument, {
    onError: errorReporter,
  });

  function updateProperty<K extends keyof UpdateStaffPreferencesInput>(
    k: K,
    v: UpdateStaffPreferencesInput[K]
  ) {
    return update({ variables: { input: { [k]: v } } });
  }

  return (
    <>
      <Header
        title={t("My preferences")}
        subtitle={t("Manage my account preferences and settings")}
      />
      <Card variant="outlined" sx={{ p: 3, m: 3 }}>
        <Box mb={2}>
          <SectionTitle>{t("Email preferences")}</SectionTitle>
          <BooleanSettingField
            label={t("Meeting schedules")}
            description={t("Receive daily emails with meeting schedules")}
            prompt={t(
              "Would you like to receive daily emails with a meeting schedule for the day?"
            )}
            value={staff.receiveDailyScheduleEmails}
            onChange={(value) =>
              updateProperty("receiveDailyScheduleEmails", value)
            }
            canEdit
          />
          <Divider />
          <BooleanSettingField
            label={t("Reminder to approve requests")}
            description={t(
              "Receive daily emails if there are requests waiting for approval"
            )}
            prompt={t(
              "Would you like to receive daily emails if there are requests waiting for approval?"
            )}
            value={staff.receiveTaskReminderEmails}
            onChange={(value) =>
              updateProperty("receiveTaskReminderEmails", value)
            }
            canEdit
          />
        </Box>
      </Card>
    </>
  );
}
