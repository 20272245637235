import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { StaffFragmentFragmentDoc } from '../../graphql/fragments/StaffFragment.generated';
export type GetStaffQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  systemId: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetStaffQuery = { __typename: 'Query', staff: { __typename: 'Staff', id: string, firstName: string, lastName: string, fullName: string, email: string, phone: string | null, staffPositions: Array<{ __typename: 'StaffPosition', id: string, facility: { __typename: 'Facility', id: string, publicId: string } }>, staffEntitlements: Array<{ __typename: 'StaffEntitlement', id: string, entitlement: Types.Entitlement }> } };


export const GetStaffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStaff"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"systemId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"staff"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"StaffFragment"}},{"kind":"Field","name":{"kind":"Name","value":"staffPositions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"systemId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"systemId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"publicId"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"staffEntitlements"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"entitlement"}}]}}]}}]}},...StaffFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetStaffQuery, GetStaffQueryVariables>;