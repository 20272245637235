import { Button } from "@ameelio/ui";
import {
  Close,
  Download,
  Rotate90DegreesCcw,
  Rotate90DegreesCwOutlined,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import { alpha, Backdrop, Box } from "@mui/material";
import { throttle } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  alt: string;
  onDownload?: () => void;
};

function ControlButton({
  onClick,
  children,
}: {
  onClick: () => void;
  children: JSX.Element | JSX.Element[];
}) {
  return (
    <Button
      onClick={() => onClick()}
      sx={(theme) => ({ color: theme.palette.primary.contrastText })}
    >
      {children}
    </Button>
  );
}

const TRANSITION_MS = 200;

enum CURSOR {
  ZOOM_IN = "zoom-in",
  GRABBING = "grabbing",
  GRAB = "grab",
}

const ZOOM_STEP = 1.5;

function rotateCartesian(x: number, y: number, angle: number) {
  const radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * x + sin * y,
    ny = cos * y - sin * x;
  return { x: nx, y: ny };
}

export default function ZoomableImage({
  src,
  alt,
  onDownload,
  ...rest
}: Props) {
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [grabbing, setGrabbing] = useState(false);
  const [translation, setTranslation] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setOpenImageDialog(false);
      }
    };
    document.addEventListener("keyup", handleKey, false);
    return () => {
      document.removeEventListener("keyup", handleKey, false);
    };
  }, []);

  const reset = useCallback(() => {
    setScale(1);
    setRotation(0);
    setGrabbing(false);
    setTranslation({ x: 0, y: 0 });
  }, []);

  const rotateClockwise = useCallback(() => {
    setRotation((v) => v + 90);
  }, []);

  const rotateCounterClockwise = useCallback(() => {
    setRotation((v) => v - 90);
  }, []);

  const zoomIn = useCallback(() => {
    setScale((v) => v * ZOOM_STEP);
  }, []);

  const zoomOut = useCallback(() => {
    setScale((v) => v / ZOOM_STEP);
  }, []);

  const handleWheel = useCallback(
    (event: React.WheelEvent<HTMLImageElement>) => {
      if (event.deltaY < 0) zoomIn();
      if (event.deltaY > 0) zoomOut();
    },
    [zoomIn, zoomOut]
  );

  const throttledHandleWheel = useMemo(
    () => throttle(handleWheel, 200),
    [handleWheel]
  );

  const translateImage = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setTranslation((v) => {
        const rotated = rotateCartesian(
          event.movementX * (1 / scale),
          event.movementY * (1 / scale),
          rotation
        );
        return {
          x: v.x + rotated.x,
          y: v.y + rotated.y,
        };
      });
    },
    [rotation, scale]
  );

  return (
    <>
      <img
        src={src}
        alt={alt}
        {...rest}
        onClick={() => {
          setOpenImageDialog(true);
        }}
        style={{ ...(rest.style || {}), cursor: CURSOR.ZOOM_IN }}
      />
      {openImageDialog && (
        <Backdrop
          open
          sx={(theme) => ({
            zIndex: theme.zIndex.modal,
            cursor: grabbing ? CURSOR.GRABBING : CURSOR.GRAB,
          })}
          onMouseDown={() => setGrabbing(true)}
          onMouseUp={() => setGrabbing(false)}
          onMouseMove={(event) => grabbing && translateImage(event)}
        >
          <Box
            display="flex"
            sx={(theme) => ({
              position: "absolute",
              height: 50,
              bottom: "6%",
              backgroundColor: alpha(theme.palette.common.black, 0.5),
              borderRadius: "9999px", // stadium
              paddingLeft: 1,
              paddingRight: 1,
              zIndex: theme.zIndex.modal + 1,
            })}
            tabIndex={-1}
          >
            <ControlButton onClick={() => rotateClockwise()}>
              <Rotate90DegreesCwOutlined />
            </ControlButton>
            <ControlButton onClick={() => rotateCounterClockwise()}>
              <Rotate90DegreesCcw />
            </ControlButton>
            <ControlButton onClick={() => zoomOut()}>
              <ZoomOut />
            </ControlButton>
            <ControlButton onClick={() => zoomIn()}>
              <ZoomIn />
            </ControlButton>
            {onDownload && (
              <ControlButton onClick={() => onDownload()}>
                <Download />
              </ControlButton>
            )}
            <ControlButton
              onClick={() => {
                setOpenImageDialog(false);
                setTimeout(reset, TRANSITION_MS);
              }}
            >
              <Close />
            </ControlButton>
          </Box>
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            py={10}
            sx={{
              width: 1,
              height: 1,
              transition: `transform ${TRANSITION_MS}ms`,
              transform: `scale(${scale}) rotate(${rotation}deg)`,
            }}
          >
            <img
              src={src}
              alt={alt}
              style={{
                maxHeight: "100%",
                transform: `translate(${translation.x}px, ${translation.y}px)`,
              }}
              draggable="false"
              onWheel={(event) => throttledHandleWheel(event)}
            />
          </Box>
        </Backdrop>
      )}
    </>
  );
}
