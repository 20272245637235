import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateStaffPreferencesMutationVariables = Types.Exact<{
  input: Types.UpdateStaffPreferencesInput;
}>;


export type UpdateStaffPreferencesMutation = { __typename: 'Mutation', updateStaffPreferences: { __typename: 'UpdateStaffPreferencesResponse', staff: { __typename: 'Staff', id: string, receiveDailyScheduleEmails: boolean, receiveTaskReminderEmails: boolean } } };


export const UpdateStaffPreferencesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateStaffPreferences"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateStaffPreferencesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateStaffPreferences"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"staff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"receiveDailyScheduleEmails"}},{"kind":"Field","name":{"kind":"Name","value":"receiveTaskReminderEmails"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateStaffPreferencesMutation, UpdateStaffPreferencesMutationVariables>;