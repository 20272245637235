import { useLazyQuery, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Entitlement, FacilityFeature } from "@src/api/graphql";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import errorReporter from "@src/lib/errorReporter";
import Header from "@src/lib/Header";
import NotAllowed from "@src/lib/NotAllowed";
import PageLoader from "@src/lib/PageLoader";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import { Layout, PageHeader } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetInmateDocument } from "../InmatePage/GetInmate.generated";
import { GetMeetingDetailsDocument } from "../MeetingDetailsPage/GetMeetingDetails.generated";
import AddGuestListStep from "./AddGuestListStep";
import ConfirmStep from "./ConfirmStep";
import normalizeGuestListData from "./normalizeGuestListData";
import SelectEventTypeStep from "./SelectEventTypeStep";
import SelectTimeStep from "./SelectTimeStep";
import { Data, defaultSteps, Step } from "./types";

type Props = {
  inmateId: string;
  isEdit: boolean;
  meetingId?: string;
};

export default function ScheduleEventPage({
  inmateId,
  isEdit,
  meetingId,
}: Props) {
  const { t } = useTranslation();
  const user = useCurrentStaff();

  // workflow control
  const steps = defaultSteps;
  const [step, setStep] = useState<Step>(defaultSteps[0]);
  const [data, setData] = useState<Data>({});

  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );

  const stepBack = useCallback(() => {
    const i = steps.indexOf(step);
    // If on the first step, do nothing
    if (i === 0) return;
    setStep(steps[i - 1]);
  }, [steps, step]);

  const stepForward = useCallback(() => {
    const i = steps.indexOf(step);
    setStep(steps[i + 1]);
  }, [steps, step]);

  const { data: inmateData, error } = useQuery(GetInmateDocument, {
    fetchPolicy: "cache-and-network",
    variables: {
      inmateId,
    },
    onError: errorReporter,
  });

  const [
    getMeetingDetails,
    {
      data: meetingDetailsData,
      error: meetingDetailsError,
      loading: meetingDetailsLoading,
    },
  ] = useLazyQuery(GetMeetingDetailsDocument, {
    fetchPolicy: "cache-and-network",
    onError: errorReporter,
  });

  // If in edit mode, retrieve meeting details data
  useEffect(() => {
    if (isEdit && meetingId) {
      getMeetingDetails({ variables: { id: meetingId } });
    }
  }, [isEdit, meetingId, getMeetingDetails]);

  // If in edit mode, normalize, populate the data and move to the next step
  useEffect(() => {
    if (isEdit && meetingDetailsData && inmateData && !data.meetingType) {
      const meeting = meetingDetailsData.meeting;
      const inmate = inmateData.inmate;
      const normalizedData = normalizeGuestListData(meeting, inmate);
      setData((prevData) => ({ ...prevData, ...normalizedData }));
      stepForward();
    }
  }, [isEdit, meetingDetailsData, inmateData, data.meetingType, stepForward]);

  if (error || meetingDetailsError) throw error;
  if (!inmateData?.inmate || meetingDetailsLoading) return <PageLoader />;

  const { inmate } = inmateData;

  const facilityHasSupportedMeetingTypes =
    inmate.facility.features.includes(FacilityFeature.VideoCall) ||
    inmate.facility.features.includes(FacilityFeature.InPersonVisit) ||
    inmate.facility.features.includes(FacilityFeature.ConfidentialVideoCall) ||
    inmate.facility.features.includes(
      FacilityFeature.ConfidentialInPersonVisit
    );

  if (
    inmate &&
    !user.staffPositions.some(
      (position) => position.facility.id === inmate.facility.id
    )
  ) {
    return (
      <Layout>
        <Header
          title={t("Not permitted")}
          subtitle={t(
            "This resident is at a facility that you do not have access to."
          )}
        />
        <NotAllowed />
      </Layout>
    );
  }

  if (!canManageVisitorsAndMeetings || !facilityHasSupportedMeetingTypes) {
    return (
      <Layout>
        <Header
          title={t("Not permitted")}
          subtitle={
            !canManageVisitorsAndMeetings
              ? t(
                  "You do not have permission to schedule meetings on behalf of this resident."
                )
              : t(
                  "The resident's facility does not offer the type of meetings you can schedule on this page."
                )
          }
        />
        <NotAllowed />
      </Layout>
    );
  }

  const headerTitle = !isEdit ? t("Schedule event") : t("Modify event");

  return (
    <Layout>
      <PageHeader
        ghost={false}
        title={headerTitle}
        breadcrumb={
          <Breadcrumbs
            paths={[
              { path: "/inmates", label: t("Residents") },
              { path: `/inmates/${inmate.id}`, label: inmate.fullName },
              {
                path: `/inmates/${inmate.id}/event/new`,
                label: headerTitle,
              },
            ]}
          />
        }
        onBack={() => window.history.back()}
      />
      <Box p={3}>
        {step === "selectEventType" && (
          <SelectEventTypeStep
            data={data}
            onBack={stepBack}
            onSubmit={(meetingType) => {
              setData({ ...data, meetingType });
              stepForward();
            }}
          />
        )}
        {step === "addGuests" && (
          <AddGuestListStep
            inmate={inmate}
            data={data}
            onBack={stepBack}
            onSubmit={({
              registeredGuests,
              unregisteredGuests,
              confidential,
            }) => {
              setData({
                ...data,
                registeredGuests,
                unregisteredGuests,
                confidential,
              });
              stepForward();
            }}
          />
        )}
        {step === "selectWhen" && (
          <SelectTimeStep
            inmateId={inmate.id}
            meetingId={meetingId}
            data={data}
            onBack={stepBack}
            onSubmit={({ availability, kiosk, customTimeChosen }) => {
              setData({
                ...data,
                availability,
                kiosk,
                customTimeChosen,
              });
              stepForward();
            }}
          />
        )}
        {step === "confirm" && (
          <ConfirmStep
            inmateId={inmate.id}
            data={data}
            meetingId={meetingId}
            onBack={stepBack}
          />
        )}
      </Box>
    </Layout>
  );
}
