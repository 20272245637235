import { SelectInputBase } from "@ameelio/ui";
import { Box, SelectChangeEvent } from "@mui/material";
import { TextInputBase } from "@src/lib/TextInput";
import { useTranslation } from "react-i18next";

interface Props {
  onSelectChange: (value: string) => void;
  onTextChange: (value: string) => void;
  selected: string;
  text: string;
  loading?: boolean;
  filterToLabelMap: Record<string, string>;
  selectStyle?: React.CSSProperties;
  searchStyle?: React.CSSProperties;
}

const SelectableFieldStringFilter = ({
  onSelectChange,
  onTextChange,
  filterToLabelMap,
  selected,
  text,
  selectStyle,
  loading,
  searchStyle,
}: Props) => {
  const { t } = useTranslation();
  const placeholder = t("Search by {{field}}...", {
    field: filterToLabelMap[selected],
  });
  return (
    <Box display="flex" gap={2} width={1}>
      <Box key="filter-1" display="flex" alignItems="flex-end">
        <SelectInputBase
          key="filter-meeting-type"
          disabled={loading}
          size="small"
          aria-label={placeholder}
          style={{ width: "auto", minWidth: 125, ...selectStyle }}
          placeholder={placeholder}
          label={t("Search by...")}
          onChange={(event: SelectChangeEvent<unknown>) => {
            onSelectChange(event.target.value as string);
          }}
          value={selected}
          items={Object.keys(filterToLabelMap).map((key) => ({
            value: key,
            name: filterToLabelMap[key] as string,
          }))}
        />
      </Box>
      <Box key="filter-2" display="flex" alignItems="flex-end">
        <TextInputBase
          size="small"
          label={filterToLabelMap[selected]}
          aria-label={placeholder}
          disabled={loading}
          style={{ ...searchStyle }}
          sx={{
            width: "auto",
            minWidth: { sm: 250 },
          }}
          placeholder={placeholder}
          value={text}
          onChange={(event) => onTextChange(event.target.value)}
        />
      </Box>
    </Box>
  );
};

export default SelectableFieldStringFilter;
